import styled from 'styled-components'
import { BORDER, FLEX, FONT, SHADOW } from '../../../utils'
import { Colors } from '../../../assets'

const BUTTON_BLOCK_WIDTH = 18

export const Container = styled.div`
  width: 270px;
  max-height: 150px;
  height: max-content;

  padding: 15px 25px;

  border-radius: 14px;

  position: absolute;

  z-index: 1;

  top: 140%;
  right: 0;

  background-color: ${Colors.white};

  ${BORDER({})}
  ${SHADOW({})}
  ${FLEX({ direction: 'column' })} 

  visibility: hidden;
  opacity: 0;

  transform: translateY(-30px);

  transition: all 0.3s ease;

  &.visible {
    visibility: visible;
    opacity: 1;

    transform: translateY(0);
  }

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;

    top: -6px;
    right: ${BUTTON_BLOCK_WIDTH}px;

    transform: rotate(45deg);

    background-color: ${Colors.white};

    border-top: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

export const UserWrapper = styled.div`
  width: 100%;

  ${FLEX({ direction: 'column', justify: 'flex-start', align: 'flex-start' })}
`

export const UserName = styled.p`
  ${FONT({ size: '17px', weight: '700' })}
`

export const UserMail = styled.p`
  ${FONT({ size: '16px', color: Colors.gray })}
`
