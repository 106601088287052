import { FC, useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import {
  BackButton,
  ButtonGroup,
  Container,
  Content,
  CreationDate,
  Header,
  InfoLabel,
  InputContainer,
  Title,
  TitleGroup,
} from '../../components/Styled/formStyled'
import { Input, TextArea, Dropdown } from '../../components/FormComponents'
import { Assets } from '../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router'
import {
  getOrderAction,
  getUserSelector,
  showToastAction,
  unsetOrderAction,
  updateOrderAction,
} from '../../store'
import { useCitiesList, useTypedSelector } from '../../hooks'
import { defaultFormatDate, generateErrorToast } from '../../helpers'
import { OrderFooter, SaveButton } from './styled'
import OrderItem from './OrderItem'
import { ModalProvider } from 'styled-react-modal'
import AddItemModal from '../../components/FormComponents/AddItemModal'
import {
  deliveryOptions,
  paymentOptions,
  currentStatusOptions,
  notifyStatusOptions,
  orderStatusOptions,
} from '../Orders/tableData'
import { checkForLabel } from '../../helpers/checkLabel'
import { InfoTitle } from '../Customer/styled'
import {
  ProfileInput,
  ProfileInputLabel,
  ProfileInputWrapper,
} from '../Profile/styled'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  getProductsAction,
  getMyProductsAction,
} from '../../store/_products/actions'

interface IOrder {}

const Order: FC<IOrder> = () => {
  const [orderInfo, setOrderInfo] = useState<any>({
    id: '',
    personalData: '',
    paymentType: '',
    bonusesUsed: '',
    status: '',
    deliveryType: '',
    address: '',
    comment: '',
    itemsTotalPrice: '',
  })

  const [address, setAddress] = useState({
    country: '',
    cityId: '',
    street: '',
    home: '',
    apartment: '',
  })

  const [items, setItems] = useState<any[]>([])
  const [orderItem, setOrderItem] = useState({})

  const [query, setQuery] = useState('')

  const [price, setPrice] = useState({ bonusesUsed: '', itemsTotalPrice: '' })

  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { order, loading } = useTypedSelector(state => state.orders)
  const { user } = useSelector(getUserSelector)

  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  console.log('order', order)

  useEffect(() => {
    order?.address && setAddress(order.address)
  }, [order])

  useEffect(() => {
    dispatch(getOrderAction.request({ id }))

    if (!checkPermission('productGetAll')) {
      dispatch(getMyProductsAction.request('limit=100000&page=1'))
      return
    }

    dispatch(getProductsAction.request('limit=100000&page=1'))
  }, [id])

  useEffect(() => {
    let total = 0
    const data = items.forEach(fe => {
      total += fe?.sum * fe?.count
    })
    setPrice(prev => {
      return {
        bonusesUsed: prev.bonusesUsed,
        itemsTotalPrice: `${total}`,
      }
    })
  }, [items])

  useEffect(() => {
    if (order) {
      const calculateTotal = () => {
        let sum = 0

        items.forEach((i: any) => {
          sum += i.sum * i.count
        })

        return sum
      }

      const {
        _id,
        personalData,
        paymentType,
        bonusesUsed,
        status,
        deliveryType,
        address,
        comment,
        items,
        itemsTotalPrice,
        increment,
      } = order

      setPrice({ itemsTotalPrice, bonusesUsed })

      const formatPersonalData = `${
        personalData?.name ? personalData.name : ''
      } ${personalData?.surname ? personalData?.surname : ''} ${
        personalData?.patronymic ? personalData?.patronymic : ''
      }`

      //${personalData?.phone ? personalData?.phone : ''}

      const formatAddress = `${address?.country ? address.country : ''} ${
        address?.city ? address.city : ''
      } ${address?.street ? address.street : ''} ${
        address?.home ? address.home : ''
      } `

      const fetchedOrder = {
        id: _id,
        increment,
        personalData: formatPersonalData,
        paymentType: { label: checkForLabel(paymentType), value: paymentType },
        bonusesUsed: bonusesUsed ? bonusesUsed : 0,
        status: { label: checkForLabel(status), value: status },
        deliveryType: {
          label: checkForLabel(deliveryType),
          value: deliveryType,
        },
        address: formatAddress,
        country: address?.country ? address.country : '',
        city: address?.city ? address.city : '',
        street: address?.street ? address.street : '',
        home: address?.home ? address.home : '',
        apartment: address?.apartment ? address.apartment : '',
        comment,
        itemsTotalPrice: itemsTotalPrice ? itemsTotalPrice : calculateTotal(),
      }

      setItems([...items])
      setOrderItem(order?.unavailableItem)
      setOrderInfo({ ...fetchedOrder })
    }
    const q = new URLSearchParams(location.search)
    setQuery(q.get('type')!)
  }, [loading, order])

  const addItems = (items: any) => {
    setItems((prev: any) => {
      return [...prev, ...items]
    })
  }

  const citiesList = useCitiesList()

  const handleSave = (data: any) => {
    const {
      paymentType,
      bonusesUsed,
      status,
      deliveryType,
      comment,
      itemsTotalPrice,
    } = data

    if (
      (!address.country || !address.cityId) &&
      data.deliveryType?.value !== 'takeout'
    ) {
      dispatch(
        showToastAction.request(
          generateErrorToast('Необходимо выбрать адрес доставки'),
        ),
      )
      return
    }
    dispatch(
      updateOrderAction.request({
        id,
        data: {
          paymentType: paymentType.value,
          bonusesUsed,
          status: status.value,
          deliveryType: deliveryType.value,
          comment,
          address: {
            country: address.country,
            street: address.street,
            home: address.home,
            apartment: address.apartment,
            cityId: Number(address.cityId),
          },
          items,
          itemsTotalPrice,
          personalData: order?.personalData,
        },
      }),
    )
    dispatch(unsetOrderAction())
  }

  const handleCountChange = (i: number, isAdd: boolean) => {
    setItems(prev => {
      const list = prev.map((item, j) => {
        if (j === i) {
          return isAdd
            ? {
                ...item,
                count: item?.count + 1,
                sum: item?.sum + item?.product?.price,
              }
            : {
                ...item,
                count: item?.count !== 1 ? item?.count - 1 : 1,
                sum:
                  item?.count !== 1
                    ? item?.sum - item?.product?.price
                    : item?.sum,
              }
        } else {
          return item
        }
      })
      return list
    })
  }

  const handleItemDelete = (i: number) => {
    setItems(prev => {
      const list = prev.filter((item, j) => {
        if (j !== i) {
          return item
        }
      })
      return list
    })
  }

  const itemsElement = items.map((i: any, index: number) => {
    if (i) {
      return (
        <OrderItem
          key={index}
          keyProp={index}
          item={i}
          handleCount={handleCountChange}
          handleDelete={handleItemDelete}
        />
      )
    }
  })

  const onBack = () => {
    dispatch(unsetOrderAction())
    history.push('/orders')
  }

  const statusOptions = () => {
    switch (query) {
      case 'current':
        return currentStatusOptions
      case 'notify':
        return notifyStatusOptions
      case 'order':
        return orderStatusOptions
      case 'archive':
        return currentStatusOptions
    }
  }

  return orderInfo ? (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>Заказ №{orderInfo ? orderInfo.increment : ''}</Title>
            <BackButton onClick={() => onBack()}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
          <CreationDate>
            Дата заказа: {order ? defaultFormatDate(order.createdAt) : ''}
          </CreationDate>
        </TitleGroup>
        <ButtonGroup>
          <SaveButton htmlFor="hiddenOrderSave">Сохранить</SaveButton>
        </ButtonGroup>
      </Header>
      <Content style={{ padding: '30px' }}>
        <Formik
          enableReinitialize={true}
          initialValues={orderInfo}
          onSubmit={values => handleSave(values)}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <InputContainer style={{ alignItems: 'baseline' }}>
                <Input
                  title="Имя"
                  value={values.personalData}
                  handleChange={handleChange}
                  name="personalData"
                  width="25%"
                  margin="0 0 30px 0"
                  disabled={true}
                />
                <Dropdown
                  title="Тип оплаты"
                  options={paymentOptions}
                  value={values.paymentType}
                  onChange={(i: any) => {
                    setFieldValue('paymentType', i)
                  }}
                  width="25%"
                />
                <Input
                  title="Бонусами"
                  value={values.bonusesUsed}
                  handleChange={handleChange}
                  name="bonusesUsed"
                  width="25%"
                  margin="0 0 30px 0"
                />
                <Dropdown
                  title="Статус заказа"
                  options={statusOptions()!}
                  value={values.status}
                  onChange={(i: any) => {
                    setFieldValue('status', i)
                  }}
                  width="25%"
                />
                <Dropdown
                  title="Способ доставки"
                  options={deliveryOptions}
                  value={values.deliveryType}
                  onChange={(i: any) => {
                    setFieldValue('deliveryType', i)
                  }}
                  width="25%"
                />
                <TextArea
                  title="Комментарий"
                  value={values.comment}
                  handleChange={handleChange}
                  name="comment"
                  width="25%"
                  margin="0 0 30px 0"
                />
              </InputContainer>
              <InfoTitle>Адрес доставки</InfoTitle>
              <InputContainer style={{ marginTop: '20px' }}>
                <ProfileInputWrapper maxWidth="130px">
                  <ProfileInputLabel>Страна</ProfileInputLabel>
                  <ProfileInput
                    value={address?.country}
                    onChange={e =>
                      setAddress(prev => ({ ...prev, country: e.target.value }))
                    }
                  />
                </ProfileInputWrapper>

                <ProfileInputWrapper maxWidth="130px">
                  <ProfileInputLabel>Город</ProfileInputLabel>
                  <Select
                    labelId="role"
                    id="role-select"
                    onChange={e =>
                      setAddress(prev => ({ ...prev, cityId: e.target.value }))
                    }
                    value={address?.cityId}
                    style={{ width: '100%', height: '40px' }}
                  >
                    {citiesList.map(city => (
                      <MenuItem value={city.cityId} key={city.cityId}>
                        {city.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </ProfileInputWrapper>

                <ProfileInputWrapper maxWidth="130px">
                  <ProfileInputLabel>Улица</ProfileInputLabel>
                  <ProfileInput
                    value={address?.street}
                    onChange={e =>
                      setAddress(prev => ({ ...prev, street: e.target.value }))
                    }
                  />
                </ProfileInputWrapper>

                <ProfileInputWrapper maxWidth="130px">
                  <ProfileInputLabel>Дом</ProfileInputLabel>
                  <ProfileInput
                    value={address?.home}
                    onChange={e =>
                      setAddress(prev => ({ ...prev, home: e.target.value }))
                    }
                  />
                </ProfileInputWrapper>

                <ProfileInputWrapper maxWidth="130px">
                  <ProfileInputLabel>Квартира</ProfileInputLabel>
                  <ProfileInput
                    value={address?.apartment}
                    onChange={e =>
                      setAddress(prev => ({
                        ...prev,
                        apartment: e.target.value,
                      }))
                    }
                  />
                </ProfileInputWrapper>
              </InputContainer>
              <div style={{ marginTop: '60px' }}>
                <InfoLabel>Товар</InfoLabel>
                {itemsElement}
                {order?.unavailableItem && (
                  <OrderItem
                    item={orderItem}
                    handleCount={() => {}}
                    handleDelete={() => {}}
                    order={true}
                  />
                )}
              </div>
              <OrderFooter>
                <ModalProvider>
                  <AddItemModal addItems={addItems} />
                </ModalProvider>
                <div className="column-container">
                  <div className="column">
                    <div>Сумма:</div>
                    <div>Бонусами:</div>
                    <div>Итого:</div>
                  </div>
                  <div className="column">
                    <div>{order?.itemsTotalPrice}</div>
                    <div>{order?.bonusesUsed}</div>
                    <div className="total">
                      {order?.price &&
                        order?.price +
                          (order?.deliveryPrice ? order.deliveryPrice : '')}
                    </div>
                  </div>
                </div>
              </OrderFooter>
              <button
                style={{ display: 'none' }}
                id="hiddenOrderSave"
                type="submit"
              >
                test
              </button>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  ) : (
    <div></div>
  )
}

export default Order
