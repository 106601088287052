import { useMemo } from 'react'
import { useTable, useBlockLayout, useResizeColumns } from 'react-table'
import FinanceContract from './FinanceContract'
import FinanceNotes from './FinanceNotes'
import FinanceSelect from './FinanceSelect'

const TableTest = ({ columns, data }) => {
  const defaultColumn = useMemo(
    () => ({
      minWidth: 5,
      width: 150,
      maxWidth: 400,
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns,
  )

  return (
    <>
      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => {
                  return (
                    <div {...cell.getCellProps()} className="td">
                      {cell.column.id !== 'paidToSupplier' &&
                        cell.column.id !== 'notes' &&
                        cell.column.id !== 'contract' &&
                        cell.render('Cell')}
                      {cell.column.id === 'paidToSupplier' ? (
                        <>
                          <FinanceSelect
                            id={cell.value?.orderId}
                            notesForOwner={cell.value?.notesForOwner}
                            supplierId={cell.value?.supplierId}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {cell.column.id === 'notes' ? (
                        <>
                          <FinanceNotes
                            id={cell.value?.orderId}
                            notesForOwner={cell.value?.notesForOwner}
                            supplierId={cell.value?.supplierId}
                          />
                        </>
                      ) : (
                        ''
                      )}
                      {cell.column.id === 'contract' ? (
                        <FinanceContract
                          id={cell.value?.orderId}
                          notesForOwner={cell.value?.notesForOwner}
                          supplierId={cell.value?.supplierId}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default TableTest
