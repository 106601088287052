import { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets, Colors } from '../../assets'
import { RowStartWrapper, RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import {
  ColumnFlex,
  ColumnWrapper,
  GrayTitle,
  LinkWithIcon,
  ModalInputRow,
} from '../Roles/styled'
import {
  createStoreAction,
  getStoresStatsByIdAction,
  getUserSelector,
} from '../../store'
import { getStoreSelector } from '../../store/stores/selectors'
import {
  BtnWrapper,
  DumbButton,
  DumbOutlineButton,
} from '../../components/FormComponents/styled'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '../../components'
import { StoresContainer } from './styled'

import ProfileModal from '../../components/Modals/ProfileModal'
import {
  PassModalTitle,
  ProfileInput,
  ProfileInputLabel,
  ProfileInputWrapper,
} from '../Profile/styled'
import { Store } from './Store'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const StoresList: FC = () => {
  const [newStoreModalIsOpen, setNewStoreModalIsOpen] = useState<boolean>(false)

  const { id } = useParams<any>()

  const dispatch = useDispatch()

  const history = useHistory()

  const { user } = useSelector(getUserSelector)

  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  useEffect(() => {
    if (!checkPermission('storeGetAll')) {
      dispatch(getStoresStatsByIdAction.request(user._id))
      return
    }

    dispatch(getStoresStatsByIdAction.request(id))
  }, [])

  const inputValues = useMemo(
    () => ({
      title: '',
      country: '',
      city: '',
      street: '',
      home: '',
    }),
    [],
  )

  const { storesList } = useSelector(getStoreSelector)

  const schema = yup.object().shape({
    title: yup.string().required(),
    country: yup.string().required(),
    city: yup.string().required(),
    street: yup.string().required(),
    home: yup.string().required(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema), mode: 'onBlur' })

  const onSubmitAddStore = data => {
    setNewStoreModalIsOpen(false)

    dispatch(
      createStoreAction.request({
        vendor: !checkPermission('storeGetAll') ? user._id : id,
        title: data.title,
        country: data.country,
        city: data.city,
        street: data.street,
        home: data.home,
      }),
    )

    Object.entries(inputValues).forEach(value => {
      setValue(`${value[0]}`, `${value[1]}`)
    })
  }

  const onCloseModal = () => {
    setNewStoreModalIsOpen(false)

    Object.entries(inputValues).forEach(value => {
      setValue(`${value[0]}`, `${value[1]}`)
    })
  }

  return (
    <>
      <RowWrapper>
        <RowStartWrapper width="auto">
          <ColumnFlex>
            <GrayTitle>Склады</GrayTitle>
          </ColumnFlex>

          <LinkWithIcon onClick={() => history.goBack()}>
            <img src={Assets.ARROW_BACK_ICON} alt="" />
            Назад
          </LinkWithIcon>
        </RowStartWrapper>

        <BtnWrapper>
          <Button
            title="Добавить склад"
            onClick={() => setNewStoreModalIsOpen(true)}
            theme={{ background: Colors.green, text: Colors.white }}
            icon={<img src={Assets.PLUS_WHITE_ICON} />}
          />
        </BtnWrapper>
      </RowWrapper>
      <SimpleDivider height="20px" />

      <ColumnWrapper>
        <StoresContainer>
          {storesList.map(store => (
            <Store key={store._id} store={store} />
          ))}
        </StoresContainer>

        <form onSubmit={handleSubmit(onSubmitAddStore)}>
          <ProfileModal
            isModalOpen={newStoreModalIsOpen}
            onModalClose={onCloseModal}
          >
            <PassModalTitle>Добавить склад</PassModalTitle>

            <SimpleDivider height="20px" />

            <ProfileInputWrapper className="role-wrapper">
              <ProfileInputLabel>Название</ProfileInputLabel>
              <ProfileInput
                {...register('title')}
                className={`${errors.title ? 'error' : ''}`}
              />
            </ProfileInputWrapper>

            <SimpleDivider height="20px" />

            <ModalInputRow>
              <ProfileInputWrapper className="role-wrapper" maxWidth="300px">
                <ProfileInputLabel>Страна</ProfileInputLabel>
                <ProfileInput
                  {...register('country')}
                  className={`${errors.country ? 'error' : ''}`}
                />
              </ProfileInputWrapper>

              <ProfileInputWrapper className="role-wrapper" maxWidth="300px">
                <ProfileInputLabel>Город</ProfileInputLabel>
                <ProfileInput
                  {...register('city')}
                  className={`${errors.city ? 'error' : ''}`}
                />
              </ProfileInputWrapper>

              <ProfileInputWrapper className="role-wrapper" maxWidth="300px">
                <ProfileInputLabel>Улица</ProfileInputLabel>
                <ProfileInput
                  {...register('street')}
                  className={`${errors.street ? 'error' : ''}`}
                />
              </ProfileInputWrapper>

              <ProfileInputWrapper className="role-wrapper" maxWidth="300px">
                <ProfileInputLabel>Дом</ProfileInputLabel>
                <ProfileInput
                  {...register('home')}
                  className={`${errors.home ? 'error' : ''}`}
                />
              </ProfileInputWrapper>
            </ModalInputRow>
            <SimpleDivider height="30px" />

            <BtnWrapper>
              <DumbOutlineButton
                color={Colors.boldGray}
                type="button"
                onClick={onCloseModal}
                className="cancel"
              >
                Отмена
              </DumbOutlineButton>

              <DumbButton
                bg={Colors.orange}
                txt={Colors.black}
                onClick={handleSubmit(onSubmitAddStore)}
              >
                Сохранить
              </DumbButton>
            </BtnWrapper>
          </ProfileModal>
        </form>
      </ColumnWrapper>
    </>
  )
}

export default StoresList
