import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { useTypedSelector } from '../../hooks'
import {
  getProductAction,
  updateProductAction,
  deleteProductAction,
  updateProductPhotoAction,
  deleteProductPhotoAction,
  unsetProductAction,
  createStoreAction,
  getStoresAction,
  updateProductPhotoOrderAction,
  getCategoriesAction,
  getUserSelector,
  getProductSelector,
  updateProductStatusAction,
  getMyStoresAction,
} from '../../store'
import {
  Content,
  Column,
  InfoTitle,
  InfoLabel,
  InfoTextArea,
  InputContainer,
  Container,
  Header,
  TitleGroup,
  Title,
  BackButton,
  CreationDate,
  ButtonGroup,
  ButtonContainer,
  SaveButton,
  ProductPublication,
} from './styled'
import { Formik, Form } from 'formik'
import { Assets, Colors } from '../../assets'
import { Input, Storage, Slide } from '../../components/FormComponents'
import { Button } from '../../components/Button'
import Images from './Images'
import { defaultFormatDate } from '../../helpers'
import { CreateStoreActionPayload } from '../../store/stores/types'
import { _IProductInfo, IStore } from '../../models/product.interface'
import Checkbox from '../../components/Input/Checkbox'
import { getBrandsAction } from '../../store/brands'
import {
  SimpleDivider,
  StyledWidthWrapper,
} from '../../components/Styled/formStyled'
import {
  DumbInput,
  StyledInputLabel,
} from '../../components/FormComponents/styled'
import { getExchangeRateAction } from '../../store/settings'
import {
  DeclineWarning,
  Icon,
  ProfileInput,
  ProfileInputError,
  ProfileInputLabel,
  ProfileInputWrapper,
  SmallDumbBtn,
} from '../Profile/styled'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { RowStartWrapper } from '../../components/Modals/styled'
import { StoreColumn } from '../ProductCreate/styled'

const Product: FC = () => {
  const [productInfo, setProductInfo] = useState<_IProductInfo>({
    en: '',
    ru: '',
    uz: '',
    price: '',
    usdPrice: '',
    vendorCode: '',
    discount: '',
    description: '',
    descriptionEn: '',
    descriptionUz: '',

    category: { label: '', value: '', subcategories: [], characteristics: [] },
    subcategory: { label: '', value: '' },
    subsubcategory: { label: '', value: '' },
    brand: '',
    characteristics: [],
  })
  const [storage, setStorage] = useState<IStore[]>([])
  const [characteristics, setCharacteristics] = useState({
    categories: [],
  })
  const [selectedCharacteristics, setSelectedCharacteristics] = useState<any>({
    selectedCategory: {},
    selectedSubcategory: {},
    selectedSubSubcategory: {},
    selectedCharacteristics: [],
  })
  const [characteristicsInput, setCharacteristicsInput] = useState<any[]>([])
  const [images, setImages] = useState<{ order: number; src: string }[]>([])
  const [status, setStatus] = useState('')
  const [productStatus, setProductStatus] = useState<any>('')
  const [declineReason, setDeclineReason] = useState<string>('')

  const [noveltyCheckbox, setNoveltyCheckbox] = useState(false)

  const [promoCheckbox, setPromoCheckbox] = useState(false)

  const [categoryTextCharacteristics, setCategoryTextCharacteristics] =
    useState<any[]>([])

  const [categoryRangeCharacteristics, setCategoryRangeCharacteristics] =
    useState<any[]>([])

  const [selectedTextCharacteristics, setSelectedTextCharacteristics] =
    useState<any[]>([])

  const [selectedRangeCharacteristics, setSelectedRangeCharacteristics] =
    useState<any[]>([])

  const [selectedStore, setSelectedStore] = useState<any>([])

  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const { user } = useSelector(getUserSelector)
  const { product } = useSelector(getProductSelector)

  useEffect(() => {
    dispatch(getMyStoresAction.request())

    return () => {
      dispatch(unsetProductAction())
    }
  }, [])

  useEffect(() => {
    selectedStore.length < 1 &&
      product?.stores.forEach(store => {
        setSelectedStore(prev => [
          ...prev,
          { store: store?.store?._id, count: store?.count },
        ])
      })
  }, [product])

  useEffect(() => {
    setStatus(product?.status)
  }, [product])

  const checkPermission = (permissions: string[]) => {
    return permissions.find(permission => permission === 'productModeration')
  }

  const productState = useTypedSelector(state => state.products)
  const categoriesState = useTypedSelector(state => state.categories)
  const { stores, myStores, loading } = useTypedSelector(state => state.stores)
  const brandsState = useTypedSelector(state => state.brands)
  const exchangeRateState = useTypedSelector(state => state.settings)

  useEffect(() => {
    dispatch(getStoresAction.request())
    dispatch(getBrandsAction.request())
    dispatch(getExchangeRateAction.request())
    dispatch(getCategoriesAction.request())
  }, [])

  useEffect(() => {
    dispatch(getProductAction.request({ id }))
  }, [id])

  useEffect(() => {
    if (productState.product) {
      const {
        images,
        en,
        ru,
        uz,
        price,
        usdPrice,
        vendorCode,
        discount,
        description,
        descriptionEn,
        descriptionUz,

        category,
        subcategory,
        subsubcategory,
        textCharacteristics,
        rangeCharacteristics,
        novelty,
        isPromo,
        brand,
      } = productState.product

      const fetchedProduct: _IProductInfo = {
        en,
        ru,
        uz,
        price,
        usdPrice,
        vendorCode,
        discount,
        description,
        descriptionEn,
        descriptionUz,
        brand,

        category: category
          ? {
              value: category._id as string,
              label: category.ru as string,
              subcategories: category.subcategories,
              characteristics: category.characteristics,
            }
          : { label: '', value: '', subcategories: [], characteristics: [] },
        subcategory: subcategory
          ? {
              value: subcategory._id as string,
              label: subcategory.ru as string,
            }
          : { label: '', value: '' },
        subsubcategory: subsubcategory
          ? {
              value: subsubcategory._id as string,
              label: subsubcategory.ru as string,
            }
          : { label: '', value: '' },
        characteristics: [],
      }

      const formatedStores = stores.map(i => {
        return { store: i._id, count: 0 }
      })

      setProductInfo({ ...fetchedProduct })
      // productState.product.stores.forEach((i: IProductStore) => {
      //   if (i.count > 0) setStatus('serial')
      // })
      setCharacteristics((prev: any) => {
        return {
          ...prev,
          categories: categoriesState.categories,
        }
      })
      setSelectedCharacteristics({
        selectedCategory: {
          ...productState.product.category,
          characteristics: productState.product.characteristics,
        },
        selectedSubcategory: productState.product.subcategory,
        selectedSubSubcategory: productState.product.subsubcategory,
      })

      setCategoryRangeCharacteristics(category?.rangeCharacteristics)
      setCategoryTextCharacteristics(category?.textCharacteristics)

      setSelectedTextCharacteristics(textCharacteristics)
      setSelectedRangeCharacteristics(rangeCharacteristics)

      setCharacteristicsInput(productState.product.characteristics)
      setNoveltyCheckbox(novelty)
      setPromoCheckbox(isPromo)
      setStorage([...formatedStores])
      setImages(
        images.map((img: string, index: number) => {
          return {
            order: index,
            src: img,
          }
        }),
      )
    }
  }, [productState.loading, loading, categoriesState.loading])

  const findCharacteristic = (id: string, type: string) => {
    switch (type) {
      case 'category':
        const category = categoriesState.categories.filter(i => id === i._id)
        if (category.length !== 0) {
          return {
            value: category[0]?._id as string,
            label: category[0]?.ru as string,
            subcategories: category[0]?.subcategories,
            characteristics: category[0]?.characteristics,
          }
        }
        break
    }
  }

  const handleStorageChange = (id: string, i: number) => {
    const storeIndex = findStore(id)
    const newState = [...storage]
    const store = { ...newState[storeIndex!] }
    store.count = i
    newState[storeIndex!] = store
    setStorage(newState)
  }

  const findStore = (id: string) => {
    for (let i = 0; i < storage.length; i++) {
      if (storage[i].store === id) {
        return i
      }
    }
    return -1
  }
  const storesElement = storage.map((i: any, index: number) => {
    return (
      <Storage
        id={i.store}
        onChange={handleStorageChange}
        value={i.count}
        key={index}
      />
    )
  })

  const handleSave = (info: _IProductInfo) => {
    const {
      en,
      ru,
      uz,
      price,
      usdPrice,
      vendorCode,
      discount,
      description,
      descriptionEn,
      descriptionUz,
    } = info

    const getProductStatus = () => {
      if (status === 'order') {
        return 'order'
      }
      for (let i = 0; i < selectedStore.length; i++) {
        if (selectedStore[i].count > 0) {
          return 'in_stock'
        }
      }
      return 'order'
    }

    const checkStorage = () => {
      if (getProductStatus() === 'order') {
        return []
      } else {
        return storage
      }
    }
    const parseStringIntoInt = (input: string) => {
      return parseInt(`${input}`.replaceAll(' ', ''))
    }

    const formatCharacteristics = (type: 'range' | 'text') => {
      switch (type) {
        case 'range':
          return selectedRangeCharacteristics.map(m => {
            return { title: m.title._id, value: m.value }
          })
        case 'text':
          return selectedTextCharacteristics.map(m => {
            return { title: m.title._id, value: m.value }
          })
      }
    }
    if (window.confirm('Вы уверены?')) {
      dispatch(
        updateProductAction.request({
          id,
          data: {
            en,
            ru,
            uz,
            usdPrice: parseStringIntoInt(usdPrice),
            price: parseStringIntoInt(price),
            vendorCode,
            brand: productInfo?.brand?._id,
            discount: parseStringIntoInt(discount),
            description,
            descriptionEn,
            descriptionUz,
            category: selectedCharacteristics.selectedCategory?._id,
            subcategory: selectedCharacteristics.selectedSubcategory?._id,
            subsubcategory: selectedCharacteristics.selectedSubSubcategory?._id,
            stores: selectedStore,
            status: getProductStatus(),
            textCharacteristics: formatCharacteristics('text'),
            rangeCharacteristics: formatCharacteristics('range'),
            novelty: noveltyCheckbox,
            isPromo: promoCheckbox,
          },
        }),
      )
      dispatch(
        updateProductPhotoOrderAction.request({
          id,
          data: {
            images: images.map(img => img.src),
          },
        }),
      )
      if (productStatus === 'accept') {
        dispatch(
          updateProductStatusAction.request({
            version: product.__v,
            id: product._id,
            body: { published: true },
          }),
        )
      } else if (productStatus === 'decline' && declineReason) {
        dispatch(
          updateProductStatusAction.request({
            version: product.__v,
            id: product._id,
            body: { declineReason: declineReason, published: false },
          }),
        )
      }

      history.goBack()
    }
  }

  const handleDelete = () => {
    if (window.confirm('Удалить товар?')) {
      dispatch(
        deleteProductAction.request({
          id,
        }),
      )
      history.push('/products')
    }
  }

  const handlePhotoAdd = (e: any) => {
    const files = Array.from(e.target.files || [])
    files.forEach((el: any) => {
      const data = new FormData()
      data.append('photo', el)
      dispatch(updateProductPhotoAction.request({ id, data }))
    })
  }

  const handlePhotoDelete = (photo: string) => {
    dispatch(
      deleteProductPhotoAction.request({
        id,
        photo,
      }),
    )
  }

  const date = defaultFormatDate(
    productState.product ? productState.product.createdAt : '',
  )

  const handleStoreCreate = (data: CreateStoreActionPayload) => {
    dispatch(createStoreAction.request(data))
  }

  const handleCharacteristics = (char: any, newValue: any) => {
    //@ts-ignore
    // const ne = { [`${title}`]: value }
    // console.log(ne)
    // const buffer = characteristicsInput

    // const obj = characteristicsInput.findIndex((i: any) => i.ru === char.ru)

    // buffer[obj] = { ...buffer[obj], value: newValue }

    // setCharacteristicsInput(buffer)

    setCharacteristicsInput(prev => {
      return [
        ...prev.filter(f => f.ru !== char.ru),
        { ...char, value: newValue },
      ]
    })
  }

  const handleCategories = (value: any) => {
    setCharacteristicsInput(
      value.textCharacteristics.map((i: any) => {
        return { ...i, value: '' }
      }),
    )
    setSelectedCharacteristics((prev: any) => {
      return {
        ...prev,
        selectedCategory: {
          ...value,
          characteristics: value.textCharacteristics.map((i: any) => {
            return {
              en: i.en,
              ru: i.ru,
              uz: i.uz,
              possibleValues: i.possibleValues,
              value: '',
            }
          }),
        },
      }
    })

    setCategoryTextCharacteristics([...value.textCharacteristics])
    setCategoryRangeCharacteristics([...value.rangeCharacteristics])
  }

  const handleSubcategories = (value: any) => {
    setSelectedCharacteristics((prev: any) => {
      return {
        ...prev,
        selectedSubcategory: value,
      }
    })
  }

  const handleSubSubcategories = (value: any) => {
    setSelectedCharacteristics((prev: any) => {
      return {
        ...prev,
        selectedSubSubcategory: value,
      }
    })
  }

  const handleBrand = (brand: any) => {
    setProductInfo(prev => {
      return {
        ...prev,
        brand,
      }
    })
  }

  const handleDynamicInput = () => {
    setCharacteristicsInput(prev => {
      return prev
    })
  }

  const subcat = () => {
    if (Object.keys(selectedCharacteristics.selectedCategory).length !== 0) {
      return (
        <Slide
          label={'Подкатегория'}
          options={
            selectedCharacteristics?.selectedCategory?.subcategories
              ? selectedCharacteristics?.selectedCategory?.subcategories
              : []
          }
          select={handleSubcategories}
          selected={selectedCharacteristics.selectedSubcategory}
        />
      )
    }
  }

  const subSubcat = () => {
    if (Object.keys(selectedCharacteristics.selectedCategory).length !== 0) {
      return (
        <Slide
          label={'3 уровень'}
          options={categoriesState.subSubcategories}
          select={handleSubSubcategories}
          selected={selectedCharacteristics.selectedSubSubcategory}
        ></Slide>
      )
    }
  }

  const handleTextCharacteristicSelect = (item: any, parent: any) => {
    setSelectedTextCharacteristics(prev => {
      return [
        ...prev.filter(f => f.title._id !== parent._id),
        { value: item, title: parent },
      ]
    })
  }

  const handleRangeCharacteristicChange = (value: string, parent: any) => {
    setSelectedRangeCharacteristics(prev => {
      return [
        ...prev.filter(f => f.title._id !== parent._id),
        { value, title: parent },
      ]
    })
  }

  const renderCharacteristics = (type: string) => {
    switch (type) {
      case 'text':
        if (categoryTextCharacteristics?.length > 0) {
          return categoryTextCharacteristics.map(i => {
            const getSelected = () => {
              const findObj = selectedTextCharacteristics.find(
                f => f.title._id === i._id,
              )
              return findObj ? findObj.value : null
            }
            return (
              <StyledWidthWrapper width="45%">
                <Slide
                  label={i.ru}
                  options={i.possibleValues}
                  select={(e: any) => handleTextCharacteristicSelect(e, i)}
                  selected={getSelected()}
                />
              </StyledWidthWrapper>
            )
          })
        }
        break

      case 'range':
        if (categoryRangeCharacteristics?.length > 0) {
          return categoryRangeCharacteristics.map(i => {
            const getValue = () => {
              const findObj = selectedRangeCharacteristics.find(
                f => f.title._id === i._id,
              )
              return findObj ? findObj.value : ''
            }

            return (
              <StyledWidthWrapper width="50%">
                <div>
                  <StyledInputLabel>{i.ru}</StyledInputLabel>
                  <DumbInput
                    style={{ margin: '4px 0 0 0', width: '100%' }}
                    value={getValue()}
                    onChange={(e: any) =>
                      handleRangeCharacteristicChange(e.target.value, i)
                    }
                    placeholder={`${i.possibleValues[0]} - ${i.possibleValues[1]}`}
                  />
                </div>
              </StyledWidthWrapper>
            )
          })
        }
        break
    }
  }

  const onBack = () => {
    history.push('/products')
    dispatch(unsetProductAction())
  }

  const content = (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>{productState.product ? productState.product.ru : ''}</Title>
            <BackButton onClick={() => onBack()}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
          <CreationDate>Дата создания: {date}</CreationDate>
        </TitleGroup>
        <ButtonGroup>
          <ButtonContainer width="195px" height="52px">
            {product?.published && (
              <Button
                title="Удалить товар"
                onClick={() => handleDelete()}
                theme={{ background: Colors.red, text: Colors.white }}
                icon={<img src={Assets.DELETE_WHITE_ICON} />}
              />
            )}
          </ButtonContainer>
          <ButtonGroup>
            <SaveButton htmlFor="hiddenSave">Сохранить </SaveButton>
          </ButtonGroup>
        </ButtonGroup>
      </Header>
      <Content>
        <Formik
          enableReinitialize={true}
          initialValues={productInfo}
          // validationSchema={productSchema}
          onSubmit={values => handleSave(values)}
        >
          {({ values, handleChange, errors, setFieldValue }) => (
            <Form id="myForm" style={{ display: 'flex', width: '100%' }}>
              <Column>
                <InfoTitle>Основные данные</InfoTitle>
                <InputContainer>
                  <Input
                    title="English"
                    handleChange={handleChange}
                    value={values.en}
                    name="en"
                    error={errors.en}
                    width="33%"
                    margin="10px"
                  />
                  <Input
                    title="Русский"
                    handleChange={handleChange}
                    value={values.ru}
                    name="ru"
                    error={errors.ru}
                    width="33%"
                    margin="10px"
                  />
                  <Input
                    title="Ozbek"
                    handleChange={handleChange}
                    value={values.uz}
                    name="uz"
                    error={errors.uz}
                    width="33%"
                    margin="10px"
                  />
                </InputContainer>
                <Images
                  images={images}
                  setImages={setImages}
                  handleAdd={handlePhotoAdd}
                  handleDelete={handlePhotoDelete}
                />
                <InputContainer>
                  <Input
                    title="Цена в долларах"
                    handleChange={handleChange}
                    value={values.usdPrice}
                    name="usdPrice"
                    error={errors.usdPrice}
                    width="50%"
                    margin="10px"
                  />
                  <Input
                    title="Цена в суммах"
                    handleChange={handleChange}
                    value={values.price}
                    name="price"
                    disabled
                    // error={errors.price}
                    width="50%"
                    margin="10px"
                  />
                  <Input
                    title="Артикул"
                    handleChange={handleChange}
                    value={values.vendorCode}
                    name="vendorCode"
                    error={errors.vendorCode}
                    width="50%"
                    margin="10px"
                  />
                  <Input
                    title="Скидка"
                    handleChange={handleChange}
                    value={values.discount}
                    name="discount"
                    error={errors.discount}
                    width="50%"
                    margin="10px"
                  />
                  <div style={{ width: 'calc(50% - 30px)', marginTop: '10px' }}>
                    <Slide
                      label="Бренд"
                      options={brandsState.brands}
                      selected={productInfo.brand}
                      select={handleBrand}
                    />
                  </div>
                </InputContainer>

                <Checkbox
                  onChange={() =>
                    setStatus(prev => (prev === 'order' ? 'in_stock' : 'order'))
                  }
                  value={status === 'order' ? true : false}
                >
                  Товар под заказ
                </Checkbox>
                {status === 'serial' ? storesElement : null}

                <SimpleDivider height="15px" />

                <>
                  {user._id !== product.owner ? (
                    product?.stores.map((store, index) => (
                      <RowStartWrapper
                        style={{ alignItems: 'flex-end', gap: 0 }}
                      >
                        <Input
                          title="Склад"
                          value={`${
                            store?.store?.title ? store?.store?.title : ''
                          } ${
                            store?.store?.country ? store?.store?.country : ''
                          } ${store?.store?.city ? store?.store?.city : ''} ${
                            store?.store?.street ? store?.store?.street : ''
                          } ${store?.store?.home ? store?.store?.home : ''}`}
                          width="100%"
                          margin="10px"
                        />

                        <Input
                          title="Кол-во"
                          value={store?.count}
                          width="140px"
                          margin="10px"
                        />
                      </RowStartWrapper>
                    ))
                  ) : (
                    <StoreColumn>
                      {selectedStore.map((store, index) => (
                        <ProfileInputWrapper
                          style={{ margin: '20px 0' }}
                          key={index}
                        >
                          <RowStartWrapper style={{ alignItems: 'flex-end' }}>
                            <ProfileInputWrapper style={{ maxWidth: '254px' }}>
                              <ProfileInputLabel>Склад</ProfileInputLabel>

                              <Select
                                labelId="store"
                                id="store-select"
                                onChange={e =>
                                  setSelectedStore(prev =>
                                    prev.map((item, i) =>
                                      i === index
                                        ? { ...item, store: e.target.value }
                                        : item,
                                    ),
                                  )
                                }
                                value={store.store}
                                style={{ width: '100%', height: '40px' }}
                              >
                                {myStores.map(store => (
                                  <MenuItem
                                    key={store._id}
                                    value={store._id}
                                  >{`${store?.country} ${store?.city} ${store?.street} ${store?.home}`}</MenuItem>
                                ))}
                              </Select>
                            </ProfileInputWrapper>

                            <ProfileInputWrapper style={{ maxWidth: '90px' }}>
                              <ProfileInputLabel>Кол-во</ProfileInputLabel>
                              <ProfileInput
                                min={0}
                                value={selectedStore[index].count}
                                onChange={e =>
                                  setSelectedStore(prev =>
                                    prev.map((item, i) =>
                                      i === index
                                        ? { ...item, count: e.target.value }
                                        : item,
                                    ),
                                  )
                                }
                                type="number"
                              />
                            </ProfileInputWrapper>
                          </RowStartWrapper>
                        </ProfileInputWrapper>
                      ))}

                      <SmallDumbBtn
                        bg={Colors.green}
                        txt={Colors.white}
                        shadow={Colors.lightGreen}
                        onClick={() =>
                          setSelectedStore(prev => [
                            ...prev,
                            { store: '', count: 0 },
                          ])
                        }
                      >
                        <Icon src={Assets.PLUS_WHITE_ICON}></Icon>
                        Добавить склад
                      </SmallDumbBtn>
                    </StoreColumn>
                  )}
                </>

                <SimpleDivider height="15px" />
                <InfoLabel htmlFor="description">Описание Русский</InfoLabel>
                <InfoTextArea
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                />

                <SimpleDivider height="15px" />
                <InfoLabel htmlFor="descriptionEn">Описание English</InfoLabel>
                <InfoTextArea
                  name="descriptionEn"
                  onChange={handleChange}
                  value={values.descriptionEn}
                />

                <SimpleDivider height="15px" />
                <InfoLabel htmlFor="descriptionUz">Описание Ozbek</InfoLabel>
                <InfoTextArea
                  name="descriptionUz"
                  onChange={handleChange}
                  value={values.descriptionUz}
                />
              </Column>
              <Column>
                <InfoTitle>Характеристики</InfoTitle>
                <SimpleDivider height="10px" />
                <InputContainer>
                  <Slide
                    label="Категория"
                    options={characteristics.categories}
                    select={handleCategories}
                    selected={selectedCharacteristics.selectedCategory}
                  />
                  <InputContainer>{subcat()}</InputContainer>
                  <InputContainer>{subSubcat()}</InputContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {renderCharacteristics('text')}
                    {renderCharacteristics('range')}
                  </div>
                </InputContainer>
                <SimpleDivider height="10px" />
                <Checkbox
                  value={noveltyCheckbox}
                  onChange={(e: any) => setNoveltyCheckbox(e.target.checked)}
                  name="novelty"
                >
                  Новинка
                </Checkbox>
                <SimpleDivider height={'10px'} />
                <Checkbox
                  value={promoCheckbox}
                  onChange={(e: any) => setPromoCheckbox(e.target.checked)}
                  name="promo"
                >
                  Акция
                </Checkbox>
                <button
                  style={{ display: 'none' }}
                  type="submit"
                  onSubmit={() => handleSave(values)}
                  id="hiddenSave"
                >
                  test
                </button>

                <SimpleDivider height="30px" />

                {checkPermission(user?.role.permissions) &&
                !product.published ? (
                  <ProductPublication>
                    <ProfileInputWrapper maxWidth="50%">
                      <ProfileInputLabel>Публикация товара</ProfileInputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={productStatus}
                        onChange={e => setProductStatus(e.target.value)}
                        style={{
                          width: '100%',
                          height: '40px',
                          borderBottom: '0',
                          border: '1px solid #c4c4c4',
                          background: '#ffffff',
                          padding: '10px 0px 8px 8px',
                        }}
                      >
                        <MenuItem value={'accept'}>Принять</MenuItem>
                        <MenuItem value={'decline'}>Отказать</MenuItem>
                      </Select>
                    </ProfileInputWrapper>

                    {productStatus === 'decline' && (
                      <ProfileInputWrapper maxWidth="50%">
                        <ProfileInputLabel>Причина отказа</ProfileInputLabel>
                        <ProfileInput
                          value={declineReason}
                          onChange={e => setDeclineReason(e.target.value)}
                          className={`${
                            productStatus === 'decline' && !declineReason
                              ? 'error'
                              : ''
                          }`}
                        />
                        {!declineReason && (
                          <ProfileInputError>
                            Введите причину отказа
                          </ProfileInputError>
                        )}
                      </ProfileInputWrapper>
                    )}
                  </ProductPublication>
                ) : !product.published ? (
                  <>
                    <ProductPublication>
                      <ProfileInputWrapper maxWidth="50%">
                        <ProfileInputLabel>Публикация товара</ProfileInputLabel>
                        <ProfileInput readOnly value={'Отказано'} />
                      </ProfileInputWrapper>

                      <ProfileInputWrapper maxWidth="50%">
                        <ProfileInputLabel>Причина отказа</ProfileInputLabel>
                        <textarea readOnly>{product.declineReason}</textarea>
                      </ProfileInputWrapper>
                    </ProductPublication>

                    <SimpleDivider height="30px" />

                    <DeclineWarning>
                      <span>Внимание!</span> Вам было отказано в публикации
                      этого товара. Причина: "{product.declineReason}".
                      Пожалуйста, внесите необходимые изменения в товар и
                      повторите попытку.
                    </DeclineWarning>
                  </>
                ) : (
                  ''
                )}
              </Column>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  )

  return <>{productState.product ? content : null}</>
}

export default Product
