import { AxiosResponse } from 'axios'
import {
  CreateColorActionPayload,
  CreateColorActionResponse,
  GetColorsActionResponse,
} from './types'

import { HttpService } from '../../services'
import { FEEDBACK_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class FeedbackAPI extends HttpService {
  static getFeedback({
    authorization,
    data,
  }): Promise<AxiosResponse<GetColorsActionResponse>> {
    return this.request<GetColorsActionResponse>('GET_FEEDBACK', {
      url: FEEDBACK_URL.rootPath(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getFeedbackById(
    request: any,
  ): Promise<AxiosResponse<GetColorsActionResponse>> {
    return this.request<GetColorsActionResponse>('GET_FEEDBACK_BY_ID', {
      url: FEEDBACK_URL.idPath(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateFeedbackById(
    request: any,
  ): Promise<AxiosResponse<GetColorsActionResponse>> {
    return this.request<GetColorsActionResponse>('GET_FEEDBACK_BY_ID', {
      url: FEEDBACK_URL.idPath(request.id),
      method: 'PUT',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
