import { createReducer } from '@reduxjs/toolkit'
import {
  deleteBannerAction,
  deleteNewBannerAction,
  getBannersAction,
  setEditedBannersAction,
  setEditedNewBannersAction,
  setNewBannerAction,
} from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
  banners: [],
  newBanners: [],
  loading: false,
}

export const bannersReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getBannersAction.request, state => ({
      ...state,
      newBanners: [],
      loading: true,
    }))
    builder.addCase(getBannersAction.success, (state, { payload }) => ({
      ...state,
      banners: payload.data,
      loading: false,
    }))
    builder.addCase(getBannersAction.failure, (state, { payload }) => ({
      ...state,
      banners: [],
      loading: false,
    }))

    builder.addCase(deleteBannerAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteBannerAction.success, (state, { payload }) => ({
      ...state,
      banners: payload.data,
      loading: false,
    }))
    builder.addCase(deleteBannerAction.failure, (state, { payload }) => ({
      ...state,
      banners: [],
      loading: false,
    }))

    builder.addCase(setNewBannerAction, (state, { payload }) => ({
      ...state,
      newBanners: [...state.newBanners, payload],
    }))

    builder.addCase(deleteNewBannerAction, (state, { payload }) => ({
      ...state,
      newBanners: payload,
    }))

    builder.addCase(setEditedNewBannersAction, (state, { payload }) => ({
      ...state,
      newBanners: payload,
    }))

    builder.addCase(setEditedBannersAction, (state, { payload }) => ({
      ...state,
      banners: payload,
    }))
  },
)
