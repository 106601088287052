import { AxiosResponse } from 'axios'
import { setAuthorizationHeadersProp } from '../../../../helpers'
import { HttpService } from '../../../../services'
import { api } from '../../../../store'
import { TSuplie } from './types'

export class SupliesrAPI extends HttpService {
  static getSuplies(token: string): Promise<AxiosResponse<any>> {
    return this.request<any>('', {
      url: `${api.withAPI}/admins/suppliers`,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(token),
      },
    })
  }

  static getSuplierById(
    token: string,
    id: string,
  ): Promise<AxiosResponse<any>> {
    return this.request<any>('', {
      url: `${api.withAPI}/percent-suppliers/${id}`,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(token),
      },
    })
  }

  static createSuplies(token: string, data: any): Promise<AxiosResponse<any>> {
    return this.request<any>('', {
      url: `${api.withAPI}/percent-suppliers`,
      method: 'POST',
      data,
      headers: {
        ...setAuthorizationHeadersProp(token),
      },
    })
  }

  static editSuplies(
    token: string,
    data: any,
    id: string,
  ): Promise<AxiosResponse<any>> {
    return this.request<any>('', {
      url: `${api.withAPI}/percent-suppliers/${id}`,
      method: 'PATCH',
      data,
      headers: {
        ...setAuthorizationHeadersProp(token),
      },
    })
  }

  static removeSuplies(
    token: string,
    _id: string,
  ): Promise<AxiosResponse<any>> {
    return this.request<any>('', {
      url: `${api.withAPI}/percent-suppliers/${_id}`,
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(token),
      },
    })
  }
}
