import React, { FC } from 'react'
import { StyledInput, StyledInputError, StyledInputLabel } from './styled'

interface ISmartInput {
  register?: any
  title: string
  error: any
  name: string
  disabled?: boolean
  value?: string
  onChange?: (e: any) => void
}

export const SmartInput: FC<ISmartInput> = ({
  register,
  title,
  error,
  name,
  disabled = false,
  value,
  onChange,
}) => {
  return (
    <>
      <StyledInputLabel htmlFor={name} error={error[name]}>
        {title}
      </StyledInputLabel>
      <StyledInput
        {...register(name)}
        id={name}
        error={error[name]}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
      {error[name] && (
        <StyledInputError>{error[name].message}</StyledInputError>
      )}
    </>
  )
}
