import React, { FC, useCallback, useMemo } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Assets, Colors } from '../../assets'
import { Button, Divider, EInputTemplate, Input } from '../../components'
import { DumbButton } from '../../components/FormComponents/styled'
import ProfileModal from '../../components/Modals/ProfileModal'
import { SimpleDivider } from '../../components/Styled/formStyled'
import { useValidation } from '../../hooks'
import { forgotPasswordAction, loginAction } from '../../store'
import { Log } from '../../utils'
import {
  PassModalTitle,
  ProfileInput,
  ProfileInputError,
  ProfileInputLabel,
  ProfileInputWrapper,
  ProfileModalText,
} from '../Profile/styled'
import {
  Container,
  Link,
  LoginWrapper,
  Title,
  Image,
  LoginContainer,
  ButtonWrapper,
} from './styled'
import { TForm, TLoginValidationSchema } from './types'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

export const Login: FC = () => {
  const dispatch = useDispatch()
  const [form, setForm] = useState<TForm>({ email: '', password: '' })
  const [isForgotPassModalOpen, setIsForgotPassModalOpen] =
    useState<boolean>(false)

  const inputHandler = useCallback(
    e => {
      setForm(prev => ({ ...prev, [e.target.name]: e.target.value }))
    },
    [form],
  )

  const schema = useMemo<TLoginValidationSchema>(
    () => ({
      email: {
        condition: form.email.includes('@'),
        error: `Неверный формат email`,
      },
      password: {
        condition: !!form.password && form.password.length >= 6,
        error: `Введите пароль`,
      },
    }),
    [form],
  )

  const {
    validationSchema,
    disableValidation,
    validation,
    enableValidation,
    isEnabled,
  } = useValidation(schema)

  const onPressLogin = async () => {
    try {
      enableValidation()
      await validation()

      dispatch(loginAction.request(form))

      disableValidation()
    } catch (error) {
      Log.ruddy('Event: onPressLogin')
    }
  }

  const changePassSchema = yup.object().shape({
    name: yup.string().matches(/^([^0-9]*)$/, 'Имя не должно содержать цифр'),
    email: yup.string().email('Введите валидный e-mail'),
    emailToChangePass: yup.string().email('Введите валидный e-mail'),
    photo: yup.mixed(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePassSchema),
  })

  const onResetPassword = data => {
    dispatch(forgotPasswordAction({ email: data.emailToChangePass }))
    setIsForgotPassModalOpen(false)
  }

  return (
    <Container>
      <Image src={Assets.LOGIN_LOGO_IMAGE} alt="logo" />
      <LoginContainer>
        <LoginWrapper>
          <Title>Вход</Title>

          <Divider height={30} />

          <Input
            label="E-mail"
            name="email"
            value={form.email}
            placeholder="Ex: demo@demo.com"
            error={schema.email.error || ''}
            template={EInputTemplate.DEFAULT}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.email}
          />

          <Divider height={15} />

          <Input
            label="Пароль"
            name="password"
            value={form.password}
            placeholder="Ex: Demo1234"
            template={EInputTemplate.PASSWORD}
            // error={schema.password.error || ''}
            onChange={v => inputHandler(v)}
            isValid={!isEnabled || validationSchema.password}
          />

          <Divider height={20} />

          <ButtonWrapper>
            <Button
              title="Войти"
              onClick={onPressLogin}
              theme={{ background: Colors.orange }}
            />
          </ButtonWrapper>

          <Divider height={30} />

          <Link onClick={() => setIsForgotPassModalOpen(true)}>
            Забыли пароль?
          </Link>
        </LoginWrapper>

        <form>
          <ProfileModal
            isModalOpen={isForgotPassModalOpen}
            onModalClose={() => setIsForgotPassModalOpen(false)}
          >
            <PassModalTitle>Сбросить пароль</PassModalTitle>

            <SimpleDivider height="25px" />

            <ProfileModalText>
              Укажите свой e-mail. На ваш адрес мы отправим <br /> письмо с
              инструкциями для смены пароля.
            </ProfileModalText>

            <SimpleDivider height="20px" />

            <ProfileInputWrapper maxWidth="300px">
              <ProfileInputLabel>E-mail</ProfileInputLabel>
              <ProfileInput
                {...register('emailToChangePass')}
                className={`${errors.emailToChangePass ? 'error' : ''}`}
              />
              {errors.emailToChangePass && (
                <ProfileInputError>
                  {errors.emailToChangePass.message}
                </ProfileInputError>
              )}
            </ProfileInputWrapper>

            <SimpleDivider height="30px" />

            <DumbButton
              bg={Colors.orange}
              txt={Colors.black}
              onClick={handleSubmit(onResetPassword)}
            >
              Сбросить
            </DumbButton>
          </ProfileModal>
        </form>
      </LoginContainer>
    </Container>
  )
}

export default Login
