import { createReducer } from '@reduxjs/toolkit'
import { IInitialState } from './types'

import {
  getAllNotificationsAction,
  resetNotificationsCounterAction,
  setNotificationsCounterAction,
} from './actions'

const InitialState: IInitialState = {
  notifications: [],
  notificationsCounter: 0,
}

export const notifications = createReducer<IInitialState>(
  InitialState,
  builder => {
    builder.addCase(getAllNotificationsAction.request, state => ({
      ...state,
    }))
    builder.addCase(
      getAllNotificationsAction.success,
      (state, { payload }) => ({
        ...state,
        notifications: payload,
      }),
    )
    builder.addCase(
      getAllNotificationsAction.failure,
      (state, { payload }) => ({
        ...state,
      }),
    )

    builder.addCase(setNotificationsCounterAction, state => ({
      ...state,
      notificationsCounter: state.notificationsCounter + 1,
    }))

    builder.addCase(resetNotificationsCounterAction, state => ({
      ...state,
      notificationsCounter: 0,
    }))
  },
)
