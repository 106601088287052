import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Assets } from '../../assets'
import {
  Container,
  Title,
  SimpleDivider,
} from '../../components/Styled/formStyled'
import {
  StyledTableWrapper,
  StyledViewButton,
  TableInstance,
} from '../../components/Table'
import { StarWrapper } from './styled'
import Rating from '@mui/material/Rating'
import { defaultFormatDate } from '../../helpers'
import { useDispatch } from 'react-redux'
import { getFeedbackAction } from '../../store/feedback'
import { useTypedSelector } from '../../hooks'
import { Pagination } from '../../components/Pagination'
import { IPagination } from '../../components/Pagination/types'

export const FeedbacksPage: FC = ({}) => {
  const [table, setTable] = useState<any[]>([])

  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })

  const dispatch = useDispatch()

  const { feedbacks } = useTypedSelector(state => state.feedbacks)

  useEffect(() => {
    dispatch(
      getFeedbackAction.request(
        `limit=${pagination.limit}&skip=${pagination.skip + 1}`,
      ),
    )
  }, [pagination])

  useEffect(() => {
    if (feedbacks.data) {
      setTable(
        feedbacks.data.map(m => {
          return {
            ...m,
            stars: (
              <StarWrapper>
                <span>{m.stars}</span>
                <SimpleDivider width="5px" />
                <Rating readOnly value={m.stars} />
              </StarWrapper>
            ),
            product: m?.productId?.ru,
            user: `${m.userId?.surname ? m?.userId?.surname : ''} ${
              m.userId?.name ? m.userId?.name : ''
            } ${m.userId?.patronymic ? m.userId?.patronymic : ''}`,
            createdAt: defaultFormatDate(m?.createdAt),
            viewButton: (
              <StyledViewButton
                onClick={() => history.push(`/feedback/${m._id}`)}
              >
                <img
                  height="24px"
                  width="24px"
                  src={Assets.SHOW_PASSWORD_ICON}
                />
              </StyledViewButton>
            ),
          }
        }),
      )
    }
  }, [feedbacks])

  const history = useHistory()

  const columns = [
    { Header: 'Дата', accessor: 'createdAt' },
    { Header: 'Пользователь', accessor: 'user' },
    { Header: 'Товар', accessor: 'product' },
    { Header: 'Оценка', accessor: 'stars' },
    { Header: 'Отзыв', accessor: 'comment' },
    { accessor: 'viewButton' },
  ]

  return (
    <Container>
      <Title>Отзывы</Title>
      <SimpleDivider height="20px" />
      <StyledTableWrapper>
        <TableInstance tableData={table} importedColumns={columns} />

        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={feedbacks.totalCount}
        />
      </StyledTableWrapper>
    </Container>
  )
}
