import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastsProvider, LoaderProvider } from './contexts'
import { persistor, store } from './store'
import { AppRouter } from './router'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoaderProvider>
          <ToastsProvider>
            <QueryClientProvider client={queryClient}>
              <AppRouter />
            </QueryClientProvider>
          </ToastsProvider>
        </LoaderProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
