import styled from 'styled-components'
import { TStyledButton, TStyledLabel } from './types'
import { FLEX, FONT } from '../../utils'
import { NavLink } from 'react-router-dom'

export const StyledButton = styled.div<TStyledButton>`
  cursor: pointer;
  padding: 14px 16px;
  border-radius: 8px;
  background: ${({ background }) => background};
  border: ${({ border }) => `${border} 1px solid`};
  ${FLEX({})};
  &:hover {
    box-shadow: ${({ background }) => `0px 0px 7px ${background}`};
  }
`

export const StyledNavLink = styled(NavLink)<TStyledButton>`
  padding: 14px 16px;
  border-radius: 8px;
  width: 100%;
  max-width: ${({ maxwidth = '100' }) => maxwidth};
  background: ${({ background }) => background};
  border: ${({ border }) => `${border} 1px solid`};
  ${FLEX({})};
  &:hover {
    box-shadow: ${({ background }) => `0px 0px 7px ${background}`};
  }
`

export const Label = styled.label<TStyledLabel>`
  display: flex;
  align-items: center;
  ${({ color }) => FONT({ color: color, size: '15px', weight: '600' })};
  &:hover {
    cursor: pointer;
  }
`
