import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { FC, useState } from 'react'
import { Assets, Colors } from '../../assets'
import { BtnWrapper, DumbButton } from '../../components/FormComponents/styled'
import { RowStartWrapper, RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import {
  ProfileInput,
  ProfileInputError,
  ProfileInputLabel,
  ProfileInputWrapper,
  UserImage,
} from '../Profile/styled'
import {
  ColumnFlex,
  ColumnWrapper,
  GrayTitle,
  LinkWithIcon,
  UserProfileContent,
} from './styled'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createUserAction, getRolesSelector } from '../../store/roles'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const CreateUserProfile: FC = () => {
  const [name, setname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [role, setRole] = useState<string>('')

  const dispatch = useDispatch()

  const history = useHistory()

  const { roles } = useSelector(getRolesSelector)

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(/^([^0-9]*)$/, 'Имя не должно содержать цифр')
      .required('Введите имя'),
    email: yup
      .string()
      .email('Введите валидный e-mail')
      .required('Введите email'),
    role: yup.string().required('Выберите роль'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = data => {
    dispatch(createUserAction.request({ email: email, role: role, name: name }))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RowWrapper>
        <RowStartWrapper width="auto">
          <ColumnFlex>
            <GrayTitle>Создать пользователя</GrayTitle>
          </ColumnFlex>

          <LinkWithIcon onClick={() => history.goBack()}>
            <img src={Assets.ARROW_BACK_ICON} alt="" />
            Назад
          </LinkWithIcon>
        </RowStartWrapper>

        <BtnWrapper>
          <DumbButton bg={Colors.orange} txt={Colors.black} type="submit">
            Создать
          </DumbButton>
        </BtnWrapper>
      </RowWrapper>

      <SimpleDivider height="22px" />

      <ColumnWrapper padding="30px">
        <UserProfileContent>
          <UserImage src={Assets.USER_ICON}></UserImage>

          <SimpleDivider height="28px" />

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>Имя</ProfileInputLabel>
            <ProfileInput
              value={name}
              className={`${errors.name ? 'error' : ''}`}
              {...register('name')}
              onChange={e => setname(e.target.value)}
            />
            {errors.name && (
              <ProfileInputError>{errors.name.message}</ProfileInputError>
            )}
          </ProfileInputWrapper>

          <SimpleDivider height="10px" />

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>E-mail</ProfileInputLabel>
            <ProfileInput
              value={email}
              {...register('email')}
              onChange={e => setEmail(e.target.value)}
              className={`${errors.email ? 'error' : ''}`}
            />
            {errors.email && (
              <ProfileInputError>{errors.email.message}</ProfileInputError>
            )}
          </ProfileInputWrapper>

          <SimpleDivider height="10px" />

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>Роль</ProfileInputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              {...register('role')}
              value={role}
              onChange={e => setRole(e.target.value)}
              style={
                errors.role
                  ? {
                      width: '100%',
                      height: '40px',
                      border: '1px solid #EE1313',
                    }
                  : { width: '100%', height: '40px' }
              }
            >
              {roles.map(role => (
                <MenuItem value={role._id} key={role._id}>
                  {role.title}
                </MenuItem>
              ))}
            </Select>
            {errors.role && (
              <ProfileInputError>{errors.role.message}</ProfileInputError>
            )}
          </ProfileInputWrapper>
        </UserProfileContent>
      </ColumnWrapper>
    </form>
  )
}

export default CreateUserProfile
