import { createActionTypes, createApiActions } from '../actions-util'
import { createAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import {
  GetUserActionResponse,
  LoginUserActionPayload,
  LoginUserActionResponse,
  ForgotPasswordActionPayload,
  ChangeUserDataPayload,
  ChangeUserDataResponse,
  ChangeEmailActionResponse,
  ChangePasswordActionPayload,
  ChangeUserPhotoPayload,
  SendChangeEmailLinkActionPayload,
} from './types'

export const loginAction = createApiActions<
  LoginUserActionPayload,
  LoginUserActionResponse,
  AxiosError
>(createActionTypes('USER/LOGIN_USER'))

export const getUserAction = createApiActions<
  void,
  GetUserActionResponse,
  AxiosError
>(createActionTypes('USER/GET_USER'))

export const logoutAction = createApiActions<void, void, AxiosError>(
  createActionTypes('USER/LOGOUT'),
)

export const initAppAction = createApiActions<void, void, AxiosError>(
  createActionTypes('USER/INIT_APP'),
)

export const verifyToken = createAction<void, 'USER/VERIFY_TOKEN'>(
  'USER/VERIFY_TOKEN',
)

export const forgotPasswordAction = createAction<
  ForgotPasswordActionPayload,
  'USER/FORGOT_PASSWORD'
>('USER/FORGOT_PASSWORD')

export const sendChangeEmailLinkAction = createApiActions<
  SendChangeEmailLinkActionPayload,
  void,
  AxiosError
>(createActionTypes('USER/SEND_CHANGE_EMAIL_LINK'))

export const changeEmailAction = createApiActions<string, void, AxiosError>(
  createActionTypes('USER/CHANGE_EMAIL'),
)

export const changePasswordAction = createApiActions<
  ChangePasswordActionPayload,
  void,
  AxiosError
>(createActionTypes('USER/CHANGE_PASSWORD'))

export const changeUserDataAction = createApiActions<
  ChangeUserDataPayload,
  ChangeUserDataPayload,
  AxiosError
>(createActionTypes('USER/CHANGE_DATA'))

export const changeUserPhotoAction = createApiActions<
  ChangeUserPhotoPayload,
  GetUserActionResponse,
  AxiosError
>(createActionTypes('USER/CHANGE_PHOTO'))
