import { AxiosResponse } from 'axios'
import {
  GetCustomerActionResponse,
  UpdateCustomerActionResponse,
  GetCustomerByPhoneActionResponse,
} from './types'

import { HttpService } from '../../services'
import { CUSTOMERS_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class CustomerAPI extends HttpService {
  static getMyCustomers({
    authorization,
    data,
  }): Promise<AxiosResponse<GetCustomerActionResponse>> {
    return this.request<GetCustomerActionResponse>('GET_MY_CUSTOMERS', {
      url: CUSTOMERS_URL.getMyCustomers(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getCustomers({
    authorization,
    data,
  }): Promise<AxiosResponse<GetCustomerActionResponse>> {
    return this.request<GetCustomerActionResponse>('GET_CUSTOMERS', {
      url: CUSTOMERS_URL.getAll(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getCustomer(
    request: any,
  ): Promise<AxiosResponse<GetCustomerActionResponse>> {
    return this.request<GetCustomerActionResponse>('GET_CUSTOMER', {
      url: CUSTOMERS_URL.getCustomerById(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateCustomer({
    authorization,
    id,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<UpdateCustomerActionResponse>> {
    console.log(data)
    return this.request<UpdateCustomerActionResponse>('UPDATE_CUSTOMER', {
      url: CUSTOMERS_URL.getCustomerById(id),
      method: 'PUT',
      data: { ...data },
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getCustomerByPhone({
    authorization,
    phone,
  }: PayloadAuth & any): Promise<
    AxiosResponse<GetCustomerByPhoneActionResponse>
  > {
    return this.request<GetCustomerByPhoneActionResponse>(
      'GET_CUSTOMER_BY_PHONE',
      {
        url: CUSTOMERS_URL.getCustomerByPhone(phone),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static sortCustomersByQuery({
    authorization,
    query,
  }: PayloadAuth & any): Promise<
    AxiosResponse<GetCustomerByPhoneActionResponse>
  > {
    return this.request<GetCustomerByPhoneActionResponse>(
      'GET_CUSTOMER_BY_NAME',
      {
        url: CUSTOMERS_URL.sortCustomersByQuery(query),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }
}
