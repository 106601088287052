import { FC, useState } from 'react'
import {
  SlideContainer,
  SlideElement,
  SlideTitle,
  SlideWrapper,
} from './styled'

interface ISlide {
  label: any
  options: any
  select: any
  selected?: any
}

const Slide: FC<ISlide> = ({ label, options, select, selected = null }) => {
  const [isOpen, setIsOpen] = useState(false)
  const clickHandler = (i: any) => {
    select(i)
    setIsOpen(!isOpen)
  }

  const slideElement = () => {
    let offset = 0
    return options.map((i: any, index: number) => {
      offset += 40
      return (
        <SlideElement
          key={index}
          offset={`${offset}px`}
          onClick={() => clickHandler(i)}
        >
          <div
            className={`item ${
              typeof selected === 'object'
                ? selected?._id === i._id
                  ? 'selected'
                  : ''
                : selected === i
                ? 'selected'
                : ''
            }`}
          >
            {i.ru ? i.ru : i}
          </div>
        </SlideElement>
      )
    })
  }
  return (
    <SlideWrapper>
      <SlideTitle>{label}</SlideTitle>
      <SlideContainer
        tabIndex={0}
        // onFocus={() => setIsOpen(true)}
        style={{ marginTop: '4px' }}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className={`label ${isOpen ? 'open' : ''}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div>
            {typeof selected === 'object'
              ? options.some((el: any) => el?._id === selected?._id)
                ? selected.ru
                : ''
              : selected}
          </div>
        </div>
        {isOpen ? slideElement() : null}
      </SlideContainer>
    </SlideWrapper>
  )
}

export default Slide
