import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'
import {
  getProductAction,
  unsetProductAction,
  getProductsAction,
  getFilteredProductsAction,
  createProductAction,
  updateProductAction,
  deleteProductAction,
  deleteProductPhotoAction,
  updateProductPhotoAction,
  searchProductByTitleAction,
  searchProductByDateAction,
  sortProductsByQueryAction,
  editProductPromoAction,
  getPendingProductsAction,
  setProductsFilterAction,
  getMyProductsAction,
  getMyPendingProductsAction,
} from './actions'
import { updateProductPhotoOrderAction } from '.'

const InitialState: TInitialState = {
  products: {},
  product: null,
  loading: false,
  filter: '',
}

export const productsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getMyProductsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getMyProductsAction.success, (state, { payload }) => ({
      ...state,
      products: payload,
      loading: false,
    }))
    builder.addCase(getMyProductsAction.failure, (state, { payload }) => ({
      ...state,
      products: [],
      loading: false,
    }))

    builder.addCase(getMyPendingProductsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      getMyPendingProductsAction.success,
      (state, { payload }) => ({
        ...state,
        products: payload,
        loading: false,
      }),
    )
    builder.addCase(
      getMyPendingProductsAction.failure,
      (state, { payload }) => ({
        ...state,
        products: [],
        loading: false,
      }),
    )

    builder.addCase(getProductsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getProductsAction.success, (state, { payload }) => ({
      ...state,
      products: payload,
      loading: false,
    }))
    builder.addCase(getProductsAction.failure, (state, { payload }) => ({
      ...state,
      products: [],
      loading: false,
    }))

    builder.addCase(getProductAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getProductAction.success, (state, { payload }) => ({
      ...state,
      product: payload.data,
      loading: false,
    }))
    builder.addCase(getProductAction.failure, (state, { payload }) => ({
      ...state,
      product: null,
      loading: false,
    }))

    builder.addCase(getFilteredProductsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      getFilteredProductsAction.success,
      (state, { payload }) => ({
        ...state,
        products: payload.data,
        loading: false,
      }),
    )
    builder.addCase(
      getFilteredProductsAction.failure,
      (state, { payload }) => ({
        ...state,
        products: [],
        loading: false,
      }),
    )

    builder.addCase(createProductAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createProductAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createProductAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(updateProductAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateProductAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(updateProductAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteProductAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteProductAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteProductAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteProductPhotoAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteProductPhotoAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteProductPhotoAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(updateProductPhotoAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateProductPhotoAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(updateProductPhotoAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(updateProductPhotoOrderAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      updateProductPhotoOrderAction.success,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
    builder.addCase(
      updateProductPhotoOrderAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )

    builder.addCase(searchProductByTitleAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      searchProductByTitleAction.success,
      (state, { payload }) => ({
        ...state,
        products: payload.data,
        loading: false,
      }),
    )
    builder.addCase(
      searchProductByTitleAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )

    builder.addCase(searchProductByDateAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      searchProductByDateAction.success,
      (state, { payload }) => ({
        ...state,
        products: payload.data,
        loading: false,
      }),
    )
    builder.addCase(
      searchProductByDateAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )

    builder.addCase(sortProductsByQueryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      sortProductsByQueryAction.success,
      (state, { payload }) => ({
        ...state,
        products: payload,
        loading: false,
      }),
    )
    builder.addCase(
      sortProductsByQueryAction.failure,
      (state, { payload }) => ({
        ...state,
        products: [],
        loading: false,
      }),
    )

    builder.addCase(editProductPromoAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(editProductPromoAction.success, (state, { payload }) => ({
      ...state,
      //   products: payload,
      loading: false,
    }))
    builder.addCase(editProductPromoAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(unsetProductAction, state => ({
      ...state,
      product: null,
      loading: false,
    }))

    builder.addCase(getPendingProductsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getPendingProductsAction.success, (state, { payload }) => ({
      ...state,
      products: payload,
      loading: false,
    }))
    builder.addCase(getPendingProductsAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setProductsFilterAction, (state, { payload }) => ({
      ...state,
      filter: payload,
    }))
  },
)
