import { Colors } from './../../assets/colors'
import styled from 'styled-components'
import Modal from 'react-modal'
import { FLEX, FONT } from '../../utils'
import { memo } from 'react'
import { lighten } from '@material-ui/core'

export const StyledRadio = styled.input`
  background: ${Colors.white};
  border: ${Colors.gray} 1px solid;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  appearance: none;

  &:hover {
    cursor: pointer;
    border: ${Colors.boldGray} 1px solid;
  }
  &:checked {
    background: radial-gradient(${Colors.green} 40%, rgba(255, 0, 0, 0) 50%);
  }
`

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Radio = styled.div`
  display: flex;
`

export const InfoLabel = styled.label`
  margin-left: 8px;
  font-weight: bold;
`

export const InfoInput = styled.input`
  margin: 4px 30px 0 0;
  padding: 10px 0 9px 8px;
  height: 40px;
  width: calc(100% - 30px);
  border: ${Colors.gray} 1px solid;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${Colors.gray};
  }
`

export const InfoTextArea = styled.textarea`
  margin: 4px 30px 0 0;
  padding: 10px 8px;
  height: 100px;
  width: calc(100% - 30px);
  border: ${Colors.gray} 1px solid;
  border-radius: 3px;
  resize: vertical;

  &:focus {
    outline: none;
  }
`

export const StyledModal = styled(Modal)`
  .ReactModal__Content {
    padding: 50px 80px;
    width: 450px;
    height: 450px;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    background-color: ${Colors.white};
    & > h1 {
      font-size: 25px;
      text-align: center;
      color: ${Colors.boldGray};
    }
    & > form {
      display: flex;
      flex-direction: column;
      // align-items: center;
    }
    & > form > label {
      margin: 5px 0 0 5px;
      font-weight: bold;
    }
    & > form > input {
      margin-top: 5px;
      padding: 10px 0 9px 8px;
      height: 35px;
      width: 100%;
      border: ${Colors.gray} 1px solid;
      border-radius: 3px;
      &:focus {
        outline: none;
      }
    }
    & > form > button {
      margin-top: 30px;
      width: 100px;
      font-weight: bold;
      cursor: pointer;
      padding: 10px 10px;
      border-radius: 8px;
      box-shadow: 0px 3px 6px ${Colors.orange};
      background: ${Colors.orange};
      border: none;
    }
    & > div {
      margin-left: auto;
    }
  }
`

export const ModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);
`

export const ModalButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: ${Colors.orange};
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin-top: 18px;
  /* margin: 16px 30px 0 0; */
  & > img {
    width: 20px;
    height: 20px;
    margin: 0;
  }
`

export const UploadButton = styled.label`
  margin: 0 0 10px 0;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${Colors.green};
  color: ${Colors.white};
  background: ${Colors.green};
  /* width: 200px;
  display: flex; */
  display: inline-block;
`

export const ImageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  & > label {
    text-decoration: underline;
    color: ${Colors.red};
  }
`

export const AddItemModalContent = styled.div`
  padding: 40px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 50px 80px; */
  width: 1000px;
  height: 90vh;
  border-radius: 25px;
  background-color: ${Colors.white};
  & > h1 {
    margin: 0;
    color: ${Colors.boldGray};
  }
  & > input {
    margin: 30px 0;
    border: ${Colors.gray} 1px solid;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    &:focus {
      outline: none;
      border: ${Colors.boldGray} 1px solid;
    }
  }
`

export const ModalContent = styled.div`
  padding: 50px 80px;
  width: 450px;
  height: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  background-color: ${Colors.white};
  & > h1 {
    font-size: 25px;
    text-align: center;
    color: ${Colors.boldGray};
  }
  & > form {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  & > form > label {
    margin: 5px 0 0 5px;
    font-weight: bold;
  }
  & > form > input {
    margin-top: 5px;
    padding: 10px 0 9px 8px;
    height: 35px;
    width: 100%;
    border: ${Colors.gray} 1px solid;
    border-radius: 3px;
    &:focus {
      outline: none;
    }
  }
  & > form > button {
    margin-top: 30px;
    width: 100px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px ${Colors.orange};
    background: ${Colors.orange};
    border: none;
  }
  & > div {
    margin-left: auto;
  }

  & > div > img {
    &:hover {
      cursor: pointer;
    }
  }
`

export const SubcategoryContainer = styled.div`
  margin: 10px 0;
  ${FLEX({ justify: 'space-between' })}
  width: 100%;
`

export const SlideTitle = styled.label`
  font-weight: bold;
  margin-left: 8px;
`

export const SlideContainer = styled.div`
  position: relative;
  width: 100%;
  user-select: none;
  ${FLEX({ direction: 'row', align: 'flex-start', justify: 'flex-start' })};
  & > div {
    /* width: calc(100% - 30px); */
    width: 100%;
    /* margin: 4px 30px 0 0; */
  }
  & .label {
    padding: 10px 5px;
    background: white;
    border: 1px ${Colors.gray} solid;
    border-radius: 3px;
    height: 40px;
    &:hover {
      cursor: pointer;
    }
  }
  & .open {
    border-radius: 3px 3px 0 0;
    border-bottom: none;
  }

  & .edit {
    ${FLEX({})};
    background: ${Colors.orange};
    border-radius: 4px;
    /* border: 1px solid ${Colors.orange}; */
    width: 40px;
    height: 40px;
  }
`

export const SlideElement = styled.div<{ offset: string }>`
  & .item {
    position: absolute;
    ${FLEX({ justify: 'space-between' })}
    top: ${props => props.offset};
    z-index: 2;
    border: 1px ${Colors.gray} solid;
    padding: 10px 5px;
    /* width: calc(100% - 30px); */
    width: 100%;
    background: white;
    &:hover {
      cursor: pointer;
    }
    &:last-child {
      border-radius: 0 0 3px 3px;
      border-bottom: 1px ${Colors.gray} solid;
    }
  }
  & .selected {
    background: ${Colors.green};
    color: ${Colors.white};
  }
`

export const SlideWrapper = styled.div`
  margin: 0px 0px 10px 0px;
  width: 100%;
`

export const BtnWrapper = styled.div`
  ${FLEX({ justify: 'flex-end', align: 'center' })};
  gap: 30px;
`

export const DumbOutlineButton = styled.button<{
  color: string
}>`
  font-weight: bold;
  cursor: pointer;
  padding: 14px 35px;
  border-radius: 8px;
  border: 1px solid ${({ color }) => color};
  background: transparent;
  color: ${({ color }) => color};
  ${FLEX({})};
  outline: none;
  &.cancel {
    width: 212px;
  }
`

export const DumbButton = styled.button<{
  bg: string
  txt: string
  padding?: string
}>`
  font-weight: bold;
  cursor: pointer;
  padding: ${({ padding = '14px 65px' }) => padding};
  border-radius: 8px;
  background: ${({ bg }) => bg};
  color: ${({ txt }) => txt};
  ${FLEX({})};
  gap: 10px;
  outline: none;
  border: none;
  &:hover {
    box-shadow: 0px 0px 5px ${({ bg }) => bg};
  }
`

export const DumbInput = styled.input`
  margin: 4px 30px 0 0;
  padding: 10px 0 9px 8px;
  height: 40px;
  width: calc(100% - 30px);
  border: ${Colors.gray} 1px solid;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${Colors.gray};
  }
`

export const StyledInputLabel = styled.label`
  font-weight: bold;
  margin-left: 8px;
`
export const FilterDropdownWrapper = styled.div<{ maxWidth?: string }>`
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth = 100 }) => maxWidth};
`

export const FilterDropdownLabel = styled.div`
  ${FLEX({ justify: 'space-between', align: 'center', wrap: 'nowrap' })}
  cursor: pointer;
  border: 1px solid ${Colors.gray};
  border-radius: 8px;
  padding: 0 8px;
  height: 45px;
  background: ${Colors.white};
  width: 100%;
  ${FONT({ size: '16px', weight: '500' })};
  white-space: nowrap;
  gap: 10px;
  &.disable {
    background: ${Colors.backgroundGray};
    pointer-events: none;
  }
`
export const FilterDropdownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-top: 5px solid ${Colors.black};
  border-right: 4px solid transparent;
  &.open {
    transform: rotate(180deg);
  }
`
export const FilterDropdownList = styled.div`
  ${FLEX({ direction: 'column' })}
  gap: 17px;
  display: none;
  background: ${Colors.white};
  z-index: 2;
  border-radius: 8px;
  box-shadow: 0px 4px 8px #43434329;
  position: absolute;
  top: 50px;
  left: 0;
  overflow: hidden;
  padding: 18px;
  &.open {
    display: block;
  }
`
export const FilterDropdownElement = styled.div`
  ${FLEX({ align: 'center', justify: 'space-between', wrap: 'nowrap' })};
  gap: 30px;
  cursor: pointer;
`
export const FilterDropdownButton = memo(styled.div`
  ${FONT({ weight: '600', size: '16px' })};
  cursor: pointer;
  color: ${Colors.green};
  margin-top: 15px;
`)

export const FilterDropdownTitle = styled.div`
  ${FONT({ weight: '500', size: '16px' })};
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FilterDropdownInputWrapper = styled.div`
  ${FLEX({ align: 'center', justify: 'flex-start', wrap: 'nowrap' })};
  gap: 20px;
`

export const FilterInput = styled.input`
  border: 1px solid ${Colors.gray};
  border-radius: 8px;
  padding: 0 8px;
  height: 45px;
  max-width: 140px;
`
