import { AxiosResponse } from 'axios'
import {
  ChangeSupportStatusActionResponse,
  SetSupportMessagesActionPayload,
  SetSupportMessagesActionResponse,
} from './types'
import { setAuthorizationHeadersProp } from '../../helpers'
import { HttpService } from '../../services'
import { SUPPORT_URL } from './config'
import { PayloadAuth } from '../types'

export class SupportAPI extends HttpService {
  static getSupportMessages(
    request: SetSupportMessagesActionPayload,
  ): Promise<AxiosResponse<SetSupportMessagesActionResponse>> {
    return this.request<SetSupportMessagesActionResponse>(
      'GET_SUPPORT_MESSAGES',
      {
        url: SUPPORT_URL.get_roles,
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(request.authorization),
        },
      },
    )
  }

  static changeStatus({
    authorization,
    data,
  }: PayloadAuth & any): Promise<
    AxiosResponse<ChangeSupportStatusActionResponse>
  > {
    return this.request<ChangeSupportStatusActionResponse>('CHANGE_STATUS', {
      url: SUPPORT_URL.change_status(data.id),
      method: 'PATCH',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
