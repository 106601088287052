import * as yup from 'yup'

export const productSchema = yup.object({
  en: yup.string().required().max(50),
  ru: yup.string().required().max(50),
  uz: yup.string().required().max(50),
  price: yup.number().required().positive().integer(),
  vendorCode: yup.number().required().integer(),
  discount: yup.number().required().integer().min(0).max(99),
  description: yup.string().max(500),
  dimensions: yup.string().required().max(50),
  power: yup.number().required().min(0),
  weight: yup.number().required().min(0),
  volts: yup.number().required().min(0),
  lightingArea: yup.number().required(),
  lampsIncluded: yup.number().required(),
  category: yup.object().shape({
    label: yup.string().required('Required'),
    value: yup.string().required('Required'),
  }),
  material: yup.object().shape({
    label: yup.string().required('Required'),
    value: yup.string().required('Required'),
  }),
  style: yup.object().shape({
    label: yup.string().required('Required'),
    value: yup.string().required('Required'),
  }),
  color: yup.object().shape({
    label: yup.string().required('Required'),
    value: yup.string().required('Required'),
  }),
})

export const _productSchema = yup.object({
  en: yup.string().required().max(50),
  ru: yup.string().required().max(50),
  uz: yup.string().required().max(50),
  price: yup.number().required().positive().integer(),
  vendorCode: yup.string().required().max(50),
  discount: yup.number().required().integer().min(0).max(99),
  description: yup.string().max(500),
  customCharacteristic: yup.string().min(0).max(50),
})
