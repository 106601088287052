import { api } from '../config'

export const CUSTOMERS_URL = {
  getMyCustomers: (params: string) => `${api.withAPI}/users/my/${params}`,
  getAll: (params: string) => `${api.withAPI}/users/${params}`,
  getCustomerById: (id: string) => `${api.withAPI}/users/${id}`,
  getCustomerByPhone: (fullName: string) =>
    `${api.withAPI}/users/?phone=${fullName}`,
  sortCustomersByQuery: (query: string) =>
    `${api.withAPI}/users/?sortBy=${query}`,
}
