import moment from 'moment'
import { TFormatDateTime } from './types'

export const DEFAULT_DATE_FORMAT: string = 'YYYY-MM-DD'
export const DATE_FORMAT: string = 'DD.MM.YYYY'
export const TIME_FORMAT: string = 'HH:mm'
export const HOURS_FORMAT: string = 'HH'

export const DATE_TIME_FORMAT: string = `${DATE_FORMAT} ${TIME_FORMAT}`

export const formatDate: TFormatDateTime = date => {
  return moment(date).format(DATE_FORMAT)
}

export const formatHours: TFormatDateTime = date => {
  return moment(date).format(HOURS_FORMAT)
}

export const formatTime: TFormatDateTime = date => {
  return moment(date).format(TIME_FORMAT)
}

export const defaultFormatDate: TFormatDateTime = date => {
  return moment(date).format(DATE_TIME_FORMAT)
}

export const defaulDateFormat: TFormatDateTime = date => {
  return moment(date).format(DEFAULT_DATE_FORMAT)
}
