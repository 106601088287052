import { createReducer } from '@reduxjs/toolkit'
import { IInitialState } from './types'

import {
  createRoleAction,
  createUserAction,
  setAdminsAction,
  setRolesAction,
  updatePermissionsAction,
  updateRolesAction,
  deleteUserAction
} from './actions'

const InitialState: IInitialState = {
  loading: false,
  roles: [],
  admins: {},
}

export const rolesReducer = createReducer<IInitialState>(
  InitialState,
  builder => {
    builder.addCase(setRolesAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(setRolesAction.success, (state, { payload }) => ({
      ...state,
      roles: payload.data,
      loading: false,
    }))
    builder.addCase(setRolesAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setAdminsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(setAdminsAction.success, (state, { payload }) => ({
      ...state,
      admins: payload,
      loading: false,
    }))
    builder.addCase(setAdminsAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(updatePermissionsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updatePermissionsAction.success, (state, { payload }) => ({
      ...state,
      roles: payload.data,
      loading: false,
    }))
    builder.addCase(updatePermissionsAction.failure, state => ({
      ...state,
      loading: false,
    }))

    builder.addCase(createRoleAction.request, state => ({
      ...state,

      loading: true,
    }))
    builder.addCase(createRoleAction.success, (state, { payload }) => ({
      ...state,
      roles: payload.data,
      loading: false,
    }))
    builder.addCase(createRoleAction.failure, state => ({
      ...state,
      loading: false,
    }))

    builder.addCase(updateRolesAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateRolesAction.success, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(updateRolesAction.failure, state => ({
      ...state,
      loading: false,
    }))

    builder.addCase(createUserAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createUserAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createUserAction.failure, state => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteUserAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteUserAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteUserAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
  },
)
