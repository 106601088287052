import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux'
import { Colors } from '../../assets'

import {
  ChangePasswordModalLink,
  ChangePasswordModalWrapper,
  PassModalTitle,
  ProfileInput,
  ProfileInputError,
  ProfileInputLabel,
  ProfileInputWrapper,
} from '../../pages/Profile/styled'
import { Divider } from '../Divider'
import { DumbButton } from '../FormComponents/styled'
import { ChangePasswordModalContent, HelpText, ModalOverlay } from './styled'
import * as yup from 'yup'
import { IChangePasswordForm } from './types'
import { changePasswordAction } from '../../store'
import { EInputTemplate, Input } from '../Input'

const NEW_PASS = 'NEW_PASS'
const OLD_PASS = 'OLD_PASS'
const REPEAT_PASS = 'REPEAT_PASS'

interface IChangePasswordModalProps {
  isModalOpen: boolean
  onModalClose: () => void
  onForgotPassModalOpen: () => void
}

const ChangePasswordModal: FC<IChangePasswordModalProps> = ({
  isModalOpen,
  onModalClose,
  onForgotPassModalOpen,
}) => {
  const [passwords, setPasswords] = useState({
    oldPass: '',
    newPass: '',
    repeatPass: '',
  })

  const dispatch = useDispatch()

  const inputHandler = (password: any, type: string) => {
    switch (type) {
      case OLD_PASS:
        setPasswords(prev => ({
          ...prev,
          oldPass: password.target.value,
        }))
        return
      case NEW_PASS:
        setPasswords(prev => ({
          ...prev,
          newPass: password.target.value,
        }))
        return
      case REPEAT_PASS:
        setPasswords(prev => ({
          ...prev,
          repeatPass: password.target.value,
        }))
        return
    }
  }

  const schema = yup.object().shape({
    oldPass: yup
      .string()
      .required('Введите пароль')
      .min(6, 'Минимально 6 символов'),
    newPass: yup.string().required('Введите пароль'),
    repeatPass: yup
      .string()
      .required('Введите пароль')
      .equals(
        [passwords.newPass],
        'Пароль не совпадает. Пожалуйста, повторите попытку.',
      ),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IChangePasswordForm> = data => {
    console.log(data)
    dispatch(
      changePasswordAction.request({
        oldPassword: data.oldPass,
        password: data.newPass,
      }),
    )
    onModalClose()
  }

  const onChangeText = (
    text: any,
    onChange: (...event: any[]) => void,
    name: any,
    trigger: any,
  ) => {
    onChange(text)
    trigger(name)
  }

  useEffect(() => {}, [])

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={() => onModalClose()}
      shouldCloseOnOverlayClick
      className="_"
      overlayClassName="_"
      contentElement={(props, children) => (
        <ChangePasswordModalContent {...props}>
          {children}
        </ChangePasswordModalContent>
      )}
      overlayElement={(props, contentElement) => (
        <ModalOverlay {...props}>{contentElement}</ModalOverlay>
      )}
    >
      <ChangePasswordModalWrapper>
        <PassModalTitle>Изменить пароль</PassModalTitle>

        <Divider height={30} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProfileInputWrapper>
            <Controller
              name="oldPass"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label="Старый Пароль"
                  value={value}
                  template={EInputTemplate.PASSWORD}
                  error={errors.oldPass?.message || ''}
                  onChange={val => {
                    onChangeText(val, onChange, 'oldPass', trigger)
                    inputHandler(val, OLD_PASS)
                    trigger()
                  }}
                  isValid={!!errors.oldPass?.message}
                />
              )}
            />

            {errors.oldPass && (
              <ProfileInputError>{errors.oldPass.message}</ProfileInputError>
            )}

            {/* <ProfileInput
              {...register('oldPass')}
              value={passwords.oldPass}
              onChange={password => inputHandler(password, OLD_PASS)}
              className={`${errors.oldPass ? 'error' : ''}`}
              type="password"
            /> */}
          </ProfileInputWrapper>

          <Divider height={15} />

          <ProfileInputWrapper>
            <Controller
              name="newPass"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label="Новый Пароль"
                  value={value}
                  template={EInputTemplate.PASSWORD}
                  error={errors.newPass?.message || ''}
                  onChange={val => {
                    onChangeText(val, onChange, 'newPass', trigger)
                    inputHandler(val, NEW_PASS)
                  }}
                  isValid={!!errors.newPass?.message}
                />
              )}
            />

            {errors.newPass && (
              <ProfileInputError>{errors.newPass.message}</ProfileInputError>
            )}
          </ProfileInputWrapper>

          <HelpText>
            Формат и последовательность пароля: Первая заглавная буква (A-Z),
            три маленьких буквы (a-z), четыре цифры (0-9).
          </HelpText>

          <Divider height={15} />

          <ProfileInputWrapper>
            <Controller
              name="repeatPass"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label="Повторите пароль"
                  value={value}
                  template={EInputTemplate.PASSWORD}
                  error={errors.repeatPass?.message || ''}
                  onChange={val => {
                    onChangeText(val, onChange, 'repeatPass', trigger)
                    inputHandler(val, REPEAT_PASS)
                  }}
                  isValid={!!errors.repeatPass?.message}
                />
              )}
            />

            {errors.repeatPass && (
              <ProfileInputError>{errors.repeatPass.message}</ProfileInputError>
            )}
          </ProfileInputWrapper>

          <Divider height={20} />

          <DumbButton
            bg={Colors.orange}
            txt={Colors.black}
            type="submit"
            style={{ width: '100%' }}
          >
            Изменить
          </DumbButton>
        </form>

        <Divider height={30} />

        <ChangePasswordModalLink onClick={onForgotPassModalOpen}>
          Забыли пароль?
        </ChangePasswordModalLink>
      </ChangePasswordModalWrapper>
    </ReactModal>
  )
}

export default ChangePasswordModal
