import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  getBonusesAction,
  getExchangeRateAction,
  setBonusesAction,
  setExchangeRateAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { SettingsAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* getExchangeRateWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof SettingsAPI.getExchangeRate>
    > = yield call([SettingsAPI, SettingsAPI.getExchangeRate], {
      authorization: token,
    })
    console.log(response)
    yield put(getExchangeRateAction.success(response.data.data[0]))
  } catch (e) {
    console.log('Error: getExrateWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категорий')),
    )
    yield put(getExchangeRateAction.failure(e as AxiosError))
  }
}

function* setExchangeRateWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)
    const { data } = payload

    const res: PromiseReturnType<
      ReturnType<typeof SettingsAPI.setExchangeRate>
    > = yield call([SettingsAPI, SettingsAPI.setExchangeRate], {
      data: payload.data,
      authorization: token,
    })

    const response: PromiseReturnType<
      ReturnType<typeof SettingsAPI.getExchangeRate>
    > = yield call([SettingsAPI, SettingsAPI.getExchangeRate], {
      authorization: token,
    })
    console.log(response)
    yield put(setExchangeRateAction.success(response.data.data[0]))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категорий')),
    )
    yield put(setExchangeRateAction.failure(e as AxiosError))
  }
}

function* getBonusesWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof SettingsAPI.getBonuses>
    > = yield call([SettingsAPI, SettingsAPI.getBonuses], {
      authorization: token,
    })

    yield put(getBonusesAction.success(response.data.data))
  } catch (e) {
    console.log('Error: getExrateWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения бонусов')),
    )
    yield put(getBonusesAction.failure(e as AxiosError))
  }
}

function* setBonusesWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)

    const res: PromiseReturnType<ReturnType<typeof SettingsAPI.setBonuses>> =
      yield call([SettingsAPI, SettingsAPI.setBonuses], {
        data: payload.data,
        authorization: token,
      })

    const response: PromiseReturnType<
      ReturnType<typeof SettingsAPI.getBonuses>
    > = yield call([SettingsAPI, SettingsAPI.getBonuses], {
      authorization: token,
    })
    console.log(response)
    yield put(setBonusesAction.success(response.data.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка изменения бонусов')),
    )
    yield put(setBonusesAction.failure(e as AxiosError))
  }
}

export function* settingsWatcher() {
  yield takeLatest(getExchangeRateAction.request, getExchangeRateWorker)
  yield takeLatest(setExchangeRateAction.request, setExchangeRateWorker)
  yield takeLatest(getBonusesAction.request, getBonusesWorker)
  yield takeLatest(setBonusesAction.request, setBonusesWorker)
}
