import { useState } from 'react'
import Topbar from './Topbar'
import Sidebar from './Sidebar'
import { LayoutWrapper, ContentWrapper, ContentInnerWrapper } from './styled'
import { NotFound } from '../NotFound/NotFound'
import { useLocation } from 'react-router-dom'

const Layout = ({ children }: any) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true)

  const location = useLocation()

  if (location.pathname === '/not-found') {
    return <NotFound />
  }

  return (
    <LayoutWrapper>
      <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <ContentWrapper className={isSidebarOpen ? '' : 'active'}>
        <Topbar />

        <ContentInnerWrapper>{children}</ContentInnerWrapper>
      </ContentWrapper>
    </LayoutWrapper>
  )
}

export default Layout
