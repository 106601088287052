import axios from 'axios'
import { api } from '../../store'

export const createBrand = async (data: any, token: string) => {
  return axios({
    method: 'post',
    url: `${api.withAPI}/brands/`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
  })
}
