import { api } from '../config'

export const STORES_URL = {
  rootPath: `${api.withAPI}/stores`,
  ownStoresBySupplierId: `${api.withAPI}/stores/my`,
  stats: params => `${api.withAPI}/stores/stats/${params}`,
  idPath: (id: string) => `${api.withAPI}/stores/${id}`,
  withoutItemPath: (id: string) =>
    `${api.withAPI}/stores/without-product/${id}`,
}
