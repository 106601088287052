import styled from 'styled-components'
import { Colors } from './../../assets/colors'
import { FLEX, FONT } from '../../utils'

export const StyledInputFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`

export const StyledInputFieldWidthWrapper = styled.div<{
  width?: string
  maxWidth?: string
}>`
  width: ${({ width = '100%' }) => width};
  max-width: ${({ maxWidth = '100%' }) => maxWidth};
`

export const FakeButton = styled.label<{ bg?: string; txt?: string }>`
  font-weight: bold;
  cursor: pointer;
  padding: 10px 65px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${({ bg = Colors.green }) => bg};
  background: ${({ bg = Colors.green }) => bg};
  color: ${({ txt = Colors.white }) => txt};
  ${FLEX({})};
`

export const HiddenButton = styled.button`
  display: none;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
`
