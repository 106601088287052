import { takeLatest, select, call, put, all } from '@redux-saga/core/effects'
import {
  changeEmailAction,
  changePasswordAction,
  changeUserDataAction,
  changeUserPhotoAction,
  forgotPasswordAction,
  getUserAction,
  initAppAction,
  loginAction,
  logoutAction,
  sendChangeEmailLinkAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { AuthorizationError } from '../../errors'
import { getCustomersAction } from '../customers'
import { getUserSelector } from './selectors'
import { PromiseReturnType } from '../types'
import { showToastAction } from '../toasts'
import { UserAPI } from './api.service'
import { Log } from '../../utils'
import { getCategoriesAction } from '../categories'
import { AxiosError } from 'axios'

export function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getUserWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof UserAPI.getUser>> =
      yield call([UserAPI, UserAPI.getUser], { authorization: token })

    yield put(getUserAction.success(response.data))
  } catch (e: any) {
    Log.ruddy('Error: getUserWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения пользователя'),
      ),
    )

    yield put(getUserAction.failure(e))
  }
}

function* logoutWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    yield call([UserAPI, UserAPI.logout], { authorization: token })

    yield put(logoutAction.success())
  } catch (e: any) {
    Log.ruddy('Error: logoutWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка выхода с аккаунта')),
    )

    yield put(logoutAction.failure(e))
  }
}

function* loginWorker({ payload }: ReturnType<typeof loginAction['request']>) {
  try {
    const response: PromiseReturnType<ReturnType<typeof UserAPI.login>> =
      yield call([UserAPI, UserAPI.login], payload)

    if (response) {
      yield put(loginAction.success(response.data))
    }
  } catch (e: any) {
    Log.ruddy('Error: loginWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast(
          'Данные для входа введены неверно! Проверьте правильность пароля или email',
        ),
      ),
    )

    yield put(loginAction.failure(e))
  }
}

function* forgotPasswordWorker({
  payload,
}: ReturnType<typeof forgotPasswordAction>) {
  try {
    const response: PromiseReturnType<
      ReturnType<typeof UserAPI.forgotPassword>
    > = yield call([UserAPI, UserAPI.forgotPassword], payload)

    yield put(
      showToastAction.request(
        generateSuccessToast(
          'Код для восстановления успешно отправлен на почту',
        ),
      ),
    )
  } catch (e) {
    Log.ruddy('Error: forgotPasswordWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка отправки кода для восстановления'),
      ),
    )
  }
}

function* initAppWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    if (token) {
      yield put(getUserAction.request())
      // yield put(getCustomersAction.request(''))
      yield put(getCategoriesAction.request())
    }

    yield put(initAppAction.success())
  } catch (e: any) {
    Log.ruddy('Error: initAppWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Невозможно получить данные')),
    )
    yield put(initAppAction.failure(e))
  }
}

function* sendChangeEmailLinkWorker({
  payload,
}: ReturnType<typeof sendChangeEmailLinkAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const { email } = payload
    yield call([UserAPI, UserAPI.sendChangeEmailLink], {
      authorization: token,
      data: payload,
    })
    yield put(sendChangeEmailLinkAction.success())
    yield put(
      showToastAction.request(
        generateSuccessToast('На новый email отправлено письмо'),
      ),
    )
  } catch (e: any) {
    console.log('Error: changeEmailWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка изменения email')),
    )
    yield put(sendChangeEmailLinkAction.failure(e))
  }
}

function* changeUserDataWorker({
  payload,
}: ReturnType<typeof changeUserDataAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([UserAPI, UserAPI.changeData], {
      authorization: token,
      data: payload,
    })
    yield put(changeUserDataAction.success(payload))
    yield put(showToastAction.request(generateSuccessToast('Данные обновлены')))
  } catch (e) {
    console.log('Error: changeUserDataWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка в обновлении данных')),
    )
    yield put(changeUserDataAction.failure(e as AxiosError))
  }
}

function* changePasswordWorker({
  payload,
}: ReturnType<typeof changePasswordAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([UserAPI, UserAPI.changePassword], {
      authorization: token,
      data: payload,
    })

    yield put(changePasswordAction.success())
    yield put(
      showToastAction.request(generateSuccessToast('Пароль успешно изменен')),
    )
  } catch (e) {
    console.log('Error: changeUserDataWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка изменения пароля')),
    )
    yield put(changePasswordAction.failure(e as AxiosError))
  }
}

function* changeUserPhotoWorker({
  payload,
}: ReturnType<typeof changeUserPhotoAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([UserAPI, UserAPI.changeData], {
      authorization: token,
      data: payload,
    })

    const response: PromiseReturnType<ReturnType<typeof UserAPI.getUser>> =
      //@ts-ignore
      yield call([UserAPI, UserAPI.getUser], { authorization: token })

    yield put(changeUserPhotoAction.success(response.data))
    yield put(
      showToastAction.request(
        generateSuccessToast('Фотография успешно обновлена'),
      ),
    )
  } catch (e) {
    console.log('Error: changeUserDataWorker', e)
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка в обновлении фотографии'),
      ),
    )
    yield put(changeUserDataAction.failure(e as AxiosError))
  }
}

function* changeEmailWorker({ payload }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof UserAPI.getUser>> =
      yield call([UserAPI, UserAPI.changeEmailByLink], {
        authorization: token,
        token: payload,
      })

    yield put(changeEmailAction.success())

    yield put(
      showToastAction.request(generateSuccessToast('Email успешно изменён')),
    )
  } catch (e: any) {
    Log.ruddy('Error: getUserWorker', e)

    if (e.toString().includes('409')) {
      yield put(
        showToastAction.request(generateErrorToast('Ошибка изменения почты')),
      )
    }

    yield put(changeEmailAction.failure(e))
  }
}

export function* userWatcher() {
  yield takeLatest(loginAction.request, loginWorker)
  yield takeLatest(logoutAction.request, logoutWorker)
  yield takeLatest(getUserAction.request, getUserWorker)
  yield takeLatest(forgotPasswordAction, forgotPasswordWorker)
  yield takeLatest(initAppAction.request, initAppWorker)
  yield takeLatest(sendChangeEmailLinkAction.request, sendChangeEmailLinkWorker)
  yield takeLatest(changeUserDataAction.request, changeUserDataWorker)
  yield takeLatest(changePasswordAction.request, changePasswordWorker)
  yield takeLatest(changeUserPhotoAction.request, changeUserPhotoWorker)
  yield takeLatest(changeEmailAction.request, changeEmailWorker)
}
