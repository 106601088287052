import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets } from '../../assets'
import { RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import { Title } from '../ProductCreate/styled'
import { Pagination } from '../../components/Pagination'
import { IPagination } from '../../components/Pagination/types'
import {
  ColumnsGrid,
  ColumnWrapper,
  FlexColumnsWrapper,
  ItemNavLink,
  RolesHeader,
} from '../Roles/styled'
import { getAllStoresStatsAction } from '../../store'
import { getStoreSelector } from '../../store/stores/selectors'

const Stores: FC = () => {
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })

  const dispatch = useDispatch()

  const { storesStats } = useSelector(getStoreSelector)

  useEffect(() => {
    dispatch(
      getAllStoresStatsAction.request(
        `?limit=${pagination.limit}&skip=${
          pagination.skip == 0 ? 0 : pagination.limit * pagination.skip
        }`,
      ),
    )
  }, [pagination])

  return (
    <>
      <RowWrapper>
        <Title>Склады</Title>
      </RowWrapper>

      <SimpleDivider height="20px" />

      <ColumnWrapper>
        <RolesHeader>
          <ColumnsGrid className="sort" template="45% 45% 10%">
            <div>Поставщик</div>
            <div>Складов</div>
            <div></div>
          </ColumnsGrid>
        </RolesHeader>

        <FlexColumnsWrapper>
          {storesStats.docs ? (
            storesStats.docs.map(storeStats => (
              <ColumnsGrid
                className="userPreview"
                template="45% 45% 10%"
                key={storeStats._id}
              >
                <div>{storeStats.name}</div>
                <div>{storeStats.stores}</div>
                <ItemNavLink
                  to={`stores/${storeStats._id}`}
                  className="justify-end"
                >
                  <img src={Assets.SHOW_PASSWORD_ICON} alt="" />
                </ItemNavLink>
              </ColumnsGrid>
            ))
          ) : (
            <></>
          )}
        </FlexColumnsWrapper>

        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={storesStats.totalCount}
        />
      </ColumnWrapper>
    </>
  )
}

export default Stores
