import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Assets, Colors } from '../../assets'
import {
  ModalButton,
  SubcategoryModalContent,
  ModalOverlay,
  ModalColumn,
  ModalFlexWrapper,
  CreateModalButton,
  ModalInputWrapper,
  UploadButton,
  BorderDumbButton,
} from './styled'
import {
  createSubcategory,
  createSubcategoryList,
  deleteSubcategory,
} from './api'
import { useTypedSelector } from '../../hooks'
import ReactModal from 'react-modal'
import { useMutation } from 'react-query'
import { SimpleDivider, StyledWidthWrapper } from '../Styled/formStyled'
import { DumbButton } from '../FormComponents/styled'
import { api, showToastAction } from '../../store'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { ISubcategory } from './types'

import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { subcategorySchema } from '../../validation'
import {
  FakeButton,
  HiddenButton,
  SmartInput,
  StyledInputFieldsWrapper,
  StyledInputFieldWidthWrapper,
} from '..'

import { v4 } from 'uuid'
import { ButtonWrapper } from '../Form'
import { StyledViewButton } from '../Table'

interface IModal {
  initData?: any
  addSubcategory: any
}

const NewSubcategoryModal: FC<IModal> = ({
  initData = null,
  addSubcategory,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [list, setList] = useState<any[]>([])

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(subcategorySchema),
  })

  const dispatch = useDispatch()

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const onSubmitSubcategory = (e: any) => {
    e.preventDefault()
    handleSubmit(data => {
      const { en, ru, uz } = data
      addSubcategory({
        en,
        ru,
        uz,
        _id: v4(),
      })
      reset()
      setIsOpen(!isOpen)
    })(e)
  }

  const showToast = (msg: string, type: 'error' | 'success') => {
    switch (type) {
      case 'error':
        dispatch(showToastAction.request(generateErrorToast(msg)))
        break
      case 'success':
        dispatch(showToastAction.request(generateSuccessToast(msg)))
        break
    }
  }

  const onClose = () => {
    reset()
    setList([])
    toggleModal()
  }

  return (
    <div>
      <CreateModalButton style={{}} type="button" onClick={() => toggleModal()}>
        Добавить
      </CreateModalButton>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => toggleModal()}
        shouldCloseOnOverlayClick
        className="_"
        overlayClassName="_"
        contentElement={(props, children) => (
          <SubcategoryModalContent {...props}>
            {children}
          </SubcategoryModalContent>
        )}
        overlayElement={(props, contentElement) => (
          <ModalOverlay {...props}>{contentElement}</ModalOverlay>
        )}
      >
        <div style={{ display: 'flex' }} className="row">
          <h1>Создать подкатегорию</h1>
          {/* <div style={{ marginLeft: '20px' }} className="clickable">
            <img onClick={onClose} src={Assets.CROSS_ICON} />
          </div> */}
        </div>
        <ModalFlexWrapper>
          <form onSubmit={e => onSubmitSubcategory(e)}>
            <StyledInputFieldsWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="English"
                  name="en"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Русский"
                  name="ru"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Ozbek"
                  name="uz"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
            </StyledInputFieldsWrapper>
            <HiddenButton id="hiddenCategoryListSubmit">submit</HiddenButton>
          </form>
        </ModalFlexWrapper>
        <ButtonWrapper>
          <BorderDumbButton
            brd={Colors.boldGray}
            txt={Colors.boldGray}
            bg={Colors.white}
            onClick={onClose}
            type="button"
          >
            Отмена
          </BorderDumbButton>
          <FakeButton htmlFor="hiddenCategoryListSubmit">Создать</FakeButton>
        </ButtonWrapper>
      </ReactModal>
    </div>
  )
}

export default NewSubcategoryModal
