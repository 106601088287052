import { FC, useEffect, useMemo, useState } from 'react'
import { RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import { Title } from '../ProductCreate/styled'
import {
  HelpEditMessage,
  HelpMessages,
  HelpMessagesHeader,
  HelpWrapper,
  HelpEditMessageContent,
  HelpEditMessageHeader,
  HelpEditMessageCustomerInfo,
  ArrowRight,
  HelpTextarea,
  HelpMessagesFooter,
} from './styled'
import { Assets, Colors } from '../../assets'
import {
  ProfileInput,
  ProfileInputLabel,
  ProfileInputWrapper,
} from '../Profile/styled'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { DumbButton } from '../../components/FormComponents/styled'
import HelpMessage from './HelpMessage'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeSupportStatusAction,
  getSupportSelector,
  setSupportMessagesAction,
} from '../../store/support'
import { IActiveSupportItem } from './types'
import { useHistory, useLocation } from 'react-router-dom'
import { defaultFormatDate } from '../../helpers'

const Help: FC = () => {
  const [activeItem, setActiveItem] = useState<null | IActiveSupportItem>(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const { items } = useSelector(getSupportSelector)

  console.log('activeItem', activeItem)

  const { search } = useLocation()

  const notification = useMemo(
    () => new URLSearchParams(search).get('id'),
    [search],
  )

  useEffect(() => {
    dispatch(setSupportMessagesAction.request())
  }, [])

  useEffect(() => {
    if (notification) {
      const newItem = items.find(item => {
        return item._id === notification
      })
      setActiveItem({
        id: newItem?._id,
        name: `${newItem?.user?.surname ? newItem?.user?.surname : ''} ${
          newItem?.user?.name ? newItem?.user?.name : ''
        } ${newItem?.user?.patronymic ? newItem?.user?.patronymic : ''}`,
        date: defaultFormatDate(newItem?.createdAt),
        phone: newItem?.user.phone,
        status: newItem?.status,
        topic: newItem?.subject,
        message: newItem?.message,
        userId: newItem?.user._id,
      })
    }
  }, [search, items])

  const onChooseItem = (item: any, name, date) => {
    setActiveItem({
      id: item._id,
      name: name,
      date: date,
      phone: item.user.phone,
      status: item.status,
      topic: item.subject,
      message: item.message,
      userId: item.user._id,
    })
  }

  const onChangeStatus = (value: string) => {
    setActiveItem(prev => prev && { ...prev, status: value })
  }

  const onSaveStatus = () => {
    dispatch(
      changeSupportStatusAction.request({
        id: activeItem?.id,
        status: activeItem?.status,
      }),
    )
  }

  const parseTopic = (topic: string) => {
    switch (topic) {
      case 'question':
        return 'Вопрос'

      case 'proposal':
        return 'Предложение'

      case 'complaint':
        return 'Жалоба'

      default:
        return topic
    }
  }

  if (!items) {
    return <></>
  }

  return (
    <>
      <RowWrapper>
        <Title>Поддержка</Title>
      </RowWrapper>

      <SimpleDivider height="20px" />

      <HelpWrapper>
        <HelpMessages>
          <HelpMessagesHeader>
            <span>Дата обращения</span>
            <span>Статус</span>
            <span>Сообщение</span>
          </HelpMessagesHeader>

          {items.map(item => (
            <HelpMessage
              item={item}
              onClick={onChooseItem}
              key={item._id}
              classname={item._id === activeItem?.id ? 'active' : ''}
            />
          ))}
        </HelpMessages>

        <HelpEditMessage>
          {activeItem && (
            <HelpEditMessageContent>
              {console.log(activeItem)}
              <HelpEditMessageHeader>
                <HelpEditMessageCustomerInfo
                  onClick={() => history.push('/clients/' + activeItem.userId)}
                >
                  <div>
                    <span>{activeItem.name}</span>
                    <span>{activeItem.phone}</span>
                  </div>
                  <ArrowRight src={Assets.ARROW_BACK_ICON}></ArrowRight>
                </HelpEditMessageCustomerInfo>

                <span>{activeItem.date}</span>
              </HelpEditMessageHeader>

              <ProfileInputWrapper maxWidth="300px">
                <ProfileInputLabel>Тема</ProfileInputLabel>
                <ProfileInput value={parseTopic(activeItem.topic)} readOnly />
              </ProfileInputWrapper>

              <SimpleDivider height="16px" />

              <ProfileInputWrapper>
                <ProfileInputLabel>Сообщение</ProfileInputLabel>
                <HelpTextarea
                  value={activeItem.message}
                  readOnly
                ></HelpTextarea>
              </ProfileInputWrapper>

              <SimpleDivider height="30px" />

              <HelpMessagesFooter>
                <ProfileInputWrapper maxWidth="300px">
                  <ProfileInputLabel>Статус</ProfileInputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={activeItem.status}
                    onChange={e => onChangeStatus(e.target.value)}
                    style={{ width: '100%', height: '40px' }}
                  >
                    <MenuItem value={'new'}>Новое</MenuItem>
                    <MenuItem value={'processed'}>Обработано</MenuItem>
                  </Select>
                </ProfileInputWrapper>

                <DumbButton
                  bg={Colors.orange}
                  txt={Colors.black}
                  onClick={onSaveStatus}
                >
                  Сохранить
                </DumbButton>
              </HelpMessagesFooter>
            </HelpEditMessageContent>
          )}
        </HelpEditMessage>
      </HelpWrapper>
    </>
  )
}

export default Help
