import React, { FC, useState } from 'react'
import { PageContainer } from '../../components'
import { ModalProvider } from 'styled-react-modal'
import { Modal } from '../../components/FormComponents'

interface ITest {}

const Test: FC<ITest> = () => {
  const [state, setState] = useState(0)
  return (
    <div>
      <PageContainer title="Тестовая страница" />
      <button onClick={() => setState(prev => (prev += 1))}>{state}</button>
    </div>
  )
}

export default Test
