import { createReducer } from '@reduxjs/toolkit'
import { IStoresStats, TInitialState } from './types'
import {
  getStoreAction,
  unsetStoreAction,
  getStoresAction,
  createStoreAction,
  updateStoreAction,
  deleteStoreAction,
  getStoresWithoutItemAction,
  getAllStoresStatsAction,
  getStoresStatsByIdAction,
  getMyStoresAction,
} from './actions'

const InitialState: TInitialState = {
  stores: [],
  myStores: [],
  storesStats: {} as IStoresStats,
  storesList: [],
  storesWithoutItem: [],
  store: null,
  loading: false,
}

export const storesReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getStoresAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getStoresAction.success, (state, { payload }) => ({
      ...state,
      stores: payload.data,
      loading: false,
    }))
    builder.addCase(getStoresAction.failure, (state, { payload }) => ({
      ...state,
      stores: [],
      loading: false,
    }))

    builder.addCase(getStoresWithoutItemAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      getStoresWithoutItemAction.success,
      (state, { payload }) => ({
        ...state,
        storesWithoutItem: payload.data,
        loading: false,
      }),
    )
    builder.addCase(
      getStoresWithoutItemAction.failure,
      (state, { payload }) => ({
        ...state,
        storesWithoutItem: [],
        loading: false,
      }),
    )

    builder.addCase(getStoreAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getStoreAction.success, (state, { payload }) => ({
      ...state,
      store: payload.data,
      loading: false,
    }))
    builder.addCase(getStoreAction.failure, (state, { payload }) => ({
      ...state,
      store: null,
      loading: false,
    }))

    builder.addCase(createStoreAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createStoreAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createStoreAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(updateStoreAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateStoreAction.success, (state, { payload }) => ({
      ...state,
      storesList: payload.docs,
      loading: false,
    }))
    builder.addCase(updateStoreAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteStoreAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteStoreAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteStoreAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(unsetStoreAction, state => ({
      ...state,
      store: null,
      storesWithoutItem: [],
      loading: false,
    }))

    builder.addCase(getAllStoresStatsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getAllStoresStatsAction.success, (state, { payload }) => ({
      ...state,
      storesStats: payload,
      loading: false,
    }))
    builder.addCase(getAllStoresStatsAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(getStoresStatsByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getStoresStatsByIdAction.success, (state, { payload }) => ({
      ...state,
      storesList: payload.docs,
      loading: false,
    }))
    builder.addCase(getStoresStatsByIdAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(getMyStoresAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getMyStoresAction.success, (state, { payload }) => ({
      ...state,
      myStores: payload,
      loading: false,
    }))
    builder.addCase(getMyStoresAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
  },
)
