import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  getBonusesAction,
  getExchangeRateAction,
  setBonusesAction,
  setExchangeRateAction,
} from './actions'

const InitialState: TInitialState = {
  exchangeRate: null,
  defaultBonuses: null,
  bonusesFromOrder: null,
  loading: false,
}

export const settingsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getExchangeRateAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getExchangeRateAction.success, (state, { payload }) => ({
      ...state,
      exchangeRate: payload,
      loading: false,
    }))
    builder.addCase(getExchangeRateAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setExchangeRateAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(setExchangeRateAction.success, (state, { payload }) => ({
      ...state,
      exchangeRate: payload,
      loading: false,
    }))
    builder.addCase(setExchangeRateAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(getBonusesAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getBonusesAction.success, (state, { payload }) => ({
      ...state,
      defaultBonuses: payload.defaultBonuses,
      bonusesFromOrder: payload.bonusesFromOrder,
      loading: false,
    }))
    builder.addCase(getBonusesAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setBonusesAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(setBonusesAction.success, (state, { payload }) => ({
      ...state,
      defaultBonuses: payload.defaultBonuses,
      bonusesFromOrder: payload.bonusesFromOrder,
      loading: false,
    }))
    builder.addCase(setBonusesAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
  },
)
