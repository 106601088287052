import { useEffect, useState } from 'react'
import { ICategory } from '../Category/types'
import {
  Container,
  Header,
  TitleGroup,
  Title,
  BackButton,
  CreationDate,
  Content,
  Column,
  DeleteButton,
  SimpleDivider,
  FullWidth,
  StyledWidthWrapper,
} from '../../components/Styled/formStyled'
import {
  FakeButton,
  HiddenButton,
  SmartInput,
  StyledInputFieldsWrapper,
  StyledInputFieldWidthWrapper,
} from '../../components'
import { Assets } from '../../assets'
import { format } from 'date-fns'
import { useHistory, useParams } from 'react-router-dom'
import { Slide, Image } from '../../components/FormComponents'
import { UploadButton } from '../../components/FormComponents/styled'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { categorySchema } from '../../validation'
import { IImage } from './types'
import { useDispatch } from 'react-redux'
import {
  updateCategoryDataAction,
  updateCharacteristicsDataAction,
  updateSubcategoryDataAction,
} from '../../store/categoryData'
import {
  getCategoryAction,
  pullEntityCategoryAction,
  showToastAction,
  updateCategoryAction,
  pushEntityCategoryAction,
  updateCategoryImageAction,
  createCategoryAction,
} from '../../store'
import {
  formatDate,
  generateErrorToast,
  generateSuccessToast,
  linkImage,
} from '../../helpers'
import { useTypedSelector } from '../../hooks'
import { CategoryDivider, HyperUpload } from './styled'
import CategoryData from './CategoryData'
import objId from 'bson-objectid'

const Category = () => {
  const { id } = useParams<{ id: string }>()

  const [category, setCategory] = useState<ICategory>({
    en: '',
    ru: '',
    uz: '',
    position: '',
    status: '',
    subcategories: [],
    characteristics: [],
    createdAt: '',
  })
  const [image, setImage] = useState<IImage>({ src: '', file: null })
  const [categoryStatus, setCategoryStatus] = useState('Да')

  const [categoryCharacteristics, setCategoryCharacteristics] = useState<any[]>(
    [],
  )

  const [rngCategoryCharacteristics, setRngCategoryCharacteristics] = useState<
    any[]
  >([])

  const [fxdCategoryCharacteristics, setFxdCategoryCharacteristics] = useState<
    any[]
  >([])

  const [categorySubcategories, setCategorySubcategories] = useState<any[]>([])

  const dispatch = useDispatch()

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(categorySchema),
  })

  const handleImageRemove = () => {
    setImage({ file: null, src: '' })
  }

  const history = useHistory()

  const onBack = () => {
    history.push('/categories')
  }

  const handleImage = (e: any) => {
    setImage({
      file: e.target.files[0],
      src: URL.createObjectURL(e.target.files[0]),
    })
    e.target.value = null
  }

  // const handleDelete = () => {
  //   if (window.confirm('Вы уверены?')) {
  //     categoryDeleteMutation.mutate()
  //   }
  // }

  const handleCategory = (formValues: any) => {
    const { en, ru, uz, position, status } = formValues

    const cleanSub = categorySubcategories.map(el => {
      const { _id, ...data } = el
      return data
    })

    const cleanRng = rngCategoryCharacteristics.map(el => {
      const { _id, ...data } = el
      return data
    })

    const cleanFxd = fxdCategoryCharacteristics.map(el => {
      const { _id, ...dt } = el
      const possible = dt.possibleValues.map((el2: any) => {
        const { _id: _id1, ...data } = el2
        return data
      })
      return { ...dt, possibleValues: possible }
    })

    if (window.confirm('Вы уверены?')) {
      //   const data = new FormData()
      //   console.log('cleanFxd', cleanFxd)
      //   console.log('cleanRng', cleanRng)
      //   console.log('cleanSub', cleanSub)
      //       image?.file && data.append('image', image.file)
      //       data.append('en', en)
      //       data.append('ru', ru)
      //       data.append('uz', uz)
      //       data.append('position', position)
      //       data.append('status', categoryStatus === 'Да' ? 'show' : 'hide')
      //   data.append('subcategories', cleanSub)
      //   data.append('rangeCharacteristics', cleanRng)
      //   data.append('textCharacteristics', cleanFxd)

      dispatch(
        createCategoryAction.request({
          image: image.file,
          en,
          ru,
          uz,
          position,
          status: categoryStatus === 'Да' ? 'show' : 'hide',
          subcategories: cleanSub,
          rangeCharacteristics: cleanRng,
          textCharacteristics: cleanFxd,
        }),
      )
      history.goBack()
    }
  }

  const addFxdChar = (rangeChar: any) => {
    setFxdCategoryCharacteristics(prev => [...prev, rangeChar])
    // delete rangeChar._id
    // rangeChar.possibleValues = rangeChar.possibleValues.map((el: any) => {
    //   delete el._id
    //   return el
    // })
    // dispatch(
    //   pushEntityCategoryAction.request({
    //     id,
    //     data: rangeChar,
    //     path: 'textCharacteristics',
    //   }),
    // )
  }

  const deleteFxdChar = (tempId: any) => {
    setFxdCategoryCharacteristics(prev => {
      return prev.filter(f => f._id !== tempId)
    })
    // dispatch(
    //   pullEntityCategoryAction.request({
    //     catId: id,
    //     entId: tempId,
    //     path: 'textCharacteristics',
    //   }),
    // )
  }

  const editFxdChar = (data: any) => {
    const ls = [...fxdCategoryCharacteristics]
    const index = ls.findIndex((i: any) => i._id === data._id)
    ls[index] = data
    setFxdCategoryCharacteristics(ls)
  }

  const addRangeChar = (rangeChar: any) => {
    setRngCategoryCharacteristics(prev => [...prev, rangeChar])
    // delete rangeChar._id
    // dispatch(
    //   pushEntityCategoryAction.request({
    //     id,
    //     data: rangeChar,
    //     path: 'rangeCharacteristics',
    //   }),
    // )
  }

  const deleteRangeChar = (tempId: any) => {
    setRngCategoryCharacteristics(prev => {
      return prev.filter(f => f._id !== tempId)
    })
    // dispatch(
    //   pullEntityCategoryAction.request({
    //     catId: id,
    //     entId: tempId,
    //     path: 'rangeCharacteristics',
    //   }),
    // )
  }

  const editRangeChar = (data: any) => {
    const ls = [...rngCategoryCharacteristics]
    const index = ls.findIndex((i: any) => i._id === data._id)
    ls[index] = data
    console.log()
    setRngCategoryCharacteristics(ls)
  }

  const addSubcategory = (subcat: any) => {
    setCategorySubcategories(prev => [...prev, subcat])
    // delete subcat._id
    // dispatch(
    //   pushEntityCategoryAction.request({
    //     id,
    //     data: subcat,
    //     path: 'subcategories',
    //   }),
    // )
  }

  const deleteSubcategory = (tempId: any) => {
    setCategorySubcategories(prev => {
      return prev.filter(f => f._id !== tempId)
    })
    // dispatch(
    //   pullEntityCategoryAction.request({
    //     catId: id,
    //     entId: tempId,
    //     path: 'subcategories',
    //   }),
    // )
  }

  const editSubcategory = (data: any) => {
    const ls = [...categorySubcategories]
    const index = ls.findIndex((i: any) => i._id === data._id)
    ls[index] = data
    setCategorySubcategories(ls)
  }

  const statusOptions = ['Да', 'Нет']

  return (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>Создание Категории</Title>
            <BackButton onClick={onBack}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
        </TitleGroup>
        <div style={{ display: 'flex' }}>
          <SimpleDivider width="20px" />
          <FakeButton htmlFor="hiddenSave">Сохранить</FakeButton>
        </div>
      </Header>
      <Content>
        <FullWidth>
          <form onSubmit={handleSubmit(data => handleCategory(data))}>
            <StyledInputFieldsWrapper>
              <StyledInputFieldWidthWrapper width="20%">
                <SmartInput
                  register={register}
                  title="English"
                  name="en"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>

              <StyledInputFieldWidthWrapper width="20%">
                <SmartInput
                  register={register}
                  title="Русский"
                  name="ru"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>

              <StyledInputFieldWidthWrapper width="20%">
                <SmartInput
                  register={register}
                  title="Ozbek"
                  name="uz"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="25%" />
            </StyledInputFieldsWrapper>
            <SimpleDivider height="10px" />
            <StyledInputFieldsWrapper>
              <StyledInputFieldWidthWrapper width="20%">
                <SmartInput
                  register={register}
                  title="Позиция"
                  name="position"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>

              <StyledInputFieldWidthWrapper width="20%">
                <Slide
                  label="Отображать"
                  options={statusOptions}
                  selected={categoryStatus}
                  select={setCategoryStatus}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="50%" />
            </StyledInputFieldsWrapper>
            <HiddenButton id="hiddenSave" />
            <StyledWidthWrapper width="40%">
              <Image src={image?.src} handleImage={handleImageRemove} />
              <UploadButton
                style={{ margin: '10px 0' }}
                htmlFor="hiddenCategoryImage"
              >
                Загрузить фото
              </UploadButton>
              {/* <HyperUpload>Загрузить фото</HyperUpload> */}
            </StyledWidthWrapper>
            <input
              style={{ margin: '10px 0', display: 'none' }}
              id="hiddenCategoryImage"
              type="file"
              onChange={e => handleImage(e)}
            />
          </form>
        </FullWidth>
        <CategoryDivider />
        <CategoryData
          addFixed={addFxdChar}
          deleteFixed={deleteFxdChar}
          editFixed={editFxdChar}
          addSubcategory={addSubcategory}
          deleteSubcategory={deleteSubcategory}
          editSubcategory={editSubcategory}
          subcategoryData={categorySubcategories}
          characteristicsData={categoryCharacteristics}
          addRange={addRangeChar}
          deleteRange={deleteRangeChar}
          editRange={editRangeChar}
          rangeData={rngCategoryCharacteristics}
          fixedData={fxdCategoryCharacteristics}
        />
      </Content>
    </Container>
  )
}

export default Category
