import styled from 'styled-components'

export const StyledWrapper = styled.thead`
  padding: 0;
`

export const Storage = styled.div`
  & > span {
    font-weight: bolder;
    margin-right: 24px;
  }
  margin-bottom: 4px;
`

export const CreateButtonContainer = styled.div`
  margin-left: auto;
  width: 195px;
  height: 45px;
`
