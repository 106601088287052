import { FC, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Assets } from '../../../assets'
import { DateSearchInput } from '../../../components'
import { RowStartWrapper } from '../../../components/Modals/styled'
import { ColumnsGrid } from '../../Roles/styled'
import { AddImage, DateColumn, DateRow, DeleteBanner } from '../styled'
import { CreateBannerProps } from '../types'
import { ChooseCategory } from './ChooseCategory'
import { ChooseBrand } from './ChooseBrand'
import { ChooseProduct } from './ChooseProduct'
import { getBannersSelector } from '../../../store/banners/selectors'
import {
  deleteNewBannerAction,
  setEditedNewBannersAction,
} from '../../../store/banners'

export const CreateBanner: FC<CreateBannerProps> = ({ newBanner }) => {
  const { newBanners } = useSelector(getBannersSelector)

  const dispatch = useDispatch()

  const linkType = useMemo(
    () => [
      { value: 'product', title: 'Товар' },
      { value: 'category', title: 'Категория' },
      { value: 'brand', title: 'Бренд' },
    ],
    [],
  )

  const onDeleteBanner = () => {
    const filteredBanners = newBanners.filter(item => {
      return item.id !== newBanner.id
    })

    dispatch(deleteNewBannerAction(filteredBanners))
  }

  const onEditImage = (e: any) => {
    const editedBanners = newBanners.map(banner => {
      if (banner.id === newBanner.id) {
        return {
          ...banner,
          image: {
            file: e.target.files[0],
            src: URL.createObjectURL(e.target.files[0]),
          },
        }
      }
      return banner
    })

    dispatch(setEditedNewBannersAction(editedBanners))
  }

  const onSetStart = date => {
    const editedBanners = newBanners.map(banner => {
      if (banner.id == newBanner.id) {
        return {
          ...banner,
          start: date,
        }
      }
      return banner
    })

    dispatch(setEditedNewBannersAction(editedBanners))
  }
  const onSetEnd = date => {
    const editedBanners = newBanners.map(banner => {
      if (banner.id == newBanner.id) {
        return {
          ...banner,
          end: date,
        }
      }
      return banner
    })

    dispatch(setEditedNewBannersAction(editedBanners))
  }
  const onSetLinkType = (linkType: string) => {
    const editedBanners = newBanners.map(banner => {
      if (banner.id == newBanner.id) {
        return {
          ...banner,
          linkType,
        }
      }
      return banner
    })

    dispatch(setEditedNewBannersAction(editedBanners))
  }

  const setLink = useCallback(
    (link: string) => {
      const editedBanners = newBanners.map(banner => {
        if (banner.id == newBanner.id) {
          return {
            ...banner,
            link: link,
          }
        }
        return banner
      })

      dispatch(setEditedNewBannersAction(editedBanners))
    },
    [newBanners],
  )

  return (
    <ColumnsGrid className="banner" template="8% 15% 19% 53% 5%">
      <div>{newBanner.order}</div>

      {!newBanner.image.src && (
        <AddImage>
          <label htmlFor="bannerImage">
            <img src={Assets.PLUS_BLACK_ICON} alt="add image" />
          </label>
          <input
            style={{ display: 'none' }}
            id="bannerImage"
            onChange={e => onEditImage(e)}
            type="file"
            accept="image/*"
          />
        </AddImage>
      )}

      {newBanner.image.src && (
        <img src={newBanner.image.src} alt="banner" className="banner-img" />
      )}

      <DateColumn>
        <DateRow>
          <span style={{ width: '20px' }}>С</span>
          <DateSearchInput
            value={newBanner.start}
            onChange={e => onSetStart(e.target.value)}
            type="date"
            style={{ width: '140px' }}
          />
        </DateRow>

        <DateRow>
          <span>По</span>
          <DateSearchInput
            value={newBanner.end}
            onChange={e => onSetEnd(e.target.value)}
            type="date"
            style={{ width: '140px' }}
          />
        </DateRow>
      </DateColumn>

      <RowStartWrapper style={{ alignItems: 'center' }}>
        <Select
          onChange={e => onSetLinkType(e.target.value)}
          value={newBanner.linkType}
          style={{ width: '25%', height: '40px' }}
        >
          {linkType.map(item => (
            <MenuItem value={item.value} key={item.value}>
              {linkType.find(link => link.value === item.value)?.title}
            </MenuItem>
          ))}
        </Select>

        {newBanner.linkType == 'category' && (
          <ChooseCategory setLink={setLink} />
        )}

        {newBanner.linkType == 'brand' && <ChooseBrand setLink={setLink} />}

        {newBanner.linkType == 'product' && <ChooseProduct setLink={setLink} />}
      </RowStartWrapper>

      <DeleteBanner
        src={Assets.DELETE_RED_ICON}
        alt="delete"
        onClick={onDeleteBanner}
      />
    </ColumnsGrid>
  )
}
