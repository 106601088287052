import styled from 'styled-components'
import { FONT } from '../../../../utils'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  gap: 8px;
`

export const Title = styled.span`
  ${FONT({ size: '16px' })}
`

export const Label = styled.label`
  ${FONT({ size: '14px' })}
`

export const PercentageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
export const PercentageInputs = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  width: 100%;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
