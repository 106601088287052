import { NavLink as NavLinks } from 'react-router-dom'
import styled from 'styled-components'
import { FLEX, FONT, SHADOW } from './../../utils/css'
import { Colors } from '../../assets'

export const TopbarWrapper = styled.div`
  width: 100%;
  padding: 25px 2%;

  background-color: ${Colors.white};

  ${FLEX({ align: 'center', justify: 'space-between' })}
`

export const UserContainer = styled.div`
  ${FLEX({ justify: 'space-between' })}
  position: relative;
`

export const LargeImage = styled.img`
  width: 24px;
  height: 24px;
`

export const Logo = styled.p`
  width: 100%;

  ${FONT({
    size: '20px',
    weight: '700',
    align: 'center',
    color: Colors.lightBlue,
  })}
`

export const CollapsedImageContainer = styled.div`
  width: 100%;
  ${FLEX({ align: 'flex-start', wrap: 'nowrap' })}
  padding: 0 auto;
`

export const ImageContainer = styled.div`
  width: 100%;
  padding-left: 17px;
  &.open {
    padding-left: 30px;
  }
`

export const LogoImage = styled.img`
  margin: 20px 0;
`

export const LayoutWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  ${FLEX({ align: 'flex-start', wrap: 'nowrap' })}
`

export const ContentWrapper = styled.div<any>`
  /* width: ${props =>
    props.calcWrapperWidth ? 'calc(100vw - 350px)' : '100vw'}; */
  /* width: calc(100vw - 350px); */
  /* display: flex;
  flex-direction: column;
  align-items: flex-end; */
  //   width: calc(100vw - 70px);
  height: 100vh;
  width: 100%;
  max-width: calc(100vw - 350px);

  &.active {
    max-width: calc(100vw - 70px);
  }
`

export const ContentInnerWrapper = styled.div`
  width: 100%;
  padding: 45px 30px;
  overflow: hidden;
  overflow-y: auto;
`

export const Image = styled.img`
  width: 16px;
  margin-right: 9px;

  ${FLEX({})}
`

export const SidebarWrapper = styled.div`
  position: relative;
  height: fit-content;
  background: ${Colors.white};
  max-width: 70px;
  min-width: 70px;
  &::-webkit-scrollbar {
    display: none;
  }
  border-right: 1px solid ${Colors.lightGray};
  transition: 0.2s linear;
  ${FLEX({
    direction: 'column',
    justify: 'space-between',
    wrap: 'nowrap',
    align: 'flex-end',
  })};
  &.open {
    min-width: 350px;
    max-width: 350px;
  }
`
export const Chevrone = styled.div`
  width: 10px;
  height: 10px;
  border-top: 2px solid ${Colors.black};
  border-right: 2px solid ${Colors.black};
  cursor: pointer;
  transform: rotate(-315deg);
  margin-right: 3px;
  &.open {
    transform: rotate(-135deg);
    margin-left: 3px;
  }
`

export const SidebarButton = styled.div`
  position: absolute;
  top: 85px;
  right: -20px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: ${Colors.white};
  width: 35px;
  height: 35px;
  box-shadow: 0 3px 6px #43434329;
  ${FLEX({
    justify: 'center',
    align: 'center',
  })};
`

export const CollapsedMenuWrapper = styled.div`
  width: 100%;
  height: 100%;

  /* margin: auto; */

  overflow: visible;

  ${FLEX({ direction: 'column', align: 'center', justify: 'center' })}
`

export const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;

  /* margin: auto; */

  overflow: visible;

  ${FLEX({ direction: 'column', align: 'flex-start', justify: 'flex-start' })}
`

export const CollapsedNavLink = styled(NavLinks)`
  outline: 0;
  padding: 13px 0 13px 10%;
  width: 100%;

  height: 45px;

  cursor: pointer;

  text-decoration: none;
  transition: 0.15s ease-in-out;

  ${FLEX({ align: 'center', justify: 'center' })}

  ${FONT({ weight: '400', size: '14px', color: Colors.black })}

  &.active {
    background: ${Colors.orange};

    border-right: 3px solid ${Colors.black};

    ${FONT({ weight: '600', size: '14px' })};
  }
`

export const NavLink = styled(NavLinks)`
  outline: 0;
  padding: 13px 0 13px 10%;
  width: 100%;

  height: 45px;

  cursor: pointer;

  text-decoration: none;
  transition: 0.15s ease-in-out;

  ${FLEX({ align: 'center', justify: 'center' })}

  ${FONT({ weight: '400', size: '14px', color: Colors.black })}

  &.open {
    ${FLEX({ align: 'center', justify: 'flex-start' })}
  }

  &.active {
    background: ${Colors.orange};

    border-right: 3px solid ${Colors.black};

    ${FONT({ weight: '600', size: '14px' })};
  }
`

export const NavLinkProfile = styled(NavLinks)`
  ${FLEX({ align: 'center', justify: 'center' })}

  &.active {
    background: ${Colors.orange};
  }

  width: 90%;

  border-radius: 12px;

  &.open {
    padding: 15px;
    margin: auto;
  }
`

export const UserCard = styled.div`
  width: 100%;

  ${FLEX({ justify: 'center' })}

  &.open {
    ${FLEX({ justify: 'flex-start' })}
  }
`

export const UserImage = styled.img`
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
  width: 40px;
  height: 40px;
`

export const UserName = styled.p`
  ${FONT({ weight: '600', size: '14px' })}
`

export const ButtonContainer = styled.div`
  padding: 5px;

  ${FLEX({})}
`
