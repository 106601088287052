import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetAllStatsManagersActionPayload,
  GetAllStatsManagersActionResponse,
  GetManagerStatsByIdPayload,
  GetManagerStatsByIdResponse,
} from './types'

export const getManagersStatsAction = createApiActions<
  GetAllStatsManagersActionPayload,
  GetAllStatsManagersActionResponse,
  AxiosError
>(createActionTypes('MANAGERS_STATS/GET_ALL_STATS_MANAGERS'))

export const getManagerStatsByIdAction = createApiActions<
  GetManagerStatsByIdPayload,
  GetManagerStatsByIdResponse,
  AxiosError
>(createActionTypes('MANAGERS_STATS/GET_MANAGER_STATS_BY_ID'))

export const setManagerStatsFilterAction = createAction<
  any,
  'MANAGERS_STATS/SET_FILTER'
>('MANAGERS_STATS/SET_FILTER')
