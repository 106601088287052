import { Assets } from '../../../../assets'
import { Divider } from '../../../../components'
import { DeleteBanner } from '../../../Banners/styled'
import { ProfileInput } from '../../../Profile/styled'

import {
  Container,
  Label,
  PercentageContainer,
  PercentageInputs,
  Title,
  TitleContainer,
} from './styled'

export type TRangeForm = {
  index?: number
  from?: string
  to?: string
  percentage?: string
  onChange?: (name: string, value: string) => void
  onRemove?: () => void
}

export const RangeForm = ({
  index,
  from = '0',
  to = '0',
  percentage = '0',
  onChange,
  onRemove,
}: TRangeForm) => {
  const Utils = {
    rangeMask: (price: string) => {
      const formatedPrice = price.replace('$', '')

      return formatedPrice + '$'
    },
  }

  return (
    <Container>
      <Divider height={20} />
      <TitleContainer>
        <Title>Диапазон {index}</Title>
        <DeleteBanner
          src={Assets.DELETE_RED_ICON}
          alt="delete"
          onClick={onRemove}
        />
      </TitleContainer>
      <PercentageInputs>
        {/* From */}
        <PercentageContainer>
          <Label>От</Label>
          <ProfileInput
            style={{ width: '85px' }}
            value={Utils.rangeMask(from)}
            onChange={e => {
              const input = e.target as HTMLInputElement
              onChange && onChange('from', input.value)
            }}
          />
        </PercentageContainer>
        {/* To */}
        <PercentageContainer>
          <Label>До</Label>
          <ProfileInput
            style={{ width: '85px' }}
            value={Utils.rangeMask(to)}
            onChange={e => {
              const input = e.target as HTMLInputElement
              onChange && onChange('to', input.value)
            }}
          />
        </PercentageContainer>
      </PercentageInputs>
      <ProfileInput
        value={percentage}
        onChange={e => {
          const input = e.target as HTMLInputElement
          onChange && onChange('percent', input.value)
        }}
      />
    </Container>
  )
}
