import { all } from 'redux-saga/effects'
import { countdownWatcher } from './countdown'
import { customersWatcher } from './customers'
import { toastsSaga } from './toasts'
import { userWatcher } from './user'
import { productsWatcher } from './_products'
import { categoriesWatcher } from './categories'
import { storesWatcher } from './stores'
import { materialsWatcher } from './materials'
import { colorsWatcher } from './colors'
import { stylesWatcher } from './styles'
import { ordersWatcher } from './orders'
import { brandsWatcher } from './brands'
// import { categoryDataWatcher } from './categoryData'
import { settingsWatcher } from './settings'
import { feedbackWatcher } from './feedback'
import { rolesWatcher } from './roles'
import { supportWatcher } from './support'
import { chatWatcher } from './chat'
import { financeWatcher } from './finance'
import { managersStatsWatcher } from './managerStatistics'
import { notificationsWatcher } from './notifications'
import { bannersWatcher } from './banners'

function* rootSaga() {
  yield all([
    toastsSaga(),
    userWatcher(),
    customersWatcher(),
    countdownWatcher(),
    productsWatcher(),
    categoriesWatcher(),
    storesWatcher(),
    materialsWatcher(),
    colorsWatcher(),
    stylesWatcher(),
    ordersWatcher(),
    brandsWatcher(),
    // categoryDataWatcher(),
    settingsWatcher(),
    feedbackWatcher(),
    rolesWatcher(),
    supportWatcher(),
    chatWatcher(),
    financeWatcher(),
    managersStatsWatcher(),
    notificationsWatcher(),
    bannersWatcher(),
  ])
}

export default rootSaga
