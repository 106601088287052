import { Colors } from '../../assets'
import styled from 'styled-components'
import { FLEX, SHADOW } from '../../utils'

export const HelpWrapper = styled.div`
  ${FLEX({ wrap: 'nowrap' })}
  width: 100%;
  border: 1px solid ${Colors.lightGray};
  border-radius: 24px;
  background: ${Colors.white};
  height: 100%;
  overflow: hidden;
  ${SHADOW({})}
`
export const HelpMessages = styled.div`
  ${FLEX({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })}
  flex: 1 1 50%;
  max-width: 50%;
  height: 100vh;
  border-right: 1px solid ${Colors.lightGray};
  overflow: auto;
  overflow-y: auto;
`
export const HelpMessagesHeader = styled.div`
  ${FLEX({ justify: 'flex-start', wrap: 'nowrap' })}
  padding: 15px 30px;
  border-bottom: 1px solid ${Colors.lightGray};
  width: 100%;
  gap: 30px;
  align-self: flex-start;
  & span {
    white-space: nowrap;
    color: ${Colors.boldGray};
    font-weight: 500;
    &:first-child {
      flex: 1 1 30%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:last-child {
      width: 40%;
    }
  }
`

export const HelpMessagesFooter = styled.div`
  ${FLEX({ justify: 'space-between', wrap: 'nowrap', align: 'flex-end' })}
  width: 100%;
  gap: 30px;
`
export const HelpMessageItem = styled.div`
  ${FLEX({ justify: 'flex-start', wrap: 'nowrap' })};
  min-height: 86px;
  padding: 22px 30px;
  width: 100%;
  gap: 30px;
  border-bottom: 1px solid ${Colors.lightGray};
  cursor: pointer;
  &.active {
    background: ${Colors.lightOrange};
  }
  & span {
    white-space: nowrap;
    &:first-child {
      flex: 1 1 30%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:last-child {
      width: 40%;
    }
  }
`

export const HelpMessageWrapper = styled.span`
  ${FLEX({ direction: 'column', align: 'flex-start' })};
  overflow: hidden;
  gap: 5px;
  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    &:first-child {
      font-weight: 500;
    }
  }
`

export const HelpEditMessage = styled.div`
  align-self: flex-start;
  flex: 1 1 50%;
  max-width: 50%;
  height: 100%;
  padding: 17px 30px;
`

export const HelpEditMessageContent = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start', justify: 'flex-start' })};
`
export const HelpEditMessageHeader = styled.div`
  ${FLEX({ align: 'center', justify: 'space-between' })};
  color: ${Colors.boldGray};
  font-weight: 500;
  width: 100%;
  margin-bottom: 30px;
`
export const HelpEditMessageCustomerInfo = styled.div`
  ${FLEX({ align: 'center', justify: 'space-between', wrap: 'nowrap' })};
  color: ${Colors.black};
  border: 1px solid ${Colors.lightGray};
  box-shadow: 0px 3px 6px #81818129;
  width: 100%;
  max-width: 300px;
  padding: 12px;
  border-radius: 14px;
  gap: 20px;
  cursor: pointer;
  & div {
    ${FLEX({ direction: 'column', align: 'flex-start' })};
    gap: 8px;
  }
  &:hover {
    ${SHADOW({})}
  }
`
export const ArrowRight = styled.img`
  transform: rotate(180deg);
`
export const HelpTextarea = styled.textarea`
  padding: 12px;
  height: 225px;
  width: 100%;
  border: 1px solid ${Colors.gray};
  border-radius: 3px;
  color: ${Colors.black};
  resize: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${Colors.gray};
  }
  &.error {
    border: 1px solid ${Colors.red};
  }
`
