import { Assets } from '../../assets'
import { ViewButton } from '../../components/Styled/tableStyled'
import { formatDate, formatTime } from '../../helpers'
import { checkForLabel } from '../../helpers/checkLabel'
import { MultipleItemsIndicator } from './styled'

export const deliveryOptions = [
  { label: 'BTS Доставка', value: 'bts' },
  { label: 'Заказ машины с помощью менеджера ', value: 'courier' },
  { label: 'Самовывоз', value: 'takeout' },
]

export const currentStatusOptions = [
  { label: 'Новый', value: 'new' },
  { label: 'В обработке', value: 'in_progress' },
  { label: 'Ожидает оплату', value: 'waiting_for_payment' },
  { label: 'Оплачен', value: 'payment_done' },
  { label: 'В пути', value: 'waiting_for_delivery' },
  { label: 'Завершен', value: 'done' },
  { label: 'Отменен', value: 'canceled' },
]

export const orderStatusOptions = [
  { label: 'Новый', value: 'new' },
  { label: 'В обработке', value: 'in_progress' },
  { label: 'Ожидает предоплату', value: 'waiting_for_payment' },
  { label: 'Оплачен', value: 'payment_done' },
  { label: 'В пути', value: 'in_going' },
  { label: 'Завершен', value: 'done' },
  { label: 'Отменен', value: 'canceled' },
]

export const notifyStatusOptions = [
  { label: 'Новый', value: 'new' },
  { label: 'Завершен', value: 'done' },
  { label: 'Под заказ', value: 'order' },
]

export const paymentOptions = [
  { label: 'Наличные', value: 'cash' },
  { label: 'Картой', value: 'card' },
  { label: 'Безналичные', value: 'no_cash' },
]

export const currentColumns = [
  {
    title: 'ID заказа',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: 'Дата',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
  },
  {
    title: 'Название товара',
    dataIndex: 'title',
    key: 'title',
    width: 100,
  },
  {
    title: 'Адрес',
    dataIndex: 'address',
    key: 'address',
    width: 100,
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'client',
    width: 100,
  },
  {
    title: 'Тип оплаты',
    dataIndex: 'payment',
    key: 'payment',
    width: 100,
  },
  {
    title: 'Бонусами',
    dataIndex: 'bonuses',
    key: 'bonuses',
    width: 100,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    width: 100,
  },
  {
    dataIndex: 'viewButton',
    key: 'viewButton',
    width: 100,
  },
]

export const notifyColumns = [
  {
    title: 'ID заказа',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: 'Дата',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
  },
  {
    title: 'Название товара',
    dataIndex: 'title',
    key: 'title',
    width: 100,
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    key: 'client',
    width: 100,
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    width: 100,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    width: 100,
  },
  {
    dataIndex: 'viewButton',
    key: 'viewButton',
    width: 100,
  },
]

export const addItemModalColumns = [
  {
    title: 'Фото',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Артикул',
    dataIndex: 'vendorCode',
    key: 'vendorCode',
  },
  //   {
  //     title: 'Категория',
  //     dataIndex: 'category',
  //     key: 'category',
  //   },
  {
    title: 'Статус товара',
    dataIndex: 'status',
    key: 'status',
  },
  {
    dataIndex: 'checkbox',
    key: 'checkbox',
  },
]

export const currentTableData = (i: any, index: number, history: any) => {
  const formatAddress = (
    <>
      <div>
        {i.address?.country} {i.address?.city}
      </div>
      <div>
        {i.address?.street} {i.address?.home}
      </div>
    </>
  )

  const formatClient = (
    <>
      <div>
        {i.personalData?.name} {i.personalData?.surname}{' '}
        {i.personalData?.patronymic}
      </div>
      <div>{i.personalData?.phone}</div>
    </>
  )

  const formatCreatedAt = (
    <>
      <div>{formatDate(i?.createdAt)}</div>
      <div>{formatTime(i?.createdAt)}</div>
    </>
  )

  const formatTitle = () => {
    if (i?.items.length > 1) {
      return (
        <div>
          {i?.items[0].product.ru}{' '}
          <MultipleItemsIndicator>+{i.items.length - 1}</MultipleItemsIndicator>
        </div>
      )
    } else if (i.items.length === 1) {
      return (
        <div>
          {i.items[0]?.count > 1 ? (
            <span style={{ fontWeight: 'bold' }}>{i.items[0]?.count}x</span>
          ) : (
            ''
          )}{' '}
          {i.items[0]?.product?.ru}
        </div>
      )
    } else {
      return <div>{i?.unavailableItem?.ru} </div>
    }
  }

  return {
    id: i.increment ? i.increment : '-',
    createdAt: formatCreatedAt,
    title: formatTitle(),
    address: formatAddress,
    client: formatClient,
    paymentType: checkForLabel(i.paymentType),
    bonuses: i.bonusesUsed,
    status: checkForLabel(i.status),
    viewButton: (
      <ViewButton onClick={() => history.push(`/orders/${i._id}?type=current`)}>
        <img height="24px" width="24px" src={Assets.SHOW_PASSWORD_ICON} />
      </ViewButton>
    ),
    key: index,
  }
}

export const notifyTableData = (i: any, index: number, history: any) => {
  const formatClient = (
    <>
      {i.personalData?.name} {i.personalData?.surname}{' '}
      {i.personalData?.patronymic}
    </>
  )

  const formatCreatedAt = (
    <>
      <div>{formatDate(i?.createdAt)}</div>
      <div>{formatTime(i?.createdAt)}</div>
    </>
  )

  const formatTitle = () => {
    if (i?.unavailableItem?.ru) {
      return <div>{i?.unavailableItem?.ru}</div>
    }
    return '-'
  }

  return {
    id: i?.increment ? i.increment : '-',
    createdAt: formatCreatedAt,
    title: formatTitle(),
    client: formatClient,
    phone: i?.personalData?.phone,
    status: checkForLabel(i?.status),
    viewButton: (
      <ViewButton onClick={() => history.push(`/orders/${i?._id}?type=notify`)}>
        <img height="24px" width="24px" src={Assets.SHOW_PASSWORD_ICON} />
      </ViewButton>
    ),
    key: index,
  }
}
