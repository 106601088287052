import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetCustomersActionResponse,
  GetCustomerActionResponse,
  GetCustomerActionPayload,
  UpdateCustomerActionPayload,
  GetCustomerByPhoneActionResponse,
  GetCustomerByPhoneActionPayload,
  SortCustomersByQueryActionPayload,
  SortCustomersByQueryActionResponse,
  GetCustomersActionPayload,
} from './types'

export const getMyCustomersAction = createApiActions<
  GetCustomersActionPayload,
  GetCustomersActionResponse,
  AxiosError
>(createActionTypes('CUSTOMER/GET_MY_CUSTOMERS'))

export const getCustomersAction = createApiActions<
  GetCustomersActionPayload,
  GetCustomersActionResponse,
  AxiosError
>(createActionTypes('CUSTOMER/GET_CUSTOMERS'))

export const getCustomerAction = createApiActions<
  GetCustomerActionPayload,
  GetCustomerActionResponse,
  AxiosError
>(createActionTypes('CUSTOMER/GET_CUSTOMER'))

export const getCustomerByPhoneAction = createApiActions<
  GetCustomerByPhoneActionPayload,
  GetCustomerByPhoneActionResponse,
  AxiosError
>(createActionTypes('CUSTOMER/GET_CUSTOMER_BY_PHONE'))

export const updateCustomerAction = createApiActions<
  UpdateCustomerActionPayload,
  void,
  AxiosError
>(createActionTypes('CUSTOMER/UPDATE_CUSTOMER'))

export const sortCustomersByQueryAction = createApiActions<
  SortCustomersByQueryActionPayload,
  SortCustomersByQueryActionResponse,
  AxiosError
>(createActionTypes('CUSTOMER/SORT_CUSTOMERS_BY_QUERY'))

export const unsetCustomerAction = createAction('CUSTOMER/UNSET_CUSTOMER')
