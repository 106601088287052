import { yupResolver } from '@hookform/resolvers/yup'
import { Switch } from '@material-ui/core'
import { FC, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Assets, Colors } from '../../assets'
import {
  BtnWrapper,
  DumbButton,
  DumbOutlineButton,
} from '../../components/FormComponents/styled'
import ChangePasswordModal from '../../components/Modals/ChangePasswordModal'
import ProfileModal from '../../components/Modals/ProfileModal'
import { RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider, Title } from '../../components/Styled/formStyled'
import { useTypedSelector } from '../../hooks'
import {
  api,
  changeUserDataAction,
  changeUserPhotoAction,
  forgotPasswordAction,
  sendChangeEmailLinkAction,
} from '../../store'
import {
  Icon,
  ProfileContent,
  ProfileWrapper,
  SmallDumbBtn,
  UserImage,
  UserImageWrapper,
  NotificationsWrapper,
  ProfileDescription,
  ProfileInput,
  ProfileInputWrapper,
  ProfileInputLabel,
  PassModalTitle,
  ProfileModalText,
  ProfileInputError,
  ProfileWarning,
} from './styled'
import { ProfileForm } from './types'
import * as yup from 'yup'
import { IImage } from '../Brand/types'

const Profile: FC = () => {
  const [isModalOpen, setModalIsOpen] = useState<boolean>(false)
  const [isEmailInfoModalOpen, setIsEmailInfoModalOpen] =
    useState<boolean>(false)
  const [isForgotPassModalOpen, setIsForgotPassModalOpen] =
    useState<boolean>(false)
  const [userName, setUserName] = useState<string>('User')
  const [userEmail, setUserEmail] = useState<string>('')
  const [image, setImage] = useState<IImage>({
    src: Assets.USER_ICON,
    file: null,
  })
  const [sendNotifications, setSendNotifications] = useState<boolean>(false)
  const [emailToChangePass, setEmailToChangePass] = useState<string>('')

  const initialEmail = useRef<string>('')
  const initialName = useRef<string>('')
  const initialNotifications = useRef<boolean>()

  const dispatch = useDispatch()

  const { user } = useTypedSelector(state => state.user)

  useEffect(() => {
    setUserEmail(user.email)
    if (user.hasOwnProperty('name')) setUserName(user.name)
  }, [user])

  useEffect(() => {
    initialEmail.current = user?.email
    if (user?.name) {
      initialName.current = user?.name
    } else {
      initialName.current = 'User'
    }

    if (user?.sendNotifications) {
      setSendNotifications(true)
    }

    initialNotifications.current = user.sendNotifications
  }, [])

  const schema = yup.object().shape({
    name: yup.string().matches(/^([^0-9]*)$/, 'Имя не должно содержать цифр'),
    email: yup.string().email('Введите валидный e-mail'),
    emailToChangePass: yup.string().email('Введите валидный e-mail'),
    photo: yup.mixed(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileForm>({
    resolver: yupResolver(schema),
  })

  const onModalClose = () => {
    setModalIsOpen(false)
  }

  const onChangeImage = (e: any) => {
    setImage({
      file: e.target.files[0],
      src: URL.createObjectURL(e.target.files[0]),
    })

    const photo = new FormData()
    photo.append('photo', e.target.files[0])

    console.log('form data', photo)

    dispatch(changeUserPhotoAction.request(photo))
    e.target.value = null
  }

  const onForgotPassModalOpen = () => {
    setModalIsOpen(false)
    setIsForgotPassModalOpen(true)
  }

  const onSubmit: SubmitHandler<ProfileForm> = data => {
    if (initialName.current !== userName) {
      dispatch(
        changeUserDataAction.request({
          name: data.name,
          sendNotifications: sendNotifications,
        }),
      )
    }

    if (initialNotifications.current !== sendNotifications) {
      dispatch(
        changeUserDataAction.request({
          sendNotifications: sendNotifications,
        }),
      )
    }

    if (initialEmail.current !== userEmail) {
      dispatch(sendChangeEmailLinkAction.request({ email: data.email }))
    }
  }

  const onResetPassword = () => {
    dispatch(forgotPasswordAction({ email: emailToChangePass }))
    setIsForgotPassModalOpen(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RowWrapper>
          <Title>Мой профиль</Title>
          <BtnWrapper>
            <DumbOutlineButton
              color={Colors.green}
              onClick={() => setModalIsOpen(true)}
              type="button"
            >
              Изменить пароль
            </DumbOutlineButton>
            <DumbButton bg={Colors.orange} txt={Colors.black} type="submit">
              Сохранить
            </DumbButton>
          </BtnWrapper>
        </RowWrapper>

        <SimpleDivider height="20px" />

        <ProfileWrapper>
          <ProfileContent>
            <UserImageWrapper>
              <UserImage
                src={user.photo ? `${api.images}${user.photo}` : image.src}
              ></UserImage>
              <SmallDumbBtn
                bg={Colors.green}
                txt={Colors.white}
                shadow={Colors.lightGreen}
                htmlFor="userProfileImage"
              >
                <Icon src={Assets.PLUS_WHITE_ICON}></Icon>
                Загрузить фото
              </SmallDumbBtn>
              <input
                style={{ margin: '10px 0', display: 'none' }}
                id="userProfileImage"
                onChange={e => onChangeImage(e)}
                type="file"
                accept="image/*"
              />
            </UserImageWrapper>

            <SimpleDivider height="28px" />

            <ProfileInputWrapper>
              <ProfileInputLabel>Имя</ProfileInputLabel>
              <ProfileInput
                {...register('name')}
                value={userName}
                onChange={e => setUserName(e.target.value)}
                className={`${errors.name ? 'error' : ''}`}
              />
              {errors.name && (
                <ProfileInputError>{errors.name.message}</ProfileInputError>
              )}
            </ProfileInputWrapper>

            <SimpleDivider height="10px" />

            <ProfileInputWrapper>
              <ProfileInputLabel>E-mail</ProfileInputLabel>
              <ProfileInput
                {...register('email')}
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
                className={`${errors.email ? 'error' : ''}`}
              />
              {errors.email && (
                <ProfileInputError>{errors.email.message}</ProfileInputError>
              )}
            </ProfileInputWrapper>

            <SimpleDivider height="16px" />

            <ProfileDescription>
              После изменения e-mail, на ваш новый адрес будет отправлено письмо
              для подтверждения.
            </ProfileDescription>

            {!user.emailVerified && (
              <>
                <SimpleDivider height="16px" />

                <ProfileWarning>
                  <span className="warning">Внимание!</span> Вы ещё не
                  подтвердили свой новый адрес. Пожалуйста, проверьте свой
                  почтовый ящик на наличие письма.
                </ProfileWarning>
              </>
            )}

            <SimpleDivider height="30px" />

            <NotificationsWrapper>
              Получать Push-уведомления
              <Switch
                color="primary"
                checked={sendNotifications}
                onChange={e => setSendNotifications(e.target.checked)}
              />
            </NotificationsWrapper>

            <SimpleDivider height="30px" />

            <ProfileInputWrapper>
              <ProfileInputLabel>Роль</ProfileInputLabel>
              <ProfileInput value={user?.role?.title} readOnly />
            </ProfileInputWrapper>
          </ProfileContent>

          <ProfileModal
            isModalOpen={isEmailInfoModalOpen}
            onModalClose={() => setIsEmailInfoModalOpen(false)}
          >
            <PassModalTitle>Изменение e-mail</PassModalTitle>

            <SimpleDivider height="25px" />

            <ProfileModalText>
              Вы изменили свой e-mail. На ваш новый адрес <br />
              <b>{userEmail}</b> отправлено письмо для <br /> подтверждения.
              Пожалуйста, проверьте свой почтовый <br /> ящик на наличие письма.
              <SimpleDivider height="25px" />
              <b>Внимание!</b> Новый адрес не станет активным до <br />{' '}
              подтверждения.
            </ProfileModalText>
          </ProfileModal>

          <ProfileModal
            isModalOpen={isForgotPassModalOpen}
            onModalClose={() => setIsForgotPassModalOpen(false)}
          >
            <PassModalTitle>Сбросить пароль</PassModalTitle>

            <SimpleDivider height="25px" />

            <ProfileModalText>
              Укажите свой e-mail. На ваш адрес мы отправим <br /> письмо с
              инструкциями для смены пароля.
            </ProfileModalText>

            <SimpleDivider height="20px" />

            <ProfileInputWrapper maxWidth="300px">
              <ProfileInputLabel>E-mail</ProfileInputLabel>
              <ProfileInput
                value={emailToChangePass}
                {...register('emailToChangePass')}
                onChange={e => setEmailToChangePass(e.target.value)}
                className={`${errors.emailToChangePass ? 'error' : ''}`}
              />
              {errors.emailToChangePass && (
                <ProfileInputError>
                  {errors.emailToChangePass.message}
                </ProfileInputError>
              )}
            </ProfileInputWrapper>

            <SimpleDivider height="30px" />

            <DumbButton
              bg={Colors.orange}
              txt={Colors.black}
              onClick={handleSubmit(onResetPassword)}
            >
              Сбросить
            </DumbButton>
          </ProfileModal>
        </ProfileWrapper>
      </form>
      <ChangePasswordModal
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onForgotPassModalOpen={onForgotPassModalOpen}
      ></ChangePasswordModal>
    </>
  )
}

export default Profile
