import { FLEX, FONT } from './../../utils'
import styled from 'styled-components'
import { Assets, Colors } from '../../assets'

export const StyledInputWrapper = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })}
  max-width: 100%;
  width: 100%;
`

export const Label = styled.span`
  width: 100%;

  padding-left: 5px;

  ${FONT({
    size: '14px',
    weight: '600',
    align: 'left',
  })}
`

export const PasswordInputWrapper = styled.div`
  width: 100%;

  position: relative;
`

export const StyledInput = styled.input`
  width: 100%;
  min-width: 300px;

  padding: 10px 8px;

  padding-right: 40px;

  outline: none;
  border: 1px solid ${Colors.gray};

  border-radius: 3px;

  background: ${Colors.white};

  &.error {
    border: 1px solid ${Colors.red};
  }

  ${FONT({
    size: '14px',
  })}

  transition: 0.2s;

  &:focus {
    border: 1px solid ${Colors.orange};
  }

  &::placeholder {
    ${FONT({
      size: '14px',
      weight: '600',
      color: '',
    })}
  }

  &.error {
    border: 1px solid ${Colors.red};
  }
`

export const Error = styled.span`
  width: 100%;
  height: 5px;

  ${FONT({
    size: '12px',
    weight: '700',
    align: 'right',
    color: Colors.red,
  })}
`

export const Button = styled.button``

export const InputImage = styled.img`
  position: absolute;

  width: 20px;
  height: 20px;

  right: 10px;
  bottom: 10px;
`

export const DateTimeContainer = styled.div`
  position: relative;

  width: 100%;
  height: 50px;

  border: 2px solid ${Colors.gray};

  margin: 1;

  padding: 10px 14px;

  background: ${Colors.gray};

  ${FLEX({ justify: 'space-between' })}
`

export const Icon = styled.img``

export const InputValue = styled.p`
  ${FONT({
    size: '14px',
    weight: '700',
    align: 'left',
    color: Colors.lightBlue,
  })}
`

export const Placeholder = styled.p`
  ${FONT({
    size: '14px',
    weight: '700',
    align: 'left',
    color: Colors.lightBlue,
  })}

  color: ${Colors.gray};
  user-select: none;
  z-index: 1;
`

export const StyledDateTimeInput = styled.input`
  width: 100% !important;
  min-width: 100% !important;

  opacity: 0;

  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  ::-webkit-datetime-edit {
    display: none;
  }

  ::-webkit-calendar-picker-indicator {
    width: 100%;
    height: 100%;
  }
`

export const CheckboxContainer = styled.div`
  width: 100%;

  ${FLEX({
    direction: 'row',
    justify: 'flex-start',
    align: 'center',
    wrap: 'nowrap',
  })};
`

export const StyledCheckbox = styled.input`
  height: 20px;
  width: 20px;

  appearance: none;
  cursor: pointer;

  border-radius: 3px;

  border: 1px solid ${Colors.green};

  &:checked {
    background: url(${Assets.CHECKBOX_ICON}) center no-repeat;
    border: none;
  }
`

export const StyledCheckboxLabel = styled.label`
  font-weight: bold;
`

export const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 90px;
  height: fit-content;

  border: 2px solid ${Colors.gray};

  margin: 1;

  padding: 10px 14px;

  background: ${Colors.gray};

  resize: none;

  ${FONT({
    size: '14px',
    weight: '700',
    color: Colors.lightBlue,
  })}

  transition: 0.2s;

  &:focus {
    border: 2px solid ${Colors.green};
    background: ${Colors.white};
  }
`

export const InvisibleInput = styled.input`
  /* border: none; */
  font-weight: bold;
  max-width: 150px;
`
