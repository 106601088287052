import React, { FC } from 'react'
import { Container, UserMail, UserName, UserWrapper } from './styled'
import { CartPopupProps } from './types'
import { cartPopupRef } from './refs'
import { Button, Divider } from '../../../components'
import { useDispatch } from 'react-redux'
import { logoutAction } from '../../../store'
import { Colors } from '../../../assets'

const CartPopup: FC<CartPopupProps> = ({ onMouseOver, onMouseLeave }) => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logoutAction.request())
  }

  return (
    <Container
      ref={cartPopupRef}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={e => e.stopPropagation()}
    >
      <UserWrapper>
        <UserName>Анна Петрова</UserName>

        <Divider height={5} />

        <UserMail>petrova@gmail.com</UserMail>
      </UserWrapper>

      <Divider height={25} />

      <Button
        title="Выйти из профиля"
        theme={{
          background: Colors.white,
          border: Colors.boldGray,
          text: Colors.boldGray,
        }}
        onClick={handleLogout}
      />
    </Container>
  )
}

export default CartPopup
