import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import { getBrandsAction, getBrandAction, createBrandAction } from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { BrandAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* getBrandsWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<ReturnType<typeof BrandAPI.getBrands>> =
      yield call([BrandAPI, BrandAPI.getBrands], {
        authorization: token,
      })
    yield put(getBrandsAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категорий')),
    )
    yield put(getBrandsAction.failure(e as AxiosError))
  }
}

function* getBrandByIDWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<ReturnType<typeof BrandAPI.getBrand>> =
      yield call([BrandAPI, BrandAPI.getBrand], {
        authorization: token,
        id: payload.id,
      })

    yield put(getBrandAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категории')),
    )

    yield put(getBrandAction.failure(e as AxiosError))
  }
}

function* createBrandWorker({
  payload,
}: ReturnType<typeof createBrandAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)
    const response: PromiseReturnType<ReturnType<typeof BrandAPI.createBrand>> =
      yield call([BrandAPI, BrandAPI.createBrand], {
        authorization: token,
        data: payload,
      })
    if (response) {
      const res: PromiseReturnType<ReturnType<typeof BrandAPI.getBrands>> =
        yield call([BrandAPI, BrandAPI.getBrands], {
          authorization: token,
        })
      yield put(createBrandAction.success(response.data))
      yield put(getBrandsAction.success(res.data))
    }

    yield put(
      showToastAction.request(generateSuccessToast('Материал успешно создан')),
    )
  } catch (e) {
    console.log('Error: createBrandWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания материала')),
    )

    yield put(createBrandAction.failure(e as AxiosError))
  }
}

// function* deleteBrandWorker({ payload }: { payload: { id: string } }) {
//   try {
//     const { token }: ReturnType<typeof getUserSelector> = yield select(
//       getUserSelector,
//     )

//     const response: PromiseReturnType<
//       ReturnType<typeof BrandAPI.deleteBrand>
//     > = yield call([BrandAPI, BrandAPI.deleteBrand], {
//       authorization: token,
//       id: payload.id,
//     })

//     yield put(deleteBrandAction.success())

//     yield put(
//       showToastAction.request(generateInfoToast('Категория успешно удалена')),
//     )

//     yield put(getBrandsAction.request())
//   } catch (e) {
//     console.log('Error: deleteBrandWorker', e)

//     yield put(
//       showToastAction.request(generateErrorToast('Ошибка удаления категории')),
//     )

//     yield put(deleteBrandAction.failure(e))
//   }
// }

export function* brandsWatcher() {
  yield takeLatest(getBrandsAction.request, getBrandsWorker)
  yield takeLatest(getBrandAction.request, getBrandByIDWorker)
  yield takeLatest(createBrandAction.request, createBrandWorker)
  // yield takeLatest(deleteBrandAction.request, deleteBrandWorker)
}
