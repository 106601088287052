import { AxiosResponse } from 'axios'
import {
  SetAllOrdersStatsActionResponse,
  SetAmountStatsActionPayload,
  SetAmountStatsActionResponse,
  SetSuppliersStatsActionResponse,
  updateNotesForOwnerActionResponse,
} from './types'

import { setAuthorizationHeadersProp } from '../../helpers'
import { HttpService } from '../../services'
import { FINANCE_URL } from './config'
import { PayloadAuth } from '../types'

export class FinanceAPI extends HttpService {
  static getSuppliersStats({
    authorization,
    data,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SetSuppliersStatsActionResponse>
  > {
    return this.request<SetSuppliersStatsActionResponse>(
      'GET_SUPPLIERS_STATS',
      {
        url: FINANCE_URL.get_suppliers_stats(data),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static getOrdersBySupplierId({
    authorization,
    data,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SetSuppliersStatsActionResponse>
  > {
    return this.request<SetSuppliersStatsActionResponse>(
      'GET_SUPPLIERS_STATS',
      {
        url: FINANCE_URL.get_orders_by_supplier_id(data),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static getAmountStats(
    request: SetAmountStatsActionPayload,
  ): Promise<AxiosResponse<SetAmountStatsActionResponse>> {
    return this.request<SetAmountStatsActionResponse>('GET_AMOUNT_STATS', {
      url: FINANCE_URL.get_amount_stats,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getAllOrdersStats({
    authorization,
    data,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SetAllOrdersStatsActionResponse>
  > {
    return this.request<SetAllOrdersStatsActionResponse>(
      'GET_ALL_ORDERS_STATS',
      {
        url: FINANCE_URL.get_all_orders_stats(data),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static updateNotesForOwner({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<
    AxiosResponse<updateNotesForOwnerActionResponse>
  > {
    return this.request<updateNotesForOwnerActionResponse>(
      'GET_ALL_ORDERS_STATS',
      {
        url: FINANCE_URL.update_paid_to_supplier(id),
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  //   static sendMessage({
  //     authorization,
  //     data,
  //   }: PayloadAuth & any): Promise<AxiosResponse<sendChatMessageActionResponse>> {
  //     return this.request<sendChatMessageActionResponse>('SEND_MESSAGE', {
  //       url: CHAT_URL.send_message,
  //       method: 'POST',
  //       data,
  //       headers: {
  //         'Content-Type': 'application/json',
  //         ...setAuthorizationHeadersProp(authorization),
  //       },
  //     })
  //   }
}
