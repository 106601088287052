import { FC, useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux'
import { Assets, Colors } from '../../assets'
import { AddItemModalContent, ModalOverlay } from './styled'
import Table from 'rc-table'
import {
  ModalTableWrapper,
  StyledCell,
  StyledHeader,
  StyledRow,
  StyledTable,
} from '../Styled/tableStyled'
import { addItemModalColumns } from '../../pages/Orders/tableData'
import { api, searchProductByTitleAction } from '../../store'
import { useTypedSelector } from '../../hooks'
import { ButtonElement } from '../../pages/Order/styled'
import { ButtonGroup } from '../Styled/formStyled'
import { checkForLabel } from '../../helpers/checkLabel'

interface IStyledModal {
  addItems: any
}

const AddItemModal: FC<IStyledModal> = ({ addItems }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [productsInfo, setProductsInfo] = useState<any[]>([])
  const [selected, setSelected] = useState<any>([])

  const { products, loading } = useTypedSelector(state => state.products)
  const dispatch = useDispatch()

  console.log('products', products)

  useEffect(() => {
    if (products.data) {
      setProductsInfo(products.data)
      setSelected([])
    }
  }, [])

  useEffect(() => {
    if (products.data) {
      setProductsInfo(products.data)
    }
  }, [loading])

  useEffect(() => {
    const filteredProducts = products?.data?.filter(product => {
      const producToLowerCase = product?.ru.toLowerCase()
      const searchToLowerCase = search.toLowerCase()

      return producToLowerCase.includes(searchToLowerCase)
    })

    setProductsInfo(filteredProducts)
  }, [search])

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const getProductById = (id: string) => {
    let returnItem: any = null
    productsInfo.forEach((item: any) => {
      if (item._id === id) {
        returnItem = item
      }
    })
    const editedProduct: any = Object.assign({}, returnItem)
    editedProduct.brand = returnItem?.brand?._id
    editedProduct.category = returnItem?.category?._id
    editedProduct.subcategory = returnItem?.subcategory?._id

    return editedProduct
  }

  const checkSelected = (id: string) => {
    return selected.findIndex((item: any) => item.product._id === id) !== -1
  }

  const handleSelection = (e: any) => {
    setSelected((prev: any) => {
      return checkSelected(e.target.id)
        ? prev.filter((item: any) => item.product._id !== e.target.id)
        : [
            ...prev,
            {
              product: getProductById(e.target.id),
              //@ts-ignore
              sum: getProductById(e.target.id).price,
              count: 1,
            },
          ]
    })
  }

  const handleAddition = () => {
    addItems(selected)
    toggleModal()
  }

  const onSearch = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(searchProductByTitleAction.request({ title: search }))
    }
  }

  const data = () => {
    return productsInfo?.map((i: any, index: number) => {
      return {
        image: (
          <img width="70px" height="70px" src={`${api.images}${i.images[0]}`} />
        ),
        title: i.ru,
        vendorCode: i.vendorCode,
        // category: i.category,
        status: checkForLabel(i.status),
        checkbox: (
          <input
            style={{ width: '20px', height: '20px' }}
            id={i._id}
            type="checkbox"
            checked={checkSelected(i._id)}
            onChange={e => handleSelection(e)}
          />
        ),
        key: index,
      }
    })
  }

  return (
    <>
      <ButtonElement htmlFor="hiddenModalButton" onClick={() => toggleModal()}>
        <img src={Assets.PLUS_WHITE_ICON} /> Добавить товар
      </ButtonElement>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => toggleModal()}
        shouldCloseOnOverlayClick
        ariaHideApp={false}
        className="_"
        overlayClassName="_"
        contentElement={(props, children) => (
          <AddItemModalContent {...props}>{children}</AddItemModalContent>
        )}
        overlayElement={(props, contentElement) => (
          <ModalOverlay {...props}>{contentElement}</ModalOverlay>
        )}
      >
        <h1>Создать товар</h1>
        <input
          value={search}
          //   onKeyDown={e => onSearch(e)}
          onChange={e => setSearch(e.target.value)}
          type="text"
        />
        <div style={{ overflow: 'auto' }}>
          <ModalTableWrapper>
            <Table
              columns={addItemModalColumns}
              data={data()}
              components={{
                body: {
                  row: StyledRow,
                  cell: StyledCell,
                },
                header: { cell: StyledHeader },
                table: StyledTable,
              }}
            />
          </ModalTableWrapper>
        </div>
        <ButtonGroup className="button-group">
          <ButtonElement
            style={{
              padding: '5px 60px',
              background: Colors.white,
              border: `${Colors.boldGray} 1px solid`,
              color: Colors.boldGray,
            }}
            onClick={() => toggleModal()}
          >
            Отмена
          </ButtonElement>
          <ButtonElement
            style={{
              marginLeft: '50px',
              padding: '5px 60px',
              background: Colors.orange,
              color: Colors.boldGray,
            }}
            onClick={() => handleAddition()}
          >
            Добавить
          </ButtonElement>
        </ButtonGroup>
      </ReactModal>
    </>
  )
}

export default AddItemModal
