import { FC, useEffect, useState } from 'react'
import { TableInstance } from '../../components/Table'
import {
  StyledWidthWrapper,
  InputContainer,
  InfoTitle,
  SimpleDivider,
} from '../../components/Styled/formStyled'
import { NewSubcategoryModal } from '../../components'

import { MdDelete } from 'react-icons/md'
import { Clickable } from '../../components/Modals/styled'
import RangeModal from '../../components/Modals/RangeModal'
import FixedModal from '../../components/Modals/FixedModal'
import { ItemNavLink } from '../Roles/styled'
import { Assets } from '../../assets'

interface ICategoryData {
  characteristicsData: any

  subcategoryData: any
  addSubcategory: any
  editSubcategory: any
  deleteSubcategory: any

  addRange: any
  deleteRange: any
  editRange: any
  rangeData: any

  addFixed: any
  deleteFixed: any
  editFixed: any
  fixedData: any
}

const CategoryData: FC<ICategoryData> = ({
  characteristicsData,
  addFixed,
  deleteFixed,
  editFixed,
  subcategoryData,
  addSubcategory,
  deleteSubcategory,
  editSubcategory,
  addRange,
  deleteRange,
  editRange,
  rangeData,
  fixedData,
}) => {
  const [subcatTableData, setSubcatTableData] = useState<any>([])
  const [rngCharTableData, setRngCharTableData] = useState<any[]>([])
  const [fxdCharTableData, setFxdCharTableData] = useState<any>([])
  const [type, setType] = useState<'range' | 'fixed'>('range')

  console.log('subcategoryData', subcategoryData)

  useEffect(() => {
    if (subcategoryData.length !== 0) {
      setSubcatTableData(
        subcategoryData.map((m: any) => ({
          en: m.en,
          ru: m.ru,
          uz: m.uz,
          delete: (
            <Clickable onClick={() => deleteSubcategory(m._id)}>
              <MdDelete color="black" size="20px" />
            </Clickable>
          ),
          edit: (
            <ItemNavLink to={`/subcategory/${m._id}`}>
              <img src={Assets.SHOW_PASSWORD_ICON} alt="eye" />
            </ItemNavLink>
          ),
        })),
      )
    } else {
      setSubcatTableData([])
    }
  }, [subcategoryData])

  useEffect(() => {
    if (rangeData && rangeData.length !== 0) {
      setRngCharTableData(
        rangeData.map(({ en, ru, uz, possibleValues, _id }: any) => ({
          en: en,
          ru: ru,
          uz: uz,
          list: `${possibleValues[0]}-${possibleValues[1]}`,
          delete: (
            <Clickable onClick={() => deleteRange(_id)}>
              <MdDelete color="black" size="20px" />
            </Clickable>
          ),
          edit: (
            <RangeModal
              init={{
                en,
                ru,
                uz,
                from: possibleValues[0],
                to: possibleValues[1],
                _id,
              }}
              type="edit"
              func={editRange}
            />
          ),
        })),
      )
    } else {
      setRngCharTableData([])
    }
  }, [rangeData])

  useEffect(() => {
    if (fixedData && fixedData.length !== 0) {
      setFxdCharTableData(
        fixedData.map(({ en, ru, uz, possibleValues, _id }: any) => ({
          en,
          ru,
          uz,
          list: possibleValues.map((ls: any, index) => (
            <div>
              {index + 1}: {ls.en} {ls.ru} {ls.uz}
            </div>
          )),
          delete: (
            <Clickable onClick={() => deleteFixed(_id)}>
              <MdDelete color="black" size="20px" />
            </Clickable>
          ),
          edit: (
            <FixedModal
              init={{
                en,
                ru,
                uz,
                possibleValues: possibleValues,
                _id,
              }}
              type="edit"
              func={editFixed}
            />
          ),
        })),
      )
    } else {
      setFxdCharTableData([])
    }
  }, [fixedData])

  const subcategoriesElement = () => {
    const columns = [
      { Header: 'English', accessor: 'en' },
      { Header: 'Русский', accessor: 'ru' },
      { Header: 'Ozbek', accessor: 'uz' },
      { Header: '', accessor: 'edit' },
      { Header: '', accessor: 'delete' },
    ]

    return (
      <TableInstance tableData={subcatTableData} importedColumns={columns} />
    )
  }

  const fixedCharEl = () => {
    const columns = [
      { Header: 'English', accessor: 'en' },
      { Header: 'Русский', accessor: 'ru' },
      { Header: 'Ozbek', accessor: 'uz' },
      { Header: 'Список', accessor: 'list' },
      { Header: '', accessor: 'edit' },
      { Header: '', accessor: 'delete' },
    ]

    return (
      <StyledWidthWrapper width="100%">
        <TableInstance tableData={fxdCharTableData} importedColumns={columns} />
        <FixedModal type="add" func={addFixed} />
      </StyledWidthWrapper>
    )
  }

  const rangeCharEl = () => {
    const columns = [
      { Header: 'English', accessor: 'en' },
      { Header: 'Русский', accessor: 'ru' },
      { Header: 'Ozbek', accessor: 'uz' },
      { Header: 'Список', accessor: 'list' },
      { Header: '', accessor: 'edit' },
      { Header: '', accessor: 'delete' },
    ]

    return (
      <StyledWidthWrapper width="100%">
        <TableInstance tableData={rngCharTableData} importedColumns={columns} />
        <RangeModal type="add" func={addRange} />
      </StyledWidthWrapper>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '40px',
        width: '100%',
      }}
    >
      <InfoTitle>Подкатегории</InfoTitle>
      <InputContainer>
        <StyledWidthWrapper width="100%">
          {subcategoriesElement()}
          <NewSubcategoryModal addSubcategory={addSubcategory} />
        </StyledWidthWrapper>
      </InputContainer>
      <SimpleDivider height="20px" />
      <InfoTitle>Значения от/до</InfoTitle>
      {rangeCharEl()}

      {/* <CharRadio active={type === 'fixed'} onClick={() => setType('fixed')}>
          Фиксированные значения
        </CharRadio> */}
      <SimpleDivider height="20px" />
      <InfoTitle>Фиксированные Значения</InfoTitle>
      {fixedCharEl()}
      {/* {characteristicsElement()} */}
      {/* <CategoryCharacteristicsModal
          title=""
          addCharacteristic={addCharacteristic}
        /> */}
      {/* <button onClick={() => console.log(charTableData)}>asd</button> */}
    </div>
  )
}

export default CategoryData
