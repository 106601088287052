import React, { FC, useState, useEffect } from 'react'
import { StyledSelect, InfoLabel } from '../../pages/ProductCreate/styled'
import Select from 'react-select'
import { Colors } from '../../assets'

interface IDropdown {
  title?: string
  onChange: any
  options: { value: any; label: any }[]
  value: any
  width?: string
  error?: any
}

const Dropdown: FC<IDropdown> = ({
  title,
  value,
  onChange,
  options,
  width = '100%',
  error,
}) => {
  const [val, setVal] = useState<any>({
    value: '',
    label: '',
    characteristics: [],
  })

  useEffect(() => {
    setVal(value)
  }, [value])

  //@ts-ignore
  const handleChange = e => {
    console.log(e)
    onChange(e)
    setVal(e)
  }

  return (
    <>
      <div
        style={{
          width: `calc(${width} - 30px)`,
          marginTop: '4px',
          marginRight: '30px',
        }}
      >
        {title ? (
          <InfoLabel>
            {title} {error ? <span style={{ color: 'red' }}>*</span> : ''}
          </InfoLabel>
        ) : null}
        <StyledSelect
          placeholder=""
          classNamePrefix="Select"
          isSearchable={false}
          options={options}
          value={val}
          onChange={(value: any) => handleChange(value)}
          theme={(theme: any) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: Colors.lightGreen,
              primary: Colors.green,
            },
          })}
        />
      </div>
    </>
  )
}

export default Dropdown
