import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  ChangeSupportStatusActionPayload,
  ChangeSupportStatusActionResponse,
  SetSupportMessagesActionResponse,
} from './types'

export const setSupportMessagesAction = createApiActions<
  void,
  SetSupportMessagesActionResponse,
  AxiosError
>(createActionTypes('SUPPORT/SET_MESSAGES'))

export const changeSupportStatusAction = createApiActions<
  ChangeSupportStatusActionPayload,
  ChangeSupportStatusActionResponse,
  AxiosError
>(createActionTypes('SUPPORT/CHANGE_STATUS'))
