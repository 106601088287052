import styled from 'styled-components'
import { Assets, Colors } from '../../assets'
import { FONT, SHADOW } from '../../utils'

export const MultipleItemsIndicator = styled.span`
  background: black;
  color: white;
  padding: 3px 5px;
  border-radius: 4px;
`

export const OrdersTabs = styled.div`
  display: flex;
  margin: 30px 30px 0 30px;
  & > .selected {
    color: ${Colors.black};
    border-bottom: ${Colors.black} 3px solid;
  }
`

export const OrdersTab = styled.div`
  color: ${Colors.boldGray};
  font-weight: bold;
  font-size: 15px;
  margin-right: 30px;
  padding-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
`
