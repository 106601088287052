import styled from 'styled-components'
import { Colors } from '../../assets'
import { FLEX, FONT } from '../../utils'
import { TSize } from './types'

export const Header = styled.div`
  width: 100%;
  background: ${Colors.white};

  ${FLEX({ justify: 'space-between' })}
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Title = styled.h1`
  margin-right: 85px;
  ${FONT({ size: '24px', color: Colors.boldGray, weight: '700' })}
`

export const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: ${Colors.boldGray};
`

export const CreationDate = styled.div`
  margin-top: 6px;
  color: ${Colors.boldGray};
`

export const Content = styled.div`
  margin-top: 22px;
  background: ${Colors.white};
  border: 1px solid ${Colors.lightGray};
  border-radius: 24px;
  box-shadow: 0px 8px 20px ${Colors.shadowGray};
  width: 100%;
  ${FLEX({ direction: 'row', justify: 'flex-start', align: 'baseline' })}
`

export const Row = styled.div`
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
  width: 100%;
  ${FLEX({ justify: 'flex-start', align: 'baseline' })}
`

export const FullWidth = styled.div`
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
  width: 100%;
  /* ${FLEX({
    direction: 'column',
    justify: 'flex-start',
    align: 'baseline',
  })} */
`

export const Column = styled.div`
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
  width: 50%;
  /* ${FLEX({
    direction: 'column',
    justify: 'flex-start',
    align: 'baseline',
  })} */
`

export const InfoLabel = styled.label`
  margin-left: 8px;
  font-weight: bolder;
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  gap: 30px;
`

export const ButtonContainer = styled.div<TSize>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    margin-left: 30px;
  }
`

export const InfoTitle = styled.h3`
  margin: 0;
`

export const StyledWidthWrapper = styled.div<{ width: string }>`
  width: ${props => props.width};
  ${FLEX({ direction: 'column', justify: 'flex-start', align: 'flex-start' })}
`

export const DeleteButton = styled.label`
  font-weight: bold;
  cursor: pointer;
  padding: 14px 65px;
  border-radius: 8px;
  color: ${Colors.white};
  box-shadow: 0px 3px 6px ${Colors.red};
  background: ${Colors.red};
  ${FLEX({})};
`

export const SaveButton = styled.label`
  font-weight: bold;
  cursor: pointer;
  padding: 14px 65px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${Colors.orange};
  background: ${Colors.orange};
  ${FLEX({})};
`

export const SimpleDivider = styled.div<{ width?: string; height?: string }>`
  width: ${({ width = 0 }) => width};
  height: ${({ height = 0 }) => height};
`
