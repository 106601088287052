import axios from 'axios'
import { api } from '../../store'

export const getBrand = async (token: string, id: string) => {
  return (
    await axios(`${api.withAPI}/brands/${id}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  ).data
}

export const updateBrand = async (id: string, data: any, token: string) => {
  return axios({
    method: 'put',
    url: `${api.withAPI}/brands/${id}`,
    data,
    headers: {
      // 'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
  })
}

export const deleteBrand = async (id: string, token: string) => {
  return axios({
    method: 'delete',
    url: `${api.withAPI}/brands/${id}`,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const updateBrandImage = async (
  id: string,
  data: FormData,
  token: string,
) => {
  return axios({
    method: 'put',
    url: `${api.withAPI}/brands/${id}/photo`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
  })
}
