import { FC } from 'react'
import { Label, StyledNavLink } from './styled'
import { TButtonLinkProps } from './types'

const ButtonLink: FC<TButtonLinkProps> = ({
  title,
  theme,
  icon = '',
  maxwidth,
  path,
}) => {
  return (
    <StyledNavLink
      background={theme.background}
      border={theme.border}
      to={path}
      maxwidth={maxwidth}
    >
      <Label color={theme.text}>
        <span>{title}</span>
        {icon}
      </Label>
    </StyledNavLink>
  )
}

export default ButtonLink
