import { AxiosResponse } from 'axios'
import {
  CreateBrandActionPayload,
  CreateBrandActionResponse,
  GetBrandsActionResponse,
} from './types'

import { HttpService } from '../../services'
import { BRANDS_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class BrandAPI extends HttpService {
  static getBrands(
    request: any,
  ): Promise<AxiosResponse<GetBrandsActionResponse>> {
    return this.request<GetBrandsActionResponse>('GET_BRANDS', {
      url: BRANDS_URL.rootPath,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getBrand(
    request: any,
  ): Promise<AxiosResponse<GetBrandsActionResponse>> {
    return this.request<GetBrandsActionResponse>('GET_BRAND', {
      url: BRANDS_URL.idPath(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createBrand({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateBrandActionResponse>> {
    console.log(data)
    return this.request<CreateBrandActionResponse>('CREATE_BRAND', {
      url: BRANDS_URL.rootPath,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
