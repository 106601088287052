import styled from 'styled-components'
import { Colors } from '../../assets'
import { FLEX, FONT, SHADOW } from '../../utils'

export const PaginationContainer = styled.div`
  ${FLEX({ align: 'center', justify: 'flex-end' })};
  padding: 0 25px;
  width: 100%;
  margin-top: auto;
`
