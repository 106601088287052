import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Assets, Colors } from '../../assets'
import {
  BtnWrapper,
  DumbButton,
  DumbOutlineButton,
} from '../../components/FormComponents/styled'
import {
  Clickable,
  RowStartWrapper,
  RowWrapper,
} from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import {
  Icon,
  PassModalTitle,
  ProfileInput,
  ProfileInputError,
  ProfileInputLabel,
  ProfileInputWrapper,
  SmallDumbBtn,
} from '../Profile/styled'
import {
  ColumnFlex,
  ColumnsGrid,
  ColumnWrapper,
  GrayTitle,
  LinkWithIcon,
  UserProfileContent,
} from '../Roles/styled'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { getCategorySelector } from '../../store/categories/selectors'
import { useParams } from 'react-router'
import { FlexColumnsWrapper, ItemNavLink } from '../Roles/styled'
import { CategoryImage, ModalPhotoLoader } from './styled'
import {
  api,
  createSubsubcategoryAction,
  deleteSubsubcategoryAction,
  editEntityCategoryAction,
  getSubsubcategoriesAction,
  pullEntityCategoryAction,
  showToastAction,
  updateSubsubcategoryAction,
} from '../../store'
import { MdDelete } from 'react-icons/md'
import ProfileModal from '../../components/Modals/ProfileModal'
import { generateErrorToast } from '../../helpers'

const SubcategoryCreate: FC = () => {
  const [subCategory, setSubCategory] = useState<any>({})
  const [modalIsOpen, setModalIsOpen] = useState({
    create: false,
    edit: false,
  })
  const [newSubcategory, setNewSubcategory] = useState({
    en: '',
    ru: '',
    uz: '',
    image: {
      file: '',
      src: '',
    },
  })
  const [editedSubcategory, setEditedSubcategory] = useState({
    en: '',
    ru: '',
    uz: '',
    image: {
      file: '',
      src: '',
    },
    id: '',
    isImageChanged: false,
  })

  const history = useHistory()

  const dispatch = useDispatch()

  const { id } = useParams<any>()

  const { category } = useSelector(getCategorySelector)

  useEffect(() => {
    if (category?.subcategories) {
      const subCategory = category.subcategories.find(item => {
        return item._id === id
      })

      setSubCategory(subCategory)
    }

    dispatch(getSubsubcategoriesAction.request())
  }, [id, category])

  const schema = yup.object().shape({
    en: yup.string().required('Введите название'),
    ru: yup.string().required('Введите название'),
    uz: yup.string().required('Введите название'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onOpenEditModal = (data: any) => {
    setEditedSubcategory({
      en: data.en,
      ru: data.ru,
      uz: data.uz,
      image: {
        file: '',
        src: data.image,
      },
      id: data._id,
      isImageChanged: false,
    })

    setModalIsOpen(prev => ({ ...prev, edit: true }))
  }

  console.log(getValues(), errors)

  useEffect(() => {
    Object.entries(subCategory).forEach(category => {
      setValue(category[0], category[1])
    })
  }, [subCategory])
  const onSubmit = data => {
    dispatch(
      editEntityCategoryAction.request({
        id: subCategory._id,
        data: { en: subCategory.en, ru: subCategory.ru, uz: subCategory.uz },
        path: 'subcategories',
      }),
    )
  }

  const onCreateSubsubcategory = () => {
    if (
      newSubcategory.en === '' ||
      newSubcategory.ru === '' ||
      newSubcategory.uz === ''
    ) {
      dispatch(
        showToastAction.request(generateErrorToast('Заполните все названия')),
      )
    } else if (newSubcategory.image.src === '') {
      dispatch(
        showToastAction.request(generateErrorToast('Загрузите фотографию')),
      )
    } else {
      const data = new FormData()
      data.append('ru', newSubcategory.ru)
      data.append('uz', newSubcategory.uz)
      data.append('en', newSubcategory.en)
      newSubcategory.image.file &&
        data.append('image', newSubcategory.image.file)

      dispatch(
        createSubsubcategoryAction.request({
          categoryId: category._id,
          data: data,
          subcategoryId: subCategory._id,
          child: subCategory.child,
        }),
      )

      setModalIsOpen(prev => ({ ...prev, create: false }))
      setNewSubcategory({
        en: '',
        ru: '',
        uz: '',
        image: {
          file: '',
          src: '',
        },
      })
    }
  }

  const onEditSubsubcategory = () => {
    if (
      editedSubcategory.en === '' ||
      editedSubcategory.ru === '' ||
      editedSubcategory.uz === ''
    ) {
      dispatch(
        showToastAction.request(generateErrorToast('Заполните все названия')),
      )
    } else if (
      editedSubcategory.image.file === '' &&
      editedSubcategory.image.src === ''
    ) {
      dispatch(
        showToastAction.request(generateErrorToast('Загрузите фотографию')),
      )
    } else {
      const data = new FormData()
      data.append('ru', editedSubcategory.ru)
      data.append('uz', editedSubcategory.uz)
      data.append('en', editedSubcategory.en)
      editedSubcategory.image.file &&
        data.append('image', editedSubcategory.image.file)

      dispatch(
        updateSubsubcategoryAction.request({
          categoryId: category._id,
          data: data,
          subsubcategoryId: editedSubcategory.id,
        }),
      )

      setModalIsOpen(prev => ({ ...prev, edit: false }))
      setNewSubcategory({
        en: '',
        ru: '',
        uz: '',
        image: {
          file: '',
          src: '',
        },
      })
    }
  }

  const onDeleteSubsubcategory = (id: string) => {
    dispatch(
      deleteSubsubcategoryAction.request({
        subsubcategoryId: id,
        categoryId: category._id,
      }),
    )
  }

  const onDeleteSubcategory = () => {
    dispatch(
      pullEntityCategoryAction.request({
        catId: category._id,
        entId: id,
        path: 'subcategories',
      }),
    )

    history.goBack()
  }

  const onChangeImage = (e: any) => {
    setNewSubcategory(prev => ({
      ...prev,
      image: {
        file: e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
      },
    }))
  }

  const onEditImage = (e: any) => {
    setEditedSubcategory(prev => ({
      ...prev,
      image: {
        file: e.target.files[0],
        src: URL.createObjectURL(e.target.files[0]),
      },
      isImageChanged: true,
    }))
  }

  const onDeleteImage = () => {
    setNewSubcategory(prev => ({
      ...prev,
      image: {
        file: '',
        src: '',
      },
    }))
  }

  const onDeleteEditedImage = () => {
    setEditedSubcategory(prev => ({
      ...prev,
      image: {
        file: '',
        src: '',
      },
    }))
  }

  const onCloseCreateModal = () => {
    setModalIsOpen(prev => ({ ...prev, create: false }))
    setNewSubcategory({
      en: '',
      ru: '',
      uz: '',
      image: {
        file: '',
        src: '',
      },
    })
  }

  const onCloseEditModal = () => {
    setModalIsOpen(prev => ({ ...prev, edit: false }))
    setEditedSubcategory({
      en: '',
      ru: '',
      uz: '',
      image: {
        file: '',
        src: '',
      },
      id: '',
      isImageChanged: false,
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RowWrapper>
          <RowStartWrapper width="auto">
            <ColumnFlex>
              <GrayTitle>{subCategory?.ru && subCategory.ru}</GrayTitle>
            </ColumnFlex>

            <LinkWithIcon onClick={() => history.goBack()}>
              <img src={Assets.ARROW_BACK_ICON} alt="" />
              Назад
            </LinkWithIcon>
          </RowStartWrapper>

          <BtnWrapper>
            <DumbButton
              bg={Colors.red}
              txt={Colors.white}
              type="button"
              onClick={onDeleteSubcategory}
            >
              Удалить
            </DumbButton>
            <DumbButton bg={Colors.orange} txt={Colors.black} type="submit">
              Сохранить
            </DumbButton>
          </BtnWrapper>
        </RowWrapper>

        <SimpleDivider height="22px" />

        <ColumnWrapper padding="30px">
          <UserProfileContent>
            <div style={{ fontWeight: 600 }}>Название</div>

            <SimpleDivider height="10px" />

            <RowStartWrapper style={{ alignItems: 'start' }}>
              <ProfileInputWrapper maxWidth="300px">
                <ProfileInputLabel>English</ProfileInputLabel>
                <ProfileInput
                  value={subCategory?.en ? subCategory.en : ''}
                  className={`${errors.en ? 'error' : ''}`}
                  {...register('en')}
                  onChange={e =>
                    setSubCategory(prev => ({
                      ...prev,
                      en: e.target.value,
                    }))
                  }
                />
                {errors.en && (
                  <ProfileInputError>{errors.en.message}</ProfileInputError>
                )}
              </ProfileInputWrapper>

              <ProfileInputWrapper maxWidth="300px">
                <ProfileInputLabel>Русский</ProfileInputLabel>
                <ProfileInput
                  value={subCategory?.ru ? subCategory.ru : ''}
                  className={`${errors.ru ? 'error' : ''}`}
                  {...register('ru')}
                  onChange={e =>
                    setSubCategory(prev => ({
                      ...prev,
                      ru: e.target.value,
                    }))
                  }
                />
                {errors.ru && (
                  <ProfileInputError>{errors.ru.message}</ProfileInputError>
                )}
              </ProfileInputWrapper>

              <ProfileInputWrapper maxWidth="300px">
                <ProfileInputLabel>Ozbek</ProfileInputLabel>
                <ProfileInput
                  value={subCategory?.uz ? subCategory.uz : ''}
                  className={`${errors.uz ? 'error' : ''}`}
                  {...register('uz')}
                  onChange={e =>
                    setSubCategory(prev => ({ ...prev, uz: e.target.value }))
                  }
                />
                {errors.uz && (
                  <ProfileInputError>{errors.uz.message}</ProfileInputError>
                )}
              </ProfileInputWrapper>
            </RowStartWrapper>
            <SimpleDivider height="30px" />

            <div style={{ fontWeight: 600 }}>3 уровень</div>

            <SimpleDivider height="20px" />

            <ColumnsGrid
              className="sort border-bottom"
              template="15% 30% 45% 5% 5%"
            >
              <div>Фото</div>
              <div>Название</div>
              <div>Кол-во товаров</div>
              <div></div>
              <div></div>
            </ColumnsGrid>

            <FlexColumnsWrapper>
              {subCategory?.child?.length > 0 ? (
                subCategory.child.map(subCategory => (
                  <ColumnsGrid
                    className="userPreview"
                    key={subCategory._id}
                    template="15% 30% 45% 5% 5%"
                  >
                    <CategoryImage src={`${api.images}${subCategory.image}`} />
                    <div>{subCategory.ru}</div>
                    <div>10</div>
                    <ItemNavLink
                      to="#"
                      onClick={() => onOpenEditModal(subCategory)}
                    >
                      <img src={Assets.SHOW_PASSWORD_ICON} alt="" />
                    </ItemNavLink>
                    <Clickable
                      onClick={() => onDeleteSubsubcategory(subCategory._id)}
                    >
                      <MdDelete
                        color="black"
                        size="20px"
                        style={{ marginLeft: '15px' }}
                      />
                    </Clickable>
                  </ColumnsGrid>
                ))
              ) : (
                <>
                  <SimpleDivider height="20px" />
                  <div style={{ alignSelf: 'start' }}>
                    Нет категорий 3 уровня
                  </div>
                </>
              )}
              <SimpleDivider height="20px" />

              <SmallDumbBtn
                bg={Colors.green}
                txt={Colors.white}
                shadow={Colors.lightGreen}
                style={{ alignSelf: 'start' }}
                onClick={() =>
                  setModalIsOpen(prev => ({ ...prev, create: true }))
                }
              >
                <Icon src={Assets.PLUS_WHITE_ICON}></Icon>
                Создать
              </SmallDumbBtn>
            </FlexColumnsWrapper>
          </UserProfileContent>
        </ColumnWrapper>
      </form>

      <ProfileModal
        isModalOpen={modalIsOpen.create}
        onModalClose={onCloseCreateModal}
      >
        <PassModalTitle>
          {modalIsOpen.create
            ? 'Создать подкатегорию (3 уровень)'
            : 'Редактировать подкатегорию'}
        </PassModalTitle>

        <SimpleDivider height="20px" />

        <div style={{ fontWeight: 600 }}>Название</div>

        <SimpleDivider height="30px" />

        <RowStartWrapper style={{ alignItems: 'start' }}>
          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>English</ProfileInputLabel>
            <ProfileInput
              value={newSubcategory.en}
              onChange={e =>
                setNewSubcategory(prev => ({ ...prev, en: e.target.value }))
              }
            />
          </ProfileInputWrapper>

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>Русский</ProfileInputLabel>
            <ProfileInput
              value={newSubcategory.ru}
              onChange={e =>
                setNewSubcategory(prev => ({ ...prev, ru: e.target.value }))
              }
            />
          </ProfileInputWrapper>

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>Ozbek</ProfileInputLabel>
            <ProfileInput
              value={newSubcategory.uz}
              onChange={e =>
                setNewSubcategory(prev => ({ ...prev, uz: e.target.value }))
              }
            />
          </ProfileInputWrapper>
        </RowStartWrapper>

        <SimpleDivider height="20px" />

        <ModalPhotoLoader>
          <div className="btns">
            <div>Фото</div>
            <label htmlFor="categoryImage" className="load">
              Загрузить фото
            </label>
            <div className="delete" onClick={onDeleteImage}>
              Удалить
            </div>
          </div>

          {newSubcategory.image.src !== '' && (
            <img
              src={newSubcategory.image.src}
              alt="preview"
              className="preview"
            />
          )}

          <input
            style={{ margin: '10px 0', display: 'none' }}
            id="categoryImage"
            onChange={e => onChangeImage(e)}
            type="file"
            accept="image/*"
          />
        </ModalPhotoLoader>

        <SimpleDivider height="30px" />

        <BtnWrapper>
          <DumbOutlineButton
            color={Colors.boldGray}
            type="button"
            onClick={onCloseCreateModal}
            className="cancel"
          >
            Отмена
          </DumbOutlineButton>

          <DumbButton
            bg={Colors.orange}
            txt={Colors.black}
            type="button"
            onClick={onCreateSubsubcategory}
          >
            Подтвердить
          </DumbButton>
        </BtnWrapper>
      </ProfileModal>

      <ProfileModal
        isModalOpen={modalIsOpen.edit}
        onModalClose={onCloseEditModal}
      >
        <PassModalTitle>
          {modalIsOpen.create
            ? 'Создать подкатегорию (3 уровень)'
            : 'Редактировать подкатегорию'}
        </PassModalTitle>

        <SimpleDivider height="20px" />

        <div style={{ fontWeight: 600 }}>Название</div>

        <SimpleDivider height="30px" />

        <RowStartWrapper style={{ alignItems: 'start' }}>
          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>English</ProfileInputLabel>
            <ProfileInput
              value={editedSubcategory.en}
              onChange={e =>
                setEditedSubcategory(prev => ({ ...prev, en: e.target.value }))
              }
            />
          </ProfileInputWrapper>

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>Русский</ProfileInputLabel>
            <ProfileInput
              value={editedSubcategory.ru}
              onChange={e =>
                setEditedSubcategory(prev => ({ ...prev, ru: e.target.value }))
              }
            />
          </ProfileInputWrapper>

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>Ozbek</ProfileInputLabel>
            <ProfileInput
              value={editedSubcategory.uz}
              onChange={e =>
                setEditedSubcategory(prev => ({ ...prev, uz: e.target.value }))
              }
            />
          </ProfileInputWrapper>
        </RowStartWrapper>

        <SimpleDivider height="20px" />

        <ModalPhotoLoader>
          <div className="btns">
            <div>Фото</div>
            <label htmlFor="categoryImage" className="load">
              Загрузить фото
            </label>
            <div className="delete" onClick={onDeleteEditedImage}>
              Удалить
            </div>
          </div>

          {editedSubcategory.image.src !== '' && (
            <img
              src={
                editedSubcategory.isImageChanged
                  ? editedSubcategory.image.src
                  : `${api.images}${editedSubcategory.image.src}`
              }
              alt="preview"
              className="preview"
            />
          )}

          <input
            style={{ margin: '10px 0', display: 'none' }}
            id="categoryImage"
            onChange={e => onEditImage(e)}
            type="file"
            accept="image/*"
          />
        </ModalPhotoLoader>

        <SimpleDivider height="30px" />

        <BtnWrapper>
          <DumbOutlineButton
            color={Colors.boldGray}
            type="button"
            onClick={onCloseEditModal}
            className="cancel"
          >
            Отмена
          </DumbOutlineButton>

          <DumbButton
            bg={Colors.orange}
            txt={Colors.black}
            type="button"
            onClick={onEditSubsubcategory}
          >
            Подтвердить
          </DumbButton>
        </BtnWrapper>
      </ProfileModal>
    </>
  )
}

export default SubcategoryCreate
