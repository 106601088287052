import React, { FC, useState, useEffect } from 'react'
import { format } from 'date-fns'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { Formik, Form } from 'formik'
import { Assets, Colors } from '../../assets'
import {
  Container,
  Header,
  TitleGroup,
  Title,
  BackButton,
  CreationDate,
  Content,
  Column,
  InfoTitle,
  InputContainer,
  StyledWidthWrapper,
  SaveButton,
  SimpleDivider,
} from '../../components/Styled/formStyled'
import { Input, Image } from '../../components/FormComponents'
import { useTypedSelector } from '../../hooks'
import { deleteBrand, getBrand, updateBrand, updateBrandImage } from './api'
import { IBrand, IImage } from './types'
import { categorySchema } from '../../validation/category.validation'
import Images from '../Product/Images'
import {
  DumbButton,
  UploadButton,
} from '../../components/FormComponents/styled'
import { colors } from '@material-ui/core'
import { brandSchema } from '../../validation/brand.validation'
import { api } from '../../store'

const Brand: FC = ({}) => {
  const queryClient = useQueryClient()
  const [brandData, setBrandData] = useState<IBrand>({
    en: '',
    ru: '',
    uz: '',
    products: [],
    createdAt: '',
  })
  const [image, setImage] = useState<IImage>({ src: '', file: null })
  const history = useHistory()
  const { token } = useTypedSelector(state => state.user)
  const { id } = useParams<{ id: string }>()
  const { data, isSuccess, isLoading } = useQuery(
    ['brand', token],
    () => getBrand(token!, id),
    { onSuccess: data => setBrandData(data.data) },
  )

  const brandMutation = useMutation((newBrand: any) =>
    updateBrand(id, newBrand, token!),
  )

  const brandDeleteMutation = useMutation(() => deleteBrand(id, token!))

  const brandPhotoMutation = useMutation(async (data: FormData) => {
    const d = await updateBrandImage(id, data, token!)
    console.log('dd', d)
    return d
  })

  useEffect(() => {
    if (data?.data) {
      // setBrandData(data.data)
      setImage({
        src: `${api.images}${data.data.image}`,
        file: null,
      })
    }
  }, [data])

  useEffect(() => {
    if (brandMutation.isSuccess) {
      console.log('Mutated data: ', brandMutation)
    }
    if (isSuccess) {
      console.log('Fetched data: ', data)
    }

    if (brandPhotoMutation.isSuccess) {
      console.log('photo', brandPhotoMutation)
    }
  }, [isSuccess, brandMutation.isSuccess, brandPhotoMutation.isSuccess])

  const handleSave = (i: any) => {
    const { en, ru, uz } = i
    const data = new FormData()
    if (window.confirm('Вы уверены?')) {
      console.log(image.file)
      if (image.file) {
        data.append('image', image.file)
        brandPhotoMutation.mutate(data)
      }
      brandMutation.mutate({
        en,
        ru,
        uz,
      })
      history.goBack()
    }
  }

  const handleDelete = () => {
    if (window.confirm('Вы уверены?')) {
      brandDeleteMutation.mutate()
      history.goBack()
    }
  }

  const handleImage = (e: any) => {
    setImage({
      file: e.target.files[0],
      src: URL.createObjectURL(e.target.files[0]),
    })
    e.target.value = null
  }

  const handleImageRemove = () => {
    setImage({ file: null, src: '' })
  }

  const onBack = () => {
    history.push('/brands')
  }

  const content = brandData ? (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>{brandData.ru}</Title>
            <BackButton onClick={() => onBack()}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
          <CreationDate>
            Дата создания:{' '}
            {brandData.createdAt
              ? format(new Date(brandData.createdAt) as Date, 'dd.mm.yyyy')
              : ''}
          </CreationDate>
        </TitleGroup>
        <div style={{ display: 'flex' }}>
          <DumbButton
            bg={Colors.red}
            txt={Colors.white}
            onClick={() => handleDelete()}
          >
            Удалить
          </DumbButton>
          <SimpleDivider width="10px" />
          <SaveButton htmlFor="hiddenSave">Сохранить</SaveButton>
          {/* <button onClick={() => brandDeleteMutation.mutate()}>delete</button> */}
        </div>
      </Header>
      <Content>
        <Formik
          enableReinitialize={true}
          initialValues={brandData}
          validationSchema={brandSchema}
          onSubmit={values => handleSave(values)}
        >
          {({ values, handleChange, errors, setFieldValue }) => (
            <Form id="myForm" style={{ display: 'flex', width: '100%' }}>
              <Column>
                <InfoTitle>Название</InfoTitle>
                <InputContainer>
                  <Input
                    title="English"
                    handleChange={handleChange}
                    value={values.en}
                    name="en"
                    error={errors.en}
                    width="33%"
                    margin="5px"
                  />
                  <Input
                    title="Русский"
                    handleChange={handleChange}
                    value={values.ru}
                    name="ru"
                    error={errors.ru}
                    width="33%"
                    margin="5px"
                  />
                  <Input
                    title="Ozbek"
                    handleChange={handleChange}
                    value={values.uz}
                    name="uz"
                    error={errors.uz}
                    width="33%"
                    margin="5px"
                  />
                </InputContainer>
                <Image src={image?.src} handleImage={handleImageRemove} />
                <UploadButton
                  style={{ margin: '10px 0' }}
                  htmlFor="hiddenBrandImage"
                >
                  Загрузить фото
                </UploadButton>
                <input
                  style={{ margin: '10px 0', display: 'none' }}
                  id="hiddenBrandImage"
                  type="file"
                  onChange={e => handleImage(e)}
                />
              </Column>
              {/* <Column>
                {brandData.products.map(i => {
                  return <div>{i.title}</div>
                })}
              </Column> */}
              <button style={{ display: 'none' }} id="hiddenSave" type="submit">
                test
              </button>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  ) : (
    <div>loading</div>
  )

  return <>{content}</>
}

export default Brand
