import { AxiosResponse } from 'axios'
import { CreateCountdownActionResponse } from './types'

import { HttpService } from '../../services'
import { COUNTDOWN_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class CountdownAPI extends HttpService {
  static getCountdowns({
    authorization,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateCountdownActionResponse>> {
    return this.request<CreateCountdownActionResponse>('GET_COUNTDOWNS', {
      url: COUNTDOWN_URL.getCountdowns(id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getCountdown({
    authorization,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateCountdownActionResponse>> {
    return this.request<CreateCountdownActionResponse>('GET_COUNTDOWN', {
      url: COUNTDOWN_URL.getCountdown(id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static createCountdown({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateCountdownActionResponse>> {
    return this.request<CreateCountdownActionResponse>('CREATE_COUNTDOWN', {
      url: COUNTDOWN_URL.createCountdown,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateCountdown({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateCountdownActionResponse>> {
    return this.request<CreateCountdownActionResponse>('UPDATE_COUNTDOWN', {
      url: COUNTDOWN_URL.updateCountdown(id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteCountdown(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('DELETE_CATEGORY', {
      url: COUNTDOWN_URL.deleteCountdown(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
