import React, { FC } from 'react'
import { InfoLabel, InfoInput } from './styled'

interface IInput {
  title?: string
  handleChange?: any
  handleChangeWithTitle?: any
  value?: string
  name?: string
  error?: string
  width?: string
  margin?: string
  disabled?: boolean
  placeholder?: string
  date?: boolean
}

const Input: FC<IInput> = ({
  title,
  handleChange = null,
  handleChangeWithTitle = null,
  value,
  name,
  error,
  width = '100%',
  margin = '0',
  disabled = false,
  placeholder = '',
  date,
}) => {
  return (
    <div style={{ width: width, margin: `${margin} 0` }}>
      <InfoLabel htmlFor={name}>
        {title}
        {error ? <span style={{ color: 'red' }}>*</span> : null}
      </InfoLabel>
      <InfoInput
        placeholder={placeholder}
        type={date ? 'date' : 'text'}
        name={name}
        value={value}
        onChange={e =>
          handleChange
            ? handleChange(e)
            : handleChangeWithTitle &&
              handleChangeWithTitle(title, e.target.value)
        }
        disabled={disabled}
      />
    </div>
  )
}

export default Input
