export const Colors = {
  gray: '#C4C4C4',
  orange: '#FFD9A5',
  black: '#101010',
  green: '#39AE10',
  white: '#FFFFFF',
  red: '#EE1313',
  darkWhite: '#F7F7F7',
  lightBlue: '#1F166A',
  lightGray: '#E8E8E8',
  lightOrange: '#FFF0DC',
  lightGreen: '#39AE1066',
  boldGray: '#707070',
  shadowGray: '#43434329',
  backgroundGray: '#F5F5F5',
}
