import { AxiosResponse } from 'axios'
import {
  CreateCategoryActionPayload,
  CreateCategoryActionResponse,
  GetCategoriesActionResponse,
  GetSubSubcategoriesActionResponse,
} from './types'

import { HttpService } from '../../services'
import { CATEGORIES_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class CategoryAPI extends HttpService {
  static getCategories(
    request: any,
  ): Promise<AxiosResponse<GetCategoriesActionResponse>> {
    return this.request<GetCategoriesActionResponse>('GET_CATEGORIES', {
      url: CATEGORIES_URL.reworkRoot,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getCategory(
    request: any,
  ): Promise<AxiosResponse<GetCategoriesActionResponse>> {
    return this.request<GetCategoriesActionResponse>('GET_CATEGORY', {
      url: CATEGORIES_URL.reworkId(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateCategoryProducts(
    request: any,
  ): Promise<AxiosResponse<GetCategoriesActionResponse>> {
    return this.request<GetCategoriesActionResponse>(
      'CATEGORY/UPDATE_CATEGORY_PRODUCTSY',
      {
        url: CATEGORIES_URL.reworkProduct(request.id),
        method: 'PATCH',
        data: request.data,
        headers: {
          ...setAuthorizationHeadersProp(request.authorization),
        },
      },
    )
  }

  static createCategory({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateCategoryActionResponse>> {
    return this.request<CreateCategoryActionResponse>('CREATE_CATEGORY', {
      url: CATEGORIES_URL.reworkRoot,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteCategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('DELETE_CATEGORY', {
      url: CATEGORIES_URL.reworkId(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static fullUpdateCategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('FULL_UPDATE_CATEGORY', {
      url: CATEGORIES_URL.reworkId(request.id),
      method: 'PUT',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateImageCategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('IMAGE_UPDATE_CATEGORY', {
      url: CATEGORIES_URL.reworkImage(request.id),
      method: 'PATCH',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static pushEntityCategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('PUSH_ENTITY_CATEGORY', {
      url: CATEGORIES_URL.reworkPush(request.id, request.path),
      method: 'PATCH',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static editEntityCategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('EDIT_ENTITY_CATEGORY', {
      url: CATEGORIES_URL.reworkEdit(request.id, request.path),
      method: 'PATCH',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static pullTextEntityCategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('EDIT_ENTITY_CATEGORY', {
      url: CATEGORIES_URL.reworkTextPull(request.charId, request.valId),
      method: 'PATCH',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static pushTextEntityCategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('EDIT_ENTITY_CATEGORY', {
      url: CATEGORIES_URL.reworkTextPush(request.id),
      method: 'PATCH',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static pullEntityCategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('PULL_ENTITY_CATEGORY', {
      url: CATEGORIES_URL.reworkPull(
        request.catId,
        request.entId,
        request.path,
      ),
      method: 'PATCH',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateSubcategoryImage(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('UPDATE_SUBCATEGORY_IMAGE ', {
      url: CATEGORIES_URL.reworkSubcategoryImage(request.id),
      method: 'PATCH',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createSubsubcategory({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateCategoryActionResponse>> {
    return this.request<CreateCategoryActionResponse>('CREATE_SUBSUBCATEGORY', {
      url: CATEGORIES_URL.subsubcategory(''),
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteSubsubcategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('DELETE_SUBSUBCATEGORY', {
      url: CATEGORIES_URL.subsubcategory(request.id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static updateSubsubcategory(request: any): Promise<AxiosResponse<void>> {
    return this.request<void>('UPDATE_SUBSUBCATEGORY', {
      url: CATEGORIES_URL.subsubcategory(request.id),
      method: 'PUT',
      data: request.data,
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getSubsubcategories(request: any): Promise<AxiosResponse<GetSubSubcategoriesActionResponse>> {
    return this.request<GetSubSubcategoriesActionResponse>('GET_SUBSUBCATEGORIES', {
      url: CATEGORIES_URL.subsubcategory(''),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }
}
