import * as yup from 'yup'
import { errMsgs } from './errorMessages'

export const settingsSchema = yup.object({
  exRate: yup
    .number()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(0)
    .typeError(errMsgs.number)
    .required(errMsgs.required),
  realExRate: yup
    .number()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(0)
    .typeError(errMsgs.number)
    .required(errMsgs.required),
  defaultBonuse: yup
    .number()
    .moreThan(-1, 'Введите число больше или равно нулю'),
  bonusesFromOrder: yup
    .number()
    .moreThan(-1, 'Введите число больше или равно нулю'),
  fromDestPrice: yup.number().min(0).required().default(0),
  toDestPrice: yup.number().min(0).required().default(0),
})
