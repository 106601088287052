import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Assets, Colors } from '../../assets'
import ButtonLink from '../../components/Button/ButtonLink'
import { RowStartWrapper, RowWrapper } from '../../components/Modals/styled'
import { Title } from '../../components/PageContainer/styled'
import { Pagination } from '../../components/Pagination'
import { IPagination } from '../../components/Pagination/types'
import { SimpleDivider } from '../../components/Styled/formStyled'
import { api } from '../../store'
import {
  getRolesSelector,
  setAdminsAction,
  setRolesAction,
} from '../../store/roles'
import { UserImage } from '../Layout/styled'
import {
  ColumnsGrid,
  ColumnWrapper,
  FlexColumnsWrapper,
  ItemNavLink,
  RelativeInput,
  RelativeInputContainer,
  RolesHeader,
} from './styled'
import { defaultFormatDate } from '../../helpers'

const Roles: FC = () => {
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })

  const dispatch = useDispatch()

  const { admins } = useSelector(getRolesSelector)

  useEffect(() => {
    dispatch(setRolesAction.request())
  }, [])

  useEffect(() => {
    dispatch(
      setAdminsAction.request(
        `?limit=${pagination.limit}&skip=${pagination.skip + 1}`,
      ),
    )
  }, [pagination])

  if (!admins.data) {
    return <></>
  }

  return (
    <>
      <RowWrapper>
        <Title>Роли</Title>

        <ButtonLink
          title="Создать"
          theme={{ background: `${Colors.orange}` }}
          maxwidth="195px"
          path="/roles/create-profile"
        />
      </RowWrapper>

      <SimpleDivider height="20px" />

      <ColumnWrapper>
        <RolesHeader>
          <RowStartWrapper>
            <RelativeInputContainer maxwidth="300px">
              <RelativeInput // value={search}
                // onChange={e => setSearch(e.target.value)}
                padding="0px 10px 0 35px"
                type="text"
                placeholder="Поиск..."
              />
              <img
                src={Assets.SEARCH_ICON}
                alt="search"
                className="search-icon"
              />
            </RelativeInputContainer>

            <ButtonLink
              title="Настройка ролей"
              theme={{ background: `${Colors.green}`, text: `${Colors.white}` }}
              maxwidth="195px"
              path="/roles/settings"
            />
          </RowStartWrapper>

          <ColumnsGrid className="sort" template="10% 20% 25% 20% 20% 5%">
            <div>Фото</div>
            <div>Имя</div>
            <div>E-mail</div>
            <div>Дата создания</div>
            <div>Роль</div>
            <div></div>
          </ColumnsGrid>
        </RolesHeader>

        <FlexColumnsWrapper>
          {admins.data.map(admin => (
            <ColumnsGrid
              className="userPreview"
              key={admin._id}
              template="10% 20% 25% 20% 20% 5%"
            >
              <UserImage
                src={
                  admin.photo ? `${api.images}${admin.photo}` : Assets.USER_ICON
                }
              />
              <div>{admin.name}</div>
              <div>{admin.email}</div>
              <div>{defaultFormatDate(admin.createdAt)}</div>
              <div>{admin?.role ? admin.role.title : ''}</div>
              <ItemNavLink to={`/roles/profile/${admin._id}`}>
                <img src={Assets.SHOW_PASSWORD_ICON} alt="" />
              </ItemNavLink>
            </ColumnsGrid>
          ))}
        </FlexColumnsWrapper>

        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={admins.totalCount}
        />
      </ColumnWrapper>
    </>
  )
}

export default Roles
