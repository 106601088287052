import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetOrdersActionResponse,
  GetOrderActionResponse,
  CreateOrderActionResponse,
  GetOrderActionPayload,
  CreateOrderActionPayload,
  UpdateOrderActionPayload,
  DeleteOrderActionPayload,
  SearchOrdersByDateActionPayload,
  SearchOrdersByDateActionResponse,
  SearchOrdersByQueryActionPayload,
  SearchOrdersByQueryActionResponse,
  SortOrdersByQueryActionPayload,
  SortOrdersByQueryActionResponse,
  GetOrdersActionpayload,
  GetOrdersStatisticActionResponse,
} from './types'

export const getMyOrdersAction = createApiActions<
  GetOrdersActionpayload,
  GetOrdersActionResponse,
  AxiosError
>(createActionTypes('ORDERS/GET_MY_ORDERS'))

export const getOrdersAction = createApiActions<
  GetOrdersActionpayload,
  GetOrdersActionResponse,
  AxiosError
>(createActionTypes('ORDERS/GET_ORDERS'))

export const getNotifyOrdersAction = createApiActions<
  GetOrdersActionpayload,
  GetOrdersActionResponse,
  AxiosError
>(createActionTypes('ORDERS/GET_NOTIFY_ORDERS'))

export const getOrdersByOrderAction = createApiActions<
  GetOrdersActionpayload,
  GetOrdersActionResponse,
  AxiosError
>(createActionTypes('ORDERS/GET_ORDERS_BY_ORDER'))

export const getArchiveOrdersAction = createApiActions<
  GetOrdersActionpayload,
  GetOrdersActionResponse,
  AxiosError
>(createActionTypes('ORDERS/GET_ARCHIVE_ORDERS'))

export const getOrdersStatisticAction = createApiActions<
  void,
  GetOrdersStatisticActionResponse,
  AxiosError
>(createActionTypes('ORDERS/GET_STATISTIC'))

export const getOrderAction = createApiActions<
  GetOrderActionPayload,
  GetOrderActionResponse,
  AxiosError
>(createActionTypes('ORDERS/GET_ORDER'))

export const unsetOrderAction = createAction('ORDERS/UNSET_ORDER')

export const createOrderAction = createApiActions<
  CreateOrderActionPayload,
  CreateOrderActionResponse,
  AxiosError
>(createActionTypes('ORDERS/CREATE_ORDER'))

export const updateOrderAction = createApiActions<
  UpdateOrderActionPayload,
  any,
  AxiosError
>(createActionTypes('ORDERS/UPDATE_ORDER'))

export const deleteOrderAction = createApiActions<
  DeleteOrderActionPayload,
  void,
  AxiosError
>(createActionTypes('ORDERS/DELETE_ORDER'))

export const searchOrdersByQueryAction = createApiActions<
  SearchOrdersByQueryActionPayload,
  SearchOrdersByQueryActionResponse,
  AxiosError
>(createActionTypes('ORDERS/SEARCH_ORDERS_BY_QUERY'))

export const searchOrdersByDateAction = createApiActions<
  SearchOrdersByDateActionPayload,
  SearchOrdersByDateActionResponse,
  AxiosError
>(createActionTypes('ORDERS/SEARCH_ORDERS_BY_DATE'))

export const sortOrdersByQueryAction = createApiActions<
  SortOrdersByQueryActionPayload,
  SortOrdersByQueryActionResponse,
  AxiosError
>(createActionTypes('ORDERS/SORT_ORDERS_BY_QUERY'))

export const setOrdersFilterAction = createAction<any, 'ORDERS/SET_FILTER'>(
  'ORDERS/SET_FILTER',
)
