import { api } from '../config'

export const CHAT_URL = {
  get_all_chats: query =>
    `${api.withAPI}/chat/pendingChats/${query ? '?my=true' : ''}`,
  get_all_active_chats: `${api.withAPI}/chat/activeChats`,
  get_my_chats_by_query: query => `${api.withAPI}/chat/?${query}`,
  get_all_my_chats: `${api.withAPI}/chat`,
  send_message: `${api.withAPI}/chat/sendMessage`,
  get_messages_by_id: id => `${api.withAPI}/chat/${id}`,
  accept_chat: id => `${api.withAPI}/chat/acceptChat/${id}`,
  close_chat: id => `${api.withAPI}/chat/${id}`,
  read_messages: data =>
    `${api.withAPI}/chat/readMessages/${data.chatId}/${data.userId}`,
  download_file: data =>
    `${api.withAPI}/chat/downloadFile/${data.chatId}/${data.fileName}`,
}
