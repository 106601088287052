import axios, { AxiosError, AxiosResponse } from 'axios'
import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  getOrderAction,
  getOrdersAction,
  createOrderAction,
  updateOrderAction,
  deleteOrderAction,
  searchOrdersByDateAction,
  searchOrdersByQueryAction,
  sortOrdersByQueryAction,
  getOrdersStatisticAction,
  getNotifyOrdersAction,
  getOrdersByOrderAction,
  getArchiveOrdersAction,
  getMyOrdersAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { OrderAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'

function* getMyOrdersWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<ReturnType<typeof OrderAPI.getMyOrders>> =
      yield call([OrderAPI, OrderAPI.getMyOrders], {
        authorization: token,
        data: payload,
      })

    yield put(getMyOrdersAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getOrdersWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения заказов')),
    )

    yield put(getMyOrdersAction.failure(e))
  }
}

function* getOrdersWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<ReturnType<typeof OrderAPI.getOrders>> =
      yield call([OrderAPI, OrderAPI.getOrders], {
        authorization: token,
        data: payload,
      })
    yield put(getOrdersAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getOrdersWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения заказов')),
    )

    yield put(getOrdersAction.failure(e))
  }
}

function* getNotifyOrdersWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof OrderAPI.getNotifyOrders>
    > = yield call([OrderAPI, OrderAPI.getNotifyOrders], {
      authorization: token,
      data: payload,
    })
    yield put(getNotifyOrdersAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getOrdersWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения заказов')),
    )

    yield put(getNotifyOrdersAction.failure(e))
  }
}

function* getOrdersByOrderWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof OrderAPI.getOrdersByOrder>
    > = yield call([OrderAPI, OrderAPI.getOrdersByOrder], {
      authorization: token,
      data: payload,
    })
    yield put(getOrdersByOrderAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getOrdersWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения заказов')),
    )

    yield put(getOrdersByOrderAction.failure(e))
  }
}

function* getArchiveOrdersWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof OrderAPI.getArchiveOrders>
    > = yield call([OrderAPI, OrderAPI.getArchiveOrders], {
      authorization: token,
      data: payload,
    })
    yield put(getArchiveOrdersAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getOrdersWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения заказов')),
    )

    yield put(getArchiveOrdersAction.failure(e))
  }
}

function* getOrdersStatisticWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<ReturnType<typeof OrderAPI.getOrders>> =
      yield call([OrderAPI, OrderAPI.getOrdersStatistic], {
        authorization: token,
      })

    console.log(response.data)

    yield put(getOrdersStatisticAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getOrdersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения статистики заказов'),
      ),
    )

    yield put(getOrdersStatisticAction.failure(e))
  }
}

function* getOrderByIDWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<ReturnType<typeof OrderAPI.getOrder>> =
      yield call([OrderAPI, OrderAPI.getOrder], {
        authorization: token,
        id: payload.id,
      })

    yield put(getOrderAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getOrderWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения заказа')),
    )

    yield put(getOrderAction.failure(e))
  }
}

function* searchOrdersByQueryWorker({
  payload,
}: {
  payload: { query: string; data: string }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof OrderAPI.searchOrdersByQuery>
    > = yield call([OrderAPI, OrderAPI.searchOrdersByQuery], {
      authorization: token,
      query: 'phone',
      data: payload.data,
    })

    yield put(searchOrdersByQueryAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getOrderWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения заказов')),
    )

    yield put(searchOrdersByQueryAction.failure(e))
  }
}

function* searchOrdersByDateWorker({
  payload,
}: {
  payload: { from: string; to: string }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const { from, to } = payload
    const response: PromiseReturnType<
      ReturnType<typeof OrderAPI.searchOrdersByDate>
    > = yield call([OrderAPI, OrderAPI.searchOrdersByDate], {
      authorization: token,
      from,
      to,
    })

    yield put(searchOrdersByDateAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getOrderWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения заказов')),
    )

    yield put(searchOrdersByDateAction.failure(e))
  }
}

function* createOrderWorker({
  payload,
}: ReturnType<typeof createOrderAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    console.log(payload)
    const response: PromiseReturnType<ReturnType<typeof OrderAPI.createOrder>> =
      yield call([OrderAPI, OrderAPI.createOrder], {
        authorization: token,
        data: payload.data,
      })
    yield put(createOrderAction.success(response.data))
    yield put(showToastAction.request(generateSuccessToast('Заказ создан')))
  } catch (e: any) {
    console.log('Error: createOrderWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка в создании заказа')),
    )

    yield put(createOrderAction.failure(e))
  }
}

function* updateOrderWorker({
  payload,
}: ReturnType<typeof updateOrderAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const { id, data } = payload
    yield call([OrderAPI, OrderAPI.updateOrder], {
      authorization: token,
      id,
      data,
    })

    const response: PromiseReturnType<ReturnType<typeof OrderAPI.getOrder>> =
      yield call([OrderAPI, OrderAPI.getOrder], {
        authorization: token,
        id,
      })

    // yield put(getOrderAction.success(response.data))

    yield put(updateOrderAction.success(response.data))
    yield put(showToastAction.request(generateSuccessToast('Заказ обновлен')))
  } catch (e: any) {
    console.log('Error: updateOrderWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка в обновлении заказа')),
    )
    yield put(createOrderAction.failure(e))
  }
}

function* deleteOrderWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    yield call([OrderAPI, OrderAPI.deleteOrder], {
      authorization: token,
      id: payload.id,
    })
    yield put(deleteOrderAction.success())
    yield put(showToastAction.request(generateSuccessToast('Заказа удален')))
  } catch (e: any) {
    console.log('Error: deleteOrderWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка в удалении заказа')),
    )

    yield put(createOrderAction.failure(e))
  }
}

function* sortOrdersByQueryWorker({ payload }: { payload: { query: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof OrderAPI.sortOrdersByQuery>
    > = yield call([OrderAPI, OrderAPI.sortOrdersByQuery], {
      authorization: token,
      query: payload.query,
    })
    yield put(sortOrdersByQueryAction.success(response.data))
  } catch (e: any) {
    console.log('Error: sortOrdersByQueryWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка в получении заказов')),
    )
    yield put(createOrderAction.failure(e))
  }
}

export function* ordersWatcher() {
  yield takeLatest(getMyOrdersAction.request, getMyOrdersWorker)
  yield takeLatest(getOrdersAction.request, getOrdersWorker)
  yield takeLatest(getNotifyOrdersAction.request, getNotifyOrdersWorker)
  yield takeLatest(getOrdersByOrderAction.request, getOrdersByOrderWorker)
  yield takeLatest(getArchiveOrdersAction.request, getArchiveOrdersWorker)
  yield takeLatest(getOrderAction.request, getOrderByIDWorker)
  yield takeLatest(createOrderAction.request, createOrderWorker)
  yield takeLatest(deleteOrderAction.request, deleteOrderWorker)
  yield takeLatest(updateOrderAction.request, updateOrderWorker)
  yield takeLatest(searchOrdersByQueryAction.request, searchOrdersByQueryWorker)
  yield takeLatest(searchOrdersByDateAction.request, searchOrdersByDateWorker)
  yield takeLatest(sortOrdersByQueryAction.request, sortOrdersByQueryWorker)
  yield takeLatest(getOrdersStatisticAction.request, getOrdersStatisticWorker)
}
