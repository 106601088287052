import { createReducer } from '@reduxjs/toolkit'
import { IInitialState, IChats, IChat, ISocketMessage } from './types'

import {
  acceptChatAction,
  closeChatAction,
  getAllActiveChatsAction,
  sendChatMessageAction,
  setActiveChatAction,
  setAllMyChatsAction,
  setAllNewChatsAction,
  setMyChatsByQueryAction,
  setNewMessageAction,
  setNewMessageToActiveChatAction,
  setNewPendingChatAction,
} from './actions'

const InitialState: IInitialState = {
  myChats: {} as IChats,
  newChats: [],
  activeChat: {} as IChat,
  allActiveChats: {} as IChats,
  message: {} as ISocketMessage,
  loading: false,
}

export const chatReducer = createReducer<IInitialState>(
  InitialState,
  builder => {
    builder.addCase(setAllMyChatsAction.request, state => ({
      ...state,
    }))
    builder.addCase(setAllMyChatsAction.success, (state, { payload }) => ({
      ...state,
      myChats: payload.data,
    }))
    builder.addCase(setAllMyChatsAction.failure, (state, { payload }) => ({
      ...state,
    }))

    builder.addCase(setMyChatsByQueryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(setMyChatsByQueryAction.success, (state, { payload }) => ({
      ...state,
      myChats: payload.data,
      loading: false,
    }))
    builder.addCase(setMyChatsByQueryAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setAllNewChatsAction.request, state => ({
      ...state,
    }))
    builder.addCase(setAllNewChatsAction.success, (state, { payload }) => ({
      ...state,
      newChats: payload.data,
    }))
    builder.addCase(setAllNewChatsAction.failure, (state, { payload }) => ({
      ...state,
    }))

    builder.addCase(sendChatMessageAction.request, state => ({
      ...state,
    }))
    builder.addCase(sendChatMessageAction.success, (state, { payload }) => ({
      ...state,
      myChats: payload.data,
    }))
    builder.addCase(sendChatMessageAction.failure, (state, { payload }) => ({
      ...state,
    }))

    builder.addCase(setActiveChatAction, (state, { payload }) => ({
      ...state,
      activeChat: payload,
    }))

    builder.addCase(acceptChatAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(acceptChatAction.success, (state, { payload }) => ({
      ...state,
      newChats: payload.data,
      loading: false,
    }))
    builder.addCase(acceptChatAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setNewMessageToActiveChatAction, (state, { payload }) => ({
      ...state,
      activeChat: {
        ...state.activeChat,
        messages: state.activeChat.messages && [
          ...state.activeChat.messages,
          payload.lastMsg,
        ],
      },
    }))

    builder.addCase(closeChatAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(closeChatAction.success, (state, { payload }) => ({
      ...state,
      myChats: payload.data,
      loading: false,
    }))
    builder.addCase(closeChatAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(getAllActiveChatsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getAllActiveChatsAction.success, (state, { payload }) => ({
      ...state,
      allActiveChats: payload.data,
      loading: false,
    }))
    builder.addCase(getAllActiveChatsAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setNewMessageAction, (state, { payload }) => ({
      ...state,
      message: payload,
    }))
  },
)
