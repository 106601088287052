import { FC, useCallback, useEffect, useState } from 'react'
import { RowStartWrapper } from '../../../components/Modals/styled'
import { ChooseBrandProps } from '../types'
import { useTypedSelector } from '../../../hooks'
import {
  BtnWrapper,
  DumbButton,
  DumbOutlineButton,
  FilterDropdownInputWrapper,
} from '../../../components/FormComponents/styled'
import { Assets, Colors } from '../../../assets'
import { ModalContainer, ProductName, ProductsWrapper } from '../styled'
import ProfileModal from '../../../components/Modals/ProfileModal'
import { PassModalTitle } from '../../Profile/styled'
import { SimpleDivider } from '../../../components/Styled/formStyled'
import {
  ColumnsGrid,
  RelativeInput,
  RelativeInputContainer,
} from '../../Roles/styled'
import { DateSearchInput } from '../../../components'
import { useDispatch } from 'react-redux'
import { api, getProductsAction } from '../../../store'
import { debounce } from 'lodash'
import { Radio } from '@mui/material'
import moment from 'moment'
import { defaultFormatDate } from '../../../helpers'

export const ChooseProduct: FC<ChooseBrandProps> = ({ setLink, link }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProductsAction.request(''))
  }, [])

  const { products } = useTypedSelector(state => state.products)

  console.log(products.data)

  const [activeProduct, setActiveProduct] = useState({
    id: '',
    name: '',
  })
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [createdDate, setCreatedDate] = useState({
    start: '',
    end: '',
  })

  const onSearchProduct = (title: string) => {
    setSearchQuery(title)
    dispatchSearchQuery(title)
  }

  const findProduct = (productId: string) => {
    if (products.data) {
      return products.data.find(product => {
        return product._id == productId
      })
    }
  }

  const dispatchSearchQuery = useCallback(
    debounce((query: string) => {
      if (query) {
        dispatch(getProductsAction.request(`${`&title=${query}`} `))
      } else {
        dispatch(getProductsAction.request(''))
      }
    }, 300),
    [],
  )

  const onChooseProduct = (productId: string) => {
    setActiveProduct({ id: productId, name: findProduct(productId).ru })
  }

  useEffect(() => {
    dispatch(
      getProductsAction.request(
        `${
          createdDate.start &&
          `createdAt=${moment(createdDate.start).toISOString()}`
        }${
          createdDate.end &&
          `&createdAt=${moment(createdDate.end).toISOString()}`
        }`,
      ),
    )
  }, [createdDate])

  const onAddProduct = () => {
    setLink(activeProduct.id)
    setIsModalOpen(false)
  }

  if (!products.data) {
    return <></>
  }

  return (
    <RowStartWrapper style={{ alignItems: 'start', width: '75%' }}>
      {(activeProduct.id || link) && (
        <ProductName>
          {link ? findProduct(link)?.ru : activeProduct.name}
        </ProductName>
      )}

      <DumbButton
        bg={Colors.green}
        txt={Colors.white}
        padding="11px 8px"
        style={{ width: '40%' }}
        onClick={() => setIsModalOpen(true)}
      >
        {activeProduct.id ? 'Изменить товар' : 'Прикрепить товар'}
      </DumbButton>

      <ModalContainer className={isModalOpen ? 'open' : ''}>
        <ProfileModal
          isModalOpen={isModalOpen}
          onModalClose={() => setIsModalOpen(false)}
        >
          <PassModalTitle>Прикрепить товар</PassModalTitle>

          <SimpleDivider height="20px" />

          <RowStartWrapper style={{ alignItems: 'start' }}>
            <RelativeInputContainer maxwidth="300px">
              <RelativeInput
                value={searchQuery}
                onChange={e => onSearchProduct(e.target.value)}
                padding="0px 10px 0 35px"
                type="text"
                placeholder="Поиск..."
              />
              <img
                src={Assets.SEARCH_ICON}
                alt="search"
                className="search-icon"
              />
            </RelativeInputContainer>

            <FilterDropdownInputWrapper>
              <DateSearchInput
                value={createdDate.start}
                onChange={e =>
                  setCreatedDate(prev => ({ ...prev, start: e.target.value }))
                }
                type="date"
              />

              <DateSearchInput
                value={createdDate.end}
                onChange={e =>
                  setCreatedDate(prev => ({ ...prev, end: e.target.value }))
                }
                type="date"
              />
            </FilterDropdownInputWrapper>
          </RowStartWrapper>

          <SimpleDivider height="20px" />

          <ColumnsGrid
            className="sort padding border"
            template="8% 15% 20% 15% 22% 20%"
          >
            <div></div>
            <div>Фото</div>
            <div>Название</div>
            <div>Артикул</div>
            <div>Дата создания</div>
            <div>Категория</div>
          </ColumnsGrid>

          <ProductsWrapper>
            {products?.data &&
              products.data.map(product => (
                <ColumnsGrid
                  className="banner-product"
                  template="8% 15% 20% 15% 22% 20%"
                  key={product._id}
                >
                  <Radio
                    color="success"
                    onChange={e => onChooseProduct(e.target.value)}
                    name="product"
                    value={product._id}
                    checked={activeProduct.id == product._id ? true : false}
                  />
                  <img
                    className="choose-product-img"
                    src={`${api.images}${product.images[0]}`}
                    alt="product"
                  />
                  <div>{product?.ru}</div>
                  <div>{product?.vendorCode}</div>
                  <div>{defaultFormatDate(product?.createdAt)}</div>
                  <div>{product?.category?.ru}</div>
                </ColumnsGrid>
              ))}
          </ProductsWrapper>

          <SimpleDivider height="20px" />

          <BtnWrapper>
            <DumbOutlineButton
              color={Colors.boldGray}
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="cancel"
            >
              Отмена
            </DumbOutlineButton>

            <DumbButton
              bg={Colors.orange}
              txt={Colors.black}
              type="button"
              onClick={onAddProduct}
            >
              Прикрепить
            </DumbButton>
          </BtnWrapper>
        </ProfileModal>
      </ModalContainer>
    </RowStartWrapper>
  )
}
