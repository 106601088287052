import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  getCustomerAction,
  getCustomersAction,
  updateCustomerAction,
  unsetCustomerAction,
  getCustomerByPhoneAction,
  sortCustomersByQueryAction,
  getMyCustomersAction,
} from './actions'

const InitialState: TInitialState = {
  customers: [],
  customer: null,
  loading: false,
  meta: {},
}

export const customersReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getMyCustomersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getMyCustomersAction.success, (state, { payload }) => ({
      ...state,
      customers: payload.data,
      meta: { totalCount: payload.totalCount },
      loading: false,
    }))
    builder.addCase(getMyCustomersAction.failure, (state, { payload }) => ({
      ...state,
      customers: [],
      loading: false,
    }))

    builder.addCase(getCustomersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCustomersAction.success, (state, { payload }) => ({
      ...state,
      customers: payload.data,
      meta: { totalCount: payload.totalCount },
      loading: false,
    }))
    builder.addCase(getCustomersAction.failure, (state, { payload }) => ({
      ...state,
      customers: [],
      loading: false,
    }))

    builder.addCase(getCustomerAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCustomerAction.success, (state, { payload }) => ({
      ...state,
      customer: payload.data,
      loading: false,
    }))
    builder.addCase(getCustomerAction.failure, (state, { payload }) => ({
      ...state,
      customer: null,
      loading: false,
    }))

    builder.addCase(getCustomerByPhoneAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCustomerByPhoneAction.success, (state, { payload }) => ({
      ...state,
      customers: payload.data,
      loading: false,
    }))
    builder.addCase(getCustomerByPhoneAction.failure, (state, { payload }) => ({
      ...state,
      customer: null,
      loading: false,
    }))

    builder.addCase(updateCustomerAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateCustomerAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(updateCustomerAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(sortCustomersByQueryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      sortCustomersByQueryAction.success,
      (state, { payload }) => ({
        ...state,
        customers: payload.data,
        loading: false,
      }),
    )
    builder.addCase(
      sortCustomersByQueryAction.failure,
      (state, { payload }) => ({
        ...state,
        customers: [],
        loading: false,
      }),
    )

    builder.addCase(unsetCustomerAction, state => ({
      ...state,
      customer: null,
      loading: false,
    }))
  },
)
