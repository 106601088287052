import _ from 'lodash'
import { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Assets, Colors } from '../../../../assets'
import { Icon } from '../../../../components'
import {
  Content,
  InfoTitle,
  FullWidth,
  SimpleDivider,
} from '../../../../components/Styled/formStyled'
import { useTypedSelector } from '../../../../hooks'
import { getUserSelector } from '../../../../store'
import {
  ProfileInputLabel,
  ProfileInputWrapper,
  SmallDumbBtn,
} from '../../../Profile/styled'
import { RangeForm } from '../RangeForm'
import { SupliesrAPI } from './api.service'
import { ButtonContainer, Container } from './styled'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export type TPercentageForDistributor = {
  buttonRef: any
}

export const PercentageForDistributor = ({
  buttonRef,
}: TPercentageForDistributor) => {
  const { token } = useTypedSelector(getUserSelector)

  const [supliers, setSupliers] = useState<any[]>([])
  const [activeSuplier, setActiveSuplier] = useState<string>('')
  const [suplierRanges, setSuplierRanges] = useState<any[]>([])

  //   const createRange = useRef<boolean>(false)

  const Events = {
    addButtonClickHandler: () => {
      setSuplierRanges(prev => [...prev, { percent: '', from: '', to: '' }])
    },
  }

  const Requests = {
    getSupliers: async () => {
      try {
        const response = await SupliesrAPI.getSuplies(token as string)
        response && setSupliers(response.data.admins as any)
      } catch (e) {}
    },
    getSuplierById: async () => {
      try {
        const response = await SupliesrAPI.getSuplierById(
          token as string,
          activeSuplier,
        )
        response && setSuplierRanges(response.data.range as any)
      } catch (e) {}
    },
    createSuplie: async () => {
      try {
        return SupliesrAPI.createSuplies(token as string, {
          adminId: activeSuplier,
          range: suplierRanges.map(range => ({
            percent: range.percent,
            from: range.from,
            to: range.to,
          })),
        })
      } catch (e) {}
    },
    editSuplie: async () => {
      try {
        return SupliesrAPI.editSuplies(
          token as string,
          {
            range: suplierRanges.map(range => ({
              percent: range.percent,
              from: range.from,
              to: range.to,
            })),
          },
          activeSuplier,
        )
      } catch (e) {}
    },
    removeSuplie: async (_id: string) => {
      try {
        return SupliesrAPI.removeSuplies(token as string, _id)
      } catch (e) {}
    },
  }

  useEffect(() => {
    Requests.getSupliers()
  }, [])

  useEffect(() => {
    activeSuplier && Requests.getSuplierById()
  }, [activeSuplier])

  //   useEffect(() => {
  //     suplierRanges.length > 0
  //       ? (createRange.current = false)
  //       : (createRange.current = true)
  //   }, [suplierRanges])

  const Utils = {
    removeNonNumber: (value: string) => {
      return value.replace('$', '')
    },
  }

  useImperativeHandle(buttonRef, () => ({
    clickHandler: async () => {
      await Requests.editSuplie()
    },
  }))

  return (
    <Content>
      <FullWidth>
        <InfoTitle>Процент для поставщиков</InfoTitle>

        <SimpleDivider height="20px" />

        <ProfileInputWrapper maxWidth="300px">
          <ProfileInputLabel>Поставщик</ProfileInputLabel>
          <Select
            labelId="role"
            id="role-select"
            onChange={e => setActiveSuplier(e.target.value)}
            value={activeSuplier}
            style={{ width: '100%', height: '40px' }}
          >
            {supliers.map(suplier => (
              <MenuItem value={suplier._id} key={suplier._id}>
                {suplier.name}
              </MenuItem>
            ))}
          </Select>
        </ProfileInputWrapper>

        <SimpleDivider height="20px" />

        <Container>
          {suplierRanges?.length > 0 &&
            suplierRanges.map((item, index) => (
              <RangeForm
                key={index}
                index={index + 1}
                from={'' + item.from}
                to={'' + item.to}
                percentage={'' + item.percent}
                onRemove={() => {
                  //   if (item._id) {
                  //     Requests.removeSuplie(item._id)
                  //   }

                  setSuplierRanges(prev =>
                    prev.filter((suplie, i) => i !== index),
                  )
                }}
                onChange={(name, value) => {
                  console.log(name, value)
                  setSuplierRanges(prev =>
                    prev.map((range, i) => {
                      if (i === index) {
                        if (name === 'to' || name === 'from') {
                          return {
                            ...range,
                            [name]: _.isNaN(
                              parseInt(Utils.removeNonNumber(value)),
                            )
                              ? 0
                              : parseInt(Utils.removeNonNumber(value)),
                          }
                        }
                        return {
                          ...range,
                          [name]: _.isNaN(parseInt(value))
                            ? 0
                            : parseInt(value),
                        }
                      }
                      return range
                    }),
                  )
                }}
              />
            ))}

          {activeSuplier && (
            <ButtonContainer>
              <SmallDumbBtn
                bg={Colors.green}
                txt={Colors.white}
                shadow={Colors.lightGreen}
                style={{
                  alignSelf: 'start',
                  marginLeft: '30px',
                  width: '161px',
                }}
                onClick={Events.addButtonClickHandler}
              >
                <Icon src={Assets.PLUS_WHITE_ICON}></Icon>
                Добавить
              </SmallDumbBtn>
            </ButtonContainer>
          )}
        </Container>
      </FullWidth>
    </Content>
  )
}
