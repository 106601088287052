import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  getStoreAction,
  getStoresAction,
  createStoreAction,
  getStoresWithoutItemAction,
  getAllStoresStatsAction,
  getStoresStatsByIdAction,
  updateStoreAction,
  deleteStoreAction,
  getMyStoresAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { StoreAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'

function* getStoresWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<ReturnType<typeof StoreAPI.getStores>> =
      yield call([StoreAPI, StoreAPI.getStores], {
        authorization: token,
      })

    yield put(getStoresAction.success(response.data))
  } catch (e: any) {
    if (!e?.toString()?.includes('403')) {
      yield put(
        showToastAction.request(
          generateErrorToast('Ошибка получения магазинов'),
        ),
      )
    }

    yield put(getStoresAction.failure(e))
  }
}

function* getMyStoresWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<ReturnType<typeof StoreAPI.getMyStores>> =
      yield call([StoreAPI, StoreAPI.getMyStores], {
        authorization: token,
      })

    yield put(getMyStoresAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getStoresWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения складов')),
    )
    yield put(getStoresAction.failure(e))
  }
}

function* getAllStoresStatsWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof StoreAPI.getAllStoresStats>
    > = yield call([StoreAPI, StoreAPI.getAllStoresStats], {
      authorization: token,
      data: payload,
    })

    yield put(getAllStoresStatsAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getStoresWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения складов')),
    )
    yield put(getAllStoresStatsAction.failure(e))
  }
}

function* getStoresStatsByIdWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof StoreAPI.getAllStoresStats>
    > = yield call([StoreAPI, StoreAPI.getAllStoresStats], {
      authorization: token,
      data: payload,
    })

    yield put(getStoresStatsByIdAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getStoresWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения складов')),
    )
    yield put(getStoresStatsByIdAction.failure(e))
  }
}

function* getStoreByIDWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)
    const response: PromiseReturnType<ReturnType<typeof StoreAPI.getStore>> =
      yield call([StoreAPI, StoreAPI.getStore], {
        authorization: token,
        id: payload.id,
      })
    yield put(getStoreAction.success(response.data))
  } catch (e) {
    console.log('Error: getStoreWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения магазина')),
    )

    yield put(getStoreAction.failure(e))
  }
}

function* getStoresWithoutItemWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof StoreAPI.getStoresWithoutItem>
    > = yield call([StoreAPI, StoreAPI.getStoresWithoutItem], {
      authorization: token,
      id: payload.id,
    })
    yield put(getStoresWithoutItemAction.success(response.data))
  } catch (e) {
    console.log('Error: getStoresWithoutItemWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения магазина')),
    )

    yield put(getStoresWithoutItemAction.failure(e))
  }
}

function* createStoreWorker({
  payload,
}: ReturnType<typeof createStoreAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([StoreAPI, StoreAPI.createStore], {
      authorization: token,
      data: payload,
    })

    const response: PromiseReturnType<
      ReturnType<typeof StoreAPI.getAllStoresStats>
    > = yield call([StoreAPI, StoreAPI.getAllStoresStats], {
      authorization: token,
      data: payload.vendor,
    })

    yield put(createStoreAction.success())

    yield put(getStoresStatsByIdAction.success(response.data))

    yield put(
      showToastAction.request(generateSuccessToast('Склад успешно создан')),
    )
  } catch (e) {
    console.log('Error: createStyleWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания материала')),
    )

    yield put(createStoreAction.failure(e))
  }
}

function* updateStoreWorker({ payload }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([StoreAPI, StoreAPI.updateStore], {
      authorization: token,
      data: payload.data,
      id: payload.storeId,
    })

    const response: PromiseReturnType<
      ReturnType<typeof StoreAPI.getAllStoresStats>
    > = yield call([StoreAPI, StoreAPI.getAllStoresStats], {
      authorization: token,
      data: payload.supplierId,
    })

    yield put(updateStoreAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getStoresWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления склада')),
    )
    yield put(updateStoreAction.failure(e))
  }
}

function* deleteStoreWorker({ payload }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([StoreAPI, StoreAPI.deleteStore], {
      authorization: token,
      id: payload.storeId,
    })

    const response: PromiseReturnType<
      ReturnType<typeof StoreAPI.getAllStoresStats>
    > = yield call([StoreAPI, StoreAPI.getAllStoresStats], {
      authorization: token,
      data: payload.supplierId,
    })

    yield put(deleteStoreAction.success())
    yield put(updateStoreAction.success(response.data))
  } catch (e: any) {
    console.log('Error: getStoresWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления склада')),
    )
    yield put(deleteStoreAction.failure(e))
  }
}

export function* storesWatcher() {
  yield takeLatest(getStoresAction.request, getStoresWorker)
  yield takeLatest(getAllStoresStatsAction.request, getAllStoresStatsWorker)
  yield takeLatest(getStoresStatsByIdAction.request, getStoresStatsByIdWorker)
  yield takeLatest(getStoreAction.request, getStoreByIDWorker)
  yield takeLatest(createStoreAction.request, createStoreWorker)
  yield takeLatest(updateStoreAction.request, updateStoreWorker)
  yield takeLatest(deleteStoreAction.request, deleteStoreWorker)
  yield takeLatest(
    getStoresWithoutItemAction.request,
    getStoresWithoutItemWorker,
  )
  yield takeLatest(getMyStoresAction.request, getMyStoresWorker)
}
