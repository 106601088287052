import {
  takeLatest,
  takeEvery,
  select,
  call,
  put,
} from '@redux-saga/core/effects'
import {
  setAllNewChatsAction,
  setAllMyChatsAction,
  sendChatMessageAction,
  setActiveChatAction,
  acceptChatAction,
  closeChatAction,
  readMessagesAction,
  downloadFileAction,
  setMyChatsByQueryAction,
  getAllActiveChatsAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { AuthorizationError } from '../../errors'
import { getUserSelector } from './selectors'
import { PromiseReturnType } from '../types'
import { showToastAction } from '../toasts'
import { Log } from '../../utils'
import { ChatAPI } from './api.service'
import { AxiosError } from 'axios'

export function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getAllNewChatsWorker({ payload }) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ChatAPI.getAllNewChats>
    > = yield call([ChatAPI, ChatAPI.getAllNewChats], {
      authorization: token,
      data: payload,
    })

    yield put(setAllNewChatsAction.success(response.data))
  } catch (e: any) {
    Log.ruddy('Error: getRolesWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения новых чатов'),
      ),
    )

    yield put(setAllNewChatsAction.failure(e))
  }
}

function* getAllMyChatsWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ChatAPI.getAllMyChats>
    > = yield call([ChatAPI, ChatAPI.getAllMyChats], { authorization: token })

    yield put(setAllMyChatsAction.success(response.data))
  } catch (e: any) {
    Log.ruddy('Error: getRolesWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения моих чатов'),
      ),
    )

    yield put(setAllMyChatsAction.failure(e))
  }
}

function* getMyChatsByQueryWorker({
  payload,
}: ReturnType<typeof setMyChatsByQueryAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ChatAPI.getMyChatsByQuery>
    > = yield call([ChatAPI, ChatAPI.getMyChatsByQuery], {
      authorization: token,
      data: payload,
    })

    yield put(setMyChatsByQueryAction.success(response.data))
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка поиска чатов')),
    )
    yield put(setMyChatsByQueryAction.failure(e as AxiosError))
  }
}

function* sendChatmessageWorker({
  payload,
}: ReturnType<typeof sendChatMessageAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([ChatAPI, ChatAPI.sendMessage], {
      authorization: token,
      data: payload,
    })

    const response: PromiseReturnType<
      ReturnType<typeof ChatAPI.getAllMyChats>
    > = yield call([ChatAPI, ChatAPI.getAllMyChats], { authorization: token })

    yield put(sendChatMessageAction.success(response.data))
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка отправки сообщения')),
    )
    yield put(sendChatMessageAction.failure(e as AxiosError))
  }
}

function* acceptChatWorker({
  payload,
}: ReturnType<typeof acceptChatAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([ChatAPI, ChatAPI.acceptChat], {
      authorization: token,
      data: payload._id,
    })

    const response: PromiseReturnType<
      ReturnType<typeof ChatAPI.getAllNewChats>
    > = yield call([ChatAPI, ChatAPI.getAllNewChats], {
      authorization: token,
      data: payload.query,
    })

    yield put(acceptChatAction.success(response.data))

    yield put(setAllMyChatsAction.request())
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка принятия чата')),
    )
    yield put(acceptChatAction.failure(e as AxiosError))
  }
}

function* closeChatWorker({
  payload,
}: ReturnType<typeof closeChatAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([ChatAPI, ChatAPI.closeChat], {
      authorization: token,
      data: payload._id,
    })

    const response: PromiseReturnType<
      ReturnType<typeof ChatAPI.getAllMyChats>
    > = yield call([ChatAPI, ChatAPI.getAllMyChats], { authorization: token })

    yield put(closeChatAction.success(response.data))
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка завершения чата')),
    )
    yield put(closeChatAction.failure(e as AxiosError))
  }
}

function* readMessagesWorker({
  payload,
}: ReturnType<typeof readMessagesAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([ChatAPI, ChatAPI.readMessages], {
      authorization: token,
      data: payload,
    })

    yield put(readMessagesAction.success())
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(readMessagesAction.failure(e as AxiosError))
  }
}

function* downloadFileWorker({
  payload,
}: ReturnType<typeof downloadFileAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<ReturnType<typeof ChatAPI.downloadFile>> =
      yield call([ChatAPI, ChatAPI.downloadFile], {
        authorization: token,
        data: payload,
      })

    console.log(response.data)

    yield put(downloadFileAction.success())
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(downloadFileAction.failure(e as AxiosError))

    yield put(
      showToastAction.request(generateErrorToast('Ошибка загрузки файла')),
    )
  }
}

function* getAllActiveChatsWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof ChatAPI.getAllActiveChats>
    > = yield call([ChatAPI, ChatAPI.getAllActiveChats], {
      authorization: token,
    })

    yield put(getAllActiveChatsAction.success(response.data))
  } catch (e: any) {
    Log.ruddy('Error: getRolesWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения всех чатов'),
      ),
    )

    yield put(getAllActiveChatsAction.failure(e))
  }
}

export function* chatWatcher() {
  yield takeLatest(setAllNewChatsAction.request, getAllNewChatsWorker)
  yield takeLatest(setAllMyChatsAction.request, getAllMyChatsWorker)
  yield takeLatest(sendChatMessageAction.request, sendChatmessageWorker)
  yield takeLatest(acceptChatAction.request, acceptChatWorker)
  yield takeLatest(closeChatAction.request, closeChatWorker)
  yield takeLatest(readMessagesAction.request, readMessagesWorker)
  yield takeLatest(downloadFileAction.request, downloadFileWorker)
  yield takeLatest(setMyChatsByQueryAction.request, getMyChatsByQueryWorker)
  yield takeLatest(getAllActiveChatsAction.request, getAllActiveChatsWorker)
  //   yield takeEvery(updateRolesAction.request, updateRolesWorker)
}
