import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  SetAllOrdersStatsActionPayload,
  SetAllOrdersStatsActionResponse,
  SetAmountStatsActionResponse,
  SetFinanceFilterActionPayload,
  SetSupplierOrdersActionPayload,
  SetSupplierOrdersActionResponse,
  SetSuppliersStatsActionPayload,
  SetSuppliersStatsActionResponse,
} from './types'

export const setSuppliersStatsAction = createApiActions<
  SetSuppliersStatsActionPayload,
  SetSuppliersStatsActionResponse,
  AxiosError
>(createActionTypes('FINANCE/SET_SUPPLIER_STATS'))

export const setSupplierOrdersAction = createApiActions<
  SetSupplierOrdersActionPayload,
  SetSupplierOrdersActionResponse,
  AxiosError
>(createActionTypes('FINANCE/SET_SUPPLIER_ORDERS'))

export const setAmountStatsAction = createApiActions<
  void,
  SetAmountStatsActionResponse,
  AxiosError
>(createActionTypes('FINANCE/SET_AMOUNT_STATS'))

export const setAllOrdersStatsAction = createApiActions<
  SetAllOrdersStatsActionPayload,
  SetAllOrdersStatsActionResponse,
  AxiosError
>(createActionTypes('FINANCE/SET_ALL_ORDERS'))

export const setFinanceFilterAction = createAction<
  SetFinanceFilterActionPayload,
  'FINANCE/SET_FILTER'
>('FINANCE/SET_FILTER')

export const setFinanceSearchQueryAction = createAction<
  SetFinanceFilterActionPayload,
  'FINANCE/SET_SEARCH_QUERY'
>('FINANCE/SET_SEARCH_QUERY')

export const updateNotesForOwnerAction = createApiActions<
  SetAllOrdersStatsActionPayload,
  any,
  AxiosError
>(createActionTypes('FINANCE/UPDATE_NOTES_FOT_OWNER'))
