import { FC } from 'react'
import ReactModal from 'react-modal'
import {
  ModalOverlay,
  ModalWrapperWithoutPadding,
  ProfileModalWrapper,
} from './styled'

interface IProfileModalProps {
  isModalOpen: boolean
  onModalClose: () => void
  withoutPadding?: boolean
}

const ProfileModal: FC<IProfileModalProps> = ({
  isModalOpen,
  onModalClose,
  children,
  withoutPadding,
}) => {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      shouldCloseOnOverlayClick
      className="_"
      overlayClassName="_"
      contentElement={(props, children) =>
        withoutPadding ? (
          <ModalWrapperWithoutPadding {...props}>
            {children}
          </ModalWrapperWithoutPadding>
        ) : (
          <ProfileModalWrapper {...props}>{children}</ProfileModalWrapper>
        )
      }
      overlayElement={(props, contentElement) => (
        <ModalOverlay {...props}>{contentElement}</ModalOverlay>
      )}
    >
      {children}
    </ReactModal>
  )
}

export default ProfileModal
