import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  moderateFeedbackAction,
  getFeedbackAction,
  getFeedbackByIdAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { FeedbackAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* getFeedbacksWorker({ payload }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.getFeedback>
    > = yield call([FeedbackAPI, FeedbackAPI.getFeedback], {
      authorization: token,
      data: payload,
    })
    yield put(getFeedbackAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения отзывов')),
    )
    yield put(getFeedbackAction.failure(e as AxiosError))
  }
}

function* getFeedbackWorker({ payload }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.getFeedbackById>
    > = yield call([FeedbackAPI, FeedbackAPI.getFeedbackById], {
      authorization: token,
      id: payload.id,
    })
    yield put(getFeedbackByIdAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения отзыва')),
    )
    yield put(getFeedbackByIdAction.failure(e as AxiosError))
  }
}

function* allowFeedbackWorker({ payload }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.updateFeedbackById>
    > = yield call([FeedbackAPI, FeedbackAPI.updateFeedbackById], {
      authorization: token,
      data: { status: payload.type === 'allow' ? 'show' : 'hide' },
      id: payload.id,
    })

    const res: PromiseReturnType<
      ReturnType<typeof FeedbackAPI.getFeedbackById>
    > = yield call([FeedbackAPI, FeedbackAPI.getFeedbackById], {
      authorization: token,
      id: payload.id,
    })

    yield put(moderateFeedbackAction.success(res.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления отзыва')),
    )
    yield put(moderateFeedbackAction.failure(e as AxiosError))
  }
}

export function* feedbackWatcher() {
  yield takeLatest(getFeedbackAction.request, getFeedbacksWorker)
  yield takeLatest(getFeedbackByIdAction.request, getFeedbackWorker)
  yield takeLatest(moderateFeedbackAction.request, allowFeedbackWorker)
}
