import { Colors } from '../../assets'
import styled from 'styled-components'
import { FLEX } from '../../utils'

export const StoresContainer = styled.div`
  ${FLEX({ justify: 'flex-start' })}
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  align-items: initial;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${Colors.lightGray};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray};
    border-radius: 20px;
  }
`
export const StoreCard = styled.div`
  ${FLEX({
    justify: 'center',
    align: 'center',
    direction: 'column',
    wrap: 'nowrap',
  })}
  width: 25%;
  overflow: hidden;
  padding: 30px 15px 20px;
  border-right: 1px solid ${Colors.lightGray};
  border-bottom: 1px solid ${Colors.lightGray};
  height: 330px;
  position: relative;
  text-align: center;
  .edit-store {
    cursor: pointer;
    position: absolute;
    left: 15px;
    top: 15px;
  }
  .delete-store {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .store {
    width: 100%;
    max-width: 175px;
  }
  .name {
    font-weight: 600;
  }
`
