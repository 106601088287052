import { FC } from 'react'
import {
  StyledCheckbox,
  CheckboxContainer,
  StyledCheckboxLabel,
} from './styled'

interface ICheckbox {
  value?: boolean
  onChange?: any
  name?: string
}

const Checkbox: FC<ICheckbox> = ({ value, children, onChange, name }) => {
  return (
    <CheckboxContainer>
      <StyledCheckbox
        checked={value}
        type="checkbox"
        onChange={onChange}
        id={name}
      />

      <StyledCheckboxLabel htmlFor={name} style={{ paddingLeft: '5px' }}>
        {children}
      </StyledCheckboxLabel>
    </CheckboxContainer>
  )
}

export default Checkbox
