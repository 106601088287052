import { FC, useEffect, useRef, useState } from 'react'
import {
  SmartInput,
  StyledInputFieldWidthWrapper,
  FakeButton,
} from '../../components'
import {
  Container,
  Header,
  TitleGroup,
  Title,
  Content,
  InfoTitle,
  InputContainer,
  SimpleDivider,
  FullWidth,
} from '../../components/Styled/formStyled'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { settingsSchema } from '../../validation/settings.validation'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBonusesAction,
  getExchangeRateAction,
  setBonusesAction,
  setExchangeRateAction,
} from '../../store/settings'
import { useTypedSelector } from '../../hooks'
import {
  ProfileInput,
  ProfileInputError,
  ProfileInputLabel,
  ProfileInputWrapper,
} from '../Profile/styled'
import { getSettingsSelector } from '../../store/settings/selectors'
import { RowStartWrapper } from '../../components/Modals/styled'
import { PercentageForDistributor } from './components'

interface ISettings {}

const Settings: FC<ISettings> = () => {
  const [defaultBonus, setDefaultBonus] = useState<number>(0)
  const [bonusFromOrder, setBonusFromOrder] = useState<number>(0)

  const { defaultBonuses, bonusesFromOrder } = useSelector(getSettingsSelector)

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(settingsSchema),
    mode: 'onBlur',
  })

  console.log(errors)

  const dispatch = useDispatch()

  const { exchangeRate } = useTypedSelector(state => state.settings)

  const saveButtonRef = useRef()

  useEffect(() => {
    dispatch(getExchangeRateAction.request())
    dispatch(getBonusesAction.request())
  }, [dispatch])

  useEffect(() => {
    if (defaultBonuses) {
      setDefaultBonus(defaultBonuses)
    }
    if (bonusesFromOrder) {
      setBonusFromOrder(bonusesFromOrder)
    }
  }, [defaultBonuses, bonusesFromOrder])

  useEffect(() => {
    if (exchangeRate) {
      setValue('exRate', exchangeRate.customRate)
      setValue('realExRate', exchangeRate.rate)
    }
  }, [exchangeRate])

  const handleSave = (data: any) => {
    dispatch(
      setExchangeRateAction.request({
        data: { customRate: data.exRate },
      }),
    )

    dispatch(
      setBonusesAction.request({
        data: {
          defaultBonuses: defaultBonus,
          bonusesFromOrder: bonusFromOrder,
        },
      }),
    )

    // @ts-ignore
    saveButtonRef?.current?.clickHandler()
  }

  return (
    <form onSubmit={handleSubmit(data => handleSave(data))}>
      <Container>
        <Header>
          <TitleGroup>
            <div style={{ display: 'flex' }}>
              <Title>Настройки</Title>
            </div>
          </TitleGroup>
          <div style={{ display: 'flex' }}>
            <FakeButton htmlFor="hiddenSave" ref={saveButtonRef as any}>
              Сохранить
            </FakeButton>
          </div>
        </Header>
        <Content>
          <FullWidth>
            <InfoTitle>Управление Курсом</InfoTitle>
            <SimpleDivider height="10px" />
            <InputContainer>
              {/* <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Курс"
                  name="exRate"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper> */}

              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Текущий курс"
                  name="realExRate"
                  error={errors}
                  disabled
                />
              </StyledInputFieldWidthWrapper>
            </InputContainer>
            <button id="hiddenSave" style={{ display: 'none' }} type="submit">
              hidden
            </button>
          </FullWidth>
        </Content>

        <PercentageForDistributor buttonRef={saveButtonRef} />

        <Content>
          <FullWidth>
            <InfoTitle>Бонусы</InfoTitle>
            <SimpleDivider height="10px" />

            <RowStartWrapper className="align-top">
              <ProfileInputWrapper maxWidth="300px">
                <ProfileInputLabel>Бонус для первого входа</ProfileInputLabel>
                <ProfileInput
                  {...register('defaultBonuse')}
                  value={defaultBonus}
                  onChange={e => setDefaultBonus(Number(e.target.value))}
                  className={`${errors.defaultBonuse ? 'error' : ''}`}
                  type="number"
                />
                {errors.defaultBonuse && (
                  <ProfileInputError>
                    {errors?.defaultBonuse.message}
                  </ProfileInputError>
                )}
              </ProfileInputWrapper>

              <ProfileInputWrapper maxWidth="300px">
                <ProfileInputLabel>
                  Бонусы от суммы заказа (%)
                </ProfileInputLabel>
                <ProfileInput
                  {...register('bonusesFromOrder')}
                  value={bonusFromOrder}
                  onChange={e => setBonusFromOrder(Number(e.target.value))}
                  className={`${errors.bonusesFromOrder ? 'error' : ''}`}
                  type="number"
                />
                {errors.bonusesFromOrder && (
                  <ProfileInputError>
                    {errors.bonusesFromOrder.message}
                  </ProfileInputError>
                )}
              </ProfileInputWrapper>
            </RowStartWrapper>
          </FullWidth>
        </Content>
      </Container>
    </form>
  )
}

export default Settings
