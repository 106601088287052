import { useEffect, useState } from 'react'
import { Assets, Colors } from '../../assets'
import { Button } from '../../components'
import {
  Container,
  Header,
  TitleGroup,
  Title,
  BackButton,
  CreationDate,
  ButtonGroup,
  ButtonContainer,
  Content,
  InfoLabel,
  UploadButton,
} from './styled'
import { Formik, Form } from 'formik'
import { Input } from '../../components/FormComponents'
import {
  getCustomerAction,
  unsetCustomerAction,
  updateCustomerAction,
} from '../../store'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks'
import { useHistory, useParams } from 'react-router-dom'
import { defaulDateFormat, formatDate, formatTime } from '../../helpers'
import { InputContainer } from '../ProductCreate/styled'

const Customers = () => {
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    surname: '',
    patronymic: '',
    dateOfBirth: '',
    phone: '',
    email: '',
    country: '',
    city: '',
    street: '',
    home: '',
    apartment: '',
    bonuses: '',
  })
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const { customer } = useTypedSelector(state => state.customers)
  const { loading } = useTypedSelector(state => state.customers)

  console.log(customerInfo.dateOfBirth)

  useEffect(() => {
    dispatch(getCustomerAction.request({ id }))
  }, [])

  useEffect(() => {
    if (customer) {
      const updatedCustomer = {
        name: customer.name,
        surname: customer.surname,
        patronymic: customer.patronymic,
        dateOfBirth: customer.dateOfBirth
          ? defaulDateFormat(customer.dateOfBirth)
          : defaulDateFormat(customer.createdAt),
        phone: customer.phone,
        email: customer.email,
        country: customer.country,
        city: customer.city,
        street: customer.street,
        home: customer.home,
        apartment: customer.apartment,
        bonuses: customer.bonuses,
      }
      setCustomerInfo(prev => ({ ...prev, ...updatedCustomer }))
    }
  }, [loading, customer])

  const handleSave = (values: any) => {
    let data = {
      ...values,
      dateOfBirth: new Date(values.dateOfBirth).toISOString(),
    }

    dispatch(updateCustomerAction.request({ id, data }))
  }

  const onChangeBonuses = (values: any) => {
    const data = { bonuses: values.bonuses }

    console.log(data)

    dispatch(updateCustomerAction.request({ id, data }))
  }

  const onBack = () => {
    dispatch(unsetCustomerAction())
    history.push('/clients')
  }

  const content = (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>
              {`${customerInfo.name ? customerInfo.name : ''}
               ${customerInfo.surname ? customerInfo.surname : ''} 
               ${customerInfo.patronymic ? customerInfo.patronymic : ''}`}
            </Title>
            <BackButton onClick={() => onBack()}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
          <CreationDate>
            Дата регистрации: {customer ? formatDate(customer.createdAt) : ''}{' '}
            {customer ? formatTime(customer.createdAt) : ''}
          </CreationDate>
        </TitleGroup>
        <ButtonGroup>
          <ButtonContainer width={'195px'} height={'45px'}>
            <Button
              title="Заблокировать"
              onClick={() => console.log()}
              theme={{
                background: Colors.white,
                text: Colors.red,
                border: Colors.red,
              }}
            />
          </ButtonContainer>
          <ButtonContainer width={'195px'} height={'45px'}>
            <Button
              title="Удалить профиль"
              onClick={() => console.log()}
              theme={{ background: Colors.red, text: Colors.white }}
            />
          </ButtonContainer>
          {/* <ButtonContainer width={'195px'} height={'45px'}>
            <Button
              title="Сохранить"
              htmlFor="hiddenCustomerButton"
              theme={{ background: Colors.orange, text: Colors.boldGray }}
            />
          </ButtonContainer> */}
          <UploadButton htmlFor="hiddenCustomerButton">Сохранить</UploadButton>
        </ButtonGroup>
      </Header>
      <Content style={{ padding: '30px' }}>
        <Formik
          enableReinitialize={true}
          initialValues={customerInfo}
          onSubmit={values => handleSave(values)}
          render={({ values, handleChange, errors, setFieldValue }) => (
            <Form>
              <InfoLabel>Личные данные</InfoLabel>
              <InputContainer style={{ marginBottom: '10px' }}>
                <Input
                  title="Имя"
                  value={values.name}
                  handleChange={handleChange}
                  name="name"
                  width="25%"
                  margin="10px 0 0 0"
                />
                <Input
                  title="Фамилия"
                  value={values.surname}
                  handleChange={handleChange}
                  name="surname"
                  width="25%"
                  margin="10px 0 0 0"
                />
                <Input
                  title="Отчество"
                  value={values.patronymic}
                  handleChange={handleChange}
                  name="patronymic"
                  width="25%"
                  margin="10px 0 0 0"
                />
                <Input
                  title="Дата рождения"
                  value={values.dateOfBirth}
                  handleChange={handleChange}
                  name="dateOfBirth"
                  width="25%"
                  margin="10px 0 0 0"
                  date={true}
                />
                <Input
                  title="Телефон"
                  value={values.phone}
                  handleChange={handleChange}
                  name="phone"
                  width="25%"
                  margin="10px 0 0 0"
                />
                <Input
                  title="E-mail"
                  value={values.email}
                  handleChange={handleChange}
                  name="email"
                  width="25%"
                  margin="10px 0 0 0"
                />
                <Input
                  title="Бонусы"
                  value={values.bonuses}
                  name="bonuses"
                  width="25%"
                  handleChange={handleChange}
                />
                <ButtonContainer
                  width={'195px'}
                  height={'40px'}
                  style={{ marginTop: 'auto' }}
                >
                  <Button
                    title="Изменить бонусы"
                    onClick={() => onChangeBonuses(values)}
                    theme={{ background: Colors.green, text: Colors.white }}
                  />
                </ButtonContainer>
              </InputContainer>
              <InfoLabel>Адрес</InfoLabel>
              <InputContainer>
                <Input
                  title="Страна"
                  value={values.country}
                  handleChange={handleChange}
                  name="country"
                  width="25%"
                  margin="10px 0 0 0"
                />
                <Input
                  title="Город"
                  value={values.city}
                  handleChange={handleChange}
                  name="city"
                  width="25%"
                  margin="10px 0 0 0"
                />
                <Input
                  title="Улица"
                  value={values.street}
                  handleChange={handleChange}
                  name="street"
                  width="25%"
                  margin="10px 0 0 0"
                />
                <Input
                  title="Дом"
                  value={values.home}
                  handleChange={handleChange}
                  name="home"
                  width="12.5%"
                  margin="10px 0 0 0"
                />
                <Input
                  title="Квартира"
                  value={values.apartment}
                  handleChange={handleChange}
                  name="apartment"
                  width="12.5%"
                  margin="10px 0 0 0"
                />
              </InputContainer>
              <button
                id="hiddenCustomerButton"
                style={{ display: 'none' }}
                type="submit"
              >
                test
              </button>
            </Form>
          )}
        />
      </Content>
    </Container>
  )

  return <>{customer ? content : ''}</>
}

export default Customers
