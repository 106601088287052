import { api } from '../config'

export const CATEGORIES_URL = {
  rootPath: `${api.withAPI}/categories`,
  idPath: (id: string) => `${api.withAPI}/categories/${id}`,
  idProductsPath: (id: string) => `${api.withAPI}/categories/${id}/products`,
  subsubcategory: (params: string) =>
    `${api.withAPI}/subsubcategories/${params}`,

  reworkRoot: `${api.withAPI}/category-rework`,
  reworkId: (id: string) => `${api.withAPI}/category-rework/${id}`,
  reworkSubcategoryImage: (id: string) =>
    `${api.withAPI}/category-rework/subcategory-image/${id}`,
  reworkImage: (id: string) => `${api.withAPI}/category-rework/image/${id}`,
  reworkPush: (id: string, path: string) =>
    `${api.withAPI}/category-rework/push/${id}?path=${path}`,
  reworkPull: (catId: string, entId: string, path: string) =>
    `${api.withAPI}/category-rework/pull/${catId}/${entId}?path=${path}`,
  reworkTextPull: (charId: string, valId: string) =>
    `${api.withAPI}/category-rework/pull-text/${charId}/${valId}`,
  reworkTextPush: (id: string) =>
    `${api.withAPI}/category-rework/push-text/${id}`,
  reworkEdit: (entId: string, path: string) =>
    `${api.withAPI}/category-rework/edit/${entId}?path=${path}`,
  reworkProduct: (id: string) =>
    `${api.withAPI}/category-rework/products/${id}`,
}
