import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetColorsActionResponse,
  GetColorActionResponse,
  GetColorActionPayload,
  CreateColorActionPayload,
  CreateColorActionResponse,
  DeleteColorActionPayload,
} from './types'

export const getFeedbackAction = createApiActions<any, any, AxiosError>(
  createActionTypes('FEEDBACK/GET_FEEDBACK'),
)

export const getFeedbackByIdAction = createApiActions<any, any, AxiosError>(
  createActionTypes('FEEDBACK/GET_FEEDBACK_BY_ID'),
)

export const moderateFeedbackAction = createApiActions<any, any, AxiosError>(
  createActionTypes('FEEDBACK/MODERATE_FEEDBACK'),
)
