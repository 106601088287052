import styled from 'styled-components'
import { Colors } from '../../assets'
import { FLEX, FONT } from '../../utils'

export const FilterWrapper = styled.div`
  width: 100%;
  ${FLEX({ direction: 'column' })};
`
export const FilterHeader = styled.div`
  width: 100%;
  ${FLEX({ justify: 'space-between', align: 'center' })};
  ${FONT({ size: '18px', weight: '600' })}
  padding: 20px 30px;
  background: ${Colors.backgroundGray};
  transition: all 0.2s ease;
  .arrow {
    &.down {
      transform: rotate(135deg);
    }
  }
`
export const FilterBody = styled.div`
  width: 100%;
  ${FLEX({ justify: 'flex-start', wrap: 'nowrap' })};
  gap: 40px;
  padding: 0 30px 20px;
  background: ${Colors.backgroundGray};
`
export const FilterFooter = styled.div`
  width: 100%;
  ${FLEX({ justify: 'flex-start' })};
  gap: 12px 25px;
  background: ${Colors.white};
  padding: 20px 30px 10px;
`
export const ToggleContainer = styled.div`
  width: 100%;
  transition: all 0.3s ease;
  max-height: 0px;
  overflow: hidden;
  &.open {
    max-height: 500px;
    overflow: visible;
  }
`

export const ChevroneTop = styled.div`
  width: 10px;
  height: 10px;
  border-top: 2px solid ${Colors.black};
  border-right: 2px solid ${Colors.black};
  transform: rotate(-45deg);
  cursor: pointer;
  margin-top: 6px;
`
export const ClearFilter = styled.span`
  ${FONT({ size: '14px', weight: '600' })};
  color: ${Colors.red};
  cursor: pointer;
`
export const FilterElement = styled.div`
  ${FLEX({ justify: 'space-between', align: 'center' })}
  ${FONT({ size: '14px', weight: '600' })};
  gap: 10px;
`
export const DeleteFilter = styled.img`
  width: 15px;
  cursor: pointer;
`
