import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets } from '../../assets'
import { TableWrapper } from '../../components'
import { RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import { Title } from '../ProductCreate/styled'
import { ColumnsGrid, FlexColumnsWrapper, ItemNavLink } from '../Roles/styled'
import { Pagination } from '../../components/Pagination'
import { IPagination } from '../../components/Pagination/types'
import Rating from '@mui/material/Rating'
import {
  getManagersStatsAction,
  getManagersStatsSelector,
} from '../../store/managerStatistics'

const ManagersStatistics: FC = () => {
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })
  const [sortBy, setSortBy] = useState({
    name: false,
    chats: false,
  })

  const { managersStats } = useSelector(getManagersStatsSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getManagersStatsAction.request(
        `?limit=${pagination.limit}&skip=${
          pagination.skip == 0 ? 0 : pagination.limit * pagination.skip
        }${sortBy.name ? '&sortName=1' : ''}${
          sortBy.chats ? '&sortChats=1' : ''
        }`,
      ),
    )
  }, [pagination, sortBy])

  console.log(managersStats.docs)

  if (!managersStats?.docs) {
    return <></>
  }

  return (
    <>
      <RowWrapper>
        <Title>Статистика работы менеджеров</Title>
      </RowWrapper>

      <SimpleDivider height="20px" />

      <TableWrapper>
        <ColumnsGrid className="header center" template="24% 24% 23% 24% 5%">
          <div
            className="sort-item"
            onClick={() => setSortBy(prev => ({ ...prev, name: !prev.name }))}
          >
            Имя <img src={Assets.SORT_ARROW_ICON} alt="arrow" />
          </div>
          <div>E-mail</div>
          <div>Оценка</div>
          <div
            className="sort-item"
            onClick={() => setSortBy(prev => ({ ...prev, chats: !prev.chats }))}
          >
            Обработано заказов
            <img src={Assets.SORT_ARROW_ICON} alt="arrow" />
          </div>
          <div></div>
        </ColumnsGrid>

        <FlexColumnsWrapper>
          {managersStats.docs.map(stat => (
            <ColumnsGrid
              key={stat._id}
              template="24% 24% 23% 24% 5%"
              className="supplier-preview"
            >
              <div>{stat.name}</div>
              <div>{stat.email}</div>
              <Rating
                name="half-rating-read"
                defaultValue={stat.chats_rating}
                precision={0.5}
                readOnly
              />
              <div>{stat.chats_count}</div>
              <ItemNavLink to={`/managersStats/${stat._id}`}>
                <img src={Assets.SHOW_PASSWORD_ICON} alt="" />
              </ItemNavLink>
            </ColumnsGrid>
          ))}
        </FlexColumnsWrapper>

        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={managersStats.totalCount}
        />
      </TableWrapper>
    </>
  )
}

export default ManagersStatistics
