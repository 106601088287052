import { api } from '../config'

export const ROLES_URL = {
  get_roles: `${api.withAPI}/roles`,
  update_permissions: (id: string) => `${api.withAPI}/roles/${id}`,
  create_role: `${api.withAPI}/roles`,
  update_roles: (id: string) => `${api.withAPI}/roles/${id}`,
  get_admins: (params: string) => `${api.withAPI}/admins/${params}`,
  create_user: `${api.withAPI}/auth/autopass-registration`,
  delete_user: (id: string) => `${api.withAPI}/admins/${id}`
}
