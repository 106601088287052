import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { FC, useEffect, useState } from 'react'
import { Assets, Colors } from '../../assets'
import { DumbButton } from '../../components/FormComponents/styled'
import { RowStartWrapper, RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import {
  Icon,
  PassModalTitle,
  ProfileInput,
  ProfileInputError,
  ProfileInputLabel,
  ProfileInputWrapper,
  SmallDumbBtn,
} from '../Profile/styled'
import {
  ChapterMenu,
  ChapterMenuItem,
  ColumnFlex,
  GrayTitle,
  LinkWithIcon,
  MaxWidthButton,
  ModalInputRow,
  PageTitle,
  RolePermissions,
  RolePermissionsWrapper,
  SelectRow,
} from './styled'
import { HelpWrapper } from '../Help/styled'
import { useHistory } from 'react-router-dom'
import { Checkbox } from '@mui/material'
import { IActiveRole, ICreatedRole, IMenuItem, IRole } from './types'
import { useDispatch, useSelector } from 'react-redux'
import {
  createRoleAction,
  getRolesSelector,
  setRolesAction,
  updateRolesAction,
} from '../../store/roles'
import { updatePermissionsAction } from '../../store/roles'
import ProfileModal from '../../components/Modals/ProfileModal'
import { useForm } from 'react-hook-form'

const RoleSettings: FC = () => {
  const [menuItems, setMenuItems] = useState<IMenuItem[]>([])
  const [activeMenuItem, setActiveMenuItem] = useState<IMenuItem>(
    {} as IMenuItem,
  )
  const [activeRoleItem, setActiveRoleItem] = useState<IActiveRole>({
    id: '',
    permissions: [],
  })

  console.log(activeRoleItem)

  const [rolesList, setRolesList] = useState<IRole[]>([])
  const [createdRoles, setCreatedRoles] = useState<ICreatedRole[]>([])
  const [isModalOpen, setModalIsOpen] = useState<boolean>(false)

  const { roles } = useSelector(getRolesSelector)

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    setRolesList(roles)
    if (!activeRoleItem.id) {
      setActiveRoleItem({ id: roles[0]._id, permissions: roles[0].permissions })
    }
  }, [])

  useEffect(() => {
    sortPermissions(activeRoleItem.permissions)
  }, [activeRoleItem])

  const sortPermissions = permissions => {
    const menuItems: IMenuItem[] = [
      {
        title: 'Заказы',
        value: 'order',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'orderGet',
            title: 'Просмотр раздела',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'orderGetAll',
            title: 'Просмотр всех заказов',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'orderCreate',
            title: 'Создание заказа',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'orderDelete',
            title: 'Удаление',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'orderUpdate',
            title: 'Редактирование',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'orderUpdateStatus',
            title: 'Изменение статуса',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'orderChangePayment',
            title: 'Изменение типа оплаты',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'orderChangeDelivery',
            title: 'Изменение способа доставки',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'orderNotifications',
            title: 'Получение уведомлений',
          },
        ],
      },
      {
        title: 'Чат',
        value: 'chat',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'chatGetAll',
            title: 'Просмотр всех активных чатов',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'chatGet',
            title: 'Просмотр личных чатов',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'chatResponse',
            title: 'Возможность отвечать',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'chatNotifications',
            title: 'Получение уведомлений',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'chatDone',
            title: 'Возможность завершить чат',
          },
        ],
      },
      {
        title: 'Поддержка',
        value: 'support',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'supportGet',
            title: 'Просмотр раздела',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'supportNotifications',
            title: 'Получение уведомлений',
          },
        ],
      },
      {
        title: 'Управление товарами',
        value: 'product',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'productGetAll',
            title: 'Просмотр всех товаров',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'productGet',
            title: 'Просмотр раздела',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'productCreate',
            title: 'Добавление',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'productDelete',
            title: 'Удаление',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'productUpdate',
            title: 'Редактирование',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'productModeration',
            title: 'Модерация товаров',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'productNotifications',
            title: 'Получение уведомлений',
          },
        ],
      },
      {
        title: 'Управление клиентами',
        value: 'client',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'clientGet',
            title: 'Просмотр раздела',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'clientGetAll',
            title: 'Просмотр списка всех клиентов',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'clientUpdate',
            title: 'Редактирование',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'clientDelete',
            title: 'Удаление',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'clientBlock',
            title: 'Блокирование',
          },
        ],
      },
      {
        title: 'Категории',
        value: 'category',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'categoryGet',
            title: 'Просмотр раздела',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'categoryGetAll',
            title: 'Просмотр всех категорий',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'categoryCreate',
            title: 'Добавление',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'categoryDelete',
            title: 'Удаление',
          },
        ],
      },
      {
        title: 'Бренды',
        value: 'brand',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'brandGet',
            title: 'Просмотр раздела',
          },
        ],
      },
      {
        title: 'Финансы',
        value: 'finance',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'financeGet',
            title: 'Просмотр раздела',
          },
        ],
      },
      {
        title: 'Отзывы',
        value: 'feedback',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'feedbackGet',
            title: 'Просмотр раздела',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'feedbackPublish',
            title: 'Возможность публикации',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'feedbackNotifications',
            title: 'Получение уведомлений',
          },
        ],
      },
      {
        title: 'Магазины / Склады',
        value: 'store',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'storeGet',
            title: 'Просмотр раздела',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'storeGetAll',
            title: 'Просмотр всех складов',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'storeCreate',
            title: 'Создание',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'storeUpdate',
            title: 'Редактирование',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'storeDelete',
            title: 'Удаление',
          },
        ],
      },
      {
        title: 'Роли',
        value: 'roles',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'rolesGet',
            title: 'Просмотр раздела',
          },
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'rolesConfig',
            title: 'Настройка ролей',
          },
        ],
      },
      {
        title: 'Статистика работы менеджеров',
        value: 'statsManager',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'statsManagerGet',
            title: 'Просмотр раздела',
          },
        ],
      },
      {
        title: 'Настройки',
        value: 'settings',
        active: false,
        allPermissions: [
          {
            value: 'Запрещено',
            values: ['Запрещено', 'Разрешено'],
            permissionName: 'settingsGet',
            title: 'Просмотр раздела',
          },
        ],
      },
    ]

    menuItems.forEach(menuItem => {
      permissions.forEach(permission => {
        if (permission.includes(menuItem.value)) {
          //   menuItem.activePermissions.push(permission)
          menuItem.active = true
          menuItem.allPermissions.forEach(allPermissionsItem => {
            if (allPermissionsItem.permissionName === permission) {
              allPermissionsItem.value = 'Разрешено'
            }
          })
        }
      })
    })

    setMenuItems(menuItems)
  }

  const setCheckedMenuItems = role => {
    const activeRoleItem = roles.find(obj => {
      return obj._id === role
    })
    setActiveRoleItem({
      id: role,
      permissions: activeRoleItem.permissions,
    })
    setActiveMenuItem({} as IMenuItem)
  }

  const onCheckMenuItem = (menuItem: IMenuItem, checked: boolean) => {
    const rolesSet = new Set(activeRoleItem.permissions)

    if (checked) {
      const newPermissions = menuItems
        .find(obj => {
          return obj.value === menuItem.value
        })
        ?.allPermissions.map(permission => ({
          ...permission,
          value: 'Разрешено',
        }))

      newPermissions &&
        setMenuItems(prev =>
          prev.map(item => {
            return item.value === menuItem.value
              ? { ...item, allPermissions: newPermissions, active: true }
              : item
          }),
        )

      newPermissions &&
        setActiveMenuItem(prev => ({
          ...prev,
          allPermissions: newPermissions,
        }))

      menuItem.allPermissions.forEach(permission => {
        rolesSet.add(permission.permissionName)
      })

      setActiveRoleItem(prev => ({
        ...prev,
        permissions: Array.from(rolesSet),
      }))
    } else if (!checked) {
      const newPermissions = menuItems
        .find(obj => {
          return obj.value === menuItem.value
        })
        ?.allPermissions.map(permission => ({
          ...permission,
          value: 'Запрещено',
        }))

      newPermissions &&
        setMenuItems(prev =>
          prev.map(item => {
            return item.value === menuItem.value
              ? { ...item, allPermissions: newPermissions, active: false }
              : item
          }),
        )

      newPermissions &&
        setActiveMenuItem(prev => ({
          ...prev,
          allPermissions: newPermissions,
        }))

      menuItem.allPermissions.forEach(permission => {
        rolesSet.delete(permission.permissionName)

        setActiveRoleItem(prev => ({
          ...prev,
          permissions: Array.from(rolesSet),
        }))
      })
    }
  }

  const setPermissionValue = (value, permission) => {
    const newPermissions = activeMenuItem.allPermissions.map(obj =>
      obj.permissionName === permission ? { ...obj, value: value } : obj,
    )

    setActiveMenuItem(prev => ({
      ...prev,
      allPermissions: newPermissions,
    }))

    if (value === 'Запрещено') {
      const newPermissions = activeRoleItem.permissions.filter(
        activePermission => {
          return activePermission !== permission
        },
      )

      setActiveRoleItem(prev => ({
        ...prev,
        permissions: newPermissions,
      }))
    } else if (value === 'Разрешено') {
      setActiveRoleItem(prev => ({
        ...prev,
        permissions: [...prev.permissions, permission],
      }))
    }
  }

  const onChangeRoles = () => {
    dispatch(updatePermissionsAction.request(activeRoleItem))
  }

  const onChangeRoleTitle = (title: string, role: IRole) => {
    setRolesList(prev =>
      prev.map(updatedRole =>
        updatedRole._id === role._id
          ? { ...updatedRole, title: title }
          : updatedRole,
      ),
    )
  }

  const onChangeCreatedroleTitle = (title: string, role: ICreatedRole) => {
    setCreatedRoles(prev =>
      prev.map(createdRole => {
        return createdRole.id === role.id
          ? { ...createdRole, title: title }
          : createdRole
      }),
    )
  }

  const onCreateRole = () => {
    setCreatedRoles(prev => [
      ...prev,
      { permissions: [], title: '', id: `${Date.now()}` },
    ])
  }

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm()

  const onSubmit = data => {
    setModalIsOpen(false)
    console.log(data)

    if (createdRoles.length > 0) {
      createdRoles.forEach(createdRole => {
        dispatch(
          createRoleAction.request({
            title: createdRole.title,
            permissions: [],
          }),
        )
      })
    }

    rolesList.forEach(role => {
      dispatch(updateRolesAction.request({ id: role._id, title: role.title }))
    })
  }

  if (roles.length < 1) {
    return <></>
  }

  return (
    <>
      <RowWrapper>
        <RowStartWrapper width="auto">
          <ColumnFlex>
            <GrayTitle>Настройка ролей</GrayTitle>
          </ColumnFlex>

          <LinkWithIcon onClick={() => history.goBack()}>
            <img src={Assets.ARROW_BACK_ICON} alt="" />
            Назад
          </LinkWithIcon>
        </RowStartWrapper>

        <DumbButton
          bg={Colors.orange}
          txt={Colors.black}
          onClick={onChangeRoles}
        >
          Сохранить
        </DumbButton>
      </RowWrapper>

      <SimpleDivider height="22px" />

      <HelpWrapper>
        <ChapterMenu>
          <ColumnFlex style={{ padding: '0 30px 30px' }}>
            <ProfileInputWrapper>
              <ProfileInputLabel>Роль</ProfileInputLabel>
              <Select
                labelId="role"
                id="role-select"
                onChange={e => setCheckedMenuItems(e.target.value)}
                value={activeRoleItem.id}
                style={{ width: '100%', height: '40px' }}
              >
                {roles.map(role => (
                  <MenuItem value={role._id} key={role._id}>
                    {role.title}
                  </MenuItem>
                ))}
              </Select>
            </ProfileInputWrapper>

            <SimpleDivider height="20px" />

            <MaxWidthButton
              bg={Colors.green}
              txt={Colors.white}
              onClick={() => setModalIsOpen(true)}
            >
              Редактировать/Создать
            </MaxWidthButton>
          </ColumnFlex>

          {menuItems.map((menuItem, index) => (
            <ChapterMenuItem
              key={`${menuItem.value}_${index}`}
              onClick={() => setActiveMenuItem(menuItem)}
              className={
                activeMenuItem.value === menuItem.value ? 'active' : ''
              }
            >
              <Checkbox
                color="success"
                onChange={e => onCheckMenuItem(menuItem, e.target.checked)}
                checked={menuItem.active}
              />
              {menuItem.title}
            </ChapterMenuItem>
          ))}
        </ChapterMenu>

        <RolePermissions>
          {activeMenuItem?.value && (
            <RolePermissionsWrapper>
              <PageTitle>{activeMenuItem.title}</PageTitle>

              <SimpleDivider height="30px" />

              {activeMenuItem.allPermissions.map(permission => (
                <SelectRow key={`${permission.permissionName}`}>
                  <div className="select-title">{permission.title}</div>

                  <div className="select-container">
                    <Select
                      labelId="permission"
                      id="permission-select"
                      onChange={e =>
                        setPermissionValue(
                          e.target.value,
                          permission.permissionName,
                        )
                      }
                      value={permission.value}
                      style={{ width: '100%', height: '40px' }}
                    >
                      {permission.values.map((value, index) => (
                        <MenuItem
                          value={value}
                          key={`${permission.permissionName}_${index}`}
                        >
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </SelectRow>
              ))}
            </RolePermissionsWrapper>
          )}
        </RolePermissions>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ProfileModal
            isModalOpen={isModalOpen}
            onModalClose={() => setModalIsOpen(false)}
          >
            <PassModalTitle>Редактировать/создать роль</PassModalTitle>
            <SimpleDivider height="20px" />

            <ModalInputRow>
              {rolesList.map((role, index) => (
                <ProfileInputWrapper
                  maxWidth="300px"
                  key={role._id}
                  className="role-wrapper"
                >
                  <ProfileInputLabel>{`Роль ${index + 1}`}</ProfileInputLabel>
                  <ProfileInput
                    value={role.title}
                    {...register(role._id, { required: true })}
                    onChange={e => onChangeRoleTitle(e.target.value, role)}
                    className={`${errors[role._id] ? 'error' : ''}`}
                  />
                  {errors[role._id] && (
                    <ProfileInputError>
                      Поле не должно быть пустым
                    </ProfileInputError>
                  )}
                </ProfileInputWrapper>
              ))}

              {createdRoles.map((role, index) => (
                <ProfileInputWrapper
                  maxWidth="300px"
                  key={role.id}
                  className="role-wrapper"
                >
                  <ProfileInputLabel>{`Роль ${
                    rolesList.length + index + 1
                  }`}</ProfileInputLabel>
                  <ProfileInput
                    value={role.title}
                    {...register(role.id, { required: true })}
                    onChange={e =>
                      onChangeCreatedroleTitle(e.target.value, role)
                    }
                    className={`${errors[role.id] ? 'error' : ''}`}
                  />
                  {errors[role.id] && (
                    <ProfileInputError>
                      Поле не должно быть пустым
                    </ProfileInputError>
                  )}
                </ProfileInputWrapper>
              ))}

              <SmallDumbBtn
                bg={Colors.green}
                txt={Colors.white}
                shadow={Colors.lightGreen}
                onClick={onCreateRole}
              >
                <Icon src={Assets.PLUS_WHITE_ICON}></Icon>
                Добавить
              </SmallDumbBtn>
            </ModalInputRow>
            <SimpleDivider height="30px" />
            <DumbButton
              bg={Colors.orange}
              txt={Colors.black}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </DumbButton>
          </ProfileModal>
        </form>
      </HelpWrapper>
    </>
  )
}

export default RoleSettings
