import { FC } from 'react'
import { Assets } from '../../assets'
import { NotFoundWrapper } from './styled'
import { Colors } from '../../assets'
import { useHistory } from 'react-router-dom'
import { DumbButton } from '../../components/FormComponents/styled'
import { StyledNavLink } from '../../components/Button/styled'

export const NotFound: FC = () => {
  const history = useHistory()

  return (
    <NotFoundWrapper>
      <img src={Assets.NOT_FOUND_BACKGROUND} alt="background" />
      <div className="content">
        <div className="code-error">404</div>
        <div className="title">Страница не найдена</div>
        <div className="text">
          Вероятно, эта cтраница была перемещена, удалена, переименована или,
          возможно, никогда не существовала!
        </div>
        {/* <StyledNavLink background={Colors.orange} style={{color: '#000'}} to='/orders'>
        Вернуться назад
        </StyledNavLink> */}
        <DumbButton
          bg={Colors.orange}
          txt={Colors.black}
          onClick={() => history.goBack()}
        >
          Вернуться назад
        </DumbButton>
      </div>
    </NotFoundWrapper>
  )
}
