import React, { FC } from 'react'

interface IImage {
  src: string
  handleImage: () => void
}

const Image: FC<IImage> = ({ src, handleImage }) => {
  const element = (
    <div style={{ width: '50%' }}>
      <div style={{ display: 'flex' }}>
        <div>Фото</div>
        <a
          onClick={() => handleImage()}
          style={{ color: 'red', marginLeft: 'auto', cursor: 'pointer' }}
        >
          Удалить
        </a>
      </div>

      <img style={{ width: '100%', aspectRatio: '1/1' }} src={src} />
    </div>
  )

  return src ? element : null
}

export default Image
