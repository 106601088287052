import React, { FC, useState } from 'react'
import { Assets } from '../../assets'
import { api } from '../../store'
import { OrderedItem } from './styled'

interface IOrderItem {
  item: any
  keyProp?: number
  handleCount?: any
  handleDelete?: any
  order?: boolean
}

const OrderItem: FC<IOrderItem> = ({
  item,
  keyProp,
  handleCount,
  handleDelete,
  order,
}) => {
  return (
    <OrderedItem key={keyProp}>
      <div className="overview">
        {item?.product?.images && (
          <img
            width="80px"
            height="80px"
            src={`${api.images}${item?.product?.images[0]}`}
          />
        )}
        {item?.images && (
          <img
            width="80px"
            height="80px"
            src={`${api.images}${item?.images[0]}`}
          />
        )}
        <div style={{ marginLeft: '10px' }}>
          <div className="title">
            {item?.product?.ru ? item?.product?.ru : item?.ru}
          </div>
          <div className="id">
            Артикул:{' '}
            {item?.product?.vendorCode
              ? item?.product?.vendorCode
              : item?.vendorCode}
          </div>
        </div>
      </div>
      <div className="count">
        <a type="button" onClick={() => handleCount(keyProp, false)}>
          -
        </a>
        <div>{item?.count ? item?.count : 1}</div>
        <a type="button" onClick={() => handleCount(keyProp, true)}>
          +
        </a>
      </div>
      <div className="sum">{item?.sum ? item?.sum : item?.price} сум</div>
      {order ? (
        <div>Товар под заказ</div>
      ) : (
        <div className="delete" onClick={() => handleDelete(keyProp)}>
          <img src={Assets.DELETE_RED_ICON} /> <span>Удалить</span>
        </div>
      )}
    </OrderedItem>
  )
}

export default OrderItem
