import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  createCountdownAction,
  deleteCountdownAction,
  getCountdownAction,
  getCountdownsAction,
  updateCountdownAction,
} from './actions'

import {
  generateErrorToast,
  generateInfoToast,
  generateSuccessToast,
} from '../../helpers'
import { PromiseReturnType } from '../types'
import { CountdownAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'
import history from '../../services/history'
import { getCountdownSelector } from './selectors'

function* createCountdownWorker({
  payload,
}: ReturnType<typeof createCountdownAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CountdownAPI.createCountdown>
    > = yield call([CountdownAPI, CountdownAPI.createCountdown], {
      authorization: token,
      data: payload.data,
    })

    yield put(createCountdownAction.success(response.data))

    yield put(
      showToastAction.request(generateSuccessToast('Счётчик успешно создан')),
    )

    yield call([history, history.push], `/#/counters/${response.data.data._id}`)
  } catch (e) {
    console.log('Error: createCountdownWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания счётчика')),
    )

    yield put(createCountdownAction.failure(e))
  }
}

function* updateCountdownWorker({
  payload,
}: ReturnType<typeof updateCountdownAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CountdownAPI.updateCountdown>
    > = yield call([CountdownAPI, CountdownAPI.updateCountdown], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    yield put(updateCountdownAction.success(response.data))

    yield put(
      showToastAction.request(generateSuccessToast('Счётчик успешно обновлён')),
    )
  } catch (e) {
    console.log('Error: createCountdownWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления счётчика')),
    )

    yield put(updateCountdownAction.failure(e))
  }
}

function* getCountdownsWorker({
  payload,
}: ReturnType<typeof getCountdownsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CountdownAPI.getCountdowns>
    > = yield call([CountdownAPI, CountdownAPI.getCountdowns], {
      authorization: token,
      id: payload.id,
    })

    yield put(getCountdownsAction.success(response.data))
  } catch (e) {
    console.log('Error: getCountdownsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения счётчиков')),
    )

    yield put(getCountdownsAction.failure(e))
  }
}

function* getCountdownWorker({
  payload,
}: ReturnType<typeof getCountdownsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CountdownAPI.getCountdown>
    > = yield call([CountdownAPI, CountdownAPI.getCountdown], {
      authorization: token,
      id: payload.id,
    })

    yield put(getCountdownAction.success(response.data))
  } catch (e) {
    console.log('Error: getCountdownWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения счётчика')),
    )

    yield put(getCountdownAction.failure(e))
  }
}

function* deleteCountdownWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const { countdowns }: ReturnType<typeof getCountdownSelector> =
      yield select(getCountdownSelector)

    const response: PromiseReturnType<
      ReturnType<typeof CountdownAPI.deleteCountdown>
    > = yield call([CountdownAPI, CountdownAPI.deleteCountdown], {
      authorization: token,
      id: payload.id,
    })

    yield put(deleteCountdownAction.success())

    yield put(
      getCountdownsAction.success(
        countdowns.filter(el => el._id === payload.id),
      ),
    )

    yield put(
      showToastAction.request(generateInfoToast('Счётчик успешно удален')),
    )
  } catch (e) {
    console.log('Error: deleteCountdownWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления счётчика')),
    )

    yield put(deleteCountdownAction.failure(e))
  }
}

export function* countdownWatcher() {
  yield takeLatest(createCountdownAction.request, createCountdownWorker)
  yield takeLatest(getCountdownsAction.request, getCountdownsWorker)
  yield takeLatest(deleteCountdownAction.request, deleteCountdownWorker)
  yield takeLatest(getCountdownAction.request, getCountdownWorker)
  yield takeLatest(updateCountdownAction.request, updateCountdownWorker)
}
