import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import { getAllNotificationsAction } from './actions'

import { generateErrorToast } from '../../helpers'
import { AuthorizationError } from '../../errors'
import { getUserSelector } from './selectors'
import { PromiseReturnType } from '../types'
import { showToastAction } from '../toasts'
import { Log } from '../../utils'
import { NotificationsAPI } from './api.service'
import { AxiosError } from 'axios'

export function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getAllNotificationsWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof NotificationsAPI.getAllNotifications>
    > = yield call([NotificationsAPI, NotificationsAPI.getAllNotifications], {
      authorization: token,
    })

    yield put(getAllNotificationsAction.success(response.data))
  } catch (e: any) {
    Log.ruddy('Error: getRolesWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения уведомлений'),
      ),
    )

    yield put(getAllNotificationsAction.failure(e))
  }
}

// function* getMyChatsByQueryWorker({
//   payload,
// }: ReturnType<typeof setMyChatsByQueryAction['request']>) {
//   try {
//     const { token }: ReturnType<typeof getUserSelector> = yield select(
//       getUserSelector,
//     )

//     const response: PromiseReturnType<
//       ReturnType<typeof ChatAPI.getMyChatsByQuery>
//     > = yield call([ChatAPI, ChatAPI.getMyChatsByQuery], {
//       authorization: token,
//       data: payload,
//     })

//     yield put(setMyChatsByQueryAction.success(response.data))
//   } catch (e) {
//     console.log('Error: updatePermissionsWorker', e)
//     yield put(
//       showToastAction.request(generateErrorToast('Ошибка поиска чатов')),
//     )
//     yield put(setMyChatsByQueryAction.failure(e as AxiosError))
//   }
// }

export function* notificationsWatcher() {
  yield takeLatest(getAllNotificationsAction.request, getAllNotificationsWorker)
}
