import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks'
import { getBrandsAction } from '../../store/brands'
import FilterDropdown from '../FormComponents/FilterDropdown'
import {
  ChevroneTop,
  ClearFilter,
  DeleteFilter,
  FilterBody,
  FilterElement,
  FilterFooter,
  FilterHeader,
  FilterWrapper,
  ToggleContainer,
} from './styled'
import { IFilter } from './types'
import deleteFilter from '../../assets/icons/timesRed.svg'
import { setOrdersFilterAction, setProductsFilterAction } from '../../store'
import { getProductsAction } from '../../store/users/actions'
import { setAllMyChatsAction, setMyChatsByQueryAction } from '../../store/chat'
import { setFinanceFilterAction } from '../../store/finance'
import { setManagerStatsFilterAction } from '../../store/managerStatistics'

interface IFilterProps {
  dateProp?: boolean
  brandsProp?: boolean
  categoriesProp?: boolean
  subcategoriesProp?: boolean
  statusProp?: boolean
  orderStatusProp?: boolean
  priceProp?: boolean
  promoProp?: boolean
  ratingProp?: boolean
  sumProp?: boolean
  paymentTypeProp?: boolean
  paidToSupplierProp?: boolean
  products?: boolean
  chats?: boolean
  finance?: boolean
  managerStats?: boolean
  orders?: boolean
  pendingProducts?: boolean
  activeOrders?: boolean
  doneOrders?: boolean
}

const Filter: FC<IFilterProps> = ({
  dateProp,
  brandsProp,
  categoriesProp,
  subcategoriesProp,
  statusProp,
  priceProp,
  promoProp,
  ratingProp,
  sumProp,
  paymentTypeProp,
  paidToSupplierProp,
  products,
  chats,
  finance,
  managerStats,
  orders,
  orderStatusProp,
  doneOrders,
  activeOrders,
}) => {
  const [dateFilters, setDateFilters] = useState<IFilter[]>([
    {
      key: 'createdAt[]',
      label: 'дата с',
      value: '',
      name: '',
    },
    {
      key: 'createdAt[]',
      label: 'дата по',
      value: '',
      name: '',
    },
  ])
  const [brandsFilters, setBrandsFilters] = useState<IFilter[]>([])
  const [categoriesFilters, setCategoriesFilters] = useState<IFilter[]>([])
  const [subcategoriesFilters, setSubcategoriesFilters] = useState<IFilter[]>(
    [],
  )
  const [statusFilters, setStatusFilters] = useState<IFilter[]>([
    {
      key: 'status[]',
      label: 'Статус',
      value: 'in_stock',
      name: 'В наличии',
      checked: false,
    },
    {
      key: 'status[]',
      label: 'Статус',
      value: 'order',
      name: 'Под заказ',
      checked: false,
    },
    {
      key: 'status[]',
      label: 'Статус',
      value: 'not_available',
      name: 'Нет в наличии',
      checked: false,
    },
  ])

  const [orderStatusFilters, setOrderStatusFilters] = useState<any>([])

  const [priceFilters, setPriceFilters] = useState<IFilter[]>([
    {
      key: 'priceGte',
      label: 'Цена от',
      value: '',
      name: '',
    },
    {
      key: 'priceLte',
      label: 'Цена до',
      value: '',
      name: '',
    },
  ])
  const [promoFilters, setPromoFilters] = useState<IFilter[]>([
    {
      key: 'isPromo',
      label: 'Скидки',
      value: 'true',
      name: 'да',
      checked: false,
    },
    {
      key: 'isPromo',
      label: 'Скидки',
      value: 'false',
      name: 'нет',
      checked: false,
    },
  ])
  const [ratingFilters, setRatingFilters] = useState<IFilter[]>([
    {
      key: 'rating[]',
      label: 'Рейтинг',
      value: '1',
      name: '1',
      checked: false,
    },
    {
      key: 'rating[]',
      label: 'Рейтинг',
      value: '2',
      name: '2',
      checked: false,
    },
    {
      key: 'rating[]',
      label: 'Рейтинг',
      value: '3',
      name: '3',
      checked: false,
    },
    {
      key: 'rating[]',
      label: 'Рейтинг',
      value: '4',
      name: '4',
      checked: false,
    },
    {
      key: 'rating[]',
      label: 'Рейтинг',
      value: '5',
      name: '5',
      checked: false,
    },
  ])
  const [paymentTypeFilters, setPaymentTypeFilters] = useState<IFilter[]>([
    {
      key: 'typePayment',
      label: 'Тип оплаты',
      value: 'cash',
      name: 'Наличные',
      checked: false,
    },
    {
      key: 'typePayment',
      label: 'Тип оплаты',
      value: 'no_cash',
      name: 'Онлайн оплата',
      checked: false,
    },
  ])
  const [paidToSupplierFilters, setPaidToSupplierFilters] = useState<IFilter[]>(
    [
      {
        key: 'paidToSupplier',
        label: 'Оплачено поставщику',
        value: 'true',
        name: 'да',
        checked: false,
      },
      {
        key: 'paidToSupplier',
        label: 'Оплачено поставщику',
        value: 'false',
        name: 'нет',
        checked: false,
      },
    ],
  )

  const [activeFilters, setActiveFilters] = useState<IFilter[]>([])
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true)

  const { brands } = useTypedSelector(state => state.brands)
  const { categories } = useTypedSelector(state => state.categories)

  const queryString = useRef<string>('')

  console.log('activeFilters', activeFilters)

  const dispatch = useDispatch()

  useEffect(() => {
    const statusArr = [
      !doneOrders && {
        key: 'status[]',
        label: 'Статус',
        value: 'new',
        name: 'Новый',
      },
      !doneOrders && {
        key: 'status[]',
        label: 'Статус',
        value: 'in_progress',
        name: 'В обработке',
      },
      !doneOrders && {
        key: 'status[]',
        label: 'Статус',
        value: 'waiting_for_payment',
        name: 'Ожидает оплату',
      },
      !doneOrders && {
        key: 'status[]',
        label: 'Статус',
        value: 'waiting_for_delivery',
        name: 'В пути',
      },
      !activeOrders && {
        key: 'status[]',
        label: 'Статус',
        value: 'done',
        name: 'Завершен',
      },
      !activeOrders && {
        key: 'status[]',
        label: 'Статус',
        value: 'canceled',
        name: 'Отменен',
      },
    ]

    setOrderStatusFilters(
      statusArr.filter(status => {
        return status !== false
      }),
    )
  }, [doneOrders, activeOrders])

  useEffect(() => {
    dispatch(getBrandsAction.request())

    createFiltersWithId(brands, categories)
  }, [brands, categories])

  useEffect(() => {
    onFilterItems()
  }, [activeFilters])

  const createFiltersWithId = (brands, categories) => {
    const filteredBrands = brands.map(obj => {
      return Object.assign(
        {},
        {
          key: 'brand[]',
          value: obj._id,
          label: 'Бренд',
          name: obj.ru,
          checked: false,
        },
      )
    })

    setBrandsFilters(filteredBrands)

    const filteredCategories = categories.map(obj => {
      return Object.assign(
        {},
        {
          key: 'category[]',
          value: obj._id,
          label: 'Категория',
          name: obj.ru,
          subcategories: obj.subcategories,
          checked: false,
        },
      )
    })

    setCategoriesFilters(filteredCategories)
  }

  const oncreateFiltersSubcategories = (category: IFilter) => {
    const subCategories = category.subcategories?.map(subcategory => {
      return Object.assign(
        {},
        {
          key: 'subcategory[]',
          value: subcategory._id,
          label: 'Подкатегория',
          category: subcategory.category,
          name: subcategory.ru,
          checked: false,
        },
      )
    })

    subCategories?.forEach(elem => {
      setSubcategoriesFilters(prev => [...prev, elem])
    })
  }

  const onDeleteFiltersSubcategories = (category: IFilter) => {
    setSubcategoriesFilters(prev =>
      prev.filter(subcategory => {
        return subcategory.category !== category.value
      }),
    )
  }

  const onClearFilters = () => {
    setActiveFilters([])
    setBrandsFilters(prev =>
      prev.map(filter => ({ ...filter, checked: false })),
    )
    setCategoriesFilters(prev =>
      prev.map(filter => ({ ...filter, checked: false })),
    )
    setSubcategoriesFilters([])
    setDateFilters([
      {
        key: 'createdAt[]',
        label: 'дата с',
        value: '',
        name: '',
      },
      {
        key: 'createdAt[]',
        label: 'дата по',
        value: '',
        name: '',
      },
    ])
    setPriceFilters([
      {
        key: 'priceGte',
        label: 'Цена от',
        value: '',
        name: '',
      },
      {
        key: 'priceLte',
        label: 'Цена до',
        value: '',
        name: '',
      },
    ])
    setPaymentTypeFilters([
      {
        key: 'typePayment',
        label: 'Тип оплаты',
        value: 'cash',
        name: 'Наличные',
        checked: false,
      },
      {
        key: 'typePayment',
        label: 'Тип оплаты',
        value: 'no_cash',
        name: 'Онлайн оплата',
        checked: false,
      },
    ])
    setPaidToSupplierFilters([
      {
        key: 'paidToSupplier',
        label: 'Оплачено поставщику',
        value: 'true',
        name: 'да',
        checked: false,
      },
      {
        key: 'paidToSupplier',
        label: 'Оплачено поставщику',
        value: 'false',
        name: 'нет',
        checked: false,
      },
    ])
    if (products) {
      dispatch(getProductsAction.request())
    } else if (chats) {
      dispatch(setAllMyChatsAction.request())
    }
  }

  const onDeleteFilter = (filter: IFilter) => {
    setActiveFilters(prev => prev.filter(elem => filter.value !== elem.value))

    switch (filter.key) {
      case 'priceGte':
        return setPriceFilters(prev => [
          { ...prev[0], value: '' },
          { ...prev[1] },
        ])
      case 'priceLte':
        return setPriceFilters(prev => [
          { ...prev[0] },
          { ...prev[1], value: '' },
        ])
      case 'paidToSupplier':
        return setPaidToSupplierFilters(prev =>
          prev.map(elem =>
            elem.value === filter.value ? { ...elem, checked: false } : elem,
          ),
        )
      case 'typePayment':
        return setPaymentTypeFilters(prev =>
          prev.map(elem =>
            elem.value === filter.value ? { ...elem, checked: false } : elem,
          ),
        )
      case 'brand[]':
        return setBrandsFilters(prev =>
          prev.map(elem =>
            elem.value === filter.value ? { ...elem, checked: false } : elem,
          ),
        )
      case 'category[]':
        setCategoriesFilters(prev =>
          prev.map(elem =>
            elem.value === filter.value ? { ...elem, checked: false } : elem,
          ),
        )
        onDeleteFiltersSubcategories(filter)
        return
      case 'subcategory[]':
        return setSubcategoriesFilters(prev =>
          prev.map(elem =>
            elem.value === filter.value ? { ...elem, checked: false } : elem,
          ),
        )
      case 'isPromo':
        return setPromoFilters(prev =>
          prev.map(elem =>
            elem.value === filter.value ? { ...elem, checked: false } : elem,
          ),
        )
      case 'rating[]':
        return setRatingFilters(prev =>
          prev.map(elem =>
            elem.value === filter.value ? { ...elem, checked: false } : elem,
          ),
        )
    }
  }

  const onFilterItems = () => {
    if (products) {
      for (let i = 0; i < activeFilters.length; i++) {
        if (i === activeFilters.length - 1) {
          queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}`
        } else {
          queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}&`
        }
      }

      dispatch(setProductsFilterAction(queryString.current))

      queryString.current = ''
    } else if (chats) {
      if (activeFilters.length == 0) {
        dispatch(setAllMyChatsAction.request())
      } else {
        for (let i = 0; i < activeFilters.length; i++) {
          if (i === activeFilters.length - 1) {
            queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}`
          } else {
            queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}&`
          }
        }
        dispatch(setMyChatsByQueryAction.request(queryString.current))

        queryString.current = ''
      }
    } else if (finance) {
      for (let i = 0; i < activeFilters.length; i++) {
        if (i === activeFilters.length - 1) {
          queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}`
        } else {
          queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}&`
        }
      }

      dispatch(setFinanceFilterAction(queryString.current))

      queryString.current = ''
    } else if (managerStats) {
      for (let i = 0; i < activeFilters.length; i++) {
        if (i === activeFilters.length - 1) {
          queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}`
        } else {
          queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}&`
        }
      }

      dispatch(setManagerStatsFilterAction(queryString.current))

      queryString.current = ''
    } else if (orders) {
      for (let i = 0; i < activeFilters.length; i++) {
        if (i === activeFilters.length - 1) {
          queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}`
        } else {
          queryString.current += `${activeFilters[i].key}=${activeFilters[i].value}&`
        }
      }
      console.log('orders')
      dispatch(setOrdersFilterAction(queryString.current))

      queryString.current = ''
    }
  }

  return (
    <FilterWrapper>
      <FilterHeader>
        Фильтр
        <ChevroneTop
          className={isFilterOpen ? 'arrow' : 'arrow down'}
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        />
      </FilterHeader>
      <ToggleContainer className={isFilterOpen ? 'open' : ''}>
        <FilterBody>
          {dateProp && (
            <FilterDropdown
              title="Дата добавления"
              maxWidth="200px"
              setActiveFilters={setActiveFilters}
              date={dateFilters}
              setDate={setDateFilters}
            ></FilterDropdown>
          )}

          {brandsProp && (
            <FilterDropdown
              title="Поставщик (Бренд)"
              maxWidth="200px"
              checkBoxElements={brandsFilters}
              setActiveFilters={setActiveFilters}
              setCheckedFilter={setBrandsFilters}
            ></FilterDropdown>
          )}

          {ratingProp && (
            <FilterDropdown
              title="Рейтинг"
              maxWidth="120px"
              checkBoxElements={ratingFilters}
              setActiveFilters={setActiveFilters}
              setCheckedFilter={setRatingFilters}
            ></FilterDropdown>
          )}

          {categoriesProp && (
            <FilterDropdown
              title="Категория"
              maxWidth="140px"
              checkBoxElements={categoriesFilters}
              setActiveFilters={setActiveFilters}
              setCheckedFilter={setCategoriesFilters}
              createFiltersSubcategories={oncreateFiltersSubcategories}
              deleteFiltersSubcategories={onDeleteFiltersSubcategories}
            ></FilterDropdown>
          )}

          {sumProp && (
            <FilterDropdown
              title="Сумма"
              maxWidth="130px"
              price={priceFilters}
              setPrice={setPriceFilters}
              setActiveFilters={setActiveFilters}
            ></FilterDropdown>
          )}

          {paymentTypeProp && (
            <FilterDropdown
              title="Тип оплаты"
              maxWidth="150px"
              checkBoxElements={paymentTypeFilters}
              setActiveFilters={setActiveFilters}
              setCheckedFilter={setPaymentTypeFilters}
            ></FilterDropdown>
          )}

          {subcategoriesProp && (
            <FilterDropdown
              title="Подкатегория"
              maxWidth="165px"
              checkBoxElements={subcategoriesFilters}
              setActiveFilters={setActiveFilters}
              setCheckedFilter={setSubcategoriesFilters}
              className={subcategoriesFilters.length > 0 ? '' : 'disable'}
            ></FilterDropdown>
          )}

          {statusProp && (
            <FilterDropdown
              title="Статус"
              maxWidth="110px"
              checkBoxElements={statusFilters}
              setActiveFilters={setActiveFilters}
              setCheckedFilter={setStatusFilters}
            ></FilterDropdown>
          )}

          {orderStatusProp && (
            <FilterDropdown
              title="Статус"
              maxWidth="110px"
              checkBoxElements={orderStatusFilters}
              setActiveFilters={setActiveFilters}
              setCheckedFilter={setOrderStatusFilters}
            ></FilterDropdown>
          )}

          {priceProp && (
            <FilterDropdown
              title="Цена товара"
              maxWidth="160px"
              price={priceFilters}
              setPrice={setPriceFilters}
              setActiveFilters={setActiveFilters}
            ></FilterDropdown>
          )}

          {promoProp && (
            <FilterDropdown
              title="Скидки"
              maxWidth="120px"
              radioElements={promoFilters}
              setActiveFilters={setActiveFilters}
              setCheckedFilter={setPromoFilters}
            ></FilterDropdown>
          )}

          {paidToSupplierProp && (
            <FilterDropdown
              title="Оплачено поставщику"
              maxWidth="233px"
              radioElements={paidToSupplierFilters}
              setActiveFilters={setActiveFilters}
              setCheckedFilter={setPaidToSupplierFilters}
            ></FilterDropdown>
          )}
        </FilterBody>

        <FilterFooter>
          {activeFilters.map((filter, i) => (
            <FilterElement key={`${filter.value}_${filter.key}_${i}`}>
              {filter.label}: {filter.name}
              <DeleteFilter
                src={deleteFilter}
                onClick={() => onDeleteFilter(filter)}
              />
            </FilterElement>
          ))}
          {activeFilters.length > 0 && (
            <ClearFilter onClick={onClearFilters}>Очистить фильтр</ClearFilter>
          )}
        </FilterFooter>
      </ToggleContainer>
    </FilterWrapper>
  )
}

export default Filter
