import React, { FC, useState, useEffect } from 'react'
import { Assets, Colors } from '../../assets'
import {
  SubcategoryModalContent,
  ModalOverlay,
  ModalFlexWrapper,
  CreateModalButton,
  BorderDumbButton,
  FlexColumn,
  MainTitle,
  SecTitle,
  MinTitle,
  FixedValues,
  Value,
  Clickable,
} from './styled'
import ReactModal from 'react-modal'
import { SimpleDivider, StyledWidthWrapper } from '../Styled/formStyled'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { subcategorySchema } from '../../validation'
import {
  FakeButton,
  HiddenButton,
  SmartInput,
  StyledInputFieldsWrapper,
  StyledInputFieldWidthWrapper,
} from '..'
import { v4 } from 'uuid'
import { ButtonWrapper } from '../Form'
import { StyledViewButton } from '../Table'
import { langValuesSchema } from '../../validation/lang-values.validation'
import {
  pullTextEntityCategoryAction,
  pushTextEntityCategoryAction,
} from '../../store'
import { useDispatch } from 'react-redux'
import { MdDelete, MdDeleteOutline } from 'react-icons/md'

interface IModal {
  init?: any
  type: 'add' | 'edit'
  func?: any
}

const FixedModal: FC<IModal> = ({ type, init = null, func }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [ls, setLs] = useState<any[]>([])

  const dispatch = useDispatch()

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(langValuesSchema),
  })

  const {
    register: valReg,
    formState: { errors: valErr, isValid: valIsValid },
    handleSubmit: valSubm,
    reset: valRes,
  } = useForm({
    resolver: yupResolver(langValuesSchema),
  })

  useEffect(() => {
    if (init) {
      console.log('init', init)
      Object.keys(init).forEach((el: any) => {
        setValue(el, init[el])
      })

      setLs(init.possibleValues)
    }
  }, [init])

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const onSubmitSubcategory = (e: any) => {
    e.preventDefault()
    handleSubmit(data => {
      const { en, ru, uz, _id } = data
      func({
        en,
        ru,
        uz,
        possibleValues: ls,
        _id: !_id ? v4() : _id,
      })
      if (type === 'add') {
        setLs([])
        valRes()
        reset()
      }
      toggleModal()
    })(e)
  }

  const handleNewValue = (e: any) => {
    e.preventDefault()
    const charId = getValues('_id')
    valSubm(data => {
      setLs(prev => [...prev, { ...data, _id: v4() }])
      if (type === 'edit')
        dispatch(pushTextEntityCategoryAction.request({ id: charId, data }))
      valRes()
    })(e)
  }

  const deleteValue = (valId: string) => {
    const charId = getValues('_id')
    setLs(prev => prev.filter(el => el._id !== valId))
    if (type === 'edit')
      dispatch(pullTextEntityCategoryAction.request({ charId, valId }))
  }

  const onClose = () => {
    if (type === 'add') {
      setLs([])
      reset()
    }
    valRes()
    toggleModal()
  }

  const lsEl = () => {
    return (
      <div style={{ marginTop: '20px' }}>
        <MinTitle>Список значений</MinTitle>
        {ls.map((el: any, i) => (
          <FixedValues>
            <div>{i + 1}:</div>
            <Value>{el.en}</Value>
            <Value>{el.ru}</Value>
            <Value>{el.uz}</Value>
            <Clickable
              style={{ marginLeft: 'auto', marginTop: '5px' }}
              onClick={() => deleteValue(el._id)}
            >
              <MdDeleteOutline color="black" size="20px" />
            </Clickable>
          </FixedValues>
        ))}
      </div>
    )
  }

  return (
    <div>
      {type === 'add' ? (
        <CreateModalButton
          style={{}}
          type="button"
          onClick={() => toggleModal()}
        >
          Добавить
        </CreateModalButton>
      ) : (
        <StyledViewButton onClick={() => toggleModal()}>
          <img height="24px" width="24px" src={Assets.SHOW_PASSWORD_ICON} />
        </StyledViewButton>
      )}

      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => toggleModal()}
        shouldCloseOnOverlayClick
        className="_"
        overlayClassName="_"
        contentElement={(props, children) => (
          <SubcategoryModalContent {...props}>
            {children}
          </SubcategoryModalContent>
        )}
        overlayElement={(props, contentElement) => (
          <ModalOverlay {...props}>{contentElement}</ModalOverlay>
        )}
      >
        <FlexColumn>
          <MainTitle>Характеристики</MainTitle>
          <SimpleDivider height="10px" />
          <SecTitle>Фиксированные значения</SecTitle>
        </FlexColumn>
        <ModalFlexWrapper>
          <form onSubmit={e => onSubmitSubcategory(e)}>
            <StyledInputFieldsWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="English"
                  name="en"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Русский"
                  name="ru"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Ozbek"
                  name="uz"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
            </StyledInputFieldsWrapper>
            <HiddenButton id="hiddenCategoryListSubmit">submit</HiddenButton>
          </form>

          <div style={{ margin: '20px 0 10px 0' }}>
            <MinTitle>Значения</MinTitle>
          </div>

          <form onSubmit={e => onSubmitSubcategory(e)}>
            <StyledInputFieldsWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={valReg}
                  title="English"
                  name="en"
                  error={valErr}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={valReg}
                  title="Русский"
                  name="ru"
                  error={valErr}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={valReg}
                  title="Ozbek"
                  name="uz"
                  error={valErr}
                />
              </StyledInputFieldWidthWrapper>
            </StyledInputFieldsWrapper>
            <SimpleDivider height="20px" />
            <BorderDumbButton
              brd={Colors.green}
              txt={Colors.green}
              bg={Colors.white}
              onClick={handleNewValue}
              type="button"
            >
              Добавить
            </BorderDumbButton>
          </form>
          {lsEl()}
        </ModalFlexWrapper>
        <ButtonWrapper>
          <BorderDumbButton
            brd={Colors.boldGray}
            txt={Colors.boldGray}
            bg={Colors.white}
            onClick={onClose}
            type="button"
          >
            Отмена
          </BorderDumbButton>
          <FakeButton htmlFor="hiddenCategoryListSubmit">Создать</FakeButton>
        </ButtonWrapper>
      </ReactModal>
    </div>
  )
}

export default FixedModal
