import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  createBannerAction,
  deleteBannerAction,
  deleteNewBannerAction,
  getBannersAction,
  updateBannerAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { BannerAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'
import { AxiosError } from 'axios'

function* getBannersWorker({ payload }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<ReturnType<typeof BannerAPI.getBanners>> =
      yield call([BannerAPI, BannerAPI.getBanners], {
        authorization: token,
        section: payload,
      })

    console.log(response)

    yield put(getBannersAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения баннеров')),
    )
    yield put(getBannersAction.failure(e as AxiosError))
  }
}

function* createBannerWorker({ payload }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([BannerAPI, BannerAPI.createBanner], {
      authorization: token,
      data: payload.data,
    })

    yield put(
      showToastAction.request(generateSuccessToast('Баннер успешно создан')),
    )

    const response: PromiseReturnType<ReturnType<typeof BannerAPI.getBanners>> =
      yield call([BannerAPI, BannerAPI.getBanners], {
        authorization: token,
        section: payload.section,
      })

    yield put(createBannerAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания баннера')),
    )

    yield put(createBannerAction.failure(e as AxiosError))
  }
}

function* updateBannerWorker({
  payload,
}: {
  payload: { id: string; data: any; section: any }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([BannerAPI, BannerAPI.updateBanner], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    const response: PromiseReturnType<ReturnType<typeof BannerAPI.getBanners>> =
      yield call([BannerAPI, BannerAPI.getBanners], {
        authorization: token,
        section: payload.section,
      })

    yield put(updateBannerAction.success(response.data))

    yield put(
      showToastAction.request(generateSuccessToast('Баннер успешно обновлён')),
    )
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления баннера')),
    )

    yield put(updateBannerAction.failure(e as AxiosError))
  }
}

function* deleteBannerWorker({
  payload,
}: {
  payload: { id: string; section: string }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    console.log(payload)

    yield call([BannerAPI, BannerAPI.deleteBanner], {
      authorization: token,
      id: payload.id,
    })

    const response: PromiseReturnType<ReturnType<typeof BannerAPI.getBanners>> =
      yield call([BannerAPI, BannerAPI.getBanners], {
        authorization: token,
        section: payload.section,
      })

    yield put(deleteBannerAction.success(response.data))

    yield put(
      showToastAction.request(generateSuccessToast('Баннер успешно удалён')),
    )
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления баннера')),
    )

    yield put(deleteBannerAction.failure(e as AxiosError))
  }
}

export function* bannersWatcher() {
  yield takeLatest(getBannersAction.request, getBannersWorker)
  yield takeLatest(createBannerAction.request, createBannerWorker)
  yield takeLatest(updateBannerAction.request, updateBannerWorker)
  yield takeLatest(deleteBannerAction.request, deleteBannerWorker)
}
