import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets } from '../../../assets'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { ColumnsGrid } from '../../Roles/styled'
import { DateColumn, DateRow, DeleteBanner } from '../styled'
import { BannerProps } from '../types'
import { DateSearchInput } from '../../../components'
import { RowStartWrapper } from '../../../components/Modals/styled'
import { ChooseCategory } from './ChooseCategory'
import { ChooseBrand } from './ChooseBrand'
import { ChooseProduct } from './ChooseProduct'
import {
  deleteBannerAction,
  setEditedBannersAction,
} from '../../../store/banners'
import { getBannersSelector } from '../../../store/banners/selectors'
import { api } from '../../../store'
import { defaulDateFormat } from '../../../helpers'

const Banner: FC<BannerProps> = ({ banner }) => {
  const { banners } = useSelector(getBannersSelector)

  const dispatch = useDispatch()

  const linkTypes = useMemo(
    () => [
      { value: 'product', title: 'Товар' },
      { value: 'category', title: 'Категория' },
      { value: 'brand', title: 'Бренд' },
    ],
    [],
  )

  const onDeleteBanner = () => {
    dispatch(
      deleteBannerAction.request({
        id: banner._id,
        section: `?section=${banner.section}`,
      }),
    )
  }

  const onSetStart = date => {
    const editedBanners = banners.map(item => {
      if (item._id == banner._id) {
        return {
          ...item,
          start: date,
          edited: true,
        }
      }
      return item
    })

    dispatch(setEditedBannersAction(editedBanners))
  }
  const onSetEnd = date => {
    const editedBanners = banners.map(item => {
      if (item._id == banner._id) {
        return {
          ...item,
          end: date,
          edited: true,
        }
      }
      return item
    })

    dispatch(setEditedBannersAction(editedBanners))
  }
  const onSetLinkType = (linkType: string) => {
    const editedBanners = banners.map(item => {
      if (item._id == banner._id) {
        return {
          ...item,
          linkType,
          link: '',
          edited: true,
        }
      }
      return item
    })

    dispatch(setEditedBannersAction(editedBanners))
  }
  const setLink = (link: string) => {
    const editedBanners = banners.map(item => {
      if (item._id == banner._id) {
        return {
          ...item,
          link: link,
          edited: true,
        }
      }
      return item
    })

    dispatch(setEditedBannersAction(editedBanners))
  }

  return (
    <ColumnsGrid className="banner" template="15% 20% 60% 5%">
      {banner?.photo && (
        <img
          src={`${api.images}${banner.photo}`}
          alt="banner"
          className="banner-img"
        />
      )}

      <DateColumn>
        <DateRow>
          <span style={{ width: '20px' }}>С</span>
          <DateSearchInput
            value={defaulDateFormat(banner.start)}
            onChange={e => onSetStart(e.target.value)}
            type="date"
            style={{ width: '140px' }}
          />
        </DateRow>

        <DateRow>
          <span>По</span>
          <DateSearchInput
            value={defaulDateFormat(banner.end)}
            onChange={e => onSetEnd(e.target.value)}
            type="date"
            style={{ width: '140px' }}
          />
        </DateRow>
      </DateColumn>

      <RowStartWrapper style={{ alignItems: 'center' }}>
        <Select
          onChange={e => onSetLinkType(e.target.value)}
          value={banner.linkType}
          style={{ width: '25%', height: '40px' }}
        >
          {linkTypes.map(item => (
            <MenuItem value={item.value} key={item.value}>
              {linkTypes.find(link => link.value === item.value)?.title}
            </MenuItem>
          ))}
        </Select>

        {banner.linkType == 'category' && (
          <ChooseCategory
            setLink={setLink}
            link={banner.link}
            subcategory={banner.subcategory}
          />
        )}

        {banner.linkType == 'brand' && (
          <ChooseBrand setLink={setLink} link={banner.link} />
        )}

        {banner.linkType == 'product' && (
          <ChooseProduct setLink={setLink} link={banner.link} />
        )}
      </RowStartWrapper>

      <DeleteBanner
        src={Assets.DELETE_RED_ICON}
        alt="delete"
        onClick={onDeleteBanner}
      />
    </ColumnsGrid>
  )
}

export default Banner
