import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'
import { ForgotPasswordActionPayload } from '../user/types'

import {
  SetAllNewChatsActionResponse,
  SetAllMyChatsActionResponse,
  sendChatMessageActionPayload,
  sendChatMessageActionResponse,
  setActiveChatPayload,
  getMessagesByIdActionResponse,
  getMessagesByIdActionPayload,
  acceptChatActionPayload,
  acceptChatActionResponse,
  setNewMessageToChatPayload,
  readMessagesActionPayload,
  setNewPendingChatActionPayload,
  downloadFileActionPayload,
  SetMyChatsByQueryActionPayload,
  SetMyChatsByQueryActionResponse,
  GetAllActiveChatsActionResponse,
} from './types'

export const setAllNewChatsAction = createApiActions<
  string,
  SetAllNewChatsActionResponse,
  AxiosError
>(createActionTypes('CHAT/SET_ALL_NEW_CHATS'))

export const setAllMyChatsAction = createApiActions<
  void,
  SetAllMyChatsActionResponse,
  AxiosError
>(createActionTypes('CHAT/SET_ALL_MY_CHATS'))

export const sendChatMessageAction = createApiActions<
  sendChatMessageActionPayload,
  sendChatMessageActionResponse,
  AxiosError
>(createActionTypes('CHAT/SEND_MESSAGE'))

export const getMessagesByIdAction = createApiActions<
  getMessagesByIdActionPayload,
  getMessagesByIdActionResponse,
  AxiosError
>(createActionTypes('CHAT/GET_MESSAGES_BY_ID'))

export const acceptChatAction = createApiActions<
  acceptChatActionPayload,
  acceptChatActionResponse,
  AxiosError
>(createActionTypes('CHAT/ACCEPT_CHAT'))

export const setActiveChatAction = createAction<
  setActiveChatPayload,
  'CHAT/SET_ACTIVE_CHAT'
>('CHAT/SET_ACTIVE_CHAT')

export const setNewMessageToActiveChatAction = createAction<
  setNewMessageToChatPayload,
  'CHAT/SET_NEW_MESSAGE_TO_ACTIVE_CHAT'
>('CHAT/SET_NEW_MESSAGE_TO_ACTIVE_CHAT')

export const closeChatAction = createApiActions<
  acceptChatActionPayload,
  acceptChatActionResponse,
  AxiosError
>(createActionTypes('CHAT/CLOSE_CHAT'))

export const readMessagesAction = createApiActions<
  readMessagesActionPayload,
  void,
  AxiosError
>(createActionTypes('CHAT/READ_MESSAGES'))

export const setNewPendingChatAction = createAction<
  setNewPendingChatActionPayload,
  'CHAT/SET_NEW_PENDING_CHAT'
>('CHAT/SET_NEW_PENDING_CHAT')

export const setNewMessageAction = createAction<any, 'CHAT/SET_NEW_MESSAGE'>(
  'CHAT/SET_NEW_MESSAGE',
)

export const downloadFileAction = createApiActions<
  downloadFileActionPayload,
  void,
  AxiosError
>(createActionTypes('CHAT/DOWNLOAD_FILE'))

export const setMyChatsByQueryAction = createApiActions<
  SetMyChatsByQueryActionPayload,
  SetMyChatsByQueryActionResponse,
  AxiosError
>(createActionTypes('CHAT/SET_MY_CHATS_BY_QUERY'))

export const getAllActiveChatsAction = createApiActions<
  void,
  GetAllActiveChatsActionResponse,
  AxiosError
>(createActionTypes('CHAT/GET_ALL_CHATS'))
