import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  getFeedbackAction,
  getFeedbackByIdAction,
  moderateFeedbackAction,
} from './actions'

const InitialState: TInitialState = {
  feedbacks: {},
  feedback: null,
  loading: false,
}

export const feedbackReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getFeedbackAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getFeedbackAction.success, (state, { payload }) => ({
      ...state,
      feedbacks: payload,
      loading: false,
    }))
    builder.addCase(getFeedbackAction.failure, (state, { payload }) => ({
      ...state,
      feedbacks: [],
      loading: false,
    }))

    builder.addCase(getFeedbackByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getFeedbackByIdAction.success, (state, { payload }) => ({
      ...state,
      feedback: payload.data,
      loading: false,
    }))
    builder.addCase(getFeedbackByIdAction.failure, (state, { payload }) => ({
      ...state,
      feedback: null,
      loading: false,
    }))

    builder.addCase(moderateFeedbackAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(moderateFeedbackAction.success, (state, { payload }) => ({
      ...state,
      feedback: payload.data,
      loading: false,
    }))
    builder.addCase(moderateFeedbackAction.failure, (state, { payload }) => ({
      ...state,
      feedback: null,
      loading: false,
    }))
  },
)
