import hidePasswordIcon from './icons/hidePassword.svg'
import showPasswordIcon from './icons/showPassword.svg'
import checkboxIcon from './icons/checkbox.svg'
import calendarIcon from './icons/calendar.svg'

import clockBlackIcon from './icons/clock_black.svg'

import searchIcon from './icons/search.svg'
import logoIcon from './icons/logo.svg'

import categoriesIcon from './icons/categories.svg'
import chatIcon from './icons/chat.svg'
import clientsIcon from './icons/clients.svg'
import deliveryIcon from './icons/delivery.svg'
import feedbackIcon from './icons/feedback.svg'
import loyaltyIcon from './icons/loyalty.svg'
import productIcon from './icons/product.svg'
import rolesIcon from './icons/roles.svg'
import settingsIcon from './icons/settings.svg'
import shopsIcon from './icons/shops.svg'
import statsIcon from './icons/stats.svg'
import supportIcon from './icons/support.svg'
import notificationsIcon from './icons/notifications.svg'
import ordersIcon from './icons/orders.svg'
import paymentIcon from './icons/payments.svg'

import categoriesActiveIcon from './icons/categoriesActive.svg'
import chatActiveIcon from './icons/chatActive.svg'
import clientsActiveIcon from './icons/clientsActive.svg'
import deliveryActiveIcon from './icons/deliveryActive.svg'
import feedbackActiveIcon from './icons/feedbackActive.svg'
import loyaltyActiveIcon from './icons/loyaltyActive.svg'
import productActiveIcon from './icons/productActive.svg'
import rolesActiveIcon from './icons/rolesActive.svg'
import settingsActiveIcon from './icons/settingsActive.svg'
import shopsActiveIcon from './icons/shopsActive.svg'
import statsActiveIcon from './icons/statsActive.svg'
import supportActiveIcon from './icons/supportActive.svg'
import notificationsActiveIcon from './icons/notificationsActive.svg'
import ordersActiveIcon from './icons/ordersActive.svg'
import paymentActiveIcon from './icons/paymentsActive.svg'
import arrowBackIcon from './icons/arrowBack.svg'
import grayArrow from './icons/arrowGray.svg'
import plusWhite from './icons/plusWhite.svg'
import plusBlack from './icons/plusBlack.svg'
import cross from './icons/crossGray.svg'
import sortArrow from './icons/sortArrow.svg'
import sortArrowUp from './icons/sortArrowUp.svg'
import deleteWhite from './icons/deleteWhite.svg'
import deleteRed from './icons/deleteRed.svg'
import grayEye from './icons/grayEye.svg'
import grayClip from './icons/grayClip.svg'
import file from './icons/downloadFile.svg'
import clock from './icons/clockIcon.svg'
import timesRed from './icons/timesRed.svg'
import statistics from './icons/statisticsIcon.svg'
import statisticsActive from './icons/activeStatisticsIcon.svg'
import deleteGray from './icons/deleteGrayIcon.svg'
import blackEye from './icons/blackEyeIcon.svg'
import logout from './icons/logoutIcon.svg'

import loginLogoImage from './images/loginLogo.png'
import userImage from './images/user.png'
import userIcon from './images/userIcon.png'
import calendarPng from './icons/calendar.png'
import storePng from './images/store.png'
import plusIcon from './icons/plusIcon.svg'
import bannersIcon from './icons/bannersDefault.svg'
import activeChatIcon from './icons/bannersActive.svg'
import notFoundBackground from './images/notFoundBackground.svg'

export class Assets {
  static readonly CALENDAR_PNG_ICON = calendarPng
  static readonly STORE_IMG = storePng
  static readonly ARROW_BACK_ICON = arrowBackIcon
  static readonly PLUS_WHITE_ICON = plusWhite
  static readonly DELETE_WHITE_ICON = deleteWhite
  static readonly DELETE_RED_ICON = deleteRed
  static readonly PLUS_BLACK_ICON = plusBlack
  static readonly CROSS_ICON = cross
  static readonly SORT_ARROW_ICON = sortArrow
  static readonly GRAY_ARROW_ICON = grayArrow
  static readonly SORT_ARROW_UP_ICON = sortArrowUp
  static readonly HIDE_PASSWORD_ICON = hidePasswordIcon
  static readonly CLOCK_ICON = clock
  static readonly TIMES_RED_ICON = timesRed
  static readonly DELETE_GRAY_ICON = deleteGray
  static readonly BLACK_EYE_ICON = blackEye
  static readonly LOGOUT_ICON = logout
  static readonly PLUS_ICON = plusIcon
  static readonly BANNERS_ICON = bannersIcon
  static readonly ACTIVE_BANNERS_ICON = activeChatIcon

  static readonly NOT_FOUND_BACKGROUND = notFoundBackground

  static readonly GRAY_EYE_ICON = grayEye

  static readonly DOWNLOAD_FILE = file

  static readonly GRAY_CLIP_ICON = grayClip

  static readonly SHOW_PASSWORD_ICON = showPasswordIcon

  static readonly CHECKBOX_ICON = checkboxIcon

  static readonly STATISTICS_ICON = statistics

  static readonly STATISTICS_ICON_ACTIVE = statisticsActive

  static readonly LOGO_ICON = logoIcon

  static readonly CALENDAR_ICON = calendarIcon

  static readonly CLOCK_BLACK_ICON = clockBlackIcon

  static readonly LOGIN_LOGO_IMAGE = loginLogoImage

  static readonly USER_IMAGE = userImage

  static readonly USER_ICON = userIcon

  static readonly ORDERS_ICON = ordersIcon

  static readonly NOTIFICATIONS_ICON = notificationsIcon

  static readonly SUPPORT_ICON = supportIcon

  static readonly STATS_ICON = statsIcon

  static readonly SHOPS_ICON = shopsIcon

  static readonly SETTINGS_ICON = settingsIcon

  static readonly SEARCH_ICON = searchIcon

  static readonly ROLES_ICON = rolesIcon

  static readonly PRODUCT_ICON = productIcon

  static readonly LOYALTY_ICON = loyaltyIcon

  static readonly FEEDBACK_ICON = feedbackIcon

  static readonly DELIVERY_ICON = deliveryIcon

  static readonly CLIENTS_ICON = clientsIcon

  static readonly CHAT_ICON = chatIcon

  static readonly CATEGORIES_ICON = categoriesIcon

  static readonly PAYMENT_ICON = paymentIcon

  static readonly ORDERS_ACTIVE_ICON = ordersActiveIcon

  static readonly NOTIFICATIONS_ACTIVE_ICON = notificationsActiveIcon

  static readonly SUPPORT_ACTIVE_ICON = supportActiveIcon

  static readonly STATS_ACTIVE_ICON = statsActiveIcon

  static readonly SHOPS_ACTIVE_ICON = shopsActiveIcon

  static readonly SETTINGS_ACTIVE_ICON = settingsActiveIcon

  static readonly ROLES_ACTIVE_ICON = rolesActiveIcon

  static readonly PRODUCT_ACTIVE_ICON = productActiveIcon

  static readonly LOYALTY_ACTIVE_ICON = loyaltyActiveIcon

  static readonly FEEDBACK_ACTIVE_ICON = feedbackActiveIcon

  static readonly DELIVERY_ACTIVE_ICON = deliveryActiveIcon

  static readonly CLIENTS_ACTIVE_ICON = clientsActiveIcon

  static readonly CHAT_ACTIVE_ICON = chatActiveIcon

  static readonly PAYMENT_ACTIVE_ICON = paymentActiveIcon

  static readonly CATEGORIES_ACTIVE_ICON = categoriesActiveIcon
}
