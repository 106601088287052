import { createReducer } from '@reduxjs/toolkit'
import { IInitialState } from './types'

import { changeSupportStatusAction, setSupportMessagesAction } from './actions'

const InitialState: IInitialState = {
  items: [],
}

export const supportReducer = createReducer<IInitialState>(
  InitialState,
  builder => {
    builder.addCase(setSupportMessagesAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(setSupportMessagesAction.success, (state, { payload }) => ({
      ...state,
      items: payload.data,
      loading: false,
    }))
    builder.addCase(setSupportMessagesAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(changeSupportStatusAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      changeSupportStatusAction.success,
      (state, { payload }) => ({
        ...state,
        items: payload.data,
        loading: false,
      }),
    )
    builder.addCase(
      changeSupportStatusAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )
  },
)
