import styled from 'styled-components'
import { Colors } from '../../assets'

export const HyperUpload = styled.a`
  color: ${Colors.green};
  text-decoration: underline;
`

export const CategoryDivider = styled.div`
  width: 100%;
  border-top: 1px ${Colors.gray} solid;
`

export const CharRadio = styled.div<{ active: boolean }>`
  color: ${({ active }) => (active ? Colors.black : Colors.boldGray)};
  padding: 5px;
  ${({ active }) => (active ? `border-bottom: 3px solid ${Colors.black};` : '')}
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`
