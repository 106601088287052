import styled from 'styled-components'
import { Colors } from '../../assets'
import { FLEX, FONT, SHADOW } from '../../utils'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Header = styled.div`
  width: 100%;

  /* padding: 25px; */

  background: ${Colors.white};

  ${FLEX({ justify: 'flex-start' })}
`

export const Title = styled.h1`
  ${FONT({ size: '24px', color: Colors.boldGray, weight: '700' })}
`

export const TableWrapper = styled.div`
  width: 100%;
  /* height: calc(100% - 100px); */

  padding: 20px;

  background: ${Colors.white};

  overflow-y: scroll;

  border-radius: 24px;

  ${SHADOW({})}
`

export const StyledRow = styled.tr`
  & td {
    padding: 9px 15px 7px 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${FONT({ size: '16px', color: Colors.lightBlue, weight: '400' })}
`

export const StyledHeader = styled.th`
  padding: 15px;

  ${SHADOW({ x: 2, y: 0 })};

  ${FONT({
    size: '16px',
    color: Colors.lightBlue,
    weight: '700',
    align: 'left',
  })}
`

export const StyledTable = styled.table`
  width: 100%;
`

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  cursor: pointer;

  ${FONT({
    size: '16px',
    color: Colors.lightBlue,
    weight: '500',
    align: 'center',
  })}

  transition: 0.2s;

  &:hover {
    color: ${Colors.green};
  }
`
