import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  createCategoryAction,
  createSubsubcategoryAction,
  deleteCategoryAction,
  deleteSubsubcategoryAction,
  editEntityCategoryAction,
  getCategoriesAction,
  getCategoryAction,
  getSubsubcategoriesAction,
  pullEntityCategoryAction,
  pullTextEntityCategoryAction,
  pushEntityCategoryAction,
  pushTextEntityCategoryAction,
  updateCategoryAction,
  updateCategoryImageAction,
  updateCategoryProductsAction,
  updateSubcategoryImageAction,
  updateSubsubcategoryAction,
} from './actions'

import {
  generateErrorToast,
  generateInfoToast,
  generateSuccessToast,
} from '../../helpers'
import { PromiseReturnType } from '../types'
import { CategoryAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'
import history from '../../services/history'
import { AxiosError } from 'axios'

function* getCategoriesWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategories>
    > = yield call([CategoryAPI, CategoryAPI.getCategories], {
      authorization: token,
    })

    yield put(getCategoriesAction.success(response.data))
  } catch (e) {
    console.log('Error: getCategoriesWorker', e)

    yield put(getCategoriesAction.failure(e as AxiosError))
  }
}

function* getCategoryByIDWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategory>
    > = yield call([CategoryAPI, CategoryAPI.getCategory], {
      authorization: token,
      id: payload.id,
    })

    yield put(getCategoryAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категории')),
    )

    yield put(getCategoryAction.failure(e as AxiosError))
  }
}

function* updateCategoryProductsWorker({
  payload,
}: {
  payload: { id: string; data: any }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.updateCategoryProducts>
    > = yield call([CategoryAPI, CategoryAPI.updateCategoryProducts], {
      authorization: token,
      id: payload.id,
      data: payload.data,
    })

    const res: PromiseReturnType<ReturnType<typeof CategoryAPI.getCategories>> =
      yield call([CategoryAPI, CategoryAPI.getCategories], {
        authorization: token,
      })

    console.log(res)

    yield put(updateCategoryProductsAction.success(res.data))
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категории')),
    )

    yield put(updateCategoryProductsAction.failure(e as AxiosError))
  }
}

function* deleteCategoryWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.deleteCategory>
    > = yield call([CategoryAPI, CategoryAPI.deleteCategory], {
      authorization: token,
      id: payload.id,
    })

    yield put(deleteCategoryAction.success())

    yield put(
      showToastAction.request(generateInfoToast('Категория успешно удалена')),
    )

    yield put(getCategoriesAction.request())
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления категории')),
    )
    yield put(deleteCategoryAction.failure(e as AxiosError))
  }
}

function* createCategoryWorker({
  payload,
}: ReturnType<typeof createCategoryAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const { image, ...categoryData } = payload

    const data = new FormData()
    data.append('image', image)

    const re: PromiseReturnType<ReturnType<typeof CategoryAPI.createCategory>> =
      yield call([CategoryAPI, CategoryAPI.createCategory], {
        authorization: token,
        data: categoryData,
      })

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.updateImageCategory>
    > = yield call([CategoryAPI, CategoryAPI.updateImageCategory], {
      authorization: token,
      data,
      id: re.data.data._id,
    })

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategory>
    > = yield call([CategoryAPI, CategoryAPI.getCategory], {
      authorization: token,
      id: re.data.data._id,
    })

    yield put(createCategoryAction.success(response.data))

    yield put(
      showToastAction.request(
        generateSuccessToast('Категория успешно создана'),
      ),
    )
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания категории')),
    )

    yield put(createCategoryAction.failure(e as AxiosError))
  }
}

function* fullCategoryUpdateWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.fullUpdateCategory>
    > = yield call([CategoryAPI, CategoryAPI.fullUpdateCategory], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategory>
    > = yield call([CategoryAPI, CategoryAPI.getCategory], {
      authorization: token,
      id: payload.id,
    })

    yield put(updateCategoryAction.success(response.data))

    // yield put(
    //   showToastAction.request(
    //     generateSuccessToast('Категория успешно создана'),
    //   ),
    // )

    // yield call([history, history.push], `/categories/${response.data.data._id}`)
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoryAction.failure(e as AxiosError))
  }
}

function* categoryImageUpdateWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const data = new FormData()
    data.append('image', payload.file)

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.updateImageCategory>
    > = yield call([CategoryAPI, CategoryAPI.updateImageCategory], {
      authorization: token,
      data,
      id: payload.id,
    })

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategory>
    > = yield call([CategoryAPI, CategoryAPI.getCategory], {
      authorization: token,
      id: payload.id,
    })

    yield put(updateCategoryImageAction.success(response.data))
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoryImageAction.failure(e as AxiosError))
  }
}

function* categoryPullEntityWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.pullEntityCategory>
    > = yield call([CategoryAPI, CategoryAPI.pullEntityCategory], {
      authorization: token,
      catId: payload.catId,
      entId: payload.entId,
      path: payload.path,
    })

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategory>
    > = yield call([CategoryAPI, CategoryAPI.getCategory], {
      authorization: token,
      id: payload.catId,
    })

    yield put(updateCategoryImageAction.success(response.data))
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoryImageAction.failure(e as AxiosError))
  }
}

function* categoryPushEntityWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.pushEntityCategory>
    > = yield call([CategoryAPI, CategoryAPI.pushEntityCategory], {
      authorization: token,
      data: payload.data,
      id: payload.id,
      path: payload.path,
    })

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategory>
    > = yield call([CategoryAPI, CategoryAPI.getCategory], {
      authorization: token,
      id: payload.id,
    })

    yield put(updateCategoryImageAction.success(response.data))
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoryImageAction.failure(e as AxiosError))
  }
}

function* categoryEditEntityWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.editEntityCategory>
    > = yield call([CategoryAPI, CategoryAPI.editEntityCategory], {
      authorization: token,
      data: payload.data,
      id: payload.id,
      path: payload.path,
    })

    // const response: PromiseReturnType<
    //   ReturnType<typeof CategoryAPI.getCategory>
    // > = yield call([CategoryAPI, CategoryAPI.getCategory], {
    //   authorization: token,
    //   id: payload.id,
    // })

    yield put(editEntityCategoryAction.success())
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoryImageAction.failure(e as AxiosError))
  }
}

function* categoryPullTextEntityWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    console.log(payload)

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.pullTextEntityCategory>
    > = yield call([CategoryAPI, CategoryAPI.pullTextEntityCategory], {
      authorization: token,
      charId: payload.charId,
      valId: payload.valId,
    })

    // const response: PromiseReturnType<
    //   ReturnType<typeof CategoryAPI.getCategory>
    // > = yield call([CategoryAPI, CategoryAPI.getCategory], {
    //   authorization: token,
    //   id: payload.id,
    // })

    // yield put(updateCategoryImageAction.success(response.data))
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoryImageAction.failure(e as AxiosError))
  }
}

function* categoryPushTextEntityWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    console.log(payload)

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.pushTextEntityCategory>
    > = yield call([CategoryAPI, CategoryAPI.pushTextEntityCategory], {
      authorization: token,
      id: payload.id,
      data: payload.data,
    })

    // const response: PromiseReturnType<
    //   ReturnType<typeof CategoryAPI.getCategory>
    // > = yield call([CategoryAPI, CategoryAPI.getCategory], {
    //   authorization: token,
    //   id: payload.id,
    // })

    // yield put(updateCategoryImageAction.success(response.data))
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoryImageAction.failure(e as AxiosError))
  }
}

function* updateSubcategoryImageWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.updateSubcategoryImage>
    > = yield call([CategoryAPI, CategoryAPI.updateSubcategoryImage], {
      authorization: token,
      id: payload.id,
      data: payload.data,
    })
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка обновления категории'),
      ),
    )

    yield put(updateCategoryImageAction.failure(e as AxiosError))
  }
}

function* createSubsubcategoryWorker({ payload }: any) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: any = yield call(
      [CategoryAPI, CategoryAPI.createSubsubcategory],
      {
        authorization: token,
        data: payload.data,
      },
    )

    yield call([CategoryAPI, CategoryAPI.editEntityCategory], {
      authorization: token,
      data: { child: [...payload.child, res.data.data._id] },
      id: payload.subcategoryId,
      path: 'subcategories',
    })

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategory>
    > = yield call([CategoryAPI, CategoryAPI.getCategory], {
      authorization: token,
      id: payload.categoryId,
    })

    yield put(getCategoryAction.success(response.data))

    yield put(
      showToastAction.request(
        generateSuccessToast('Подкатегория успешно создана'),
      ),
    )
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания категории')),
    )

    yield put(updateCategoryImageAction.failure(e as AxiosError))
  }
}

function* deleteSubsubcategoryWorker({
  payload,
}: {
  payload: { subsubcategoryId: string; categoryId: string }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.deleteSubsubcategory>
    > = yield call([CategoryAPI, CategoryAPI.deleteSubsubcategory], {
      authorization: token,
      id: payload.subsubcategoryId,
    })

    yield put(deleteSubsubcategoryAction.success())

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategory>
    > = yield call([CategoryAPI, CategoryAPI.getCategory], {
      authorization: token,
      id: payload.categoryId,
    })

    yield put(getCategoryAction.success(response.data))

    yield put(
      showToastAction.request(
        generateSuccessToast('Категория успешно удалена'),
      ),
    )
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast('Ошибка удаления категории')),
    )
    yield put(deleteSubsubcategoryAction.failure(e as AxiosError))
  }
}

function* updateSubsubcategoryWorker({
  payload,
}: {
  payload: { subsubcategoryId: string; categoryId: string; data: any }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: PromiseReturnType<
      ReturnType<typeof CategoryAPI.updateSubsubcategory>
    > = yield call([CategoryAPI, CategoryAPI.updateSubsubcategory], {
      authorization: token,
      id: payload.subsubcategoryId,
      data: payload.data,
    })

    yield put(updateSubsubcategoryAction.success())

    const response: PromiseReturnType<
      ReturnType<typeof CategoryAPI.getCategory>
    > = yield call([CategoryAPI, CategoryAPI.getCategory], {
      authorization: token,
      id: payload.categoryId,
      data: payload.data,
    })

    yield put(getCategoryAction.success(response.data))

    yield put(
      showToastAction.request(
        generateSuccessToast('Категория успешно изменена'),
      ),
    )
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast('Ошибка изменения категории')),
    )
    yield put(updateSubsubcategoryAction.failure(e as AxiosError))
  }
}

function* getSubSubcategoriesWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
        getUserSelector,
    )

    const response: PromiseReturnType<
        ReturnType<typeof CategoryAPI.getSubsubcategories>
        > = yield call([CategoryAPI, CategoryAPI.getSubsubcategories], {
      authorization: token,
    })

    yield put(getSubsubcategoriesAction.success(response.data))
  } catch (e) {
    console.log('Error: getCategoriesWorker', e)

    yield put(getSubsubcategoriesAction.failure(e as AxiosError))
  }
}


export function* categoriesWatcher() {
  yield takeLatest(getCategoriesAction.request, getCategoriesWorker)
  yield takeLatest(getCategoryAction.request, getCategoryByIDWorker)
  yield takeLatest(
    updateCategoryProductsAction.request,
    updateCategoryProductsWorker,
  )
  yield takeLatest(deleteCategoryAction.request, deleteCategoryWorker)
  yield takeLatest(updateCategoryAction.request, fullCategoryUpdateWorker)
  yield takeLatest(updateCategoryImageAction.request, categoryImageUpdateWorker)
  yield takeLatest(pushEntityCategoryAction.request, categoryPushEntityWorker)
  yield takeLatest(pullEntityCategoryAction.request, categoryPullEntityWorker)
  yield takeLatest(editEntityCategoryAction.request, categoryEditEntityWorker)
  yield takeLatest(
    pullTextEntityCategoryAction.request,
    categoryPullTextEntityWorker,
  )
  yield takeLatest(
    pushTextEntityCategoryAction.request,
    categoryPushTextEntityWorker,
  )
  yield takeLatest(
    updateSubcategoryImageAction.request,
    updateSubcategoryImageWorker,
  )
  yield takeLatest(createCategoryAction.request, createCategoryWorker)
  yield takeLatest(
    createSubsubcategoryAction.request,
    createSubsubcategoryWorker,
  )
  yield takeLatest(
    deleteSubsubcategoryAction.request,
    deleteSubsubcategoryWorker,
  )
  yield takeLatest(
    updateSubsubcategoryAction.request,
    updateSubsubcategoryWorker,
  )
  yield takeLatest(
      getSubsubcategoriesAction.request, getSubSubcategoriesWorker
  )
}
