import { useMemo } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import {
  SidebarWrapper,
  NavLink,
  MenuWrapper,
  Image,
  LogoImage,
  UserCard,
  UserImage,
  UserName,
  ImageContainer,
  NavLinkProfile,
  SidebarButton,
  Chevrone,
} from './styled'

import { Assets } from '../../assets'
import { Divider } from '../../components'
import { useTypedSelector } from '../../hooks'
import { api, logoutAction } from '../../store'
import statistics from '../../assets/icons/statisticsIcon.svg'
import statisticsActive from '../../assets/icons/activeStatisticsIcon.svg'
import { useDispatch } from 'react-redux'

export default withRouter(function Sidebar({
  onMenuItemClick,
  isOpen,
  setIsOpen,
}: any) {
  const location = useLocation()

  const dispatch = useDispatch()

  const { user } = useTypedSelector(state => state.user)

  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  const sidebarMenus = useMemo(
    () => [
      checkPermission('orderGet') && {
        name: 'Заказы',
        path: '/orders',
        exact: false,
        icon: Assets.ORDERS_ICON,
        activeIcon: Assets.ORDERS_ACTIVE_ICON,
      },
      checkPermission('chatGet') && {
        name: 'Чат',
        path: '/chat',
        exact: false,
        icon: Assets.CHAT_ICON,
        activeIcon: Assets.CHAT_ACTIVE_ICON,
      },
      checkPermission('supportGet') && {
        name: 'Поддержка',
        path: '/help',
        exact: false,
        icon: Assets.SUPPORT_ICON,
        activeIcon: Assets.SUPPORT_ACTIVE_ICON,
      },
      checkPermission('productGet') && {
        name: 'Управление товарами',
        path: '/products',
        exact: false,
        icon: Assets.PRODUCT_ICON,
        activeIcon: Assets.PRODUCT_ACTIVE_ICON,
      },
      checkPermission('clientGet') && {
        name: 'Управление клиентами',
        path: '/clients',
        exact: false,
        icon: Assets.CLIENTS_ICON,
        activeIcon: Assets.CLIENTS_ACTIVE_ICON,
      },
      checkPermission('categoryGet') && {
        name: 'Категории',
        path: '/categories',
        exact: false,
        icon: Assets.CATEGORIES_ICON,
        activeIcon: Assets.CATEGORIES_ACTIVE_ICON,
      },
      checkPermission('brandGet') && {
        name: 'Бренды',
        path: '/brands',
        exact: false,
        icon: Assets.LOYALTY_ICON,
        activeIcon: Assets.LOYALTY_ACTIVE_ICON,
      },
      checkPermission('financeGet') && {
        name: 'Финансы',
        path: '/finance',
        exact: true,
        icon: Assets.PAYMENT_ICON,
        activeIcon: Assets.PAYMENT_ACTIVE_ICON,
      },
      checkPermission('financeGet') && {
        name: 'Статистика финансов',
        path: '/finance/statistics',
        exact: false,
        icon: statistics,
        activeIcon: statisticsActive,
      },
      checkPermission('bannerGet') && {
        name: 'Баннеры',
        path: '/banners',
        exact: true,
        icon: Assets.BANNERS_ICON,
        activeIcon: Assets.ACTIVE_BANNERS_ICON,
      },
      checkPermission('feedbackGet') && {
        name: 'Отзывы',
        path: '/feedback',
        exact: false,
        icon: Assets.FEEDBACK_ICON,
        activeIcon: Assets.FEEDBACK_ACTIVE_ICON,
      },
      checkPermission('storeGet') && {
        name: 'Магазины / Склады',
        path: '/stores',
        exact: false,
        icon: Assets.SHOPS_ICON,
        activeIcon: Assets.SHOPS_ACTIVE_ICON,
      },
      checkPermission('rolesGet') && {
        name: 'Роли',
        path: '/roles',
        exact: false,
        icon: Assets.ROLES_ICON,
        activeIcon: Assets.ROLES_ACTIVE_ICON,
      },
      checkPermission('statsManagerGet') && {
        name: 'Статистика работы менеджеров',
        path: '/managersStats',
        exact: false,
        icon: Assets.STATS_ICON,
        activeIcon: Assets.STATS_ACTIVE_ICON,
      },
      checkPermission('settingsGet') && {
        name: 'Настройки',
        path: '/settings',
        exact: false,
        icon: Assets.SETTINGS_ICON,
        activeIcon: Assets.SETTINGS_ACTIVE_ICON,
      },
    ],
    [],
  )

  const onLogout = () => {
    dispatch(logoutAction.request())
  }

  return (
    <SidebarWrapper className={isOpen ? 'open' : ''}>
      <Divider height={40} />

      <SidebarButton onClick={() => setIsOpen(!isOpen)}>
        <Chevrone className={isOpen ? 'open' : ''} />
      </SidebarButton>

      <ImageContainer className={isOpen ? 'open' : ''}>
        <LogoImage src={Assets.LOGO_ICON} />
      </ImageContainer>

      <Divider height={40} />

      <NavLinkProfile to="/profile" className={isOpen ? 'open' : ''}>
        <UserCard className={isOpen ? 'open' : ''}>
          <UserImage
            src={user.photo ? `${api.images}${user.photo}` : Assets.USER_ICON}
          />
          <Divider width={6} />
          <UserName>{isOpen && user.name}</UserName>
        </UserCard>
      </NavLinkProfile>

      <Divider height={40} />

      <MenuWrapper>
        {sidebarMenus.map(
          (menu: any, index: number) =>
            menu && (
              <NavLink
                key={index}
                to={menu.path}
                exact={menu.exact}
                onClick={onMenuItemClick}
                className={isOpen ? 'open' : ''}
              >
                {menu.icon ? (
                  <Image
                    src={
                      location.pathname !== menu.path
                        ? menu.icon
                        : menu.activeIcon
                    }
                  />
                ) : (
                  ''
                )}
                {isOpen && menu.name}
              </NavLink>
            ),
        )}

        <NavLink
          to="#"
          exact={false}
          onClick={onLogout}
          style={{
            marginTop: '60px',
            background: '#fff',
            border: '0',
            justifyContent: 'start',
            fontWeight: 400,
          }}
        >
          <Image src={Assets.LOGOUT_ICON} />
          {isOpen && 'Выйти'}
        </NavLink>
      </MenuWrapper>
    </SidebarWrapper>
  )
})
