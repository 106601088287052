import React, { FC, useMemo } from 'react'
import { useTable } from 'react-table'
import { TableLayout } from './TableLayout'

interface ITableLayout {
  getTableProps: any
  getTableBodyProps: any
  headerGroups: any
  rows: any
  prepareRow: any
}

interface ITableInstance {
  tableData: any
  importedColumns: any[]
}

export const TableInstance: FC<ITableInstance> = ({
  tableData,
  importedColumns,
}) => {
  const [columns, data] = useMemo(() => {
    const columns = importedColumns
    return [columns, tableData]
  }, [tableData])

  const tableInstance = useTable({ columns, data })

  return <TableLayout {...tableInstance} />
}
