import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetColorsActionResponse,
  GetColorActionResponse,
  GetColorActionPayload,
  CreateColorActionPayload,
  CreateColorActionResponse,
  DeleteColorActionPayload,
} from './types'

export const getColorsAction = createApiActions<
  void,
  GetColorsActionResponse,
  AxiosError
>(createActionTypes('COLOR/GET_COLORS'))

export const getColorAction = createApiActions<
  GetColorActionPayload,
  GetColorActionResponse,
  AxiosError
>(createActionTypes('COLOR/GET_COLOR'))

export const createColorAction = createApiActions<
  CreateColorActionPayload,
  CreateColorActionResponse,
  AxiosError
>(createActionTypes('COLOR/CREATE_COLOR'))

export const deleteColorAction = createApiActions<
  DeleteColorActionPayload,
  void,
  AxiosError
>(createActionTypes('COLOR/DELETE_COLOR'))
