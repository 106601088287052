import * as yup from 'yup'
import { errMsgs } from './errorMessages'

export const categorySchema = yup.object({
  en: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(1, errMsgs.min(1))
    .required(errMsgs.required),
  ru: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(1, errMsgs.min(1))
    .required(errMsgs.required),
  uz: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(1, errMsgs.min(1))
    .required(errMsgs.required),
  position: yup
    .number()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .typeError(errMsgs.number)
    .required(errMsgs.required),
})

export const testSchema = yup.object({
  number: yup
    .number()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(6, errMsgs.min(6))
    .typeError('Значение должно быть числом')
    .required('Поле не может быть пустым'),
})

export const arraySchema = yup.object().shape({
  test: yup.array().of(
    yup.object().shape({
      name: yup.string().email('emau').required(),
    }),
  ),
})
