import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { Assets, Colors } from '../../assets'
import { BtnWrapper, DumbButton } from '../../components/FormComponents/styled'
import { RowStartWrapper, RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import {
  ProfileInput,
  ProfileInputLabel,
  ProfileInputWrapper,
  UserImage,
} from '../Profile/styled'
import {
  ColumnFlex,
  ColumnWrapper,
  GrayTitle,
  LinkWithIcon,
  UserProfileContent,
} from './styled'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUserAction, getRolesSelector } from '../../store/roles'
import { api } from '../../store'
import { defaultFormatDate } from '../../helpers'

const UserProfile: FC = () => {
  const [admin, setAdmin] = useState({
    name: '',
    email: '',
    role: '',
    created: '',
    photo: '',
    id: '',
  })

  const dispatch = useDispatch()

  const history = useHistory()

  const { id } = useParams<{ id }>()

  const { admins } = useSelector(getRolesSelector)

  useEffect(() => {
    if (_.isArray(admins.data)) {
      const adminData = admins.data.find(obj => {
        return obj._id === id
      })
      setAdmin({
        name: adminData.name,
        email: adminData.email,
        role: adminData.role ? adminData.role.title : '',
        created: adminData.createdAt,
        photo: adminData.photo ? adminData.photo : '',
        id: adminData._id,
      })
    }
  }, [admins])

  const onDeleteUser = () => {
    dispatch(deleteUserAction.request({ id: admin.id }))
    history.goBack()
  }

  return (
    <>
      <RowWrapper>
        <RowStartWrapper width="auto">
          <ColumnFlex>
            <GrayTitle>{admin.name}</GrayTitle>
            <SimpleDivider height="4px" />
            <div className="date">
              Дата создания: {defaultFormatDate(admin.created)}
            </div>
          </ColumnFlex>

          <LinkWithIcon
            style={{ paddingBottom: '20px' }}
            onClick={() => history.goBack()}
          >
            <img src={Assets.ARROW_BACK_ICON} alt="" />
            Назад
          </LinkWithIcon>
        </RowStartWrapper>

        <BtnWrapper>
          <DumbButton
            bg={Colors.red}
            txt={Colors.white}
            padding="11px 20px"
            onClick={() => onDeleteUser()}
          >
            <img src={Assets.DELETE_WHITE_ICON} alt="delete" />
            Удалить профиль
          </DumbButton>
        </BtnWrapper>
      </RowWrapper>

      <SimpleDivider height="22px" />

      <ColumnWrapper padding="30px">
        <UserProfileContent>
          <UserImage
            src={admin.photo ? `${api.images}${admin.photo}` : Assets.USER_ICON}
          ></UserImage>

          <SimpleDivider height="28px" />

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>Имя</ProfileInputLabel>
            <ProfileInput value={admin.name} readOnly />
          </ProfileInputWrapper>

          <SimpleDivider height="10px" />

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>E-mail</ProfileInputLabel>
            <ProfileInput value={admin.email} readOnly />
          </ProfileInputWrapper>

          <SimpleDivider height="10px" />

          <ProfileInputWrapper maxWidth="300px">
            <ProfileInputLabel>Роль</ProfileInputLabel>
            <ProfileInput value={admin.role} readOnly />
            {/* <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // onChange={handleChange}
              style={{ width: '100%', height: '40px' }}
            >
              <MenuItem value={'manager'}>Менеджер</MenuItem>
              <MenuItem value={'admin'}>Администратор</MenuItem>
            </Select> */}
          </ProfileInputWrapper>
        </UserProfileContent>
      </ColumnWrapper>
    </>
  )
}

export default UserProfile
