import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetBrandsActionResponse,
  GetBrandActionResponse,
  GetBrandActionPayload,
  CreateBrandActionPayload,
  CreateBrandActionResponse,
  DeleteBrandActionPayload,
} from './types'

export const getBrandsAction = createApiActions<
  void,
  GetBrandsActionResponse,
  AxiosError
>(createActionTypes('BRAND/GET_BRANDS'))

export const getBrandAction = createApiActions<
  GetBrandActionPayload,
  GetBrandActionResponse,
  AxiosError
>(createActionTypes('BRAND/GET_BRAND'))

export const createBrandAction = createApiActions<
  CreateBrandActionPayload,
  CreateBrandActionResponse,
  AxiosError
>(createActionTypes('BRAND/CREATE_BRAND'))

export const deleteBrandAction = createApiActions<
  DeleteBrandActionPayload,
  void,
  AxiosError
>(createActionTypes('BRAND/DELETE_BRAND'))
