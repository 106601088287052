import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import { GetAllNotificationsActionResponse } from './types'

export const getAllNotificationsAction = createApiActions<
  void,
  GetAllNotificationsActionResponse,
  AxiosError
>(createActionTypes('NOTIFICATIONS/GET_ALL_BY_ID'))

export const setNotificationsCounterAction = createAction<
  void,
  'NOTIFICATIONS/SET_NOTIFICATIONS_COUNTER'
>('NOTIFICATIONS/SET_NOTIFICATIONS_COUNTER')

export const resetNotificationsCounterAction = createAction<
  void,
  'NOTIFICATIONS/RESET_NOTIFICATIONS_COUNTER'
>('NOTIFICATIONS/RESET_NOTIFICATIONS_COUNTER')
