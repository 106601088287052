import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  createColorAction,
  deleteColorAction,
  getColorsAction,
  getColorAction,
} from './actions'

const InitialState: TInitialState = {
  colors: [],
  color: null,
  loading: false,
}

export const colorsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getColorsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getColorsAction.success, (state, { payload }) => ({
      ...state,
      colors: payload.data,
      loading: false,
    }))
    builder.addCase(getColorsAction.failure, (state, { payload }) => ({
      ...state,
      colors: [],
      loading: false,
    }))

    builder.addCase(getColorAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getColorAction.success, (state, { payload }) => ({
      ...state,
      color: payload.data,
      loading: false,
    }))
    builder.addCase(getColorAction.failure, (state, { payload }) => ({
      ...state,
      color: null,
      loading: false,
    }))

    builder.addCase(createColorAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createColorAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createColorAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteColorAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteColorAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteColorAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
  },
)
