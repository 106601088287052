import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Assets, Colors } from '../../assets'
import {
  ModalButton,
  SubcategoryModalContent,
  ModalOverlay,
  ModalColumn,
  ModalFlexWrapper,
  CreateModalButton,
  ModalInputWrapper,
  UploadButton,
  BorderDumbButton,
  FlexColumn,
  MainTitle,
  SecTitle,
} from './styled'
import {
  createSubcategory,
  createSubcategoryList,
  deleteSubcategory,
} from './api'
import { useTypedSelector } from '../../hooks'
import ReactModal from 'react-modal'
import { useMutation } from 'react-query'
import { SimpleDivider, StyledWidthWrapper } from '../Styled/formStyled'
import { DumbButton } from '../FormComponents/styled'
import { api, showToastAction } from '../../store'
import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { ISubcategory } from './types'

import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { subcategorySchema } from '../../validation'
import {
  FakeButton,
  HiddenButton,
  SmartInput,
  StyledInputFieldsWrapper,
  StyledInputFieldWidthWrapper,
} from '..'

import { v4 } from 'uuid'
import { ButtonWrapper } from '../Form'
import { StyledViewButton } from '../Table'
import { langValuesSchema } from '../../validation/lang-values.validation'

interface IModal {
  init?: any
  type: 'add' | 'edit'
  func?: any
}

const RangeModal: FC<IModal> = ({ type, init = null, func }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [initData, setInitData] = useState(null)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(langValuesSchema),
  })

  useEffect(() => {
    if (init) {
      Object.keys(init).forEach((el: any) => {
        setValue(el, init[el])
      })

      setInitData(init)
    }
  }, [init])

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const onSubmitSubcategory = (e: any) => {
    e.preventDefault()
    handleSubmit(data => {
      const { en, ru, uz, from, to, _id } = data

      func({
        en,
        ru,
        uz,
        possibleValues: [from, to],
        _id: !_id ? v4() : _id,
      })
    })(e)
    if (type === 'add') reset()
    toggleModal()
  }

  const onClose = () => {
    if (type === 'add') reset()
    toggleModal()
  }

  return (
    <div>
      {type === 'add' ? (
        <CreateModalButton
          style={{}}
          type="button"
          onClick={() => toggleModal()}
        >
          Добавить
        </CreateModalButton>
      ) : (
        <StyledViewButton onClick={() => toggleModal()}>
          <img height="24px" width="24px" src={Assets.SHOW_PASSWORD_ICON} />
        </StyledViewButton>
      )}

      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => toggleModal()}
        shouldCloseOnOverlayClick
        className="_"
        overlayClassName="_"
        contentElement={(props, children) => (
          <SubcategoryModalContent {...props}>
            {children}
          </SubcategoryModalContent>
        )}
        overlayElement={(props, contentElement) => (
          <ModalOverlay {...props}>{contentElement}</ModalOverlay>
        )}
      >
        <FlexColumn>
          <MainTitle>Характеристики</MainTitle>
          <SimpleDivider height="10px" />
          <SecTitle>Значения от/до</SecTitle>
        </FlexColumn>
        <ModalFlexWrapper>
          <form onSubmit={e => onSubmitSubcategory(e)}>
            <StyledInputFieldsWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="English"
                  name="en"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Русский"
                  name="ru"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Ozbek"
                  name="uz"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="От"
                  name="from"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="До"
                  name="to"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%" />
            </StyledInputFieldsWrapper>
            <HiddenButton id="hiddenCategoryListSubmit">submit</HiddenButton>
          </form>
        </ModalFlexWrapper>
        <ButtonWrapper>
          <BorderDumbButton
            brd={Colors.boldGray}
            txt={Colors.boldGray}
            bg={Colors.white}
            onClick={onClose}
            type="button"
          >
            Отмена
          </BorderDumbButton>
          <FakeButton htmlFor="hiddenCategoryListSubmit">Создать</FakeButton>
        </ButtonWrapper>
      </ReactModal>
    </div>
  )
}

export default RangeModal
