import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Assets, Colors } from '../../assets'
import {
  ModalButton,
  SubcategoryModalContent,
  ModalOverlay,
  ModalColumn,
  ModalFlexWrapper,
  CreateModalButton,
  ModalInputWrapper,
  UploadButton,
  Clickable,
  ScrollBox,
  BorderDumbButton,
} from './styled'
import { Image } from '../../components/FormComponents'
import { useTypedSelector } from '../../hooks'
import ReactModal from 'react-modal'
import { useMutation } from 'react-query'
import { SimpleDivider, StyledWidthWrapper } from '../Styled/formStyled'
import { DumbButton } from '../FormComponents/styled'
import { api, showToastAction, updateSubcategoryImageAction } from '../../store'
import {
  generateErrorToast,
  generateSuccessToast,
  linkImage,
} from '../../helpers'
import { ISubcategory } from './types'

import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { subcategorySchema } from '../../validation'
import {
  FakeButton,
  HiddenButton,
  SmartInput,
  StyledInputFieldsWrapper,
  StyledInputFieldWidthWrapper,
} from '..'
import InvisibleInput from '../Input/InvisibleInput'
import { v4 } from 'uuid'
import { MdModeEdit, MdDeleteOutline } from 'react-icons/md'
import { StyledViewButton } from '../Table'
import { ButtonWrapper } from '../Form'

interface IModal {
  title?: string
  editSubcategory: any
  initData?: any
  imageUpdate?: boolean
}

const NewSubcategoryEditModal: FC<IModal> = ({
  title,
  editSubcategory,
  initData = null,
  imageUpdate = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState<any>({ src: '', file: null })

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(subcategorySchema),
  })

  useEffect(() => {
    if (initData) {
      setValue('en', initData.en)
      setValue('ru', initData.ru)
      setValue('uz', initData.uz)
      setImage({
        src: `${api.images}${initData?.image}`,
        file: null,
      })
    }
  }, [initData])

  const dispatch = useDispatch()

  const { token } = useTypedSelector(state => state.user)

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const handleSubcatImage = (e: any) => {
    setImage({
      src: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    })
    const data = new FormData()
    data.append('image', e.target.files[0])
    dispatch(updateSubcategoryImageAction.request({ id: initData._id, data }))
    e.target.value = null
  }

  const onSubmitSubcategoryList = (e: any) => {
    e.preventDefault()
    handleSubmit(data => {
      const { en, ru, uz } = data
      editSubcategory({
        en,
        ru,
        uz,
        _id: initData._id,
      })
    })(e)
    toggleModal()
  }

  const showToast = (msg: string, type: 'error' | 'success') => {
    switch (type) {
      case 'error':
        dispatch(showToastAction.request(generateErrorToast(msg)))
        break
      case 'success':
        dispatch(showToastAction.request(generateSuccessToast(msg)))
        break
    }
  }

  const onClose = () => {
    // reset()
    // reset2()
    // setImage(null)
    // setList([])
    toggleModal()
  }

  return (
    <div>
      <StyledViewButton onClick={() => toggleModal()}>
        <img height="24px" width="24px" src={Assets.SHOW_PASSWORD_ICON} />
      </StyledViewButton>
      <ReactModal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={() => toggleModal()}
        shouldCloseOnOverlayClick
        className="_"
        overlayClassName="_"
        contentElement={(props, children) => (
          <SubcategoryModalContent {...props}>
            {children}
          </SubcategoryModalContent>
        )}
        overlayElement={(props, contentElement) => (
          <ModalOverlay {...props}>{contentElement}</ModalOverlay>
        )}
      >
        <div style={{ display: 'flex' }} className="row">
          <h1>Изменение подкатегории</h1>
        </div>
        <ModalFlexWrapper>
          <form onSubmit={e => onSubmitSubcategoryList(e)}>
            <StyledInputFieldsWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="English"
                  name="en"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Русский"
                  name="ru"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="30%">
                <SmartInput
                  register={register}
                  title="Ozbek"
                  name="uz"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
            </StyledInputFieldsWrapper>
            {imageUpdate ? (
              <>
                <SimpleDivider height="20px" />
                <StyledWidthWrapper width="40%">
                  <img
                    style={{ width: '200px', height: '200px' }}
                    src={image?.src}
                  />
                  <UploadButton
                    style={{ margin: '10px 0' }}
                    htmlFor="hiddenSubcategoryImage"
                  >
                    Загрузить фото
                  </UploadButton>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    id="hiddenSubcategoryImage"
                    onChange={e => handleSubcatImage(e)}
                  />
                </StyledWidthWrapper>
              </>
            ) : (
              <></>
            )}

            <HiddenButton id="hiddenCategoryListSubmit">submit</HiddenButton>
          </form>
        </ModalFlexWrapper>
        <ButtonWrapper>
          <BorderDumbButton
            brd={Colors.boldGray}
            txt={Colors.boldGray}
            bg={Colors.white}
            onClick={onClose}
            type="button"
          >
            Отмена
          </BorderDumbButton>
          <FakeButton htmlFor="hiddenCategoryListSubmit">Изменить</FakeButton>
        </ButtonWrapper>
      </ReactModal>
    </div>
  )
}

export default NewSubcategoryEditModal
