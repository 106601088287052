import styled from 'styled-components'
import { Colors } from '../../assets'
import { FLEX } from '../../utils'

export const NotificationsWrapper = styled.div`
  ${FLEX({
    direction: 'column-reverse',
    wrap: 'nowrap',
    justify: 'flex-start',
  })};
  background: ${Colors.white};
  padding: 30px 0;
  border-radius: 14px;
  box-shadow: 0px 8px 20px #81818129;
  height: fit-content;
  max-height: 650px;
  overflow-y: auto;
  width: 500px;
  position: absolute;
  top: 40px;
  right: -20px;
  z-index: 500;
  opacity: 0;
  pointer-events: none;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
`
export const NotificationsRelativeContainer = styled.div`
  position: relative;
`
export const NotificationsCounter = styled.div`
  ${FLEX({ justify: 'center', align: 'center' })};
  position: absolute;
  top: -3px;
  right: -5px;
  border-radius: 50%;
  background: ${Colors.red};
  color: ${Colors.white};
  width: 16px;
  height: 16px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 600;
`

export const Notification = styled.div`
  background: ${Colors.white};
  padding: 20px 30px;
  border-bottom: 1px solid ${Colors.lightGray};
  width: 100%;
  gap: 20px;
  ${FLEX({ wrap: 'nowrap', justify: 'flex-start', align: 'center' })};
  &:last-child {
    border-top: 1px solid ${Colors.lightGray};
  }
  .placeholder {
    width: 63px;
  }
  img {
    width: 100%;
    max-width: 63px;
    max-height: 63px;
    object-fit: cover;
  }
  .data-container {
    ${FLEX({
      wrap: 'nowrap',
      justify: 'space-between',
      direction: 'column',
      align: 'flex-start',
    })};
    width: 100%;
    max-width: 260px;
    overflow: hidden;
    gap: 5px;
  }
  .name {
    font-weight: 600;
  }
  .content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 25px;
  }
  .time {
    color: ${Colors.boldGray};
  }
  &:hover {
    background: ${Colors.lightOrange};
  }
`
