export const api = {
  images: `https://preproduction-misslight.kitg.com.ua/images/`,
  chatFiles: `https://preproduction-misslight.kitg.com.ua/`,
  withAPI: `https://preproduction-misslight.kitg.com.ua/admin/api`,
  socket: `wss://preproduction-misslight.kitg.com.ua:5006`,
}

// export const api = {
//   images: `http://144.76.103.235:5005/images/`,
//   chatFiles: `http://144.76.103.235:5005/`,
//   withAPI: `http://144.76.103.235:5005/admin/api`,
//   socket: `http://144.76.103.235:5024/`,
// }
