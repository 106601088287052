import { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets } from '../../assets'
import { formatDate, formatTime } from '../../helpers'
import { ItemNavLink } from '../../pages/Roles/styled'
import { api } from '../../store'
import {
  getNotificationsSelector,
  resetNotificationsCounterAction,
} from '../../store/notifications'
import { Notification, NotificationsWrapper } from './styled'

interface NotificationsProps {
  visibility: boolean
  setVisibility: (visibility) => void
}

const Notifications: FC<NotificationsProps> = ({
  visibility,
  setVisibility,
}) => {
  const notificationsRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()

  const { notifications } = useSelector(getNotificationsSelector)

  useEffect(() => {
    document.body.addEventListener('click', closeNotificationsModal)

    return () => {
      document.body.removeEventListener('click', closeNotificationsModal)
    }
  }, [visibility])

  useEffect(() => {
    if (!visibility) {
      notificationsRef.current &&
        notificationsRef.current.scrollTo({
          top: -100000,
          left: 0,
        })
    }

    if (visibility) {
      dispatch(resetNotificationsCounterAction())
    }
  }, [visibility])

  const closeNotificationsModal = (event: any) => {
    if (visibility) {
      const path = event.path || (event.composedPath && event.composedPath())
      if (!path.includes(notificationsRef.current)) {
        setVisibility(false)
      }
    }
  }

  return (
    <NotificationsWrapper
      className={visibility ? 'active' : ''}
      ref={notificationsRef}
    >
      {notifications.map(notification => (
        <Notification key={notification._id}>
          {notification.type === 'order' && (
            <img
              src={`${api.images}${notification?.order?.items[0]?.product?.images[0]}`}
              alt="preview"
            />
          )}
          {notification.type === 'message' && (
            <img
              src={`${api.images}${notification?.chat?.product?.images[0]}`}
              alt="preview"
            />
          )}
          {notification.type === 'new_chat' && (
            <img
              src={`${api.images}${
                notification?.chat?.product?.images &&
                notification?.chat?.product?.images[0]
              }`}
              alt="preview"
            />
          )}
          {notification?.product && (
            <img
              src={`${api.images}${notification.product.images[0]}`}
              alt="preview"
            />
          )}
          {notification?.comment &&
            (notification?.comment?.image ? (
              <img
                src={`${api.images}${notification?.comment?.image}`}
                alt="preview"
              />
            ) : (
              <div className="placeholder"></div>
            ))}
          {notification?.type === 'support' && (
            <div className="placeholder"></div>
          )}
          <div className="data-container">
            <div className="name">
              {notification.type === 'order' && 'Новый заказ'}
              {notification.type === 'message' && 'Новое сообщение'}
              {notification.type === 'new_chat' && 'Новый чат'}
              {notification.type === 'product_pending' && 'Новый товар'}
              {notification.type === 'product_verified' && 'Новый товар'}
              {notification.type === 'support' && 'Поддержка'}
              {notification.type === 'comment' && 'Новый отзыв'}
            </div>
            <div className="content">
              {notification?.order && notification.order?.items[0]?.product?.ru}
              {notification?.chat && notification.chat?.product?.ru}
              {notification?.product && notification.product?.ru}
              {notification?.support && notification.support?.message}
              {notification?.comment && notification?.comment?.comment}
            </div>
            <div className="time">{`${formatDate(
              notification.createdAt,
            )} ${formatTime(notification?.createdAt)}`}</div>
          </div>
          {notification.type === 'message' && (
            <ItemNavLink
              to={`/chat?notification=${notification.chat._id}`}
              className="justify-end"
              onClick={() => setVisibility(false)}
            >
              <img
                src={Assets.SHOW_PASSWORD_ICON}
                alt=""
                style={{ width: '22px' }}
              />
            </ItemNavLink>
          )}
          {notification.type === 'new_chat' && (
            <ItemNavLink
              to={`/chat?newChatNotification=${notification.chat._id}`}
              className="justify-end"
              onClick={() => setVisibility(false)}
            >
              <img
                src={Assets.SHOW_PASSWORD_ICON}
                alt=""
                style={{ width: '22px' }}
              />
            </ItemNavLink>
          )}
          {notification?.product && (
            <ItemNavLink
              to={`/products/${notification.product._id}`}
              className="justify-end"
              onClick={() => setVisibility(false)}
            >
              <img
                src={Assets.SHOW_PASSWORD_ICON}
                alt=""
                style={{ width: '22px' }}
              />
            </ItemNavLink>
          )}
          {notification.type === 'order' && (
            <ItemNavLink
              to={`/orders/${notification.order._id}`}
              className="justify-end"
              onClick={() => setVisibility(false)}
            >
              <img
                src={Assets.SHOW_PASSWORD_ICON}
                alt=""
                style={{ width: '22px' }}
              />
            </ItemNavLink>
          )}
          {notification.type === 'support' && (
            <ItemNavLink
              to={`/help?id=${notification?.support?._id}`}
              className="justify-end"
              onClick={() => setVisibility(false)}
            >
              <img
                src={Assets.SHOW_PASSWORD_ICON}
                alt=""
                style={{ width: '22px' }}
              />
            </ItemNavLink>
          )}
          {notification.type === 'comment' && (
            <ItemNavLink
              to={`/feedback/${notification?.comment?._id}`}
              className="justify-end"
              onClick={() => setVisibility(false)}
            >
              <img
                src={Assets.SHOW_PASSWORD_ICON}
                alt=""
                style={{ width: '22px' }}
              />
            </ItemNavLink>
          )}
        </Notification>
      ))}
      {notifications.length < 1 && 'Пока нет уведомлений'}
    </NotificationsWrapper>
  )
}

export default Notifications
