import { useEffect, useState } from 'react'
import { ICategory } from '../Category/types'
import {
  Container,
  Header,
  TitleGroup,
  Title,
  BackButton,
  CreationDate,
  Content,
  Column,
  DeleteButton,
  SimpleDivider,
  FullWidth,
  StyledWidthWrapper,
} from '../../components/Styled/formStyled'
import {
  FakeButton,
  HiddenButton,
  SmartInput,
  StyledInputFieldsWrapper,
  StyledInputFieldWidthWrapper,
} from '../../components'
import { Assets } from '../../assets'
import { format } from 'date-fns'
import { useHistory, useParams } from 'react-router-dom'
import { Slide, Image } from '../../components/FormComponents'
import { UploadButton } from '../../components/FormComponents/styled'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { categorySchema } from '../../validation'
import { IImage } from './types'
import { useDispatch } from 'react-redux'
import {
  getCategoryAction,
  pullEntityCategoryAction,
  showToastAction,
  updateCategoryAction,
  pushEntityCategoryAction,
  updateCategoryImageAction,
  deleteCategoryAction,
  editEntityCategoryAction,
} from '../../store'
import {
  generateErrorToast,
  generateSuccessToast,
  linkImage,
} from '../../helpers'
import { useTypedSelector } from '../../hooks'
import { CategoryDivider } from './styled'
import CategoryData from './CategoryData'

const Category = () => {
  const { id } = useParams<{ id: string }>()

  const [category, setCategory] = useState<ICategory>({
    en: '',
    ru: '',
    uz: '',
    position: '',
    status: '',
    subcategories: [],
    characteristics: [],
    createdAt: '',
  })

  const [image, setImage] = useState<IImage>({ src: '', file: null })
  const [categoryStatus, setCategoryStatus] = useState('')
  const [categoryCharacteristics, setCategoryCharacteristics] = useState<any[]>(
    [],
  )

  const [rngCategoryCharacteristics, setRngCategoryCharacteristics] = useState<
    any[]
  >([])

  const [fxdCategoryCharacteristics, setFxdCategoryCharacteristics] = useState<
    any[]
  >([])

  const [categorySubcategories, setCategorySubcategories] = useState<any[]>([])
  const { category: cat } = useTypedSelector(state => state.categories)

  console.log('CATEGORY', cat)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCategoryAction.request({ id }))
  }, [])

  useEffect(() => {
    if (cat) {
      const {
        ru,
        en,
        uz,
        position,
        createdAt,
        image,
        status,
        subcategories,
        textCharacteristics,
        rangeCharacteristics,
      } = cat

      setCategory(prev => ({
        ...prev,
        ru,
        en,
        uz,
        position,
        createdAt,
      }))

      setValue('ru', ru)
      setValue('en', en)
      setValue('uz', uz)
      setValue('position', position)

      setCategoryStatus(status === 'show' ? 'Да' : 'Нет')

      setImage(prev => ({ ...prev, src: linkImage(image) }))

      if (subcategories && subcategories.length !== 0) {
        setCategorySubcategories(
          subcategories.map(({ en, ru, uz, image, _id }: any) => ({
            en,
            ru,
            uz,
            _id,
            image,
          })),
        )
      }

      setRngCategoryCharacteristics(
        rangeCharacteristics.map(
          ({ en, ru, uz, possibleValues, _id }: any) => ({
            en,
            ru,
            uz,
            possibleValues,
            _id,
          }),
        ),
      )
      setFxdCategoryCharacteristics(
        textCharacteristics.map(({ en, ru, uz, possibleValues, _id }: any) => ({
          en,
          ru,
          uz,
          possibleValues: possibleValues.map((m: any) => ({
            en: m.en,
            ru: m.ru,
            uz: m.uz,
            _id: m._id,
          })),
          _id,
        })),
      )

      // setCategoryCharacteristics(formattedCharacteristics)
    }
  }, [cat])

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(categorySchema),
  })

  const handleImageRemove = () => {
    setImage({ file: null, src: '' })
  }

  const handleImageAdd = (e: any) => {
    console.log(e)
    dispatch(updateCategoryImageAction.request({ id, file: e.target.files[0] }))
  }

  const history = useHistory()

  const onBack = () => {
    history.push('/categories')
  }

  const handleImage = (e: any) => {
    setImage({
      file: e.target.files[0],
      src: URL.createObjectURL(e.target.files[0]),
    })
    e.target.value = null
  }

  const handleDelete = () => {
    if (window.confirm('Вы уверены?')) {
      dispatch(deleteCategoryAction.request({ id }))
      history.push('/categories/')
    }
  }

  const showToast = (msg: string, type: 'error' | 'success') => {
    switch (type) {
      case 'error':
        dispatch(showToastAction.request(generateErrorToast(msg)))
        break
      case 'success':
        dispatch(showToastAction.request(generateSuccessToast(msg)))
        break
    }
  }

  const handleCategory = (formValues: any) => {
    const { en, ru, uz, position, status } = formValues
    console.log(status)
    if (window.confirm('Вы уверены?')) {
      dispatch(
        updateCategoryAction.request({
          data: {
            en,
            ru,
            uz,
            position,
            status: categoryStatus === 'Да' ? 'show' : 'hide',
            subcategories: categorySubcategories,
            rangeCharacteristics: rngCategoryCharacteristics,
            // textCharacteristics: fxdCategoryCharacteristics,
          },
          id,
        }),
      )
      history.push('/categories')
    }
  }

  const addFxdChar = (rangeChar: any) => {
    // setFxdCategoryCharacteristics(prev => [...prev, rangeChar])
    delete rangeChar._id
    rangeChar.possibleValues = rangeChar.possibleValues.map((el: any) => {
      delete el._id
      return el
    })
    dispatch(
      pushEntityCategoryAction.request({
        id,
        data: rangeChar,
        path: 'textCharacteristics',
      }),
    )
  }

  const deleteFxdChar = (tempId: any) => {
    setFxdCategoryCharacteristics(prev => {
      return prev.filter(f => f._id !== tempId)
    })
    dispatch(
      pullEntityCategoryAction.request({
        catId: id,
        entId: tempId,
        path: 'textCharacteristics',
      }),
    )
  }

  const editFxdChar = (data: any) => {
    const ls = [...fxdCategoryCharacteristics]
    const index = ls.findIndex((i: any) => i._id === data._id)
    ls[index] = data
    setFxdCategoryCharacteristics(ls)
    dispatch(
      editEntityCategoryAction.request({
        id: data._id,
        data: data,
        path: 'textCharacteristics',
      }),
    )
  }

  const addRangeChar = (rangeChar: any) => {
    setRngCategoryCharacteristics(prev => [...prev, rangeChar])
    delete rangeChar._id
    dispatch(
      pushEntityCategoryAction.request({
        id,
        data: rangeChar,
        path: 'rangeCharacteristics',
      }),
    )
  }

  const deleteRangeChar = (tempId: any) => {
    setRngCategoryCharacteristics(prev => {
      return prev.filter(f => f._id !== tempId)
    })
    dispatch(
      pullEntityCategoryAction.request({
        catId: id,
        entId: tempId,
        path: 'rangeCharacteristics',
      }),
    )
  }

  const editRangeChar = (data: any) => {
    const ls = [...rngCategoryCharacteristics]
    const index = ls.findIndex((i: any) => i._id === data._id)
    ls[index] = data
    console.log()
    setRngCategoryCharacteristics(ls)
    dispatch(
      editEntityCategoryAction.request({
        id: data._id,
        data: data,
        path: 'rangeCharacteristics',
      }),
    )
  }

  const addSubcategory = (subcat: any) => {
    // setCategorySubcategories(prev => [...prev, subcat])
    delete subcat._id
    dispatch(
      pushEntityCategoryAction.request({
        id,
        data: subcat,
        path: 'subcategories',
      }),
    )
  }

  const deleteSubcategory = (tempId: any) => {
    // setCategorySubcategories(prev => {
    //   return prev.filter(f => f._id !== tempId)
    // })
    dispatch(
      pullEntityCategoryAction.request({
        catId: id,
        entId: tempId,
        path: 'subcategories',
      }),
    )
  }

  const editSubcategory = (data: any) => {
    const ls = [...categorySubcategories]
    const index = ls.findIndex((i: any) => i._id === data._id)
    ls[index] = data
    setCategorySubcategories(ls)
    dispatch(
      editEntityCategoryAction.request({
        id: data._id,
        data: data,
        path: 'subcategories',
      }),
    )
  }

  const statusOptions = ['Да', 'Нет']

  return (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>{category.ru}</Title>
            <BackButton onClick={onBack}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
          <CreationDate>
            Дата создания:{' '}
            {category.createdAt
              ? format(new Date(category.createdAt) as Date, 'dd.MM.yyyy')
              : ''}
          </CreationDate>
        </TitleGroup>
        <div style={{ display: 'flex' }}>
          <DeleteButton onClick={handleDelete}>Удалить</DeleteButton>
          <SimpleDivider width="20px" />
          <FakeButton htmlFor="hiddenSave">Сохранить</FakeButton>
        </div>
      </Header>
      <Content>
        <FullWidth>
          <form onSubmit={handleSubmit(data => handleCategory(data))}>
            <StyledInputFieldsWrapper>
              <StyledInputFieldWidthWrapper width="20%">
                <SmartInput
                  register={register}
                  title="English"
                  name="en"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>

              <StyledInputFieldWidthWrapper width="20%">
                <SmartInput
                  register={register}
                  title="Русский"
                  name="ru"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>

              <StyledInputFieldWidthWrapper width="20%">
                <SmartInput
                  register={register}
                  title="Ozbek"
                  name="uz"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="25%" />
            </StyledInputFieldsWrapper>
            <StyledInputFieldsWrapper>
              <StyledInputFieldWidthWrapper width="20%">
                <SmartInput
                  register={register}
                  title="Позиция"
                  name="position"
                  error={errors}
                />
              </StyledInputFieldWidthWrapper>

              <StyledInputFieldWidthWrapper width="20%">
                <Slide
                  label="Отображать"
                  options={statusOptions}
                  selected={categoryStatus}
                  select={setCategoryStatus}
                />
              </StyledInputFieldWidthWrapper>
              <StyledInputFieldWidthWrapper width="50%" />
            </StyledInputFieldsWrapper>
            <HiddenButton id="hiddenSave" />
            <StyledWidthWrapper width="40%">
              <Image src={image?.src} handleImage={handleImageRemove} />
              <UploadButton
                style={{ margin: '10px 0' }}
                htmlFor="hiddenCategoryImage"
              >
                Загрузить фото
              </UploadButton>
            </StyledWidthWrapper>
            <input
              style={{ margin: '10px 0', display: 'none' }}
              id="hiddenCategoryImage"
              type="file"
              onChange={e => handleImageAdd(e)}
            />
          </form>
        </FullWidth>
        <CategoryDivider />
        <CategoryData
          addFixed={addFxdChar}
          deleteFixed={deleteFxdChar}
          editFixed={editFxdChar}
          addSubcategory={addSubcategory}
          deleteSubcategory={deleteSubcategory}
          editSubcategory={editSubcategory}
          subcategoryData={categorySubcategories}
          characteristicsData={categoryCharacteristics}
          addRange={addRangeChar}
          deleteRange={deleteRangeChar}
          editRange={editRangeChar}
          rangeData={rngCategoryCharacteristics}
          fixedData={fxdCategoryCharacteristics}
        />
      </Content>
    </Container>
  )
}

export default Category
