import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTypedSelector } from '../../hooks'
import {
  getProductsAction,
  getPendingProductsAction,
  getUserSelector,
  getMyProductsAction,
  getMyPendingProductsAction,
  api,
} from '../../store'
import { Button, PageContainer } from '../../components'
import { Storage, CreateButtonContainer } from './styled'
import {
  StyledRow,
  StyledHeader,
  StyledTable,
  TableWrapper,
  FunctionalRow,
  ViewButton,
  SearchInput,
  HeaderItem,
} from '../../components'
import Table from 'rc-table'
import { Assets, Colors } from '../../assets'
import Checkbox from '../../components/Input/Checkbox'
import { Filter } from '../../components/Filter'
import { OrdersTab, OrdersTabs } from '../Orders/styled'
import { Pagination } from '../../components/Pagination'
import { IPagination } from '../../components/Pagination/types'
import { defaultFormatDate } from '../../helpers'

interface IProductInfo {
  _id: string
  images: []
  discount: number
  novelty: boolean
  en: string
  ru: string
  uz: string
  status: string
  price: number
  category: any
  description: string
  vendorCode: number
  characteristics: any
  stores: any
  createdAt: string
  updatedAt: string
  __v: number
  stars: number
  similar: any
  isPromo: boolean
}

const Products: FC = () => {
  const [productsInfo, setProductsInfo] = useState<IProductInfo[]>([])
  const [sorting, setSorting] = useState<string>('')
  const [search, setSearch] = useState('')
  const [tab, setTab] = useState<string>('all')
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const { products, filter } = useTypedSelector(state => state.products)

  const { user } = useSelector(getUserSelector)

  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  useEffect(() => {
    if (!checkPermission('productGetAll')) {
      if (tab === 'all') {
        dispatch(
          getMyProductsAction.request(
            `limit=${pagination.limit}&page=${pagination.skip + 1}${
              filter ? `&${filter}` : ''
            }${sorting ? `&sortBy=${sorting}` : ''}`,
          ),
        )
        return
      }
      dispatch(
        getMyPendingProductsAction.request(
          `limit=${pagination.limit}&page=${pagination.skip + 1}${
            filter ? `&${filter}` : ''
          }${sorting ? `&sortBy=${sorting}` : ''}`,
        ),
      )
      return
    }

    if (tab === 'all') {
      dispatch(
        getProductsAction.request(
          `limit=${pagination.limit}&page=${pagination.skip + 1}${
            filter ? `&${filter}` : ''
          }${sorting ? `&sortBy=${sorting}` : ''}`,
        ),
      )
      return
    }
    dispatch(
      getPendingProductsAction.request(
        `limit=${pagination.limit}&page=${pagination.skip + 1}${
          filter ? `&${filter}` : ''
        }${sorting ? `&sortBy=${sorting}` : ''}`,
      ),
    )
  }, [tab, pagination, filter, sorting])

  useEffect(() => {
    if (products.data) {
      setProductsInfo(products.data)
    }
  }, [products])

  const sortingArrow = (query: string) => {
    if (query === 'earlier') {
      return (
        <>
          {sorting === 'earlier' ? (
            <img src={Assets.SORT_ARROW_UP_ICON} />
          ) : (
            <img src={Assets.SORT_ARROW_ICON} />
          )}
        </>
      )
    }
    if (query === 'titleUp') {
      return (
        <>
          {sorting === 'titleUp' ? (
            <img src={Assets.SORT_ARROW_UP_ICON} />
          ) : (
            <img src={Assets.SORT_ARROW_ICON} />
          )}
        </>
      )
    }
    if (query === 'statusUp') {
      return (
        <>
          {sorting === 'statusUp' ? (
            <img src={Assets.SORT_ARROW_UP_ICON} />
          ) : (
            <img src={Assets.SORT_ARROW_ICON} />
          )}
        </>
      )
    }
  }

  const columns = [
    {
      title: 'Фото',
      dataIndex: 'photo',
      key: 'photo',
    },
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Артикул',
      dataIndex: 'article',
      key: 'article',
    },
    {
      title: (
        <HeaderItem onClick={() => handleAsyncSort('createdAt')}>
          Дата создания {sortingArrow('earlier')}
        </HeaderItem>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Склады',
      dataIndex: 'storage',
      key: 'storage',
    },
    {
      title: (
        <HeaderItem onClick={() => handleAsyncSort('status')}>
          Статус {sortingArrow('statusUp')}
        </HeaderItem>
      ),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Акция',
      dataIndex: 'promo',
      key: 'promo',
    },
    {
      dataIndex: 'viewButton',
      key: 'viewButton',
    },
  ]

  const handleAsyncSort = (sortingQuery: string) => {
    switch (sortingQuery) {
      case 'createdAt':
        setSorting(prev => {
          if (!prev) {
            return 'earlier'
          }
          if (prev === 'earlier') {
            return 'later'
          }
          return 'earlier'
        })

        break
      case 'title':
        setSorting(prev => {
          if (!prev) {
            return 'titleUp'
          }
          if (prev === 'titleUp') {
            return 'titleDown'
          }
          return 'titleUp'
        })

        break
      case 'category':
        setSorting(prev => {
          if (!prev) {
            return 'titleUp'
          }
          if (prev === 'titleUp') {
            return 'titleDown'
          }
          return 'titleUp'
        })

        break
      case 'status':
        setSorting(prev => {
          if (!prev) {
            return 'statusUp'
          }
          if (prev === 'statusUp') {
            return 'statusDown'
          }
          return 'statusUp'
        })

        break
    }
  }

  const onSearch = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(
        getProductsAction.request(
          `limit=${pagination.limit}&page=${pagination.skip + 1}${
            filter ? `&${filter}` : ''
          }${`&title=${e.target.value}`} `,
        ),
      )
    }
  }

  const data = productsInfo.map((i, index) => {
    let storage = ''
    if (i.stores) {
      storage = i.stores.map((s: any, index) => {
        if (s.count !== 0) {
          return (
            <Storage key={index}>
              <span style={{ fontWeight: 'bolder' }}>{s.count}x</span>{' '}
              {s.store ? s.store.country : ''} {s.store ? s.store.city : ''}{' '}
              {s.store ? s.store.street : ''}, {s.store ? s.store.home : ''}
            </Storage>
          )
        }
      })
    }

    const image = products ? (
      <img
        width="70px"
        height="70px"
        //@ts-ignore
        src={`${api.images}${i.images[0]}`}
      />
    ) : (
      ''
    )

    return {
      photo: image,
      title: i.ru,
      article: i.vendorCode,
      createdAt: defaultFormatDate(i.createdAt),
      category: i.category?.ru,
      storage: storage,
      status: i.status === 'in_stock' ? 'В наличии' : 'Под заказ',
      key: index,
      promo: (
        <Checkbox value={i.isPromo} onChange={() => {}} name="promo"></Checkbox>
      ),
      viewButton: (
        <ViewButton onClick={() => history.push(`/products/${i._id}`)}>
          <img height="24px" width="24px" src={Assets.SHOW_PASSWORD_ICON} />
        </ViewButton>
      ),
    }
  })

  const onTabSwitch = (query: string) => {
    setTab(query)
  }

  return (
    <PageContainer title={'Товары'}>
      <TableWrapper>
        <OrdersTabs>
          <OrdersTab
            onClick={() => onTabSwitch('all')}
            className={tab === 'all' ? 'selected' : ''}
          >
            Все товары
          </OrdersTab>
          <OrdersTab
            className={tab === 'pending' ? 'selected' : ''}
            onClick={() => onTabSwitch('pending')}
          >
            На рассмотрении
          </OrdersTab>
        </OrdersTabs>

        <FunctionalRow>
          <SearchInput
            value={search}
            onChange={e => setSearch(e.target.value)}
            type="text"
            placeholder="Название..."
            onKeyDown={e => onSearch(e)}
          />

          <div
            style={{
              fontWeight: 'bold',
              alignSelf: 'center',
              marginLeft: '20px',
            }}
          >
            Кол-во товаров:
            {products?.data &&
              (filter !== '' || search !== ''
                ? products?.data.length
                : products?.totalCount)}
            {products?.length && products?.length}
          </div>
          <CreateButtonContainer>
            <Button
              title="Добавить товар"
              onClick={() => history.push('/product-create')}
              theme={{ background: Colors.green, text: Colors.white }}
              icon={<img src={Assets.PLUS_WHITE_ICON} />}
            />
          </CreateButtonContainer>
        </FunctionalRow>

        <Filter
          dateProp={true}
          brandsProp={true}
          categoriesProp={true}
          subcategoriesProp={true}
          statusProp={true}
          priceProp={true}
          promoProp={true}
          products={true}
        />

        <Table
          columns={columns}
          data={data}
          components={{
            body: {
              row: StyledRow,
            },
            header: { cell: StyledHeader },
            table: StyledTable,
          }}
        />

        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={products?.totalCount}
        />
      </TableWrapper>
    </PageContainer>
  )
}

export default Products
