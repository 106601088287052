import {
  takeLatest,
  takeEvery,
  select,
  call,
  put,
} from '@redux-saga/core/effects'
import {
  createRoleAction,
  createUserAction,
  deleteUserAction,
  setAdminsAction,
  setRolesAction,
  updatePermissionsAction,
  updateRolesAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { AuthorizationError } from '../../errors'
import { getUserSelector } from './selectors'
import { PromiseReturnType } from '../types'
import { showToastAction } from '../toasts'
import { Log } from '../../utils'
import { RolesAPI } from './api.service'
import { AxiosError } from 'axios'
import { getUserAction, UserAPI } from '../user'

export function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getRolesWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof RolesAPI.getRoles>> =
      yield call([RolesAPI, RolesAPI.getRoles], { authorization: token })

    yield put(setRolesAction.success(response.data))
  } catch (e: any) {
    Log.ruddy('Error: getRolesWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения ролей')),
    )

    yield put(setRolesAction.failure(e))
  }
}

function* getAdminsWorker({
  payload,
}: ReturnType<typeof setAdminsAction['request']>) {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<ReturnType<typeof RolesAPI.getAdmins>> =
      yield call([RolesAPI, RolesAPI.getAdmins], {
        authorization: token,
        data: payload,
      })

    yield put(setAdminsAction.success(response.data))
  } catch (e: any) {
    Log.ruddy('Error: getAdminsWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения пользователей'),
      ),
    )

    yield put(setAdminsAction.failure(e))
  }
}

function* updatePermissionsWorker({
  payload,
}: ReturnType<typeof updatePermissionsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([RolesAPI, RolesAPI.updatePermissions], {
      authorization: token,
      data: payload,
    })

    const response: PromiseReturnType<ReturnType<typeof RolesAPI.getRoles>> =
      yield call([RolesAPI, RolesAPI.getRoles], { authorization: token })

    yield put(updatePermissionsAction.success(response.data))

    yield put(getUserAction.request())

    yield put(
      showToastAction.request(
        generateSuccessToast('Разрешения успешно изменены'),
      ),
    )
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка изменения разрешений'),
      ),
    )
    yield put(updatePermissionsAction.failure(e as AxiosError))
  }
}

function* updateRolesWorker({
  payload,
}: ReturnType<typeof updateRolesAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([RolesAPI, RolesAPI.updateRoles], {
      authorization: token,
      data: payload,
    })

    yield put(updateRolesAction.success())
    yield put(
      showToastAction.request(
        generateSuccessToast('Роль успешно отредактирована'),
      ),
    )
  } catch (e) {
    console.log('Error: updateRolesWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка редактирования роли')),
    )
    yield put(updateRolesAction.failure(e as AxiosError))
  }
}

function* createRoleWorker({
  payload,
}: ReturnType<typeof createRoleAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([RolesAPI, RolesAPI.createRole], {
      authorization: token,
      data: payload,
    })

    const response: PromiseReturnType<ReturnType<typeof RolesAPI.getRoles>> =
      yield call([RolesAPI, RolesAPI.getRoles], { authorization: token })

    yield put(createRoleAction.success(response.data))
    yield put(
      showToastAction.request(generateSuccessToast('Роль успешно создана')),
    )
  } catch (e) {
    console.log('Error: createRoleWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания роли')),
    )
    yield put(createRoleAction.failure(e as AxiosError))
  }
}

function* createUserWorker({
  payload,
}: ReturnType<typeof createUserAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([RolesAPI, RolesAPI.createUser], {
      authorization: token,
      data: payload,
    })

    yield put(createUserAction.success())
    yield put(
      showToastAction.request(
        generateSuccessToast('Пользователь усешно создан'),
      ),
    )
  } catch (e) {
    console.log('Error: createUserWorker', e)
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка создания пользователя'),
      ),
    )
    yield put(createUserAction.failure(e as AxiosError))
  }
}

function* deleteUserWorker({ payload }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
        getUserSelector,
    )

    yield call([RolesAPI, RolesAPI.deleteUser], {
      authorization: token,
      id: payload.id,
    })

    yield put(deleteUserAction.success())
  } catch (e: any) {
    console.log('Error: deleteUserWorker', e)
    yield put(
        showToastAction.request(generateErrorToast('Ошибка удаления пользователя')),
    )
    yield put(deleteUserAction.failure(e))
  }
}

export function* rolesWatcher() {
  yield takeLatest(setRolesAction.request, getRolesWorker)
  yield takeLatest(setAdminsAction.request, getAdminsWorker)
  yield takeLatest(updatePermissionsAction.request, updatePermissionsWorker)
  yield takeEvery(createRoleAction.request, createRoleWorker)
  yield takeEvery(updateRolesAction.request, updateRolesWorker)
  yield takeLatest(createUserAction.request, createUserWorker)
  yield takeLatest(deleteUserAction.request, deleteUserWorker)
}
