import { AxiosResponse } from 'axios'
import {
  CreateColorActionPayload,
  CreateColorActionResponse,
  GetColorsActionResponse,
} from './types'

import { HttpService } from '../../services'
import { COLORS_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class ColorAPI extends HttpService {
  static getColors(
    request: any,
  ): Promise<AxiosResponse<GetColorsActionResponse>> {
    return this.request<GetColorsActionResponse>('GET_COLORS', {
      url: COLORS_URL.rootPath,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getColor(
    request: any,
  ): Promise<AxiosResponse<GetColorsActionResponse>> {
    return this.request<GetColorsActionResponse>('GET_COLOR', {
      url: COLORS_URL.idPath(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createColor({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateColorActionResponse>> {
    console.log(data)
    return this.request<CreateColorActionResponse>('CREATE_COLOR', {
      url: COLORS_URL.rootPath,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
