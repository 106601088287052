import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTypedSelector } from '../../hooks'
import {
  getCustomersAction,
  getCustomerByPhoneAction,
  sortCustomersByQueryAction,
  getUserSelector,
  getMyCustomersAction,
} from '../../store'
import { PageContainer } from '../../components'
import {
  StyledRow,
  StyledHeader,
  StyledTable,
  TableWrapper,
  FunctionalRow,
  ViewButton,
  SearchInput,
  HeaderItem,
} from '../../components'
import Table from 'rc-table'
import { Assets } from '../../assets'
import { formatDate } from '../../helpers'
import { Pagination } from '../../components/Pagination'
import { IPagination } from '../../components/Pagination/types'

interface ITest {}

const Customers: FC<ITest> = () => {
  const [customersInfo, setCustomersInfo] = useState<any[]>([])
  const [sorting, setSorting] = useState({
    title: false,
    category: false,
    status: false,
    createdAt: false,
  })
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const { customers, loading, meta } = useTypedSelector(
    state => state.customers,
  )

  const { user } = useSelector(getUserSelector)

  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  useEffect(() => {
    if (!checkPermission('clientGetAll')) {
      dispatch(
        getMyCustomersAction.request(
          `?limit=${pagination.limit}&page=${pagination.skip + 1}$${
            sorting.createdAt ? `&sortBy=later` : `&sortBy=earlier`
          }`,
        ),
      )

      return
    }
    dispatch(
      getCustomersAction.request(
        `?limit=${pagination.limit}&page=${pagination.skip + 1}$${
          sorting.createdAt ? `&sortBy=later` : `&sortBy=earlier`
        }`,
      ),
    )
  }, [pagination, sorting])

  useEffect(() => {
    if (customers) {
      setCustomersInfo(customers)
    }
  }, [loading])

  const sortingArrow = (query: string) => {
    return (
      <>
        {/*@ts-ignore*/}
        {sorting[query] ? (
          <img src={Assets.SORT_ARROW_UP_ICON} />
        ) : (
          <img src={Assets.SORT_ARROW_ICON} />
        )}
      </>
    )
  }

  const columns = [
    {
      title: (
        <HeaderItem onClick={() => handleAsyncSort('createdAt')}>
          Дата регистрации {sortingArrow('createdAt')}
        </HeaderItem>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      // width: 100,
    },
    {
      title: 'Клиент',
      dataIndex: 'name',
      key: 'name',
      // width: 50,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      // width: 200,
    },
    {
      title: 'Дата рождения',
      dataIndex: 'birth',
      key: 'birth',
      // width: 200,
    },
    {
      title: 'Заказов',
      dataIndex: 'orders',
      key: 'orders',
      // width: 200,
    },
    {
      title: 'Общая сумма',
      dataIndex: 'sum',
      key: 'sum',
      // width: 200,
    },
    {
      dataIndex: 'viewButton',
      key: 'viewButton',
      // width: 50,
    },
  ]

  const data = () => {
    const formatFullDate = (unformated: string) => {
      const dateObj = new Date(unformated)
      const date = `${('0' + dateObj.getDate()).slice(-2)}.${(
        '0' + dateObj.getMonth()
      ).slice(-2)}.${dateObj.getFullYear()}
    ${('0' + dateObj.getHours()).slice(-2)}:${(
        '0' + dateObj.getMinutes()
      ).slice(-2)}`
      return date
    }

    return customersInfo.map((i, index) => {
      return {
        key: index,
        createdAt: formatDate(i.createdAt),
        name: i.name,
        phone: i.phone,
        birth: formatDate(i.dateOfBirth),
        orders: i?.ordersCount,
        sum: i?.ordersTotalSum,
        viewButton: (
          <ViewButton onClick={() => history.push(`/clients/${i._id}`)}>
            <img height="24px" width="24px" src={Assets.SHOW_PASSWORD_ICON} />
          </ViewButton>
        ),
      }
    })
  }

  const handleAsyncSort = (sortingQuery: string) => {
    switch (sortingQuery) {
      case 'createdAt':
        setSorting((prev: any) => {
          return {
            ...prev,
            createdAt: !sorting.createdAt,
          }
        })
        break
    }
  }

  const onSearch = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(getCustomerByPhoneAction.request({ phone: search }))
    }
  }

  return (
    <PageContainer title={'Клиенты'}>
      <TableWrapper>
        <FunctionalRow>
          <SearchInput
            value={search}
            onChange={e => setSearch(e.target.value)}
            onKeyDown={e => onSearch(e)}
            type="text"
            placeholder="Номер телефона..."
          />
        </FunctionalRow>
        <Table
          columns={columns}
          data={data()}
          components={{
            body: {
              row: StyledRow,
            },
            header: { cell: StyledHeader },
            table: StyledTable,
          }}
        />

        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={meta?.totalCount || 10}
        />
      </TableWrapper>
    </PageContainer>
  )
}

export default Customers
