import { AxiosResponse } from 'axios'
import {
  SetAllNewChatsActionPayload,
  SetAllNewChatsActionResponse,
  SetAllMyChatsActionPayload,
  SetAllMyChatsActionResponse,
  sendChatMessageActionResponse,
  getMessagesByIdActionResponse,
  acceptChatActionResponse,
  readMessagesActionResponse,
  readMessagesActionPayload,
  downloadFileActionResponse,
  SetMyChatsByQueryActionResponse,
  SetMyChatsByQueryActionPayload,
} from './types'

import { setAuthorizationHeadersProp } from '../../helpers'
import { HttpService } from '../../services'
import { CHAT_URL } from './config'
import { PayloadAuth } from '../types'

export class ChatAPI extends HttpService {
  static getAllNewChats({
    authorization,
    data,
  }): Promise<AxiosResponse<SetAllNewChatsActionResponse>> {
    return this.request<SetAllNewChatsActionResponse>('GET_ALL_NEW_CHATS', {
      url: CHAT_URL.get_all_chats(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getAllActiveChats(
    request: SetAllNewChatsActionPayload,
  ): Promise<AxiosResponse<SetAllNewChatsActionResponse>> {
    return this.request<SetAllNewChatsActionResponse>('GET_ALL_ACTIVE_CHATS', {
      url: CHAT_URL.get_all_active_chats,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getAllMyChats(
    request: SetAllMyChatsActionPayload,
  ): Promise<AxiosResponse<SetAllMyChatsActionResponse>> {
    return this.request<SetAllMyChatsActionResponse>('GET_ALL_MY_CHATS', {
      url: CHAT_URL.get_all_my_chats,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getMyChatsByQuery({
    authorization,
    data,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SetMyChatsByQueryActionResponse>
  > {
    return this.request<SetMyChatsByQueryActionResponse>(
      'GET_MY_CHATS_BY_QUERY',
      {
        url: CHAT_URL.get_my_chats_by_query(data),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static sendMessage({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<sendChatMessageActionResponse>> {
    return this.request<sendChatMessageActionResponse>('SEND_MESSAGE', {
      url: CHAT_URL.send_message,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getMessagesById({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<getMessagesByIdActionResponse>> {
    return this.request<getMessagesByIdActionResponse>('GET_MESSAGES_BY_ID', {
      url: CHAT_URL.get_messages_by_id(data),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static acceptChat({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<acceptChatActionResponse>> {
    return this.request<acceptChatActionResponse>('ACCEPT_CHAT', {
      url: CHAT_URL.accept_chat(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static closeChat({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<acceptChatActionResponse>> {
    return this.request<acceptChatActionResponse>('CLOSE_CHAT', {
      url: CHAT_URL.close_chat(data),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static readMessages({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<readMessagesActionResponse>> {
    return this.request<readMessagesActionResponse>('READ_MESSAGES', {
      url: CHAT_URL.read_messages(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static downloadFile({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<downloadFileActionResponse>> {
    return this.request<downloadFileActionResponse>('DOWNLOAD_FILE', {
      url: CHAT_URL.download_file(data),
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
