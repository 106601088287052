import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  createBrandAction,
  deleteBrandAction,
  getBrandsAction,
  getBrandAction,
} from './actions'

const InitialState: TInitialState = {
  brands: [],
  brand: null,
  loading: false,
}

export const brandsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getBrandsAction.request, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(getBrandsAction.success, (state, { payload }) => ({
      ...state,
      brands: payload.data,
      loading: false,
    }))
    builder.addCase(getBrandsAction.failure, (state, { payload }) => ({
      ...state,
      brands: [],
      loading: false,
    }))

    builder.addCase(getBrandAction.request, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(getBrandAction.success, (state, { payload }) => ({
      ...state,
      brand: payload.data,
      loading: false,
    }))
    builder.addCase(getBrandAction.failure, (state, { payload }) => ({
      ...state,
      brand: null,
      loading: false,
    }))

    builder.addCase(createBrandAction.request, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createBrandAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createBrandAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteBrandAction.request, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteBrandAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteBrandAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
  },
)
