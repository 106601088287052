import { Colors } from '../../assets'
import styled from 'styled-components'
import { FLEX, FONT, SHADOW } from '../../utils'
import { NavLink } from 'react-router-dom'

export const DateColumn = styled.div`
  ${FLEX({
    direction: 'column',
    justify: 'flex-start',
    wrap: 'nowrap',
    align: 'flex-start',
  })};
  gap: 10px;
`
export const DateRow = styled.div`
  ${FLEX({
    justify: 'flex-start',
    wrap: 'nowrap',
    align: 'center',
  })};
  gap: 12px;
  align-self: start;
`
export const DeleteBanner = styled.img`
  cursor: pointer;
`

export const ProductName = styled.div`
  width: 33%;
  ${FLEX({
    direction: 'column',
    align: 'center',
  })};
  line-height: 19px !important;
  padding-top: 12px;
  height: 40px;
  line-height: 12px;
  font-size: 14px;
  white-space: normal;
  overflow: hidden;
  -webkit-line-clamp: 2;
`

export const ProductsWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  height: 300px;
  &::-webkit-scrollbar {
    width: 12px;
  }
`

export const AddImage = styled.div`
  width: 147px;
  height: 100px;
  background: ${Colors.lightGray};
  label {
    cursor: pointer;

    ${FLEX({
      align: 'center',
      justify: 'center',
    })};
    width: 100%;
    height: 100%;
  }
  img {
    width: 40px;
  }
`

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000;
  background: ${Colors.white};
  display: none;
  &.open {
    display: block;
  }
`
