import React, { FC } from 'react'
import {
  StyledTable,
  StyledTableHead,
  StyledTableRow,
  StyledTH,
} from './styled'

interface ITableLayout {
  getTableProps: any
  getTableBodyProps: any
  headerGroups: any
  rows: any
  prepareRow: any
}

export const TableLayout: FC<ITableLayout> = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
}) => {
  return (
    <StyledTable {...getTableProps()}>
      <StyledTableHead>
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <StyledTH {...column.getHeaderProps()}>
                {column.render('Header')}
              </StyledTH>
            ))}
          </tr>
        ))}
      </StyledTableHead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, i: any) => {
          prepareRow(row)
          return (
            <StyledTableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </StyledTableRow>
          )
        })}
      </tbody>
    </StyledTable>
  )
}
