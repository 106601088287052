import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTypedSelector } from '../../hooks'
import {
  getFeedbackByIdAction,
  moderateFeedbackAction,
} from '../../store/feedback'
import {
  Container,
  Header,
  TitleGroup,
  Title,
  BackButton,
  CreationDate,
  Content,
  Column,
  SaveButton,
  SimpleDivider,
} from '../../components/Styled/formStyled'
import { DumbButton } from '../../components/FormComponents/styled'
import { Assets, Colors } from '../../assets'
import { defaultFormatDate } from '../../helpers'
import {
  Comment,
  FeedbackProduct,
  FeedbackUser,
  Image,
  MinorTitle,
  StarWrapper,
} from './styled'
import { Rating } from '@mui/material'
import { api } from '../../store'

export const FeedbackPage: FC<any> = ({}) => {
  const [feedback, setFeedback] = useState<any>(null)
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history = useHistory()

  const { feedback: feedbackData } = useTypedSelector(state => state.feedbacks)

  useEffect(() => {
    dispatch(getFeedbackByIdAction.request({ id }))
  }, [id])

  useEffect(() => {
    if (feedbackData) {
      setFeedback(feedbackData)
    }
  }, [feedbackData])

  const confirm = action => {
    if (window.confirm('Вы уверены?')) {
      action()
    }
  }

  return !feedback ? (
    <div>loading</div>
  ) : (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>Отзыв</Title>
            <BackButton onClick={() => history.goBack()}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
          <CreationDate>
            Дата создания:{' '}
            {feedbackData ? defaultFormatDate(feedbackData.createdAt) : ''}
          </CreationDate>
        </TitleGroup>
        <div style={{ display: 'flex' }}>
          <DumbButton
            bg={Colors.red}
            txt={Colors.white}
            onClick={() =>
              confirm(() =>
                dispatch(
                  moderateFeedbackAction.request({ id, type: 'delete' }),
                ),
              )
            }
          >
            Скрыть
          </DumbButton>
          <SimpleDivider width="10px" />
          <SaveButton htmlFor="hiddenSave">Опубликовать</SaveButton>
          {/* <button onClick={() => brandDeleteMutation.mutate()}>delete</button> */}
        </div>
      </Header>
      <Content>
        <Column>
          <FeedbackUser>
            <div>
              {`${feedback?.userId?.surname ? feedback.userId.surname : ''} 
              ${feedback?.userId?.name ? feedback.userId.name : ''} 
              ${feedback.userId.patronymic ? feedback.userId.patronymic : ''}`}
              <div>{feedback.userId.phone}</div>
            </div>
            <img
              src={Assets.ARROW_BACK_ICON}
              onClick={() => history.push(`/clients/${feedback?.userId?._id}`)}
            />
          </FeedbackUser>
          <SimpleDivider height="10px" />
          <>
            <MinorTitle>Товар</MinorTitle>
            <FeedbackProduct>
              <Image src={`${api.images}${feedback?.productId?.images[0]}`} />
              <SimpleDivider width="10px" />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{feedback?.productId?.ru} </div>
                <div style={{ color: Colors.boldGray }}>
                  Артикул: {feedback?.productId?.vendorCode}
                </div>
              </div>
            </FeedbackProduct>
          </>
          <SimpleDivider height="10px" />
          <>
            <MinorTitle>Оценка</MinorTitle>

            <StarWrapper>
              <span>{feedback.stars}</span>
              <SimpleDivider width="5px" />
              <Rating readOnly value={feedback.stars} />
            </StarWrapper>
          </>
          <SimpleDivider height="10px" />
          <>
            <MinorTitle>Отзыв</MinorTitle>
            <Comment>{feedback.comment}</Comment>
          </>
          {/* <div>{feedback?.status}</div> */}
        </Column>
      </Content>
      <button
        style={{ display: 'none' }}
        id="hiddenSave"
        onClick={() =>
          confirm(() =>
            dispatch(moderateFeedbackAction.request({ id, type: 'allow' })),
          )
        }
      >
        asd
      </button>
    </Container>
  )
}
