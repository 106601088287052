import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets, Colors } from '../../assets'
import { DumbButton } from '../../components/FormComponents/styled'
import { RowStartWrapper, RowWrapper } from '../../components/Modals/styled'
import { Title } from '../../components/PageContainer/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import { generateErrorToast } from '../../helpers'
import { getCategoriesAction, showToastAction } from '../../store'
import {
  createBannerAction,
  deleteNewBannerAction,
  getBannersAction,
  setNewBannerAction,
  updateBannerAction,
} from '../../store/banners'
import { getBannersSelector } from '../../store/banners/selectors'
import { ChatHeaderTab } from '../Chat/styled'
import { Icon, SmallDumbBtn } from '../Profile/styled'
import {
  ColumnsGrid,
  ColumnWrapper,
  FlexColumnsWrapper,
  RolesHeader,
} from '../Roles/styled'
import Banner from './components/Banner'
import { CreateBanner } from './components/CreateBanner'

const Banners: FC = () => {
  const [activetab, setActivetab] = useState({
    value: 'first',
    title: 'Первая секция',
  })

  const dispatch = useDispatch()

  const { banners, newBanners } = useSelector(getBannersSelector)

  const setNewBanner = () => {
    dispatch(
      setNewBannerAction({
        id: `${Date.now()}`,
        order: `${banners.length + newBanners.length + 1}`,
        section: `${activetab.value}`,
        image: {
          src: '',
          file: '',
        },
        start: '',
        end: '',
        linkType: '',
        link: '',
      }),
    )
  }

  useEffect(() => {
    dispatch(getCategoriesAction.request())
  }, [])

  useEffect(() => {
    dispatch(getBannersAction.request(`?section=${activetab.value}`))
  }, [activetab])

  const onSaveBanners = () => {
    if (newBanners.length > 0) {
      newBanners.forEach(banner => {
        if (
          !banner.image.src ||
          !banner.start ||
          !banner.end ||
          !banner.linkType ||
          !banner.link
        ) {
          dispatch(
            showToastAction.request(
              generateErrorToast('Необходимо заполнить все поля'),
            ),
          )
          return
        }

        const data = new FormData()
        data.append('photo', banner.image.file)
        data.append('start', banner.start)
        data.append('end', banner.end)
        data.append('linkType', banner.linkType)
        data.append('link', banner.link)
        data.append('section', banner.section)
        data.append('order', banner.order)

        dispatch(
          createBannerAction.request({
            data: data,
            section: `?section=${activetab.value}`,
          }),
        )
      })

      dispatch(deleteNewBannerAction([]))
    }

    banners.forEach(banner => {
      if (banner?.edited) {
        if (!banner.start || !banner.end || !banner.linkType || !banner.link) {
          dispatch(
            showToastAction.request(
              generateErrorToast('Необходимо заполнить все поля'),
            ),
          )
          return
        }

        const data = new FormData()
        data.append('start', banner.start)
        data.append('end', banner.end)
        data.append('linkType', banner.linkType)
        data.append('link', banner.link)

        dispatch(
          updateBannerAction.request({
            data: data,
            id: banner._id,
            section: `?section=${activetab.value}`,
          }),
        )
      }
    })
  }

  return (
    <>
      <RowWrapper>
        <Title>Баннеры</Title>

        <DumbButton
          bg={Colors.orange}
          txt={Colors.black}
          onClick={onSaveBanners}
        >
          Сохранить
        </DumbButton>
      </RowWrapper>

      <SimpleDivider height="20px" />

      <ColumnWrapper>
        <RolesHeader style={{ padding: '0 0 10px 0', gap: '20px' }}>
          <RowStartWrapper className="border-bottom">
            <ChatHeaderTab
              className={activetab.value == 'first' ? 'active' : ''}
              onClick={() =>
                setActivetab({ value: 'first', title: 'Первая секция' })
              }
            >
              Первая секция
            </ChatHeaderTab>

            <ChatHeaderTab
              className={activetab.value == 'second' ? 'active' : ''}
              onClick={() =>
                setActivetab({ value: 'second', title: 'Вторая секция' })
              }
            >
              Вторая секция
            </ChatHeaderTab>
          </RowStartWrapper>

          <ColumnsGrid className="sort padding" template="15% 20% 60% 5%">
            <div>Изображение</div>
            <div>Дата</div>
            <div>Вид</div>
            <div></div>
          </ColumnsGrid>
        </RolesHeader>

        <FlexColumnsWrapper className="responsive-height">
          {banners.map(item => (
            <Banner key={item._id} banner={item} />
          ))}

          {newBanners.map(
            banner =>
              banner.section == activetab.value && (
                <CreateBanner key={banner.id} newBanner={banner} />
              ),
          )}
        </FlexColumnsWrapper>

        <SimpleDivider height="20px" />

        <SmallDumbBtn
          bg={Colors.green}
          txt={Colors.white}
          shadow={Colors.lightGreen}
          style={{ alignSelf: 'start', marginLeft: '30px' }}
          onClick={setNewBanner}
        >
          <Icon src={Assets.PLUS_WHITE_ICON}></Icon>
          Добавить
        </SmallDumbBtn>
      </ColumnWrapper>
    </>
  )
}

export default Banners
