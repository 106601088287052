import { FC, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RoutesTree } from './routes'
import {
  getCategoriesAction,
  getStoresAction,
  getMaterialsAction,
  getColorsAction,
  getStylesAction,
  getUserSelector,
} from '../store'
import useChat from '../hooks/useChat'
import StoresList from '../pages/Stores/StoresList'

const Authorizated: FC = () => {
  const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(getCategoriesAction.request())
  //   dispatch(getStoresAction.request())
  //   dispatch(getMaterialsAction.request())
  //   dispatch(getColorsAction.request())
  //   dispatch(getStylesAction.request())
  // }, [dispatch])

  const { onConnect } = useChat()

  useEffect(() => {
    onConnect()
  }, [])

  const { user } = useSelector(getUserSelector)
  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  return (
    <Switch>
      <Route
        exact
        path={RoutesTree.client.path}
        component={RoutesTree.client.component}
      />

      <Route
        exact
        path={RoutesTree.clients.path}
        component={RoutesTree.clients.component}
      />

      <Route
        exact
        path={RoutesTree.orderCreate.path}
        component={RoutesTree.orderCreate.component}
      />

      <Route
        exact
        path={RoutesTree.order.path}
        component={RoutesTree.order.component}
      />

      <Route
        exact
        path={RoutesTree.orders.path}
        component={RoutesTree.orders.component}
      />

      <Route
        exact
        path={RoutesTree.main.path}
        component={RoutesTree.main.component}
      />

      <Route exact path={'/login'} component={RoutesTree.main.component} />

      <Route
        exact
        path={RoutesTree.categories.path}
        component={RoutesTree.categories.component}
      />

      <Route
        exact
        path={RoutesTree.category.path}
        component={RoutesTree.category.component}
      />

      <Route
        exact
        path={RoutesTree.categoryCreate.path}
        component={RoutesTree.categoryCreate.component}
      />

      <Route
        exact
        path={RoutesTree.subcategory.path}
        component={RoutesTree.subcategory.component}
      />

      <Route
        exact
        path={RoutesTree.chat.path}
        component={RoutesTree.chat.component}
      />

      <Route
        exact
        path={RoutesTree.help.path}
        component={RoutesTree.help.component}
      />

      <Route
        exact
        path={RoutesTree.products.path}
        component={RoutesTree.products.component}
      />

      <Route
        exact
        path={RoutesTree.product.path}
        component={RoutesTree.product.component}
      />

      <Route
        exact
        path={RoutesTree.productCreate.path}
        component={RoutesTree.productCreate.component}
      />

      <Route
        exact
        path={RoutesTree.loyaltyProgram.path}
        component={RoutesTree.loyaltyProgram.component}
      />

      <Route
        exact
        path={RoutesTree.finance.path}
        component={RoutesTree.finance.component}
      />
      <Route
        exact
        path={RoutesTree.financeStatistics.path}
        component={RoutesTree.financeStatistics.component}
      />
      <Route
        exact
        path={RoutesTree.financeSupplierStatistics.path}
        component={RoutesTree.financeSupplierStatistics.component}
      />

      <Route
        exact
        path={RoutesTree.banners.path}
        component={RoutesTree.banners.component}
      />

      <Route
        exact
        path={RoutesTree.loyaltyProgram.path}
        component={RoutesTree.loyaltyProgram.component}
      />
      <Route
        exact
        path={RoutesTree.feedbacks.path}
        component={RoutesTree.feedbacks.component}
      />
      <Route
        exact
        path={RoutesTree.feedback.path}
        component={RoutesTree.feedback.component}
      />
      <Route
        exact
        path={RoutesTree.stores.path}
        component={
          checkPermission('storeGetAll')
            ? RoutesTree.stores.component
            : StoresList
        }
      />
      <Route
        exact
        path={RoutesTree.storesList.path}
        component={RoutesTree.storesList.component}
      />

      <Route
        exact
        path={RoutesTree.roles.path}
        component={RoutesTree.roles.component}
      />
      <Route
        exact
        path={RoutesTree.rolesSettings.path}
        component={RoutesTree.rolesSettings.component}
      />
      <Route
        exact
        path={RoutesTree.rolesProfile.path}
        component={RoutesTree.rolesProfile.component}
      />
      <Route
        exact
        path={RoutesTree.rolesCreateProfile.path}
        component={RoutesTree.rolesCreateProfile.component}
      />
      <Route
        exact
        path={RoutesTree.managerStats.path}
        component={RoutesTree.managerStats.component}
      />
      <Route
        exact
        path={RoutesTree.managersStats.path}
        component={RoutesTree.managersStats.component}
      />
      <Route
        exact
        path={RoutesTree.settings.path}
        component={RoutesTree.settings.component}
      />
      <Route
        exact
        path={RoutesTree.brand.path}
        component={RoutesTree.brand.component}
      />
      <Route
        exact
        path={RoutesTree.brands.path}
        component={RoutesTree.brands.component}
      />
      <Route
        exact
        path={RoutesTree.brandCreate.path}
        component={RoutesTree.brandCreate.component}
      />
      <Route
        exact
        path={RoutesTree.profile.path}
        component={RoutesTree.profile.component}
      />
      <Route
        path={RoutesTree.changeEmail.path}
        component={RoutesTree.changeEmail.component}
      />

      <Redirect to={RoutesTree.notFound.path} />
    </Switch>
  )
}

export default Authorizated
