import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets } from '../../assets'
import { TableWrapper } from '../../components'
import { Filter } from '../../components/Filter'
import { RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import {
  getFinanceSelector,
  setAllOrdersStatsAction,
  setFinanceSearchQueryAction,
} from '../../store/finance'
import { Title } from '../ProductCreate/styled'
import { RelativeInput, RelativeInputContainer } from '../Roles/styled'
import { FinanceHeader, Styles } from './styled'
import { Pagination } from '../../components/Pagination'
import { IPagination } from '../../components/Pagination/types'
import TableTest from './Table'
import { debounce } from 'lodash'
import { defaultFormatDate } from '../../helpers'

const Finance: FC = () => {
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })

  const [searchQuery, setSearchQuery] = useState({
    name: '',
    phone: '',
  })

  const [rowData, setRowData] = useState<any>([])

  const columns = useMemo<any>(
    () => [
      {
        Header: 'ID заказа',
        accessor: 'id',
      },
      {
        Header: 'Дата',
        accessor: 'date',
      },
      {
        Header: 'Поставщик',
        accessor: 'supplier',
      },
      {
        Header: 'Категория',
        accessor: 'category',
      },
      {
        Header: 'Сумма',
        accessor: 'sum',
      },
      {
        Header: 'Расход',
        accessor: 'expense',
      },
      {
        Header: 'Приход',
        accessor: 'inflow',
      },
      {
        Header: 'Тип оплаты',
        accessor: 'paymentType',
      },
      {
        Header: 'Оплачено поставщику',
        accessor: 'paidToSupplier',
      },
      {
        Header: 'Договор',
        accessor: 'contract',
      },
      {
        Header: '',
        accessor: 'notes',
      },
    ],
    [],
  )

  const dispatch = useDispatch()

  const { allOrdersStats, filter } = useSelector(getFinanceSelector)

  const onSearchOrder = (query: string) => {
    if (query.match(/[0-9]/)) {
      setSearchQuery({ name: '', phone: query })
    } else {
      setSearchQuery({ phone: '', name: query })
    }
  }

  const findNotesForOwner = (ownerId: string, notesForOwner: any) => {
    if (notesForOwner.length) {
      return notesForOwner?.find(notes => {
        return notes?.supplier === ownerId
      })
    }
  }

  useEffect(() => {
    if (allOrdersStats?.docs) {
      const orders = allOrdersStats.docs.map(order => ({
        id: order?.increment,
        date: defaultFormatDate(order?.createdAt),
        supplier: order?.items?.product?.owner?.name,
        category: order?.items?.product?.category?.ru,
        sum: order?.items?.sumWithDiscount,
        expense: order?.expense,
        inflow: order?.inflow,
        paymentType:
          order?.paymentType === 'no_cash' ? 'Онлайн-оплата' : 'Наличные',
        contract: {
          notesForOwner: Array.isArray(order?.notesForOwner)
            ? findNotesForOwner(
                order?.items?.product?.owner?._id,
                order?.notesForOwner,
              )
            : order?.notesForOwner,
          orderId: order._id,
          supplierId: order?.items?.product?.owner?._id,
        },
        paidToSupplier: {
          notesForOwner: Array.isArray(order?.notesForOwner)
            ? findNotesForOwner(
                order?.items?.product?.owner?._id,
                order?.notesForOwner,
              )
            : order?.notesForOwner,
          orderId: order._id,
          supplierId: order?.items?.product?.owner?._id,
        },
        notes: {
          notesForOwner: Array.isArray(order?.notesForOwner)
            ? findNotesForOwner(
                order?.items?.product?.owner?._id,
                order?.notesForOwner,
              )
            : order?.notesForOwner,
          orderId: order._id,
          supplierId: order?.items?.product?.owner?._id,
        },
      }))

      setRowData(orders)
    }
  }, [allOrdersStats])

  useEffect(() => {
    let query = `limit=${pagination.limit}&skip=${
      pagination.skip == 0 ? 0 : pagination.limit * pagination.skip
    }&`

    if (searchQuery.name !== '' || searchQuery.phone !== '') {
      query +=
        searchQuery.name !== ''
          ? `name=${searchQuery.name}`
          : `phone=${searchQuery.phone}`
    }

    if (filter !== '') {
      query += filter
    }

    dispatch(setFinanceSearchQueryAction(query))

    dispatchSearchQuery(query)
  }, [searchQuery, filter, pagination])

  const dispatchSearchQuery = useCallback(
    debounce((query: string) => {
      dispatch(setAllOrdersStatsAction.request(query))
    }, 300),
    [],
  )

  return (
    <>
      <RowWrapper>
        <Title>Финансы</Title>
      </RowWrapper>

      <SimpleDivider height="20px" />

      <TableWrapper>
        <FinanceHeader>
          <RelativeInputContainer maxwidth="300px">
            <RelativeInput
              value={
                searchQuery.name !== '' ? searchQuery.name : searchQuery.phone
              }
              onChange={e => onSearchOrder(e.target.value)}
              padding="0px 10px 0 35px"
              type="text"
              placeholder="Поиск..."
            />
            <img
              src={Assets.SEARCH_ICON}
              alt="search"
              className="search-icon"
            />
          </RelativeInputContainer>
        </FinanceHeader>

        <Filter
          dateProp={true}
          brandsProp={true}
          categoriesProp={true}
          sumProp={true}
          paymentTypeProp={true}
          paidToSupplierProp={true}
          finance={true}
        />

        <Styles>
          <TableTest columns={columns} data={rowData} />
        </Styles>

        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={allOrdersStats.totalCount}
        />
      </TableWrapper>
    </>
  )
}

export default Finance
