import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  createStyleAction,
  deleteStyleAction,
  getStylesAction,
  getStyleAction,
} from './actions'

const InitialState: TInitialState = {
  styles: [],
  style: null,
  loading: false,
}

export const stylesReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getStylesAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getStylesAction.success, (state, { payload }) => ({
      ...state,
      styles: payload.data,
      loading: false,
    }))
    builder.addCase(getStylesAction.failure, (state, { payload }) => ({
      ...state,
      styles: [],
      loading: false,
    }))

    builder.addCase(getStyleAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getStyleAction.success, (state, { payload }) => ({
      ...state,
      style: payload.data,
      loading: false,
    }))
    builder.addCase(getStyleAction.failure, (state, { payload }) => ({
      ...state,
      style: null,
      loading: false,
    }))

    builder.addCase(createStyleAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createStyleAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createStyleAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteStyleAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteStyleAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteStyleAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
  },
)
