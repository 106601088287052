import { AxiosError } from 'axios'
import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  getProductAction,
  getProductsAction,
  createProductAction,
  updateProductAction,
  deleteProductAction,
  updateProductPhotoAction,
  deleteProductPhotoAction,
  searchProductByTitleAction,
  searchProductByDateAction,
  sortProductsByQueryAction,
  editProductPromoAction,
  getFilteredProductsAction,
  getPendingProductsAction,
  updateProductStatusAction,
  getMyProductsAction,
  getMyPendingProductsAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { ProductAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'
import { updateProductPhotoOrderAction } from '.'

function* getProductsWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ProductAPI.getProductsByQueryParams>
    > = yield call([ProductAPI, ProductAPI.getProductsByQueryParams], {
      authorization: token,
      data: payload,
    })
    yield put(getProductsAction.success(response.data))
  } catch (e) {
    console.log('Error: getProductsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения продуктов')),
    )

    yield put(getProductsAction.failure(e as AxiosError))
  }
}

function* getMyProductsWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ProductAPI.getMyProducts>
    > = yield call([ProductAPI, ProductAPI.getMyProducts], {
      authorization: token,
      data: payload,
    })

    console.log('MY PRODUCTS', response.data)

    yield put(getMyProductsAction.success(response.data))
  } catch (e) {
    console.log('Error: getProductsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения продуктов')),
    )

    yield put(getMyProductsAction.failure(e as AxiosError))
  }
}

function* getMyPendingProductsWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ProductAPI.getMyPendingProducts>
    > = yield call([ProductAPI, ProductAPI.getMyPendingProducts], {
      authorization: token,
      data: payload,
    })

    yield put(getMyPendingProductsAction.success(response.data))
  } catch (e) {
    console.log('Error: getProductsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения товаров')),
    )

    yield put(getMyPendingProductsAction.failure(e as AxiosError))
  }
}

function* getPendingProductsWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ProductAPI.getPendingProducts>
    > = yield call([ProductAPI, ProductAPI.getPendingProducts], {
      authorization: token,
      data: payload,
    })

    yield put(getPendingProductsAction.success(response.data))
  } catch (e) {
    console.log('Error: getProductsWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения продуктов')),
    )

    yield put(getPendingProductsAction.failure(e as AxiosError))
  }
}

function* getProductByIDWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ProductAPI.getProduct>
    > = yield call([ProductAPI, ProductAPI.getProduct], {
      authorization: token,
      id: payload.id,
    })

    yield put(getProductAction.success(response.data))
  } catch (e) {
    console.log('Error: getProductWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения продукта')),
    )

    yield put(getProductAction.failure(e as AxiosError))
  }
}

function* getFilteredProductsWorker({
  payload,
}: {
  payload: { query: string }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ProductAPI.getProduct>
    > = yield call([ProductAPI, ProductAPI.getFilteredProducts], {
      authorization: token,
      query: payload.query,
    })

    yield put(getFilteredProductsAction.success(response.data))
  } catch (e) {
    console.log('Error: getProductWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения продуктов')),
    )

    yield put(getFilteredProductsAction.failure(e as AxiosError))
  }
}

function* createProductWorker({
  payload,
}: ReturnType<typeof createProductAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const res: PromiseReturnType<ReturnType<typeof ProductAPI.createProduct>> =
      yield call([ProductAPI, ProductAPI.createProduct], {
        authorization: token,
        data: payload.data,
      })
    if (res && payload.permission) {
      const response: PromiseReturnType<
        ReturnType<typeof ProductAPI.getProducts>
      > = yield call([ProductAPI, ProductAPI.getProducts], {
        authorization: token,
      })
      yield put(getProductsAction.success(response.data))
    }

    if (res && !payload.permission) {
      const response: PromiseReturnType<
        ReturnType<typeof ProductAPI.getMyProducts>
      > = yield call([ProductAPI, ProductAPI.getMyProducts], {
        authorization: token,
        data: payload,
      })
      yield put(getMyProductsAction.success(response.data))
    }

    yield put(createProductAction.success())

    yield put(showToastAction.request(generateSuccessToast('Продукт создан')))
  } catch (e) {
    console.log('Error: createProductWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка в создании продукта')),
    )

    yield put(createProductAction.failure(e as AxiosError))
  }
}

function* updateProductWorker({
  payload,
}: ReturnType<typeof updateProductAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const { id, data } = payload
    yield call([ProductAPI, ProductAPI.updateProduct], {
      authorization: token,
      id,
      data,
    })
    yield put(updateProductAction.success())
    yield put(showToastAction.request(generateSuccessToast('Товар обновлен')))
  } catch (e) {
    console.log('Error: updateProductWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка в обновлении товара')),
    )
    yield put(createProductAction.failure(e as AxiosError))
  }
}

function* updateProductPhotoWorker({
  payload,
}: ReturnType<typeof updateProductPhotoAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: PromiseReturnType<
      ReturnType<typeof ProductAPI.addProductPhoto>
    > = yield call([ProductAPI, ProductAPI.addProductPhoto], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    if (res) {
      const response: PromiseReturnType<
        ReturnType<typeof ProductAPI.getProduct>
      > = yield call([ProductAPI, ProductAPI.getProduct], {
        authorization: token,
        id: payload.id,
      })
      yield put(getProductAction.success(response.data))
      yield put(updateProductPhotoAction.success())
    }
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка в добавлении фотографии продукта'),
      ),
    )
    yield put(updateProductPhotoAction.failure(e as AxiosError))
  }
}

function* deleteProductPhotoWorker({
  payload,
}: ReturnType<typeof deleteProductPhotoAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)
    const res: PromiseReturnType<
      ReturnType<typeof ProductAPI.deleteProductPhoto>
    > = yield call([ProductAPI, ProductAPI.deleteProductPhoto], {
      authorization: token,
      id: payload.id,
      data: payload.photo,
    })
    if (res) {
      const response: PromiseReturnType<
        ReturnType<typeof ProductAPI.getProduct>
      > = yield call([ProductAPI, ProductAPI.getProduct], {
        authorization: token,
        id: payload.id,
      })
      yield put(getProductAction.success(response.data))
      yield put(deleteProductPhotoAction.success())
    }
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка в удалении фотографии продукта'),
      ),
    )
    yield put(deleteProductPhotoAction.failure(e as AxiosError))
  }
}

function* updateProductPhotoOrderWorker({
  payload,
}: ReturnType<typeof updateProductPhotoOrderAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const res: PromiseReturnType<
      ReturnType<typeof ProductAPI.updateProductPhotoOrder>
    > = yield call([ProductAPI, ProductAPI.updateProductPhotoOrder], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    // if (res) {
    //   const response: PromiseReturnType<
    //     ReturnType<typeof ProductAPI.getProduct>
    //   > = yield call([ProductAPI, ProductAPI.getProduct], {
    //     authorization: token,
    //     id: payload.id,
    //   })
    //   yield put(getProductAction.success(response.data))
    // }
    yield put(updateProductPhotoOrderAction.success())
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка в обновлении фотографий продукта'),
      ),
    )
    yield put(updateProductPhotoAction.failure(e as AxiosError))
  }
}

function* deleteProductWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    yield call([ProductAPI, ProductAPI.deleteProduct], {
      authorization: token,
      id: payload.id,
    })
    yield put(deleteProductAction.success())
    yield put(showToastAction.request(generateSuccessToast('Продукт удален')))
  } catch (e) {
    console.log('Error: deleteProductWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка в удалении продукта')),
    )

    yield put(createProductAction.failure(e as AxiosError))
  }
}

function* searchProductByTitleWorker({
  payload,
}: {
  payload: { title: string }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const res: PromiseReturnType<
      ReturnType<typeof ProductAPI.searchProductByTitle>
    > = yield call([ProductAPI, ProductAPI.searchProductByTitle], {
      authorization: token,
      title: payload.title,
    })
    yield put(searchProductByTitleAction.success(res.data))
  } catch (e) {
    console.log('Error: searchProductByTitleWorker', e)

    yield put(showToastAction.request(generateErrorToast('Ошибка поиска')))

    yield put(createProductAction.failure(e as AxiosError))
  }
}

function* searchProductByDateWorker({
  payload,
}: {
  payload: { from: string; to: string }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const res: PromiseReturnType<
      ReturnType<typeof ProductAPI.searchProductByDate>
    > = yield call([ProductAPI, ProductAPI.searchProductByDate], {
      authorization: token,
      from: payload.from,
      to: payload.to,
    })
    yield put(searchProductByDateAction.success(res.data))
  } catch (e) {
    console.log('Error: searchProductByTitleWorker', e)

    yield put(showToastAction.request(generateErrorToast('Ошибка поиска')))

    yield put(createProductAction.failure(e as AxiosError))
  }
}

function* sortProductsByQueryWorker({
  payload,
}: {
  payload: { query: string }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)
    const res: PromiseReturnType<
      ReturnType<typeof ProductAPI.sortProductsByQuery>
    > = yield call([ProductAPI, ProductAPI.sortProductsByQuery], {
      authorization: token,
      query: payload.query,
    })
    yield put(sortProductsByQueryAction.success(res.data))
  } catch (e) {
    console.log('Error: sortProductsByQueryWorker', e)
    yield put(showToastAction.request(generateErrorToast('Ошибка поиска')))
    yield put(createProductAction.failure(e as AxiosError))
  }
}

function* editProductPromoWorker({
  payload,
}: {
  payload: { query; data; id }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const { id, data, query } = payload

    const res: PromiseReturnType<ReturnType<typeof ProductAPI.updateProduct>> =
      yield call([ProductAPI, ProductAPI.updateProduct], {
        authorization: token,
        id,
        data,
      })

    // const response: PromiseReturnType<
    //   ReturnType<typeof ProductAPI.getFilteredProducts>
    // > = yield call([ProductAPI, ProductAPI.getFilteredProducts], {
    //   query,
    //   authorization: token,
    // })

    yield put(editProductPromoAction.success())
    yield put(showToastAction.request(generateSuccessToast('Товар обновлен')))
  } catch (e) {
    console.log(e)
    yield put(showToastAction.request(generateErrorToast('Ошибка поиска')))
    yield put(editProductPromoAction.failure(e as AxiosError))
  }
}

function* updateProductStatusWorker({
  payload,
}: {
  payload: {
    version: string
    id: string
    body: { published?: boolean; declineReason?: string }
  }
}) {
  try {
    console.log(payload)

    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ProductAPI.updateProductStatus>
    > = yield call([ProductAPI, ProductAPI.updateProductStatus], {
      authorization: token,
      version: payload.version,
      id: payload.id,
      data: { ...payload.body },
    })

    //   yield put(updateProductStatusAction.success(response.data))
  } catch (e) {
    console.log('Error: getProductWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка изменения статуса товара'),
      ),
    )

    yield put(updateProductStatusAction.failure(e as AxiosError))
  }
}

export function* productsWatcher() {
  yield takeLatest(getMyProductsAction.request, getMyProductsWorker)
  yield takeLatest(
    getMyPendingProductsAction.request,
    getMyPendingProductsWorker,
  )
  yield takeLatest(getProductsAction.request, getProductsWorker)
  yield takeLatest(getProductAction.request, getProductByIDWorker)
  yield takeLatest(getFilteredProductsAction.request, getFilteredProductsWorker)
  yield takeLatest(createProductAction.request, createProductWorker)
  yield takeLatest(deleteProductAction.request, deleteProductWorker)
  yield takeLatest(updateProductAction.request, updateProductWorker)
  yield takeLatest(deleteProductPhotoAction.request, deleteProductPhotoWorker)
  yield takeLatest(updateProductPhotoAction.request, updateProductPhotoWorker)
  yield takeLatest(
    searchProductByTitleAction.request,
    searchProductByTitleWorker,
  )
  yield takeLatest(searchProductByDateAction.request, searchProductByDateWorker)
  yield takeLatest(sortProductsByQueryAction.request, sortProductsByQueryWorker)
  yield takeLatest(
    updateProductPhotoOrderAction.request,
    updateProductPhotoOrderWorker,
  )
  yield takeLatest(editProductPromoAction.request, editProductPromoWorker)
  yield takeLatest(getPendingProductsAction.request, getPendingProductsWorker)
  yield takeLatest(updateProductStatusAction.request, updateProductStatusWorker)
}
