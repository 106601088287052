import { ordersReducer } from './orders/reducer'
import { materialsReducer } from './materials/reducer'
import { combineReducers } from '@reduxjs/toolkit'
import { productsReducer } from './_products'
import { countdownReducer } from './countdown/reducer'
import { customersReducer } from './customers'
import { toastsReducer } from './toasts'
import { userReducer } from './user'
import { categoriesReducer } from './categories'
import { storesReducer } from './stores'
import { colorsReducer } from './colors'
import { stylesReducer } from './styles'
import { brandsReducer } from './brands'
// import { categoryDataReducer } from './categoryData'
import { settingsReducer } from './settings'
import { feedbackReducer } from './feedback'
import { rolesReducer } from './roles'
import { supportReducer } from './support'
import { chatReducer } from './chat'
import { financeReducer } from './finance'
import { managerStatsReducer } from './managerStatistics'
import { notifications } from './notifications'
import { bannersReducer } from './banners'

export default combineReducers({
  countdown: countdownReducer,
  customers: customersReducer,
  toasts: toastsReducer,
  user: userReducer,
  products: productsReducer,
  categories: categoriesReducer,
  stores: storesReducer,
  materials: materialsReducer,
  colors: colorsReducer,
  styles: stylesReducer,
  orders: ordersReducer,
  brands: brandsReducer,
  // categoryData: categoryDataReducer,
  settings: settingsReducer,
  feedbacks: feedbackReducer,
  roles: rolesReducer,
  support: supportReducer,
  chat: chatReducer,
  finance: financeReducer,
  managerStats: managerStatsReducer,
  notifications: notifications,
  banners: bannersReducer,
})
