import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Assets } from '../../assets'
import { Divider } from '../../components'
import Notifications from '../../components/Notifications/Notifications'
import {
  NotificationsCounter,
  NotificationsRelativeContainer,
} from '../../components/Notifications/styled'
import { useTypedSelector } from '../../hooks'
import { api } from '../../store'
import { getNotificationsSelector } from '../../store/notifications'
import { CartPopup } from './CartPopup'
import { cartPopupRef } from './CartPopup/refs'
import {
  TopbarWrapper,
  UserContainer,
  LargeImage,
  UserImage,
  ButtonContainer,
} from './styled'

const TIMEOUT_DELAY = 1000

const Topbar = () => {
  const [visibleNotifications, setVisibleNotifications] =
    useState<boolean>(false)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const notificationsRef = useRef(null)

  const onMouseOver = useCallback(() => {
    cartPopupRef.current?.classList.add('visible')

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  const onMouseLeave = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      cartPopupRef.current?.classList.remove('visible')
    }, TIMEOUT_DELAY)
  }, [])

  const { user } = useTypedSelector(state => state.user)
  const { notificationsCounter } = useSelector(getNotificationsSelector)

  return (
    <TopbarWrapper>
      <div></div>

      <UserContainer>
        <NotificationsRelativeContainer>
          <LargeImage
            src={Assets.NOTIFICATIONS_ICON}
            style={{ cursor: 'pointer' }}
            onClick={() => setVisibleNotifications(!visibleNotifications)}
          />
          {notificationsCounter > 0 ? (
            <NotificationsCounter>{notificationsCounter}</NotificationsCounter>
          ) : (
            ''
          )}
          <Notifications
            visibility={visibleNotifications}
            setVisibility={setVisibleNotifications}
          />
        </NotificationsRelativeContainer>

        <Divider width={15} />

        <ButtonContainer
          style={{
            backgroundColor: 'transparent',
            position: 'relative',
            borderRadius: '15px',
          }}
        >
          <NavLink to="/profile">
            <UserImage
              src={
                user?.photo ? `${api.images}${user.photo}` : Assets.USER_ICON
              }
            />
          </NavLink>

          <CartPopup onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} />
        </ButtonContainer>
      </UserContainer>
    </TopbarWrapper>
  )
}

export default Topbar
