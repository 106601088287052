import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFinanceSelector,
  updateNotesForOwnerAction,
} from '../../store/finance'
import { NotesWrapper } from './styled'
import { Assets } from '../../assets'
import { debounce } from 'lodash'

interface IFinanceNotesProps {
  id: string
  supplierId: any
  notesForOwner: any
}

const FinanceNotes: FC<IFinanceNotesProps> = ({
  id,
  notesForOwner,
  supplierId,
}) => {
  const [financeNotes, setFinanceNotes] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    setFinanceNotes(notesForOwner?.notes ? notesForOwner.notes : '')
  }, [notesForOwner])

  const handleChange = (value: string) => {
    setFinanceNotes(value)

    dispatchNotes(value)
  }

  const { seacrhQuery } = useSelector(getFinanceSelector)

  const dispatchNotes = useCallback(
    debounce((value: string) => {
      dispatch(
        updateNotesForOwnerAction.request({
          id: id,
          data: {
            ...notesForOwner,
            notes: value,
            supplier: supplierId,
          },
          seacrhQuery,
        }),
      )
    }, 900),
    [],
  )

  return (
    <NotesWrapper>
      <img src={Assets.CHAT_ICON} alt="notes" className="notes-icon" />
      <textarea
        value={financeNotes}
        className="notes-textarea"
        onChange={e => handleChange(e.target.value)}
      >
        {financeNotes}
      </textarea>
    </NotesWrapper>
  )
}

export default FinanceNotes
