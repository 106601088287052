import styled from 'styled-components'
import { Colors } from '../../assets'
import { FLEX, FONT, SHADOW } from '../../utils'
import { TSize } from './types'

export const Header = styled.div`
  width: 100%;
  background: ${Colors.white};

  ${FLEX({ justify: 'space-between' })}
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Title = styled.h1`
  margin-right: 85px;
  ${FONT({ size: '24px', color: Colors.boldGray, weight: '700' })}
`

export const CreationDate = styled.div`
  margin-top: 6px;
  color: ${Colors.boldGray};
`

export const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: ${Colors.boldGray};
`
export const ProductPublication = styled.div`
  ${FLEX({ align: 'flex-start', justify: 'flex-start', wrap: 'nowrap' })}
  gap: 30px;
  width: 100%;
  padding: 30px;
  background: ${Colors.backgroundGray};
  border: 1px solid ${Colors.gray};
  border-radius: 12px;
`

export const ButtonContainer = styled.div<TSize>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    margin-left: 30px;
  }
`

export const Content = styled.div`
  /* padding: 30px; */

  margin-top: 22px;
  background: ${Colors.white};
  border: 1px solid ${Colors.lightGray};
  border-radius: 24px;
  box-shadow: 0px 8px 20px ${Colors.shadowGray};
  ${FLEX({ direction: 'row', justify: 'flex-start', align: 'baseline' })}
`

export const Column = styled.div`
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
  width: 50%;
  ${FLEX({ direction: 'column', justify: 'flex-start', align: 'baseline' })}
`

export const SubColumnContainer = styled.div`
  width: 100%;
  display: flex;
`

export const SubColumn = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 50%;
  margin: 0 15px 0 0;
  ${FLEX({ direction: 'column' })};
  &:last-of-type {
    margin: 0 0 0 15px;
  }
`

export const InfoTitle = styled.h3`
  margin: 0;
`

export const InfoContainer = styled.div`
  margin-top: 7px;
  width: 100%;
`

export const InfoLabel = styled.label`
  margin-left: 8px;
  font-weight: bolder;
`

export const InfoInput = styled.input`
  margin-top: 4px;
  padding: 10px 0 9px 8px;
  height: 40px;
  width: 100%;
  border: ${Colors.gray} 1px solid;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
`

export const InfoPhoto = styled.div`
  margin-top: 30px;
  display: flex;
  & > div {
    margin-right: 37px;
  }
`

export const InfoTextArea = styled.textarea`
  margin-top: 4px;
  padding: 10px;
  width: 100%;
  height: 212px;
  resize: vertical;
  border: ${Colors.gray} 1px solid;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
`

export const CheckboxContainer = styled.div`
  margin: 13px 0 20px 0;
  & > div > input {
    margin: 10px 5px;
  }
`

export const DropdownContainer = styled.div`
  border: ${Colors.gray} 1px solid;
  cursor: pointer;
  padding: 10px;
  margin-top: 4px;
  font-weight: bold;
  & .option {
    /* position: relative; */
    margin-top: 10px;
    &:hover {
      color: ${Colors.green};
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
`

export const SaveButton = styled.label`
  font-weight: bold;
  cursor: pointer;
  padding: 14px 65px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${Colors.orange};
  background: ${Colors.orange};
  ${FLEX({})};
`

export const StyledImages = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const StyledImage = styled.img`
  width: calc(11vw - 10px);
  height: calc(11vw - 10px);
`

export const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`

export const StyledImageHeaderContainer = styled.div`
  /* width: calc(100%-10px); */
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
`

export const StyledImageLabel = styled.div`
  color: ${Colors.red};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`

export const UploadButton = styled.label`
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${Colors.green};
  color: ${Colors.white};
  background: ${Colors.green};
  width: 200px;
  ${FLEX({})};
`

export const ImagesContainer = styled.div`
  margin: 10px 0;
`

export const CharacteristicContainer = styled.div`
  display: flex;
  width: 50%;
  margin-right: 30px;
  box-sizing: border-box;
`

export const StyledInputWrapper = styled.div`
  ${FLEX({ justify: 'flex-start' })}
  width: 100%;
  & > div {
    ${FLEX({ direction: 'column', wrap: 'wrap', align: 'flex-start' })}
    width: 50%;
    padding-right: 30px;
  }
  & input {
    width: 100%;
    height: 40px;
    border: ${Colors.gray} 1px solid;
    border-radius: 4px;
  }
`
