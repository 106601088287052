import { AxiosResponse } from 'axios'

import {
  GetStoresActionResponse,
  GetStoreActionResponse,
  CreateStoreActionResponse,
  UpdateStoreActionResponse,
  DeleteStoreActionResponse,
  GetStoresWithoutItemActionResponse,
} from './types'

import { HttpService } from '../../services'
import { STORES_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class StoreAPI extends HttpService {
  static getStores(
    request: any,
  ): Promise<AxiosResponse<GetStoresActionResponse>> {
    return this.request<GetStoreActionResponse>('GET_STORES', {
      url: STORES_URL.rootPath,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getMyStores(
    request: any,
  ): Promise<AxiosResponse<GetStoresActionResponse>> {
    return this.request<GetStoreActionResponse>('GET_MY_STORES', {
      url: STORES_URL.ownStoresBySupplierId,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getAllStoresStats({
    authorization,
    data,
  }): Promise<AxiosResponse<GetStoresActionResponse>> {
    return this.request<GetStoreActionResponse>('GET_ALL_STORES_STATS', {
      url: STORES_URL.stats(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getStore(
    request: any,
  ): Promise<AxiosResponse<GetStoreActionResponse>> {
    return this.request<GetStoreActionResponse>('GET_STORE', {
      url: STORES_URL.idPath(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getStoresWithoutItem(
    request: any,
  ): Promise<AxiosResponse<GetStoresWithoutItemActionResponse>> {
    return this.request<GetStoresWithoutItemActionResponse>(
      'GET_STORES_WITHOUT_ITEM',
      {
        url: STORES_URL.withoutItemPath(request.id),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(request.authorization),
        },
      },
    )
  }

  static createStore({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateStoreActionResponse>> {
    console.log(data)
    return this.request<CreateStoreActionResponse>('CREATE_STORE', {
      url: STORES_URL.rootPath,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateStore({
    authorization,
    id,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<UpdateStoreActionResponse>> {
    console.log(data)
    return this.request<UpdateStoreActionResponse>('UPDATE_STORE', {
      url: STORES_URL.idPath(id),
      method: 'PUT',
      data: { ...data },
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteStore({
    authorization,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<DeleteStoreActionResponse>> {
    console.log(authorization, id)
    return this.request<DeleteStoreActionResponse>('DELETE_STORE', {
      url: STORES_URL.idPath(id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
