import { AxiosResponse } from 'axios'

import {
  GetOrdersActionResponse,
  GetOrderActionResponse,
  CreateOrderActionResponse,
  UpdateOrderActionResponse,
  DeleteOrderActionResponse,
  SearchOrdersByQueryActionResponse,
  SearchOrdersByDateActionResponse,
} from './types'

import { HttpService } from '../../services'
import { ORDERS_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class OrderAPI extends HttpService {
  static getMyOrders({
    authorization,
    data,
  }): Promise<AxiosResponse<GetOrdersActionResponse>> {
    return this.request<GetOrderActionResponse>('GET_MY_ORDERS', {
      url: ORDERS_URL.getMyOrders(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getOrders({
    authorization,
    data,
  }): Promise<AxiosResponse<GetOrdersActionResponse>> {
    return this.request<GetOrderActionResponse>('GET_ORDERS', {
      url: ORDERS_URL.getorders(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getNotifyOrders({
    authorization,
    data,
  }): Promise<AxiosResponse<GetOrdersActionResponse>> {
    return this.request<GetOrderActionResponse>('GET_NOTIFY_ORDERS', {
      url: ORDERS_URL.getNotifyOrders(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getOrdersByOrder({
    authorization,
    data,
  }): Promise<AxiosResponse<GetOrdersActionResponse>> {
    return this.request<GetOrderActionResponse>('GET_ORDERS_BY_ORDER', {
      url: ORDERS_URL.getOrdersByOrder(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getArchiveOrders({
    authorization,
    data,
  }): Promise<AxiosResponse<GetOrdersActionResponse>> {
    return this.request<GetOrderActionResponse>('GET_ARCHIVE_ORDERS', {
      url: ORDERS_URL.getArchiveOrders(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getOrder(
    request: any,
  ): Promise<AxiosResponse<GetOrderActionResponse>> {
    return this.request<GetOrderActionResponse>('GET_ORDER', {
      url: ORDERS_URL.idPath(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getOrdersStatistic({
    authorization,
  }): Promise<AxiosResponse<GetOrderActionResponse>> {
    return this.request<GetOrderActionResponse>('GET_ORDER_STATISTIC', {
      url: ORDERS_URL.statistic,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static createOrder({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateOrderActionResponse>> {
    console.log(data)
    return this.request<CreateOrderActionResponse>('CREATE_ORDER', {
      url: ORDERS_URL.rootPath,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateOrder({
    authorization,
    id,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<UpdateOrderActionResponse>> {
    console.log(data)
    return this.request<UpdateOrderActionResponse>('UPDATE_ORDER', {
      url: ORDERS_URL.idPath(id),
      method: 'PUT',
      data: { ...data },
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteOrder({
    authorization,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<DeleteOrderActionResponse>> {
    console.log(authorization, id)
    return this.request<DeleteOrderActionResponse>('DELETE_ORDER', {
      url: ORDERS_URL.idPath(id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static searchOrdersByDate({
    authorization,
    from,
    to,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SearchOrdersByDateActionResponse>
  > {
    return this.request<SearchOrdersByDateActionResponse>(
      'SEARCH_ORDER_BY_DATE',
      {
        url: ORDERS_URL.dateSearchPath(from, to),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static searchOrdersByQuery({
    authorization,
    query,
    data,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SearchOrdersByQueryActionResponse>
  > {
    return this.request<SearchOrdersByQueryActionResponse>(
      'SEARCH_ORDER_BY_QUERY',
      {
        url: ORDERS_URL.querySearchPath(query, data),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static sortOrdersByQuery({
    authorization,
    query,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SearchOrdersByQueryActionResponse>
  > {
    return this.request<SearchOrdersByQueryActionResponse>(
      'SEARCH_ORDER_BY_QUERY',
      {
        url: ORDERS_URL.querySortPath(query),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }
}
