import { FC, useEffect, useState } from 'react'
import { RowStartWrapper } from '../../../components/Modals/styled'
import { ChooseCategoryProps } from '../types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useTypedSelector } from '../../../hooks'
import { getSubsubcategoriesAction } from '../../../store'
import { useDispatch } from 'react-redux'

export const ChooseCategory: FC<ChooseCategoryProps> = ({
  setLink,
  subcategory,
  link,
}) => {
  const { categories } = useTypedSelector(state => state.categories)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSubsubcategoriesAction.request())
  }, [])

  const [activeCategories, setActiveCategories] = useState({
    category: { id: '', title: '' },
    subCategory: { id: '', title: '' },
    subSubCategory: { id: '', title: '' },
  })

  const findCategory = (categoryId: string) => {
    return categories.find(category => {
      return category._id == categoryId
    })
  }
  const findSubcategory = (subcategoryId: string) => {
    return findCategory(
      activeCategories.category.id
        ? activeCategories.category.id
        : subcategory?.category,
    )?.subcategories.find(subcategory => {
      return subcategory?._id == subcategoryId
    })
  }
  const findSubsubcategory = (subSubCategoryId: string) => {
    return findSubcategory(
      activeCategories.subCategory.id
        ? activeCategories.subCategory.id
        : subcategory?._id,
    )?.child.find(subsubcategory => {
      return subsubcategory?._id == subSubCategoryId
    })
  }
  const setActiveCategory = (id: string) => {
    setActiveCategories({
      category: { id: findCategory(id)._id, title: findCategory(id)._ru },
      subCategory: { id: '', title: '' },
      subSubCategory: { id: '', title: '' },
    })
  }
  const setActiveSubCategory = (id: string) => {
    setActiveCategories(prev => ({
      ...prev,
      subCategory: {
        id: findSubcategory(id)._id,
        title: findSubcategory(id)._ru,
      },
      subSubCategory: { id: '', title: '' },
    }))
  }
  const setActiveSubSubCategory = (id: string) => {
    setActiveCategories(prev => ({
      ...prev,
      subSubCategory: {
        id: findSubsubcategory(id)._id,
        title: findSubsubcategory(id)._ru,
      },
    }))

    setLink(id)
  }

  return (
    <RowStartWrapper style={{ alignItems: 'start', width: '75%' }}>
      <Select
        onChange={e => setActiveCategory(e.target.value)}
        value={
          activeCategories?.category?.id
            ? activeCategories.category.id
            : subcategory?.category
        }
        style={{ width: '33%', height: '40px' }}
      >
        {categories.map(category => (
          <MenuItem value={category?._id} key={category?._id}>
            {category?.ru}
          </MenuItem>
        ))}
      </Select>

      <Select
        onChange={e => setActiveSubCategory(e.target.value)}
        value={
          activeCategories?.subCategory?.id
            ? activeCategories?.subCategory.id
            : subcategory?._id
        }
        style={{ width: '33%', height: '40px' }}
      >
        {findCategory(
          activeCategories?.category?.id
            ? activeCategories.category.id
            : subcategory?.category,
        )?.subcategories.map(subcategory => (
          <MenuItem value={subcategory?._id} key={subcategory?._id}>
            {subcategory?.ru}
          </MenuItem>
        ))}
      </Select>

      <Select
        onChange={e => setActiveSubSubCategory(e.target.value)}
        value={
          activeCategories?.subSubCategory?.id
            ? activeCategories.subSubCategory.id
            : link
        }
        style={{ width: '33%', height: '40px' }}
      >
        {findSubcategory(
          activeCategories?.subCategory?.id
            ? activeCategories.subCategory.id
            : subcategory?._id,
        )?.child.map(subsubcategory => (
          <MenuItem
            value={subsubcategory?._id ? subsubcategory?._id : subsubcategory}
            key={subsubcategory?._id ? subsubcategory?._id : subsubcategory}
          >
            {subsubcategory?.ru}
          </MenuItem>
        ))}
      </Select>
    </RowStartWrapper>
  )
}
