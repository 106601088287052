import { useState } from 'react'
import { Assets } from '../../assets'
import { Button, InputImage, PasswordInputWrapper, StyledInput } from './styled'
import { TInputTemplates } from './types'

export const InputTemplates: TInputTemplates = {
  DEFAULT: ({ value, placeholder, onChange, name, ...props }) => (
    <StyledInput
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
    />
  ),

  PASSWORD: ({ value, placeholder, onChange, name, ...props }, error) => {
    const [isShown, toggle] = useState<boolean>(false)

    console.log('ERROR', error)

    const toggleShown = () => {
      toggle(p => !p)
    }
    return (
      <PasswordInputWrapper>
        <StyledInput
          name={name}
          value={value}
          placeholder={placeholder}
          type={isShown ? 'text' : 'password'}
          onChange={onChange}
          className={error ? 'error' : ''}
          // {...props.register}
        />
        <InputImage
          src={isShown ? Assets.HIDE_PASSWORD_ICON : Assets.SHOW_PASSWORD_ICON}
          onClick={toggleShown}
        />
      </PasswordInputWrapper>
    )
  },

  IMAGE: ({ value, onChange, name }) => {
    return (
      <StyledInput
        name={name}
        type="file"
        value={value}
        accept="image/*"
        onChange={onChange}
      />
    )
  },
}
