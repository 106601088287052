import { api } from '../config'

export const ORDERS_URL = {
  rootPath: `${api.withAPI}/orders`,
  statistic: `${api.withAPI}/orders/statistic`,
  getMyOrders: (query: string) => `${api.withAPI}/orders/my/${query}`,
  getorders: (query: string) => `${api.withAPI}/orders/current/${query}`,
  getNotifyOrders: (query: string) => `${api.withAPI}/orders/notify/${query}`,
  getOrdersByOrder: (query: string) => `${api.withAPI}/orders/byOrder/${query}`,
  getArchiveOrders: (query: string) => `${api.withAPI}/orders/archive/${query}`,
  idPath: (id: string) => `${api.withAPI}/orders/${id}`,
  dateSearchPath: (from: string, to: string) =>
    `${api.withAPI}/orders/?createdAt[]=${from}&createdAt[]=${to}`,
  querySearchPath: (query: string, data: string) =>
    `${api.withAPI}/orders/?${query}=${data}`,
  querySortPath: (query: string) => `${api.withAPI}/orders/?sortBy=${query}`,
}
