import { FC, useState } from 'react'
import { RowStartWrapper } from '../../../components/Modals/styled'
import { ChooseBrandProps } from '../types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useTypedSelector } from '../../../hooks'

export const ChooseBrand: FC<ChooseBrandProps> = ({ setLink, link }) => {
  const { brands } = useTypedSelector(state => state.brands)

  const [activeBrand, setActiveBrand] = useState({
    id: '',
    title: '',
  })

  const findBrand = (brandId: string) => {
    return brands.find(brand => {
      return brand._id == brandId
    })
  }

  const setBrand = (id: string) => {
    setActiveBrand(prev => ({
      ...prev,
      id: findBrand(id)._id,
      title: findBrand(id)._ru,
    }))

    setLink(id)
  }

  return (
    <RowStartWrapper style={{ alignItems: 'start', width: '75%' }}>
      <Select
        onChange={e => setBrand(e.target.value)}
        value={link ? link : activeBrand.id}
        style={{ width: '33%', height: '40px' }}
      >
        {brands.map(brand => (
          <MenuItem value={brand?._id} key={brand?._id}>
            {brand?.ru}
          </MenuItem>
        ))}
      </Select>
    </RowStartWrapper>
  )
}
