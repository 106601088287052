import { AxiosResponse } from 'axios'
import {
  ChangeEmailActionResponse,
  ChangePasswordActionResponse,
  ChangeUserDataResponse,
  ForgotPasswordActionPayload,
  GetUserActionResponse,
  LoginUserActionPayload,
  LoginUserActionResponse,
} from './types'

import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'
import { HttpService } from '../../services'
import { USER_URL } from './config'

export class UserAPI extends HttpService {
  static login(
    request: LoginUserActionPayload,
  ): Promise<AxiosResponse<LoginUserActionResponse>> {
    return this.request<LoginUserActionResponse>('LOGIN', {
      url: USER_URL.login,
      method: 'POST',
      data: request,
    })
  }

  static getUser(
    request: PayloadAuth,
  ): Promise<AxiosResponse<GetUserActionResponse>> {
    return this.request<GetUserActionResponse>('GET_USER', {
      url: USER_URL.get_user,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static changeEmailByLink(
    request: any,
  ): Promise<AxiosResponse<GetUserActionResponse>> {
    return this.request<GetUserActionResponse>('CHANGE_EMAIL', {
      url: USER_URL.change_email(request.token),
      method: 'PUT',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static logout(request: PayloadAuth): Promise<AxiosResponse<void>> {
    return this.request('LOGOUT', {
      url: USER_URL.logout,
      method: 'POST',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static forgotPassword(
    request: ForgotPasswordActionPayload,
  ): Promise<AxiosResponse<void>> {
    return this.request('FORGOT_PASSWORD', {
      url: USER_URL.forgot_password,
      method: 'POST',
      data: request,
    })
  }

  static sendChangeEmailLink({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<ChangeEmailActionResponse>> {
    return this.request<ChangeEmailActionResponse>('SEND_CHANGE_EMAIL_LINK', {
      url: USER_URL.send_change_email_link,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static changeData({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<ChangeUserDataResponse>> {
    return this.request<ChangeUserDataResponse>('CHANGE_DATA', {
      url: USER_URL.change_data,
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static changePassword({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<ChangePasswordActionResponse>> {
    return this.request<ChangePasswordActionResponse>('CHANGE_PASSWORD', {
      url: USER_URL.change_password,
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
