import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  createCategoryAction,
  deleteCategoryAction,
  getCategoriesAction,
  getCategoryAction,
  getSubsubcategoriesAction,
  updateCategoryImageAction,
  updateCategoryProductsAction,
} from './actions'

const InitialState: TInitialState = {
  categories: [],
  subSubcategories: [],
  category: null,
  loading: false,
}

export const categoriesReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getCategoriesAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCategoriesAction.success, (state, { payload }) => ({
      ...state,
      categories: payload,
      loading: false,
    }))
    builder.addCase(getCategoriesAction.failure, (state, { payload }) => ({
      ...state,
      categories: [],
      loading: false,
    }))

    builder.addCase(getCategoryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCategoryAction.success, (state, { payload }) => ({
      ...state,
      category: payload,
      loading: false,
    }))
    builder.addCase(getCategoryAction.failure, (state, { payload }) => ({
      ...state,
      category: null,
      loading: false,
    }))

    builder.addCase(createCategoryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createCategoryAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createCategoryAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteCategoryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteCategoryAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteCategoryAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(updateCategoryImageAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      updateCategoryImageAction.success,
      (state, { payload }) => ({
        ...state,
        category: payload,
        loading: false,
      }),
    )
    builder.addCase(
      updateCategoryImageAction.failure,
      (state, { payload }) => ({
        ...state,
        category: null,
        loading: false,
      }),
    )

    builder.addCase(updateCategoryProductsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      updateCategoryProductsAction.success,
      (state, { payload }) => ({
        ...state,
        categories: payload,
        loading: false,
      }),
    )
    builder.addCase(
      updateCategoryProductsAction.failure,
      (state, { payload }) => ({
        ...state,
        categories: [],
        loading: false,
      }),
    )

    builder.addCase(getSubsubcategoriesAction.request, state => ({
      ...state,
    }))
    builder.addCase(
      getSubsubcategoriesAction.success,
      (state, { payload }) => ({
        ...state,
        subSubcategories: payload.data,
      }),
    )
    builder.addCase(
      getSubsubcategoriesAction.failure,
      (state, { payload }) => ({
        ...state,
      }),
    )
  },
)
