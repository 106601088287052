import { AxiosResponse } from 'axios'
import {
  CreateMaterialActionPayload,
  CreateMaterialActionResponse,
  GetMaterialsActionResponse,
} from './types'

import { HttpService } from '../../services'
import { MATERIALS_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class MaterialAPI extends HttpService {
  static getMaterials(
    request: any,
  ): Promise<AxiosResponse<GetMaterialsActionResponse>> {
    return this.request<GetMaterialsActionResponse>('GET_MATERIALS', {
      url: MATERIALS_URL.rootPath,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getMaterial(
    request: any,
  ): Promise<AxiosResponse<GetMaterialsActionResponse>> {
    return this.request<GetMaterialsActionResponse>('GET_MATERIAL', {
      url: MATERIALS_URL.idPath(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createMaterial({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateMaterialActionResponse>> {
    console.log(data)
    return this.request<CreateMaterialActionResponse>('CREATE_MATERIAL', {
      url: MATERIALS_URL.rootPath,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  // static deleteMaterial(request: any): Promise<AxiosResponse<void>> {
  //   return this.request<void>('DELETE_MATERIAL', {
  //     url: MATERIALS_URL.deleteMaterial(request.id),
  //     method: 'DELETE',
  //     headers: {
  //       ...setAuthorizationHeadersProp(request.authorization),
  //     },
  //   })
  // }
}
