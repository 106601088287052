import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetCountdownsActionPayload,
  GetCountdownsActionResponse,
  CreateCountdownActionPayload,
  CreateCountdownActionResponse,
  DeleteCountdownActionPayload,
} from './types'

export const getCountdownsAction = createApiActions<
  GetCountdownsActionPayload,
  GetCountdownsActionResponse,
  AxiosError
>(createActionTypes('COUNTDOWN/GET_COUNTDOWNS'))

export const getCountdownAction = createApiActions<
  GetCountdownsActionPayload,
  GetCountdownsActionResponse,
  AxiosError
>(createActionTypes('COUNTDOWN/GET_COUNTDOWN'))

export const createCountdownAction = createApiActions<
  CreateCountdownActionPayload,
  CreateCountdownActionResponse,
  AxiosError
>(createActionTypes('COUNTDOWN/CREATE_COUNTDOWN'))

export const updateCountdownAction = createApiActions<
  CreateCountdownActionPayload,
  CreateCountdownActionResponse,
  AxiosError
>(createActionTypes('COUNTDOWN/UPDATE_COUNTDOWN'))

export const deleteCountdownAction = createApiActions<
  DeleteCountdownActionPayload,
  void,
  AxiosError
>(createActionTypes('COUNTDOWN/DELETE_COUNTDOWN'))
