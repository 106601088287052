import { AxiosResponse } from 'axios'
import {
  CreateRoleActionResponse,
  CreateUserActionResponse,
  DeleteUserActionPayload,
  SetAdminsActionPayload,
  SetAdminsActionResponse,
  SetRolesActionPayload,
  SetRolesActionResponse,
  UpdatePermissionsActionPayload,
  UpdatePermissionsActionResponse,
  UpdateRolesActionResponse,
} from './types'

import { setAuthorizationHeadersProp } from '../../helpers'
import { HttpService } from '../../services'
import { ROLES_URL } from './config'
import { PayloadAuth } from '../types'

export class RolesAPI extends HttpService {
  static getRoles(
    request: SetRolesActionPayload,
  ): Promise<AxiosResponse<SetRolesActionResponse>> {
    return this.request<SetRolesActionResponse>('GET_ROLES', {
      url: ROLES_URL.get_roles,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getAdmins({
    authorization,
    data,
  }): Promise<AxiosResponse<SetAdminsActionResponse>> {
    return this.request<SetAdminsActionResponse>('GET_ADMINS', {
      url: ROLES_URL.get_admins(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updatePermissions({
    authorization,
    data,
  }: PayloadAuth & any): Promise<
    AxiosResponse<UpdatePermissionsActionResponse>
  > {
    return this.request<UpdatePermissionsActionResponse>('UPDATE_PERMISSIONS', {
      url: ROLES_URL.update_permissions(data.id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateRoles({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<UpdateRolesActionResponse>> {
    return this.request<UpdateRolesActionResponse>('UPDATE_ROLES', {
      url: ROLES_URL.update_roles(data.id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static createRole({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateRoleActionResponse>> {
    return this.request<CreateRoleActionResponse>('CREATE_ROLE', {
      url: ROLES_URL.create_role,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static createUser({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateUserActionResponse>> {
    return this.request<CreateUserActionResponse>('CREATE_USER', {
      url: ROLES_URL.create_user,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteUser({
    authorization,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<DeleteUserActionPayload>> {
    console.log(authorization, id)
    return this.request<DeleteUserActionPayload>('DELETE_USER', {
      url: ROLES_URL.delete_user(id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  //   static changeEmail({
  //     authorization,
  //     data,
  //   }: PayloadAuth & any): Promise<AxiosResponse<ChangeEmailActionResponse>> {
  //     return this.request<ChangeEmailActionResponse>('CHANGE_EMAIL', {
  //       url: USER_URL.change_email,
  //       method: 'POST',
  //       data,
  //       headers: {
  //         'Content-Type': 'application/json',
  //         ...setAuthorizationHeadersProp(authorization),
  //       },
  //     })
  //   }
}
