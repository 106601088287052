import { api } from '../config'

export const FINANCE_URL = {
  get_amount_stats: `${api.withAPI}/finance/amountStats`,
  get_suppliers_stats: params =>
    `${api.withAPI}/finance/suppliersStats/${params}`,
  get_orders_by_supplier_id: data =>
    `${api.withAPI}/finance/${data.id}${
      data?.queryString ? `?${data.queryString}` : ''
    }`,
  get_all_orders_stats: params =>
    `${api.withAPI}/finance/${params !== '' ? `?${params}` : ''}`,
  update_notes_for_owner: id => `${api.withAPI}/finance/${id}`,
  update_paid_to_supplier: id => `${api.withAPI}/orders/${id}/supplier`,
}
