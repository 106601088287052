import { Colors } from '../../assets'
import styled from 'styled-components'
import { FLEX, FONT, SHADOW } from '../../utils'
import { NavLink as NavLinks } from 'react-router-dom'

export const ProfileWrapper = styled.div`
  width: 100%;
  border: 1px solid ${Colors.lightGray};
  border-radius: 24px;
  background: ${Colors.white};
  //   overflow: hidden;
  padding: 30px;
  ${SHADOW({})}
`
export const ProfileContent = styled.div`
  width: 100%;
  max-width: 345px;
  ${FLEX({ direction: 'column', align: 'flex-start' })}
`

export const UserImageWrapper = styled.div`
  ${FLEX({ align: 'center' })}
  gap: 30px;
`
export const ProfileWarning = styled.div`
  width: 100%;
  line-height: 20px;
  .warning {
    color: ${Colors.red};
    font-weight: 600;
  }
  .mail {
    font-weight: 600;
  }
`

export const UserImage = styled.img`
  height: 106px;
  width: 106px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: contain;
`
export const SmallDumbBtn = styled.label<{
  bg: string
  txt: string
  shadow: string
}>`
  font-weight: bold;
  cursor: pointer;
  padding: 8px 26px;
  border-radius: 8px;
  background: ${({ bg }) => bg};
  color: ${({ txt }) => txt};
  ${FLEX({})};
  outline: none;
  border: none;
  &:hover {
    box-shadow: 0px 0px 7px ${({ shadow }) => shadow};
  }
`
export const Icon = styled.img`
  width: 24px;
  margin-right: 5px;
`
export const ProfileDumbButton = styled.button<{
  bg: string
  txt: string
}>`
  font-weight: bold;
  cursor: pointer;
  padding: 14px 35px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${({ bg }) => bg};
  background: ${({ bg }) => bg};
  color: ${({ txt }) => txt};
  ${FLEX({})};
  margin: 0 auto;
  outline: none;
  border: none;
  pointer-events: none;
`
export const NotificationsWrapper = styled.div`
  ${FONT({ weight: '600', size: '17px' })}
  ${FLEX({ align: 'center', justify: 'space-between' })}
  gap: 20px;
`
export const PassModalTitle = styled.h2`
  ${FONT({ weight: '600', size: '22px' })};
  ${FLEX({ align: 'center', justify: 'space-between' })};
  color: ${Colors.boldGray};
  margin: 0;
`
export const ChangePasswordModalWrapper = styled.div`
  ${FLEX({ justify: 'center', direction: 'column', align: 'center' })};
  width: 300px;
`
export const ChangePasswordModalLink = styled.div`
  cursor: pointer;
  ${FONT({ size: '14px' })};
  color: ${Colors.boldGray};
  border-bottom: 1px solid ${Colors.boldGray};
  &:hover {
    border-bottom: 1px solid transparent;
  }
`
export const ProfileDescription = styled.div`
  width: 100%;
  color: ${Colors.boldGray};
  line-height: 19px;
`

export const ProfileInput = styled.input`
  padding: 10px 0 9px 8px;
  height: 40px;
  width: 100%;
  border: 1px solid ${Colors.gray};
  border-radius: 3px;
  color: ${Colors.black};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${Colors.gray};
  }
  &.error {
    border: 1px solid ${Colors.red};
  }
`
export const ProfileInputError = styled.div`
  margin: 5px 0 0 10px;
  color: ${Colors.red};
  align-self: flex-start;
`

export const ProfileInputWrapper = styled.div<{ maxWidth?: string }>`
  width: 100%;
  max-width: ${({ maxWidth = '100%' }) => maxWidth};
  ${FLEX({ direction: 'column' })};
  textarea {
    border: 1px solid ${Colors.gray};
    border-radius: 3px;
    resize: none;
    height: 80px;
    overflow-y: auto;
    width: 100%;
  }
`

export const DeclineWarning = styled.div`
  font-weight: 500;
  span {
    color: ${Colors.red};
  }
`

export const ProfileInputLabel = styled.div`
  margin: 0 0 5px 10px;
  ${FONT({ weight: '600', size: '14px' })};
  align-self: flex-start;
  white-space: nowrap;
`

export const ProfileModalText = styled.div`
  text-align: center !important;
  line-height: 20px;
  ${FONT({ weight: '400', size: '16px' })};
`
