import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetStoresActionResponse,
  GetStoreActionResponse,
  CreateStoreActionResponse,
  UpdateStoreActionResponse,
  DeleteStoreActionResponse,
  GetStoreActionPayload,
  CreateStoreActionPayload,
  UpdateStoreActionPayload,
  DeleteStoreActionPayload,
  GetStoresWithoutItemActionPayload,
  GetStoresWithoutItemActionResponse,
  GetAllStoresStatsActionResponse,
  GetAllStoresStatsActionPayload,
  GetStoresStatsByIdActionPayload,
  GetStoresStatsByIdActionResponse,
} from './types'

export const getStoresAction = createApiActions<
  void,
  GetStoresActionResponse,
  AxiosError
>(createActionTypes('STORES/GET_STORES'))

export const getMyStoresAction = createApiActions<
  void,
  GetStoresActionResponse,
  AxiosError
>(createActionTypes('STORES/GET_MY_STORES'))

export const getAllStoresStatsAction = createApiActions<
  GetAllStoresStatsActionPayload,
  GetAllStoresStatsActionResponse,
  AxiosError
>(createActionTypes('STORES/GET_ALL_STORES_STATS'))

export const getStoresStatsByIdAction = createApiActions<
  GetStoresStatsByIdActionPayload,
  GetStoresStatsByIdActionResponse,
  AxiosError
>(createActionTypes('STORES/GET_STORES_STATS_BY_ID'))

export const getStoreAction = createApiActions<
  GetStoreActionPayload,
  GetStoreActionResponse,
  AxiosError
>(createActionTypes('STORES/GET_STORE'))

export const unsetStoreAction = createAction(' STORES/UNSET_STORE')

export const createStoreAction = createApiActions<
  CreateStoreActionPayload,
  void,
  AxiosError
>(createActionTypes('STORES/CREATE_STORE'))

export const updateStoreAction = createApiActions<
  UpdateStoreActionPayload,
  UpdateStoreActionResponse,
  AxiosError
>(createActionTypes('STORES/UPDATE_STORE'))

export const deleteStoreAction = createApiActions<
  DeleteStoreActionPayload,
  void,
  AxiosError
>(createActionTypes('STORES/DELETE_STORE'))

export const getStoresWithoutItemAction = createApiActions<
  GetStoresWithoutItemActionPayload,
  GetStoresWithoutItemActionResponse,
  AxiosError
>(createActionTypes('STORES/GET_STORES_WITHOUT_ITEM'))
