import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFinanceSelector,
  updateNotesForOwnerAction,
} from '../../store/finance'
import { FinanceContractInput } from './styled'
import { debounce } from 'lodash'

interface IFinanceContractProps {
  id: string
  supplierId: any
  notesForOwner: any
}

const FinanceContract: FC<IFinanceContractProps> = ({
  id,
  supplierId,
  notesForOwner,
}) => {
  const [financeContract, setFinanceContract] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    setFinanceContract(notesForOwner?.contract ? notesForOwner.contract : '')
  }, [notesForOwner])

  const handleChange = (value: string) => {
    setFinanceContract(value)

    dispatchNotes(value)
  }

  const { seacrhQuery } = useSelector(getFinanceSelector)

  const dispatchNotes = useCallback(
    debounce((value: string) => {
      dispatch(
        updateNotesForOwnerAction.request({
          id,
          data: {
            ...notesForOwner,
            contract: value,
            supplier: supplierId,
          },
          seacrhQuery,
        }),
      )
    }, 900),
    [],
  )

  return (
    <FinanceContractInput
      onChange={e => handleChange(e.target.value)}
      value={financeContract}
    />
  )
}

export default FinanceContract
