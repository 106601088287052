import { AxiosResponse } from 'axios'

import { HttpService } from '../../services'
import { SETTINGS_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class SettingsAPI extends HttpService {
  static getExchangeRate({ authorization }: any): Promise<AxiosResponse<any>> {
    return this.request<any>('GET_SETTINGS', {
      url: SETTINGS_URL.exRate,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static setExchangeRate({
    authorization,
    data,
  }: any): Promise<AxiosResponse<any>> {
    return this.request<any>('SET_SETTINGS', {
      url: SETTINGS_URL.exRate,
      method: 'PUT',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getBonuses({ authorization }: any): Promise<AxiosResponse<any>> {
    return this.request<any>('GET_BONUSES', {
      url: SETTINGS_URL.bonuses,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static setBonuses({ authorization, data }: any): Promise<AxiosResponse<any>> {
    return this.request<any>('SET_BONUSES', {
      url: SETTINGS_URL.bonuses,
      method: 'PUT',
      data,
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
