import styled from 'styled-components'
import { Colors } from './../../../assets/colors'

export const StyledInput = styled.input<{ error: boolean }>`
  padding: 10px 0 9px 8px;
  height: 40px;
  width: 100%;
  border: ${({ error }) =>
    error ? `${Colors.red} 1px solid` : `${Colors.gray} 1px solid`};
  border-radius: 3px;
  color: ${({ error }) => (error ? Colors.red : Colors.black)};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${Colors.gray};
  }
`

export const StyledInputLabel = styled.label<{ error: boolean }>`
  color: ${({ error }) => (error ? Colors.red : Colors.black)};
  display: inline-block;
  font-weight: bold;
  margin: 0 0 5px 10px;
`

export const StyledInputError = styled.div`
  color: ${Colors.red};
  font-size: 14px;
`
