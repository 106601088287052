import { AxiosResponse } from 'axios'
import {
  GetAllNotificationsActionPayload,
  GetAllNotificationsActionResponse,
} from './types'

import { setAuthorizationHeadersProp } from '../../helpers'
import { HttpService } from '../../services'
import { PayloadAuth } from '../types'
import { NOTIFICATIONS_URL } from './config'

export class NotificationsAPI extends HttpService {
  static getAllNotifications(
    request: GetAllNotificationsActionPayload,
  ): Promise<AxiosResponse<GetAllNotificationsActionResponse>> {
    return this.request<GetAllNotificationsActionResponse>(
      'GET_ALL_NOTIFICATIONS',
      {
        url: NOTIFICATIONS_URL.get_all_notifications,
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(request.authorization),
        },
      },
    )
  }

  //   static getMyChatsByQuery({
  //     authorization,
  //     data,
  //   }: PayloadAuth & any): Promise<
  //     AxiosResponse<SetMyChatsByQueryActionResponse>
  //   > {
  //     return this.request<SetMyChatsByQueryActionResponse>(
  //       'GET_MY_CHATS_BY_QUERY',
  //       {
  //         url: CHAT_URL.get_my_chats_by_query(data),
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           ...setAuthorizationHeadersProp(authorization),
  //         },
  //       },
  //     )
  //   }
}
