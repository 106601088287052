import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { useHistory } from 'react-router'
import { Assets, Colors } from '../../assets'
import { Button } from '../../components'
import { TextArea, Input } from '../../components/FormComponents'
import { InfoLabel } from '../../components/FormComponents/styled'
import {
  BackButton,
  ButtonContainer,
  ButtonGroup,
  Container,
  Content,
  Header,
  InputContainer,
  Title,
  TitleGroup,
} from '../../components/Styled/formStyled'
import { ButtonElement, OrderFooter } from '../Order/styled'

interface IOrderCreate {}

const OrderCreate: FC<IOrderCreate> = () => {
  const [orderInfo, setOrderInfo] = useState<any>({
    id: '',
    personalData: '',
    paymentType: '',
    bonusesUsed: '',
    status: '',
    deliveryType: '',
    address: '',
    comment: '',
    itemsTotalPrice: '',
  })

  const history = useHistory()

  const handleSave = (data: any) => {
    console.log(data)
  }

  return (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>Создать заказ</Title>
            <BackButton onClick={() => history.push('/orders')}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
        </TitleGroup>
        <ButtonGroup>
          <ButtonContainer width={'195px'} height={'45px'}>
            <Button
              title="Отменить заказ"
              onClick={() => console.log()}
              theme={{
                background: Colors.white,
                text: Colors.red,
                border: Colors.red,
              }}
            />
          </ButtonContainer>
          <ButtonContainer width={'195px'} height={'45px'}>
            <Button
              title="Сохранить"
              onClick={() => console.log(orderInfo)}
              theme={{ background: Colors.orange, text: Colors.boldGray }}
            />
          </ButtonContainer>
        </ButtonGroup>
      </Header>
      <Content style={{ padding: '30px' }}>
        <Formik
          enableReinitialize={true}
          initialValues={orderInfo}
          onSubmit={values => handleSave(values)}
        >
          {({ values, handleChange, errors, setFieldValue }) => (
            <Form>
              <InputContainer style={{ alignItems: 'baseline' }}>
                <Input
                  title="Имя"
                  value={values.personalData}
                  handleChange={handleChange}
                  name="personalData"
                  width="25%"
                  margin="0 0 30px 0"
                />
                <Input
                  title="Тип оплаты"
                  value={values.paymentType}
                  handleChange={handleChange}
                  name="paymentType"
                  width="25%"
                  margin="0 0 30px 0"
                />
                <Input
                  title="Бонусами"
                  value={values.bonusesUsed}
                  handleChange={handleChange}
                  name="bonusesUsed"
                  width="25%"
                  margin="0 0 30px 0"
                />
                <Input
                  title="Статус заказ"
                  value={values.status}
                  handleChange={handleChange}
                  name="status"
                  width="25%"
                  margin="0 0 30px 0"
                />
                <Input
                  title="Способ доставки"
                  value={values.deliveryType}
                  handleChange={handleChange}
                  name="deliveryType"
                  width="25%"
                  margin="0 0 30px 0"
                />
                <TextArea
                  title="Адрес доставки"
                  value={values.address}
                  handleChange={handleChange}
                  name="address"
                  width="25%"
                  margin="0 0 30px 0"
                />
                <TextArea
                  title="Комментарий"
                  value={values.comment}
                  handleChange={handleChange}
                  name="comment"
                  width="25%"
                  margin="0 0 30px 0"
                />
              </InputContainer>
              <div style={{ marginTop: '60px' }}>
                <InfoLabel>Товар</InfoLabel>
                {/* {itemsElement} */}
              </div>
              <OrderFooter>
                <ButtonElement>
                  <img src={Assets.PLUS_WHITE_ICON} /> Добавить товар
                </ButtonElement>
                <div>
                  <div>Сумма: {values.itemsTotalPrice}</div>
                  <div>Бонусами: {values.bonusesUsed}</div>
                  <div>
                    Итого: {values.itemsTotalPrice - values.bonusesUsed}
                  </div>
                </div>
              </OrderFooter>
              {/* <button type="submit">test</button> */}
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  )
}

export default OrderCreate
