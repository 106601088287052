import { ChangeEvent, HTMLProps, ReactElement } from 'react'

export type TInputProps = {
  template?: EInputTemplate
  placeholder?: string
  label?: string
  error?: string
  value?: string
  name?: string
  isValid?: boolean
  onChange: (v: string) => void
  register?: any
}

export enum EInputTemplate {
  DEFAULT = 'DEFAULT',
  PASSWORD = 'PASSWORD',
  IMAGE = 'IMAGE',
}

export type TInputTemplates = {
  [key in EInputTemplate]: (props: any, error: any) => ReactElement
}

type TInputsDefaultProps<T = HTMLInputElement> = {
  name: string
  label?: string
  placeholder?: string
  error?: string
  isValid?: boolean
  onChange: (v: string) => void
} & Required<Pick<HTMLProps<T>, 'value'>> &
  Pick<HTMLProps<T>, 'disabled'>

export type TDateTimeInputProps = Omit<
  TInputsDefaultProps,
  'value' | 'onChange'
> & {
  value: Date
  mode: TDateTimeInputMode
  onChange: (v: any) => void
}

export type TFormatDateTime = (v: Date | string) => string

export type TDateTimeInputTemplates = {
  [mode in TDateTimeInputMode]: {
    mode: mode
    icon: string
    formater: TFormatDateTime
  }
}

export type TDateTimeInputMode = 'date' | 'time'

export type TOnChange<T = HTMLInputElement> = (e: ChangeEvent<T>) => void

export type TCheckboxProps = {
  value: boolean
  onChange: () => void
}

export type TTextAreaProps = TInputsDefaultProps<HTMLTextAreaElement>
