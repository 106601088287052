export const checkForLabel = (key: string) => {
  switch (key) {
    case 'new':
      return 'Новый'
    case 'accepted':
      return 'Принятый'
    case 'in_going':
      return 'В пути'
    case 'in_stock':
      return 'В Наличии'
    case 'in_progress':
      return 'В обработке'
    case 'waiting_for_payment':
      return 'Ожидает оплату'
    case 'done':
      return 'Завершен'
    case 'canceled':
      return 'Отменен'
    case 'payment_error':
      return 'Ошибка оплаты'
    case 'payment_processing':
      return 'Обработка оплаты'
    case 'card':
      return 'Картой'
    case 'cash':
      return 'Наличными'
    case 'courier':
      return 'Заказ машины с помощью менеджера'
    case 'takeout':
      return 'Самовывоз'
    case 'online':
      return 'Картой'
    case 'payment_done':
      return 'Оплачен'
    case 'payment_canceled':
      return 'Отменен'
    case 'bts':
      return 'BTS Доставка'
    case 'yandex':
      return 'Яндекс Доставка'
    case 'no_cash':
      return 'Безналичные'
    case 'waitingForDelivery':
      return 'В пути'
    case 'waiting_for_delivery':
      return 'В пути'
    case 'order':
      return 'Под заказ'
    case 'notify':
      return 'Под заказ'
    default:
      return 'Тест'
  }
}
