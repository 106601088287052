import React, { FC, useState, useEffect, Fragment } from 'react'
import {
  StorageContainer,
  InfoLabel,
  InfoStorage,
} from '../../pages/ProductCreate/styled'
import { InfoInput } from '../../pages/Customer/styled'
import Dropdown from './Dropdown'
import { getStoreAction } from '../../store'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks'

interface IStorage {
  id: any
  onChange: any
  value: any
  key: number
}

const Storage: FC<IStorage> = ({ id, onChange, value, key }) => {
  const options = [
    { value: 'inStock', label: 'Есть в наличии' },
    { value: 'outOfStock', label: 'Нет в наличии' },
  ]
  const [status, setStatus] = useState({
    value: 'outOfStock',
    label: 'Нет в наличии',
  })
  const [store, setStore] = useState({
    country: '',
    city: '',
    street: '',
    home: '',
    _id: '',
  })

  const storesState = useTypedSelector(state => state.stores)

  const dispatch = useDispatch()

  useEffect(() => {
    if (value > 0) {
      setStatus(options[0])
    }
    // dispatch(getStoreAction.request({ id }))
    for (let i = 0; i < storesState.stores.length; i++) {
      const el = storesState.stores[i]
      if (el._id === id) {
        setStore(el)
      }
    }
  }, [])

  useEffect(() => {}, [storesState.loading])

  const handleChange = (e: any) => {
    onChange(store._id, e.target.value)
  }

  return (
    <div style={{ width: '100%' }} key={key}>
      <StorageContainer>
        <div style={{ display: 'block', width: '50%' }}>
          <InfoLabel>Склад</InfoLabel>
          <InfoStorage>
            {`${store.country}, ${store.city}, ${store.street}, ${store.home}`}
          </InfoStorage>
        </div>
        <div
          style={{
            display: 'flex',
            width: '50%',
            marginLeft: '15px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '75%' }}
          >
            <Dropdown
              title="Статус"
              onChange={setStatus}
              options={options}
              value={status}
            />
          </div>
          <div style={{ width: '25%' }}>
            <InfoLabel>К-во</InfoLabel>
            <InfoInput
              value={status.value === 'inStock' ? value : 0}
              disabled={status.value === 'outOfStock'}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
      </StorageContainer>
    </div>
  )
}

export default Storage
