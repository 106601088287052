import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  createMaterialAction,
  deleteMaterialAction,
  getMaterialsAction,
  getMaterialAction,
} from './actions'

const InitialState: TInitialState = {
  materials: [],
  material: null,
  loading: false,
}

export const materialsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getMaterialsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getMaterialsAction.success, (state, { payload }) => ({
      ...state,
      materials: payload.data,
      loading: false,
    }))
    builder.addCase(getMaterialsAction.failure, (state, { payload }) => ({
      ...state,
      materials: [],
      loading: false,
    }))

    builder.addCase(getMaterialAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getMaterialAction.success, (state, { payload }) => ({
      ...state,
      material: payload.data,
      loading: false,
    }))
    builder.addCase(getMaterialAction.failure, (state, { payload }) => ({
      ...state,
      material: null,
      loading: false,
    }))

    builder.addCase(createMaterialAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createMaterialAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createMaterialAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteMaterialAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteMaterialAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteMaterialAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
  },
)
