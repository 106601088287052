import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets } from '../../assets'
import { TableWrapper } from '../../components'
import { RowStartWrapper, RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import {
  ColumnsGrid,
  FlexColumnsWrapper,
  GrayTitle,
  ItemNavLink,
  LinkWithIcon,
  RelativeInput,
  RelativeInputContainer,
} from '../Roles/styled'
import Rating from '@mui/material/Rating'
import {
  getManagersStatsSelector,
  getManagerStatsByIdAction,
} from '../../store/managerStatistics'
import { useHistory, useParams } from 'react-router-dom'
import { IParams } from '../Finance/types'
import { debounce } from 'lodash'
import { IPagination } from '../../components/Pagination/types'
import { FinanceHeader } from '../Finance/styled'
import { Filter } from '../../components/Filter'
import { Pagination } from '../../components/Pagination'

const ManagerStats: FC = () => {
  const [searchQuery, setSearchQuery] = useState({
    name: '',
    phone: '',
  })

  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })

  const { manager, filter } = useSelector(getManagersStatsSelector)

  const history = useHistory()

  const { id } = useParams<IParams>()

  const dispatch = useDispatch()

  const queryString = useRef<string>(
    `limit=${pagination.limit}&skip=${pagination.skip}`,
  )

  const onSearch = (query: string) => {
    if (query.match(/[0-9]/)) {
      setSearchQuery({ name: '', phone: query })
    } else {
      setSearchQuery({ phone: '', name: query })
    }
  }

  const dispatchSearchQuery = useCallback(
    debounce(() => {
      dispatch(
        getManagerStatsByIdAction.request({
          id: id,
          query: queryString.current,
        }),
      )
    }, 300),
    [],
  )

  useEffect(() => {
    queryString.current = `limit=${pagination.limit}&skip=${pagination.skip}`

    if (searchQuery.name !== '' || searchQuery.phone !== '') {
      queryString.current +=
        searchQuery.name !== ''
          ? `&name=${searchQuery.name}`
          : `&phone=${searchQuery.phone}`
    }

    if (filter !== '') {
      queryString.current += `&${filter}`
    }

    dispatchSearchQuery()
  }, [searchQuery, filter, pagination])

  const parseDate = (date: string) => {
    const newDate = new Date(date).toLocaleString().slice(0, 10)
    const time = new Date(date).toLocaleString().slice(12, 17)

    return `${newDate} ${time}`
  }

  if (!manager?.docs) {
    return <></>
  }

  return (
    <>
      <RowStartWrapper width="auto">
        <GrayTitle>{manager.name}</GrayTitle>

        <LinkWithIcon onClick={() => history.goBack()}>
          <img src={Assets.ARROW_BACK_ICON} alt="" />
          Назад
        </LinkWithIcon>
      </RowStartWrapper>

      <SimpleDivider height="20px" />

      <TableWrapper>
        <FinanceHeader>
          <RelativeInputContainer maxwidth="300px">
            <RelativeInput
              value={
                searchQuery.name !== '' ? searchQuery.name : searchQuery.phone
              }
              onChange={e => onSearch(e.target.value)}
              padding="0px 10px 0 35px"
              type="text"
              placeholder="Поиск по имени..."
            />
            <img
              src={Assets.SEARCH_ICON}
              alt="search"
              className="search-icon"
            />
          </RelativeInputContainer>
        </FinanceHeader>

        <Filter
          brandsProp={true}
          categoriesProp={true}
          subcategoriesProp={true}
          managerStats={true}
        />

        <ColumnsGrid className="header center" template="24% 24% 23% 24% 5%">
          <div>Дата</div>
          <div>Клиент</div>
          <div>Оценка</div>
          <div>Отзыв</div>
          <div></div>
        </ColumnsGrid>

        <FlexColumnsWrapper>
          {manager.docs.map(stat => (
            <ColumnsGrid
              key={stat._id}
              template="24% 24% 23% 24% 5%"
              className="supplier-preview"
            >
              <div>{parseDate(stat.createdAt)}</div>
              <div>{`${stat.user.surname ? stat.user.surname : ''} ${
                stat.user.name
              }`}</div>
              <Rating
                name="half-rating-read"
                defaultValue={
                  stat?.evaluation?.rating ? stat.evaluation.rating : 0
                }
                precision={0.5}
                readOnly
              />
              <div>{stat?.evaluation?.commentary}</div>
              <ItemNavLink to={`/clients/${stat?.user?._id}`}>
                <img src={Assets.SHOW_PASSWORD_ICON} alt="" />
              </ItemNavLink>
            </ColumnsGrid>
          ))}
        </FlexColumnsWrapper>

        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={manager.totalCount}
        />
      </TableWrapper>
    </>
  )
}

export default ManagerStats
