import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'rc-table'
import { PageContainer } from '../../components'
import {
  FunctionalRow,
  HeaderItem,
  SearchInput,
  StyledCell,
  StyledHeader,
  StyledRow,
  StyledTable,
  TableWrapper,
} from '../../components'
import { useTypedSelector } from '../../hooks'
import {
  getArchiveOrdersAction,
  getMyOrdersAction,
  getNotifyOrdersAction,
  getOrdersAction,
  getOrdersByOrderAction,
  getOrdersStatisticAction,
  getUserSelector,
} from '../../store'
import { OrdersTab, OrdersTabs } from './styled'
import { useHistory } from 'react-router'
import { currentTableData, notifyTableData } from './tableData'
import { Assets } from '../../assets'
import { Filter } from '../../components/Filter'
import { Pagination } from '../../components/Pagination'
import { IPagination } from '../../components/Pagination/types'

interface IOrders {}

const Orders: FC<IOrders> = () => {
  const [tab, setTab] = useState('current')
  const [sorting, setSorting] = useState<string>('')
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })

  const history = useHistory()

  const dispatch = useDispatch()
  const { orders, filter, ordersStatistic } = useTypedSelector(
    state => state.orders,
  )

  console.log('orders', orders)

  const { user } = useSelector(getUserSelector)

  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  useEffect(() => {
    if (!checkPermission('orderGetAll')) {
      if (tab === 'notify') {
        dispatch(
          getMyOrdersAction.request(
            `?limit=${pagination.limit}&page=${pagination.skip + 1}${
              filter !== '' ? `&${filter}` : ''
            }${sorting ? `&sortBy=${sorting}` : ''}${
              search ? `&phone=${search}` : ''
            }&status=notify`,
          ),
        )
        return
      }

      if (tab === 'order') {
        dispatch(
          getMyOrdersAction.request(
            `?limit=${pagination.limit}&page=${pagination.skip + 1}${
              filter !== '' ? `&${filter}` : ''
            }${sorting ? `&sortBy=${sorting}` : ''}${
              search ? `&phone=${search}` : ''
            }&status=order`,
          ),
        )
        return
      }

      if (tab === 'archive') {
        dispatch(
          getMyOrdersAction.request(
            `?limit=${pagination.limit}&page=${pagination.skip + 1}${
              filter !== '' ? `&${filter}` : ''
            }${sorting ? `&sortBy=${sorting}` : ''}${
              search ? `&phone=${search}` : ''
            }&status=done`,
          ),
        )
        return
      }

      dispatch(
        getMyOrdersAction.request(
          `?limit=${pagination.limit}&page=${pagination.skip + 1}${
            filter !== '' ? `&${filter}` : ''
          }${sorting ? `&sortBy=${sorting}` : ''}${
            search ? `&phone=${search}` : ''
          }`,
        ),
      )
      return
    }

    if (tab === 'current') {
      dispatch(
        getOrdersAction.request(
          `?limit=${pagination.limit}&page=${pagination.skip + 1}${
            filter !== '' ? `&${filter}` : ''
          }${sorting ? `&sortBy=${sorting}` : ''}${
            search ? `&phone=${search}` : ''
          }`,
        ),
      )
      return
    }

    if (tab === 'notify') {
      dispatch(
        getNotifyOrdersAction.request(
          `?limit=${pagination.limit}&page=${pagination.skip + 1}${
            filter !== '' ? `&${filter}` : ''
          }${sorting ? `&sortBy=${sorting}` : ''}${
            search ? `&phone=${search}` : ''
          }`,
        ),
      )
      return
    }

    if (tab === 'order') {
      dispatch(
        getOrdersByOrderAction.request(
          `?limit=${pagination.limit}&page=${pagination.skip + 1}${
            filter !== '' ? `&${filter}` : ''
          }${sorting ? `&sortBy=${sorting}` : ''}${
            search ? `&phone=${search}` : ''
          }`,
        ),
      )
      return
    }

    if (tab === 'archive') {
      dispatch(
        getArchiveOrdersAction.request(
          `?limit=${pagination.limit}&page=${pagination.skip + 1}${
            filter !== '' ? `&${filter}` : ''
          }${sorting ? `&sortBy=${sorting}` : ''}${
            search ? `&phone=${search}` : ''
          }`,
        ),
      )
      return
    }
  }, [filter, pagination, tab, sorting, search])

  useEffect(() => {
    dispatch(getOrdersStatisticAction.request())
  }, [])

  //   const onSearch = (e: any) => {
  //     if (e.key === 'Enter') {
  //       dispatch(
  //         searchOrdersByQueryAction.request({ query: 'phone', data: search }),
  //       )
  //     }
  //   }

  const data = orders?.orders?.map((i, index) => {
    switch (tab) {
      case 'current':
        return currentTableData(i, index, history)
      case 'notify':
        return notifyTableData(i, index, history)
      case 'order':
        return notifyTableData(i, index, history)
      case 'archive':
        return currentTableData(i, index, history)
      default:
        return currentTableData(i, index, history)
    }
  })

  const handleAsyncSort = (sortingQuery: string) => {
    switch (sortingQuery) {
      case 'createdAt':
        setSorting(prev => (prev === 'later' ? 'earlier' : 'later'))
        break
      case 'status':
        setSorting(prev => (prev === 'statusUp' ? 'statusDown' : 'statusUp'))
        break
      case 'paymentType':
        setSorting(prev =>
          prev === 'paymentTypeUp' ? 'paymentTypeDown' : 'paymentTypeUp',
        )
        break
    }
  }

  const testingColumns = () => {
    switch (tab) {
      case 'current':
        return currentColumns
      case 'notify':
        return notifyColumns
      case 'order':
        return notifyColumns
      case 'archive':
        return currentColumns
    }
  }

  const sortingArrow = query => {
    const sortingValues = ['later', 'statusUp', 'paymentTypeDown']
    return (
      <>
        {sortingValues.includes(sorting) ? (
          <img src={Assets.SORT_ARROW_UP_ICON} />
        ) : (
          <img src={Assets.SORT_ARROW_ICON} />
        )}
      </>
    )
  }

  const currentColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: (
        <HeaderItem onClick={() => handleAsyncSort('createdAt')}>
          Дата {sortingArrow('createdAt')}
        </HeaderItem>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Название товара',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Клиент',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: (
        <HeaderItem onClick={() => handleAsyncSort('paymentType')}>
          Тип оплаты {sortingArrow('paymentType')}
        </HeaderItem>
      ),
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
    {
      title: 'Бонусами',
      dataIndex: 'bonuses',
      key: 'bonuses',
    },
    {
      title: (
        <HeaderItem onClick={() => handleAsyncSort('status')}>
          Статус {sortingArrow('status')}
        </HeaderItem>
      ),
      dataIndex: 'status',
      key: 'status',
    },
    {
      dataIndex: 'viewButton',
      key: 'viewButton',
    },
  ]

  const notifyColumns = [
    {
      title: 'ID заказа',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: (
        <HeaderItem onClick={() => handleAsyncSort('createdAt')}>
          Дата {sortingArrow('createdAt')}
        </HeaderItem>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Название товара',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Клиент',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: (
        <HeaderItem onClick={() => handleAsyncSort('status')}>
          Статус {sortingArrow('status')}
        </HeaderItem>
      ),
      dataIndex: 'status',
      key: 'status',
    },
    {
      dataIndex: 'viewButton',
      key: 'viewButton',
    },
  ]

  const onTabSwitch = (query: string) => {
    setTab(query)
  }

  return (
    <PageContainer title="Заказы">
      <TableWrapper>
        <OrdersTabs>
          <OrdersTab
            onClick={() => onTabSwitch('current')}
            className={tab === 'current' ? 'selected' : ''}
          >
            Текущие заказы
          </OrdersTab>

          <OrdersTab
            className={tab === 'notify' ? 'selected' : ''}
            onClick={() => onTabSwitch('notify')}
          >
            Уведомить о наличии
          </OrdersTab>

          <OrdersTab
            className={tab === 'order' ? 'selected' : ''}
            onClick={() => onTabSwitch('order')}
          >
            Под заказ
          </OrdersTab>

          <OrdersTab
            className={tab === 'archive' ? 'selected' : ''}
            onClick={() => onTabSwitch('archive')}
          >
            Архив
          </OrdersTab>
        </OrdersTabs>
        <FunctionalRow>
          <SearchInput
            value={search}
            onChange={e => setSearch(e.target.value)}
            type="text"
            placeholder="Номер телефона..."
          />

          <div className="applications">
            <span>Всего заявок: {ordersStatistic.totalOrders}</span>

            <span className="success">
              Выполнено: {ordersStatistic.doneOrders}
            </span>
            <span className="canceled">
              Отменено: {ordersStatistic.declinedOrders}
            </span>
          </div>
        </FunctionalRow>

        <Filter
          dateProp={true}
          brandsProp={true}
          categoriesProp={true}
          subcategoriesProp={true}
          orderStatusProp={true}
          activeOrders={tab !== 'archive' ? true : false}
          doneOrders={tab === 'archive' ? true : false}
          sumProp={true}
          orders={true}
        />
        <Table
          columns={testingColumns()}
          data={data}
          components={{
            body: {
              row: StyledRow,
              cell: StyledCell,
            },
            header: { cell: StyledHeader },
            table: StyledTable,
          }}
        />
        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={orders.totalCount}
        />
      </TableWrapper>
    </PageContainer>
  )
}

export default Orders
