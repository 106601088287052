import React, { FC, useState } from 'react'
import { api } from '../../store'
import {
  StyledImage,
  StyledImageContainer,
  StyledImageHeaderContainer,
  StyledImageLabel,
  StyledImages,
  UploadButton,
  ImagesContainer,
} from './styled'

interface IImages {
  images: { order: number; src: string }[]
  setImages: any
  handleDelete: any
  handleAdd: any
}

const Images: FC<IImages> = ({
  images,
  setImages,
  handleDelete,
  handleAdd,
}) => {
  const [draggedImage, setDraggedImage] = useState<{
    order: number
    src: string
  } | null>(null)
  const dragStartHandler = (e: any, i: any) => {
    setDraggedImage(i)
  }
  const dragLeaveHandler = (e: any) => {
    e.target.style.opacity = 1
  }
  const dragEndHandler = (e: any) => {
    e.target.style.opacity = 1
  }
  const dragOverHandler = (e: any) => {
    e.preventDefault()
    e.target.style.opacity = 0.5
  }
  const dropHandler = (e: any, i: any) => {
    e.preventDefault()
    e.target.style.opacity = 1
    setImages(
      images.map((n: any) => {
        if (n.src === i.src) {
          return { ...n, order: draggedImage?.order }
        }
        if (n.src === draggedImage?.src) {
          return { ...n, order: i.order }
        }
        return n
      }),
    )
  }

  const sortImg = (a: any, b: any) => {
    if (a.order > b.order) {
      return 1
    } else {
      return -1
    }
  }

  const elements = images.sort(sortImg).map((i, index) => {
    return (
      <>
        <StyledImageContainer
          draggable
          onDragStart={e => dragStartHandler(e, i)}
          onDragLeave={e => dragLeaveHandler(e)}
          onDragEnd={e => dragEndHandler(e)}
          onDragOver={e => dragOverHandler(e)}
          onDrop={e => dropHandler(e, i)}
        >
          <StyledImageHeaderContainer>
            <div>Фото {index + 1}</div>
            <StyledImageLabel onClick={() => handleDelete(i.src)}>
              Удалить
            </StyledImageLabel>
          </StyledImageHeaderContainer>
          <StyledImage
            draggable={false}
            src={`${api.images}${i.src}`}
            // src={`https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc=`}
          />
        </StyledImageContainer>
      </>
    )
  })
  return (
    <ImagesContainer>
      <StyledImages>{elements}</StyledImages>
      <UploadButton htmlFor="hidden">Загрузить фото</UploadButton>
      <input
        multiple={true}
        style={{ display: 'none' }}
        id="hidden"
        type="file"
        onChange={e => handleAdd(e)}
      />
    </ImagesContainer>
  )
}

export default Images
