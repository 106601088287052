import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetProductsActionResponse,
  GetProductActionResponse,
  CreateProductActionResponse,
  UpdateProductActionResponse,
  DeleteProductActionResponse,
  GetProductActionPayload,
  CreateProductActionPayload,
  UpdateProductActionPayload,
  DeleteProductActionPayload,
} from './types'

export const getProductsAction = createApiActions<
  void,
  GetProductsActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/GET_PRODUCTS'))

export const getProductAction = createApiActions<
  GetProductActionPayload,
  GetProductActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/GET_PRODUCT'))

export const unsetProductAction = createAction(' PRODUCTS/UNSET_PRODUCT')

export const createProductAction = createApiActions<
  CreateProductActionPayload,
  CreateProductActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/CREATE_PRODUCT'))

export const updateProductAction = createApiActions<
  UpdateProductActionPayload,
  void,
  AxiosError
>(createActionTypes('PRODUCTS/UPDATE_PRODUCT'))

export const deleteProductAction = createApiActions<
  DeleteProductActionPayload,
  void,
  AxiosError
>(createActionTypes('PRODUCTS/DELETE_PRODUCT'))
