import { Colors } from '../../assets'
import styled from 'styled-components'
import { FLEX, SHADOW } from '../../utils'
import { NavLink } from 'react-router-dom'

export const ChatMessagesWrapper = styled.div`
  ${FLEX({ direction: 'column', wrap: 'nowrap', justify: 'flex-start' })}
  width: 100%;
  max-width: 40%;
  border-right: 1px solid ${Colors.lightGray};
  height: 100vh;
  //   overflow: hidden;
`
export const ChatWrapper = styled.div`
  width: 100%;
  max-width: 60%;
  height: 100vh;
`

export const ChatContent = styled.div`
  ${FLEX({ direction: 'column', wrap: 'nowrap', justify: 'flex-start' })}
  align-self: flex-start;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

export const ChatHeader = styled.div<{ justify?: string }>`
  ${FLEX({ wrap: 'nowrap', align: 'center' })}
  width: 100%;
  padding: 0 30px;
  gap: 30px;
  justify-content: ${({ justify = 'flex-start' }) => justify};
  border-bottom: 1px solid ${Colors.gray};
  .green-link {
    font-weight: 500;
    color: ${Colors.green};
    cursor: pointer;
  }
  .red-link {
    font-weight: 500;
    color: ${Colors.red};
    cursor: pointer;
  }
`
export const ChatHeaderTab = styled.div`
  padding: 25px 0;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
  font-weight: 500;
  cursor: pointer;
  color: ${Colors.boldGray};
  &.active {
    border-bottom: 2px solid ${Colors.black};
    color: ${Colors.black};
  }
`
export const NavLinkWithIcon = styled(NavLink)<{ weight?: string }>`
  ${FLEX({ align: 'center', justify: 'flex-start', wrap: 'nowrap' })}
  gap: 8px;
  color: ${Colors.black};
  cursor: pointer;
  font-weight: ${({ weight = '400' }) => weight};
`
export const ProductPreview = styled.div`
  ${FLEX({ align: 'center', justify: 'flex-start', wrap: 'nowrap' })};
  gap: 10px;
  padding: 16px 0;
  .chat-product-preview {
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
`
export const ChatMessages = styled.div`
  ${FLEX({
    direction: 'column',
    justify: 'flex-start',
    wrap: 'nowrap',
  })};
  height: 100%;
  min-width: 100%;
  overflow-y: auto;
`
export const ChatMessagePreview = styled.div`
  ${FLEX({
    direction: 'row',
    justify: 'space-between',
    align: 'center',
    wrap: 'nowrap',
  })};

  cursor: pointer;
  border-bottom: 1px solid ${Colors.gray};
  font-size: 14px;
  gap: 15px;
  overflow: hidden;
  width: 100%;
  min-height: 100px;
  height: 100px;
  padding: 0 30px;
  &:hover {
    background: ${Colors.lightOrange};
  }
  &.active {
    background: ${Colors.orange};
  }
  img {
    height: 62px;
    width: 62px;
    object-fit: cover;
  }
  .message-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-name {
    font-weight: 500;
    // white-space: nowrap;
    font-size: 16px;
  }
  .message-topic {
    text-transform: uppercase;
    color: ${Colors.boldGray};
  }
  .message {
    white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // max-width: 300px;
  }
  .date-container {
    height: 100%;
    padding: 22px 0;
    min-width: 33px;
    ${FLEX({
      direction: 'column',
      align: 'center',
      justify: 'space-between',
    })};
  }
  .time {
    color: ${Colors.boldGray};
    width: 67px;
    text-align: end;
  }
  .counter {
    ${FLEX({
      align: 'center',
      justify: 'center',
    })};
    height: 25px;
    width: 25px;
    border-radius: 50%;
    overflow: hidden;
    color: ${Colors.white};
    background: ${Colors.green};
  }
`

export const ChatMessageWrapper = styled.div`
  ${FLEX({
    direction: 'column',
    wrap: 'nowrap',
    align: 'flex-end',
  })};
  gap: 10px;
  width: 100%;
  .chat-message {
    border-radius: 12px;
    background: ${Colors.lightGray};
    &:last-child {
      border-radius: 12px 12px 0 12px;
    }
  }
  &.customer {
    align-items: flex-start;
    .chat-message {
      background: ${Colors.orange};
      &:last-child {
        border-radius: 12px 12px 12px 0;
      }
    }
  }
`
export const ChatMessage = styled.div`
  ${FLEX({
    direction: 'column',
    align: 'flex-start',
    wrap: 'nowrap',
  })};
  padding: 14px 14px 30px 14px;
  max-width: 550px;
  min-width: 90px;
  line-height: 20px;
  position: relative;
  background: ${Colors.lightGray};
  border-radius: 12px 12px 0 12px;
  align-self: end;

  .chat-img {
    height: 100%;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
    object-fit: contain;
    // border-radius: 12px;
    cursor: pointer;
    padding-bottom: 10px;
  }
  &.customer {
    background: ${Colors.orange};
    border-radius: 12px 12px 12px 0;
    align-self: start;
    .message-icon {
      display: none;
    }
  }
  .time {
    ${FLEX({
      direction: 'row-reverse',
      align: 'center',
      justify: 'space-between',
    })};
    padding: 0 10px;
    width: 100%;
    font-size: 14px;
    position: absolute;
    left: 0;
    bottom: 5px;
    gap: 4px;
    color: ${Colors.boldGray};
  }
  .message-time-icon {
    width: 18px;
  }
`

export const FilePreview = styled.div`
  ${FLEX({ align: 'center' })};
  gap: 10px;
  font-weight: 500;
  padding-bottom: 10px;
  cursor: pointer;
  img {
    width: 40px;
  }
`

export const ActiveChat = styled.div`
  ${FLEX({
    direction: 'column-reverse',
    justify: 'flex-start',
    wrap: 'nowrap',
  })};
  height: 100%;
  overflow: hidden;
  min-width: 100%;
`
export const ActiveChatContainer = styled.div`
  ${FLEX({
    direction: 'column',
    justify: 'flex-start',
    wrap: 'nowrap',
  })};
  gap: 10px;
  min-width: 100%;
  overflow-y: auto;
  padding: 30px;
`

export const MessageEditor = styled.div`
  ${FLEX({
    justify: 'flex-start',
    wrap: 'nowrap',
    align: 'center',
  })};
  border-top: 1px solid ${Colors.gray};
  min-height: 55px;
  width: 100%;
  overflow: hidden;
  padding: 0 30px;
  gap: 15px;
  img {
    width: 24px;
    cursor: pointer;
  }
  textarea {
    resize: none;
    border: 0;
    width: 100%;
    outline: none;
    padding-top: 17px;
  }
  .send-message-btn {
    font-weight: 500;
    cursor: pointer;
  }
  .load-file {
    display: none;
  }
  .upload-file-preview {
    width: 40px;
    object-fit: contain;
    position: relative;
  }
  .upload-file-preview-img {
    width: 100%;
  }
  .delete-preview-icon {
    width: 16px;
    position: absolute;
    right: -15px;
    top: -10px;
  }
`
