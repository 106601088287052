import { Colors } from '../../assets'
import styled from 'styled-components'
import { FLEX } from '../../utils'

export const FinanceHeader = styled.div`
  ${FLEX({ justify: 'flex-start', align: 'center' })}
  padding: 20px 30px;
`

export const RowSpaceBetween = styled.div`
  ${FLEX({ justify: 'space-between', align: 'center', wrap: 'nowrap' })}
  gap: 60px;
`
export const SumBlock = styled.div<{ bg: string; color: string }>`
  ${FLEX({ justify: 'center', align: 'center', direction: 'column' })}
  text-align: center;
  width: 33%;
  gap: 20px;
  padding: 36px;
  border-radius: 24px;
  overflow: hidden;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
  .sum {
    font-size: 32px;
    font-weight: 700;
  }
`
export const DateInputsWrapper = styled.div`
  ${FLEX({ align: 'center', wrap: 'nowrap' })}
  gap: 20px;
  margin-left: 100px;
`
export const Styles = styled.div`
  overflow-x: auto;
  padding-bottom: 15px;
  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${Colors.lightGray};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray};
    border-radius: 20px;
  }

  .table {
    display: inline-block;
    border-spacing: 0;

    .th {
      color: ${Colors.boldGray};
      font-weight: 500;
    }

    .th,
    .td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
      padding: 20px 30px;
      border-bottom: 1px solid ${Colors.lightGray};
      position: relative;
      display: flex !important;
      align-items: center;
      .resizer {
        display: inline-block;
        background: ${Colors.gray};
        width: 6px;
        height: 50%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        z-index: 1;
        touch-action: none;
        &.isResizing {
          background: ${Colors.boldGray};
        }
      }
      &:last-child {
        overflow: visible;
      }
    }
  }
`
export const NotesWrapper = styled.div`
  position: relative;
  .notes-icon {
    cursor: pointer;
  }
  &:hover {
    .notes-textarea {
      display: block;
    }
  }
  .notes-textarea {
    position: absolute;
    right: 0;
    bottom: -40px;
    display: none;
    width: 400px;
    height: 120px;
    border-radius: 16px;
    padding: 14px;
    box-shadow: 0px 8px 16px #43434329;
    border: 0;
    resize: none;
    outline: none;
    background: ${Colors.white}
    z-index: 500444;
    &:focus {
      display: block;
    }
  }
`
export const FinanceContractInput = styled.input`
  border: 0;
  outline: 0;
  height: 100%;
`
