import React, { FC } from 'react'
import { InfoLabel, InfoInput, InfoTextArea } from './styled'

interface IInput {
  title?: string
  handleChange?: any
  value?: string
  name?: string
  error?: string
  width?: string
  margin?: string
}

const TextArea: FC<IInput> = ({
  title,
  handleChange,
  value,
  name,
  error,
  width = '100%',
  margin = '0',
}) => {
  return (
    <div style={{ width: width, margin: margin }}>
      <InfoLabel htmlFor={name}>
        {title}
        {error ? <span style={{ color: 'red' }}>*</span> : null}
      </InfoLabel>
      <InfoTextArea name={name} value={value} onChange={handleChange} />
    </div>
  )
}

export default TextArea
