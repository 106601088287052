export const $customers: Array<any> = [
  {
    _id: '1',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '2',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '3',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '4',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '5',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },

  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '2',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '3',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '4',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '5',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
  {
    _id: '6',
    name: 'Dummy',
    email: 'dummy@dummy.com',
    phone: '+380111111111',
    createdAt: '2021-07-27T06:34:34.419Z',
    movies: 1,
  },
]
