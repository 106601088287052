import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import { getColorsAction, getColorAction, createColorAction } from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { ColorAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'

function* getColorsWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<ReturnType<typeof ColorAPI.getColors>> =
      yield call([ColorAPI, ColorAPI.getColors], {
        authorization: token,
      })
    yield put(getColorsAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категорий')),
    )
    yield put(getColorsAction.failure(e))
  }
}

function* getColorByIDWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<ReturnType<typeof ColorAPI.getColor>> =
      yield call([ColorAPI, ColorAPI.getColor], {
        authorization: token,
        id: payload.id,
      })

    yield put(getColorAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категории')),
    )

    yield put(getColorAction.failure(e))
  }
}

function* createColorWorker({
  payload,
}: ReturnType<typeof createColorAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)
    const response: PromiseReturnType<ReturnType<typeof ColorAPI.createColor>> =
      yield call([ColorAPI, ColorAPI.createColor], {
        authorization: token,
        data: payload,
      })
    if (response) {
      const res: PromiseReturnType<ReturnType<typeof ColorAPI.getColors>> =
        yield call([ColorAPI, ColorAPI.getColors], {
          authorization: token,
        })
      yield put(createColorAction.success(response.data))
      yield put(getColorsAction.success(res.data))
    }

    yield put(
      showToastAction.request(generateSuccessToast('Материал успешно создан')),
    )
  } catch (e) {
    console.log('Error: createColorWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания материала')),
    )

    yield put(createColorAction.failure(e))
  }
}

// function* deleteColorWorker({ payload }: { payload: { id: string } }) {
//   try {
//     const { token }: ReturnType<typeof getUserSelector> = yield select(
//       getUserSelector,
//     )

//     const response: PromiseReturnType<
//       ReturnType<typeof ColorAPI.deleteColor>
//     > = yield call([ColorAPI, ColorAPI.deleteColor], {
//       authorization: token,
//       id: payload.id,
//     })

//     yield put(deleteColorAction.success())

//     yield put(
//       showToastAction.request(generateInfoToast('Категория успешно удалена')),
//     )

//     yield put(getColorsAction.request())
//   } catch (e) {
//     console.log('Error: deleteColorWorker', e)

//     yield put(
//       showToastAction.request(generateErrorToast('Ошибка удаления категории')),
//     )

//     yield put(deleteColorAction.failure(e))
//   }
// }

export function* colorsWatcher() {
  yield takeLatest(getColorsAction.request, getColorsWorker)
  yield takeLatest(getColorAction.request, getColorByIDWorker)
  yield takeLatest(createColorAction.request, createColorWorker)
  // yield takeLatest(deleteColorAction.request, deleteColorWorker)
}
