import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Log } from '../utils'

export class HttpService {
  static async request<T = any>(
    identifier: string,
    axiosConfig: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    const { method, url } = axiosConfig
    const baseMessage = `${method} ⟴ Axios ⟴ ${identifier} ⟴ URL: ${url}`

    try {
      const response = await axios.request(axiosConfig)

      Log.zelda(`${response.status} ${baseMessage}`, response)

      return response
    } catch (e: any) {
      Log.ruddy(
        `${e?.response?.status || 'UNANABLE'} ${baseMessage}`,
        e?.response || e,
      )

      throw new Error(e)
    }
  }
}
