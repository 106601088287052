import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  getUserAction,
  logoutAction,
  loginAction,
  initAppAction,
  changeUserDataAction,
  changeUserPhotoAction,
  changePasswordAction,
  changeEmailAction,
} from './actions'

const InitialState: TInitialState = {
  user: null,
  token: null,
  loading: false,
}

export const userReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(loginAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(loginAction.success, (state, { payload }) => ({
      ...state,
      token: payload.access_token,
      user: payload.data,
      loading: false,
    }))
    builder.addCase(loginAction.failure, (state, { payload }) => ({
      ...state,
      token: null,
      user: null,
      loading: false,
    }))

    builder.addCase(getUserAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getUserAction.success, (state, { payload }) => ({
      ...state,
      user: payload.data,
      loading: false,
    }))
    builder.addCase(getUserAction.failure, (state, { payload }) => ({
      ...state,
      token: null,
      user: null,
      loading: false,
    }))

    builder.addCase(logoutAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(logoutAction.success, state => ({
      ...state,
      user: null,
      token: null,
      loading: false,
    }))
    builder.addCase(logoutAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }))

    builder.addCase(initAppAction.failure, (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false,
    }))

    builder.addCase(changeUserDataAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(changeUserDataAction.success, (state, { payload }) => ({
      ...state,
      user: { ...state.user, name: payload.name },
      loading: false,
    }))
    builder.addCase(changeUserDataAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }))

    builder.addCase(changeUserPhotoAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(changeUserPhotoAction.success, (state, { payload }) => ({
      ...state,
      user: payload.data,
      loading: false,
    }))
    builder.addCase(changeUserPhotoAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }))

    builder.addCase(changePasswordAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(changePasswordAction.success, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(changePasswordAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }))

    builder.addCase(changeEmailAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(changeEmailAction.success, state => ({
      ...state,
      loading: false,
    }))
    builder.addCase(changeEmailAction.failure, state => ({
      ...state,
      loading: false,
    }))
  },
)
