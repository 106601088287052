import { FLEX } from './../../utils/css'
import { Colors } from './../../assets/colors'
import styled from 'styled-components'
import Modal from 'react-modal'

export const StyledRadio = styled.input`
  background: ${Colors.white};
  border: ${Colors.gray} 1px solid;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  appearance: none;

  &:hover {
    cursor: pointer;
    border: ${Colors.boldGray} 1px solid;
  }
  &:checked {
    background: radial-gradient(${Colors.green} 40%, rgba(255, 0, 0, 0) 50%);
  }
`

export const RowWrapper = styled.div`
  ${FLEX({ direction: 'row', justify: 'space-between' })}
`

export const RowStartWrapper = styled.div<{ width?: string }>`
  ${FLEX({ direction: 'row', justify: 'flex-start', wrap: 'nowrap' })}
  width: ${({ width = '100%' }) => width};
  gap: 30px;
  &.align-top {
    align-items: start;
  }
  &.border-bottom {
    border-bottom: 1px solid ${Colors.lightGray};
    padding: 0 30px;
  }
`

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Radio = styled.div`
  display: flex;
`

export const InfoLabel = styled.label`
  margin-left: 8px;
  font-weight: bold;
`

export const InfoInput = styled.input`
  margin: 4px 30px 0 0;
  padding: 10px 0 9px 8px;
  height: 40px;
  width: calc(100% - 30px);
  border: ${Colors.gray} 1px solid;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
`

export const InfoTextArea = styled.textarea`
  margin: 4px 30px 0 0;
  padding: 10px 8px;
  height: 100px;
  width: calc(100% - 30px);
  border: ${Colors.gray} 1px solid;
  border-radius: 3px;
  resize: vertical;

  &:focus {
    outline: none;
  }
`

export const StyledModal = styled(Modal)`
  .ReactModal__Content {
    padding: 50px 80px;
    width: 450px;
    height: 450px;
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    background-color: ${Colors.white};
    & > h1 {
      font-size: 25px;
      text-align: center;
      color: ${Colors.boldGray};
    }
    & > form {
      display: flex;
      flex-direction: column;
      // align-items: center;
    }
    & > form > label {
      margin: 5px 0 0 5px;
      font-weight: bold;
    }
    & > form > input {
      margin-top: 5px;
      padding: 10px 0 9px 8px;
      height: 35px;
      width: 100%;
      border: ${Colors.gray} 1px solid;
      border-radius: 3px;
      &:focus {
        outline: none;
      }
    }
    & > form > button {
      margin-top: 30px;
      width: 100px;
      font-weight: bold;
      cursor: pointer;
      padding: 10px 10px;
      border-radius: 8px;
      box-shadow: 0px 3px 6px ${Colors.orange};
      background: ${Colors.orange};
      border: none;
    }
    & > div {
      margin-left: auto;
    }
  }
`

export const ModalOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 500;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);
`

export const ModalButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: ${Colors.orange};
  border-radius: 5px;
  width: 40px;
  height: 40px;
  margin-top: 18px;
  /* margin: 16px 30px 0 0; */
  & > img {
    width: 20px;
    height: 20px;
    margin: 0;
  }
`

export const CreateModalButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 65px;
  margin: 20px 0;
  background: ${Colors.orange};
  border-radius: 5px;
  /* width: 40px;
  height: 40px; */
  font-weight: bold;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  /* margin: 16px 30px 0 0; */
  & > img {
    width: 20px;
    height: 20px;
    margin: 0;
  }
`

export const UploadButton = styled.label`
  margin: 0 0 10px 0;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${Colors.green};
  color: ${Colors.white};
  background: ${Colors.green};
  /* width: 200px;
  display: flex; */
  display: inline-block;
`

export const ImageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  & > label {
    text-decoration: underline;
    color: ${Colors.red};
  }
`

export const AddItemModalContent = styled.div`
  padding: 40px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 50px 80px; */
  width: 1000px;
  height: 90vh;
  border-radius: 25px;
  background-color: ${Colors.white};
  & > h1 {
    margin: 0;
    color: ${Colors.boldGray};
  }
  & > input {
    margin: 30px 0;
    border: ${Colors.gray} 1px solid;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    &:focus {
      outline: none;
      border: ${Colors.boldGray} 1px solid;
    }
  }
`

export const ModalContent = styled.div`
  padding: 50px 80px;
  width: 450px;
  height: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  background-color: ${Colors.white};
  & > h1 {
    font-size: 25px;
    text-align: center;
    color: ${Colors.boldGray};
  }
  & > form {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  & > form > label {
    margin: 5px 0 0 5px;
    font-weight: bold;
  }
  & > form > input {
    margin-top: 5px;
    padding: 10px 0 9px 8px;
    height: 35px;
    width: 100%;
    border: ${Colors.gray} 1px solid;
    border-radius: 3px;
    &:focus {
      outline: none;
    }
  }
  & > form > button {
    margin-top: 30px;
    width: 100px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px ${Colors.orange};
    background: ${Colors.orange};
    border: none;
  }
  & > div {
    margin-left: auto;
  }

  & > div > img {
    &:hover {
      cursor: pointer;
    }
  }
`

export const CharacteristicsModalContent = styled.div`
  padding: 50px 80px;
  width: 1000px;
  height: 600px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  background-color: ${Colors.white};
  & > h1 {
    font-size: 25px;
    text-align: center;
    color: ${Colors.boldGray};
  }
  & > form {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  & > form > label {
    margin: 5px 0 0 5px;
    font-weight: bold;
  }
  & > form > input {
    margin-top: 5px;
    padding: 10px 0 9px 8px;
    height: 35px;
    width: 100%;
    border: ${Colors.gray} 1px solid;
    border-radius: 3px;
    &:focus {
      outline: none;
    }
  }
  & > form > button {
    margin-top: 30px;
    width: 100px;
    font-weight: bold;
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px ${Colors.orange};
    background: ${Colors.orange};
    border: none;
  }

  & .row {
    ${FLEX({ justify: 'flex-start', direction: 'column', align: 'flex-start' })}
    width: 100%;
  }
  & .input {
    margin: 10px 0;
    ${FLEX({ justify: 'flex-start', direction: 'column', align: 'flex-start' })}
    & > input {
      height: 40px;
      width: 100%;
      border: ${Colors.gray} 1px solid;
      border-radius: 5px;
    }
    & > label {
      font-weight: bold;
    }
  }
  & > div > img {
    &:hover {
      cursor: pointer;
    }
  }
`

export const SubcategoryModalContent = styled.div`
  padding: 50px 80px;
  width: 1000px;
  height: fit-content;
  border-radius: 25px;
  background-color: ${Colors.white};
  ${FLEX({ direction: 'column', wrap: 'nowrap' })}

  & .subcategory {
    margin: 10px 0;
    ${FLEX({ align: 'center', justify: 'center' })}
  }
  & .clickable:hover {
    cursor: pointer;
  }
  & .row {
    ${FLEX({ justify: 'space-between', direction: 'row', align: 'flex-start' })}
  }
  & .input {
    margin: 10px 0;
    ${FLEX({ justify: 'flex-start', direction: 'column', align: 'flex-start' })}
    & > input {
      height: 40px;
      width: 100%;
      border: ${Colors.gray} 1px solid;
      border-radius: 5px;
    }
    & > label {
      font-weight: bold;
    }
  }
  & .file-input {
    margin: 10px 0;
    ${FLEX({ justify: 'flex-start', direction: 'column', align: 'flex-start' })}
    & img {
      width: 150px;
      height: 150px;
    }
    & a {
      color: ${Colors.red};
      cursor: pointer;
    }
  }
  & .title {
    font-weight: bold;
    font-size: larger;
  }

  & .delete {
    color: red;
    font-size: 30px;

    transform: rotate(45deg);
    &:hover {
      cursor: pointer;
    }
  }

  & .bold {
    font-weight: bold;
  }

  & .lang-column {
    & > div {
      margin: 5px;
    }
  }
`

export const ModalFlexWrapper = styled.div`
  height: 100%;
  margin: 20px 0;
  ${FLEX({ direction: 'row', justify: 'flex-start', align: 'flex-start' })}
`

export const ModalColumn = styled.div`
  padding: 0 20px;
  height: 100%;
  width: 50%;
`

export const ModalInputWrapper = styled.div`
  ${FLEX({ justify: 'space-between' })}
`

export const ColoredRadio = styled.input`
  background: ${Colors.white};
  border: ${Colors.gray} 1px solid;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  appearance: none;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
    border: ${Colors.boldGray} 1px solid;
  }
  &:checked {
    background: radial-gradient(${Colors.green} 40%, rgba(255, 0, 0, 0) 50%);
  }
`

export const HoverPointer = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export const Clickable = styled.span`
  &:hover {
    cursor: pointer;
  }
`

export const ScrollBox = styled.div`
  max-height: 300px;
  overflow-y: auto;
`

export const BorderDumbButton = styled.button<{
  brd: string
  bg: string
  txt: string
}>`
  font-weight: bold;
  cursor: pointer;
  padding: 10px 65px;
  border-radius: 8px;
  border: 1px solid ${({ brd }) => brd};
  box-shadow: 0px 3px 6px ${({ bg }) => bg};
  background: ${({ bg }) => bg};
  color: ${({ txt }) => txt};
  ${FLEX({})};
  outline: none;
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MainTitle = styled.div`
  font-size: 1.7rem;
  font-weight: 600;
  color: ${Colors.black};
`

export const SecTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${Colors.boldGray};
`

export const MinTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  color: ${Colors.black};
`

export const FixedValues = styled.div`
  display: flex;
  flex-direction: row;
`

export const Value = styled.div`
  margin: 0 20px;
`
export const ChangePasswordModalContent = styled.div`
  padding: 30px 80px 22px;
  border-radius: 24px;
  background-color: ${Colors.white};
`

export const ProfileModalWrapper = styled.div`
  margin: 20px 0;
  padding: 35px;
  border-radius: 24px;
  background-color: ${Colors.white};
  ${FLEX({ direction: 'column', align: 'center' })}
`
export const ModalWrapperWithoutPadding = styled.div`
  border-radius: 24px;
  background-color: ${Colors.white};
  ${FLEX({ direction: 'column', align: 'center' })};
  overflow: hidden;
  outline: 0;
  img {
    width: 100%;
    max-width: 600px;
  }
`

export const HelpText = styled.div`
  color: ${Colors.boldGray};
  line-height: 19px;
  max-width: 300px;
  margin: 8px 0;
`
