import styled from 'styled-components'
import { Colors } from '../../assets'

export const OrderedItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
  justify-content: space-between;
  & > .overview {
    display: flex;
    align-items: center;
    width: 300px;
    & > div > .title {
      font-weight: bold;
    }
    & > div > .id {
      color: ${Colors.boldGray};
    }
  }
  & > .count {
    width: 160px;
    height: 40px;
    display: flex;
    & > a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 24px;
      height: 100%;
      width: 50px;
      background: ${Colors.lightGray};
      border: none;
      &:hover {
        cursor: pointer;
      }
    }
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 60px;
      padding: auto;
      border: none;
      border-top: ${Colors.lightGray} 1px solid;
      border-bottom: ${Colors.lightGray} 1px solid;
    }
  }
  & > .delete {
    display: flex;
    padding: 5px;
    /* background: ${Colors.gray}; */
    color: ${Colors.red};
    /* border-radius: 5px; */
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
  & > .sum {
    width: 200px;
    font-weight: bold;
  }
`

export const OrderFooter = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  & > .column-container {
    display: flex;
    & > .column {
      display: flex;
      flex-direction: column;
      margin-left: 60px;
      & > .total {
        color: ${Colors.green};
      }
    }
  }
`

export const ButtonElement = styled.label`
  height: 45px;
  display: flex;
  align-items: center;
  background: ${Colors.green};
  color: ${Colors.white};
  border-radius: 8px;
  border: none;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`

export const StyledCounter = styled.div`
  width: 160px;
  height: 40px;
  display: flex;
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    height: 100%;
    width: 50px;
    background: ${Colors.lightGray};
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 60px;
    padding: auto;
    border: none;
    border-top: ${Colors.lightGray} 1px solid;
    border-bottom: ${Colors.lightGray} 1px solid;
  }
`

export const SaveButton = styled.label`
  margin-left: 20px;
  font-weight: bold;
  cursor: pointer;
  padding: 14px 65px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${Colors.orange};
  background: ${Colors.orange};
  display: flex;
`
