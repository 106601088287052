import styled from 'styled-components'
import { Colors } from '../../assets'
import { FLEX, FONT, SHADOW } from '../../utils'
import { TSize } from './types'

export const Header = styled.div`
  width: 100%;
  background: ${Colors.white};

  ${FLEX({ justify: 'space-between' })}
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Title = styled.h1`
  margin-right: 85px;
  ${FONT({ size: '24px', color: Colors.boldGray, weight: '700' })}
`

export const CreationDate = styled.div`
  margin-top: 6px;
  color: ${Colors.boldGray};
`

export const TitleGroup = styled.div`
  display: flex;
  flex-direction: column;
`

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: ${Colors.boldGray};
`

export const ButtonContainer = styled.div<TSize>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    margin-left: 30px;
  }
`

export const Content = styled.div`
  margin-top: 22px;
  background: ${Colors.white};
  border: 1px solid ${Colors.lightGray};
  border-radius: 24px;
  box-shadow: 0px 8px 20px ${Colors.shadowGray};
  ${FLEX({ direction: 'row', justify: 'flex-start', align: 'baseline' })}
`

export const Row = styled.div`
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
  width: 100%;
  ${FLEX({ justify: 'flex-start', align: 'baseline' })}
`

export const Column = styled.div`
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
  width: 50%;
  ${FLEX({ direction: 'column', justify: 'flex-start', align: 'baseline' })}
`

export const SubColumnContainer = styled.div`
  width: 100%;
  display: flex;
`

export const SubColumn = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 50%;
  margin: 0 15px 0 0;
  ${FLEX({ direction: 'column' })};
  &:last-of-type {
    margin: 0 0 0 15px;
  }
`

export const InfoTitle = styled.h3`
  margin: 0;
`

export const InfoContainer = styled.div`
  box-sizing: border-box;
  width: calc(25% - 60px);
  margin-right: 60px;
  ${FLEX({ direction: 'row', justify: 'flex-start', align: 'baseline' })};
`

export const SubInfoContainer = styled.div`
  width: calc(50% - 10px);
  ${FLEX({ direction: 'column', justify: 'flex-start', align: 'baseline' })};
  &:last-child {
    margin-left: 20px;
  }
`

export const InfoLabel = styled.label`
  margin-left: 8px;
  font-weight: bolder;
`

export const InfoInput = styled.input`
  box-sizing: border-box;
  margin-top: 4px;
  padding: 10px 0 9px 8px;
  height: 40px;
  width: 100%;
  border: ${Colors.gray} 1px solid;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${Colors.lightGray};
  }
`

export const SubInfoInput = styled(InfoInput)`
  width: 50%;
`

export const InfoPhoto = styled.div`
  margin-top: 30px;
  display: flex;
  & > div {
    margin-right: 37px;
  }
`

export const InfoTextArea = styled.textarea`
  margin-top: 4px;
  width: 100%;
  height: 212px;
  resize: none;
  border: ${Colors.gray} 1px solid;
  border-radius: 3px;
`

export const CheckboxContainer = styled.div`
  margin: 13px 0 20px 0;
  & > div > input {
    margin: 10px 5px;
  }
`

export const UploadButton = styled.label`
  font-weight: bold;
  cursor: pointer;
  padding: 10px 10px;
  margin-left: 30px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px ${Colors.orange};
  color: ${Colors.boldGray};
  background: ${Colors.orange};
  width: 200px;
  ${FLEX({})};
`
