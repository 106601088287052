import * as yup from 'yup'
import { errMsgs } from './errorMessages'

export const subcategorySchema = yup.object({
  en: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(1, errMsgs.min(1))
    .required(errMsgs.required),
  ru: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(1, errMsgs.min(1))
    .required(errMsgs.required),
  uz: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(1, errMsgs.min(1))
    .required(errMsgs.required),
})
