import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RowWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import { Title } from '../ProductCreate/styled'
import {
  ActiveChat,
  ActiveChatContainer,
  ChatContent,
  ChatHeader,
  ChatHeaderTab,
  ChatMessage,
  ChatMessagePreview,
  ChatMessages,
  ChatMessagesWrapper,
  ChatWrapper,
  FilePreview,
  MessageEditor,
  NavLinkWithIcon,
  ProductPreview,
} from './styled'
import { Assets } from '../../assets'
import { useDispatch, useSelector } from 'react-redux'
import { HelpWrapper } from '../Help/styled'
import { RelativeInput, RelativeInputContainer } from '../Roles/styled'
import {
  getChatsSelector,
  setAllNewChatsAction,
  setAllMyChatsAction,
  sendChatMessageAction,
  setActiveChatAction,
  acceptChatAction,
  setNewMessageToActiveChatAction,
  closeChatAction,
  readMessagesAction,
  setMyChatsByQueryAction,
  getAllActiveChatsAction,
} from '../../store/chat'
import { api, getUserSelector } from '../../store'
import { Filter } from '../../components/Filter'
import { IChat, IMessage, INewMessage } from '../../store/chat/types'
import { IChatTab } from './types'
import ProfileModal from '../../components/Modals/ProfileModal'
import clock from '../../assets/icons/clockIcon.svg'
import timesRed from '../../assets/icons/timesRed.svg'
import { debounce } from 'lodash'
import { useLocation } from 'react-router-dom'
import { formatDate, formatTime } from '../../helpers'

const Chat: FC = () => {
  const [activetab, setActiveTab] = useState<IChatTab>()
  const [newMessage, setNewMessage] = useState<INewMessage>({
    value: '',
    file: {
      file: '',
      src: '',
    },
  })
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [activeImage, setActiveImage] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>('')

  const { search } = useLocation()

  const newChatNotification = useMemo(
    () => new URLSearchParams(search).get('newChatNotification'),
    [search],
  )

  const notification = useMemo(
    () => new URLSearchParams(search).get('notification'),
    [search],
  )
  const dispatch = useDispatch()

  const { myChats, newChats, activeChat, allActiveChats, message } =
    useSelector(getChatsSelector)

  const { token, user } = useSelector(getUserSelector)

  const messagesEnd = useRef<HTMLDivElement>(null)

  //   const sortChatsByDate = arr => {
  //     const chats = [...arr]

  //     return chats.sort((a, b) => {
  //       let firstDate = Date.parse(a.createdAt)
  //       let secondDate = Date.parse(b.createdAt)

  //       if (a.messages.length > 0) {
  //         firstDate = Date.parse(a.messages[a.messages.length - 1]?.createdAt)
  //       } else {
  //         firstDate = Date.parse(a.createdAt)
  //       }

  //       if (b.messages.length > 0) {
  //         secondDate = Date.parse(b.messages[b.messages.length - 1]?.createdAt)
  //       } else {
  //         secondDate = Date.parse(b.createdAt)
  //       }

  //       return firstDate - secondDate
  //     })
  //   }

  //   const sortMessagesByDate = (arr: IMessage[]) => {
  //     return arr.sort((a: IChat | IMessage, b: IMessage | IChat) => {
  //       const firstDate = Number(new Date(a.createdAt))
  //       const secondDate = Number(new Date(b.createdAt))

  //       return firstDate - secondDate
  //     })
  //   }
  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  const chatTabs: IChatTab[] = [
    {
      title: 'Мои чаты',
      value: 'myChats',
      chats: myChats.docs,
    },
    {
      title: 'Новые чаты',
      value: 'newChats',
      chats: newChats,
    },
    checkPermission('chatGetAll') && {
      title: 'Все чаты',
      value: 'allChats',
      chats: allActiveChats.docs,
    },
  ]

  useEffect(() => {
    checkPermission('chatGetAll')
      ? dispatch(setAllNewChatsAction.request(''))
      : dispatch(setAllNewChatsAction.request('?my=true'))
    dispatch(setAllMyChatsAction.request())
    setActiveTab(chatTabs[0])
    checkPermission('chatGetAll') && dispatch(getAllActiveChatsAction.request())
  }, [])
  useEffect(() => {
    if (activetab?.value === 'newChats') {
      setActiveTab(chatTabs[1])
    } else if (activetab?.value === 'myChats') {
      setActiveTab(chatTabs[0])

      if (activeChat?._id || notification) {
        let newActiveChat = myChats.docs.find(chat => {
          //@ts-ignore
          return chat._id === activeChat._id
        })

        if (notification) {
          newActiveChat = myChats.docs.find(chat => {
            //@ts-ignore
            return chat._id === notification
          })
        }

        newActiveChat && dispatch(setActiveChatAction(newActiveChat))
      }

      if (newChatNotification) {
        const newAvailableChat = newChats.find(chat => {
          //@ts-ignore
          return chat._id === newChatNotification
        })

        setActiveTab(chatTabs[1])

        newAvailableChat && dispatch(setActiveChatAction(newAvailableChat))
      }
    }
  }, [newChats, myChats, notification])

  useEffect(() => {
    return () => {
      dispatch(setActiveChatAction({} as IChat))
    }
  }, [])
  useEffect(() => {
    if (message?.idChat && message.lastMsg.sender !== user._id) {
      if (message?.idChat === activeChat._id) {
        dispatch(setNewMessageToActiveChatAction(message))
        dispatch(
          readMessagesAction.request({
            chatId: message?.idChat,
            userId: message.user._id,
          }),
        )
      }
      dispatch(setAllMyChatsAction.request())
    }
  }, [message])
  useEffect(() => {
    if (messagesEnd.current) {
      const height = messagesEnd.current.scrollHeight
      messagesEnd.current.scrollTo(0, height)
    }
  }, [activeChat])

  const calculateNewMessages = (messages: IMessage[]): number => {
    console.log(messages)

    return messages.reduce((prev, current) => {
      if (current && !current.read && current.sender !== user._id) {
        return (prev += 1)
      }
      return prev
    }, 0)
  }

  const parseDate = (date: string): string => {
    const time = new Date(date).toLocaleTimeString().slice(0, -3)

    return time
  }

  const onSendMessage = () => {
    const message = new FormData()
    message.append('sender', user._id)
    message.append('toChat', activeChat._id)
    message.append('value', newMessage.value)
    newMessage.file.file && message.append('file', newMessage.file.file)

    if (newMessage.value !== '' || newMessage.file.file !== '') {
      dispatch(
        setActiveChatAction({
          ...activeChat,
          messages: [
            ...activeChat.messages,
            {
              read: false,
              createdAt: `${new Date(Date.now()).toISOString()}`,
              _id: `${Date.now()}`,
              value: newMessage.value,
              sender: user._id,
              file: newMessage.file.src,
              pending: true,
            },
          ],
        }),
      )

      dispatch(sendChatMessageAction.request(message))
    }

    setNewMessage({ value: '', file: { file: '', src: '' } })
  }

  const onSetActiveTab = tab => {
    dispatch(setActiveChatAction({} as IChat))
    setActiveTab(tab)
  }

  const onSetActiveChat = (chat: IChat) => {
    dispatch(setActiveChatAction(chat))
    dispatch(
      readMessagesAction.request({ chatId: chat._id, userId: chat.user?._id }),
    )
  }

  const onAcceptChat = () => {
    checkPermission('chatGetAll')
      ? dispatch(acceptChatAction.request({ _id: activeChat._id }))
      : dispatch(
          acceptChatAction.request({ _id: activeChat._id, query: '?my=true' }),
        )
    setActiveTab(chatTabs[0])
  }

  const onCloseChat = () => {
    dispatch(closeChatAction.request({ _id: activeChat._id }))
  }

  const onOpenImage = (img: string | undefined) => {
    img && setActiveImage(img)
    setIsModalOpen(true)
  }

  const isFileImg = (file: string) => {
    if (file.endsWith('png') || file.endsWith('jpg')) {
      return true
    }
    return false
  }

  const getFileName = (str: string | undefined, id: string) => {
    console.log('file name', str)

    if (str) {
      const from = str.search(id) + id.length + 1
      const to = str.length

      return str.substring(from, to)
    }
  }

  const onDownloadFile = async fileName => {
    const response = await fetch(
      `${api.withAPI}/chat/downloadFile/${activeChat._id}/${fileName}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    if (response.status === 200) {
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  useEffect(() => {
    if (notification && activetab?.chats) {
      const tab = activetab?.chats.find(chat => {
        //@ts-ignore
        return chat._id === notification
      })
      // tab && onSetActiveTab && onSetActiveTab(tab)
    }
  }, [notification, onSetActiveTab])

  const onUploadFile = (e: any) => {
    setNewMessage(prev => ({
      ...prev,
      value: prev.value,
      file: {
        file: e.target.files[0],
        src: e.target.files[0].type.includes('image')
          ? URL.createObjectURL(e.target.files[0])
          : '',
      },
    }))
  }

  const onDeleteUploadFile = () => {
    setNewMessage(prev => ({
      ...prev,
      value: prev.value,
      file: {
        file: '',
        src: '',
      },
    }))
  }

  const onSearchChat = (query: string) => {
    setSearchQuery(query)

    if (query === '') {
      dispatch(setAllMyChatsAction.request())
    }

    dispatchSearchQuery(query)
  }

  const dispatchSearchQuery = useCallback(
    debounce((query: string) => {
      if (query.match(/[0-9]/)) {
        dispatch(setMyChatsByQueryAction.request(`phone=${query}`))
      } else {
        dispatch(setMyChatsByQueryAction.request(`name=${query}`))
      }
    }, 300),
    [],
  )

  const checkDate = (messageDate: string, chatDate: string) => {
    let currentDate = Date.now()

    if ((currentDate - Date.parse(messageDate)) / 86400000 > 1) {
      return formatDate(chatDate)
    }

    return formatTime(messageDate)
  }

  return (
    <>
      <RowWrapper>
        <Title>Чат</Title>
      </RowWrapper>

      <SimpleDivider height="20px" />

      <HelpWrapper>
        <ChatMessagesWrapper>
          <ChatHeader>
            <RelativeInputContainer maxwidth="300px">
              <RelativeInput
                className="absolute"
                value={searchQuery}
                onChange={e => onSearchChat(e.target.value)}
                padding="0px 10px 0 35px"
                type="text"
                placeholder="Поиск..."
              />
              <img
                src={Assets.SEARCH_ICON}
                alt="search"
                className="search-icon"
              />
            </RelativeInputContainer>

            {chatTabs.map(
              tab =>
                tab.title && (
                  <ChatHeaderTab
                    className={tab.value === activetab?.value ? 'active' : ''}
                    onClick={() => onSetActiveTab(tab)}
                    key={tab.value}
                  >
                    {tab.title}
                  </ChatHeaderTab>
                ),
            )}
          </ChatHeader>

          <Filter
            categoriesProp={true}
            subcategoriesProp={true}
            ratingProp={true}
            chats={true}
          />

          <ChatMessages>
            {activetab?.chats ? (
              activetab?.chats.map(chat => (
                <ChatMessagePreview
                  key={chat._id}
                  onClick={() => onSetActiveChat(chat)}
                  className={activeChat?._id === chat._id ? 'active' : ''}
                >
                  <img
                    src={
                      chat.user.photo
                        ? `${api.images}${chat.user.photo}`
                        : Assets.USER_ICON
                    }
                    alt="user photo"
                  />

                  <div className="message-info">
                    <div className="user-name">
                      {chat?.user?.name ? chat.user.name : ''}
                    </div>
                    <div className="message-topic">
                      {chat?.product?.ru ? chat.product.ru : ''}
                    </div>
                    <div className="message">
                      {chat.messages.length > 0
                        ? chat.messages[chat.messages.length - 1].value
                        : ''}
                    </div>
                  </div>

                  <div className="date-container">
                    <div className="time">
                      {chat.messages.length > 0
                        ? checkDate(
                            chat.messages[chat.messages.length - 1].createdAt,
                            chat.createdAt,
                          )
                        : formatDate(chat.createdAt)}
                    </div>
                    {calculateNewMessages(chat.messages) ? (
                      <div className="counter">
                        {calculateNewMessages(chat.messages) > 9
                          ? '9+'
                          : calculateNewMessages(chat.messages)}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </ChatMessagePreview>
              ))
            ) : (
              <></>
            )}
          </ChatMessages>
        </ChatMessagesWrapper>

        <ChatWrapper>
          {activeChat?._id && (
            <ChatContent>
              <ChatHeader justify="space-between">
                <NavLinkWithIcon
                  to={`/clients/${activeChat.user?._id}`}
                  weight="500"
                >
                  {activeChat.user?.name}
                  <img src={Assets.GRAY_ARROW_ICON} alt="" />
                </NavLinkWithIcon>

                <ProductPreview>
                  <img
                    src={`${api.images}${activeChat.product?.images[0]}`}
                    alt="product"
                    className="chat-product-preview"
                  />
                  {activeChat.product?.ru}
                </ProductPreview>
                {activeChat.status === 'Accepted' && (
                  <div className="red-link" onClick={onCloseChat}>
                    Завершить чат
                  </div>
                )}
                {activeChat.status === 'Pending' && (
                  <div className="green-link" onClick={onAcceptChat}>
                    Начать чат
                  </div>
                )}
                {activeChat.status === 'Closed' && (
                  <div className="red-link">Чат завершен</div>
                )}
              </ChatHeader>

              <ActiveChat>
                {activeChat.status === 'Accepted' && (
                  <MessageEditor>
                    {newMessage.file.file ? (
                      <div className="upload-file-preview">
                        {newMessage.file.src ? (
                          <img
                            src={newMessage.file.src}
                            className="upload-file-preview-img"
                          />
                        ) : (
                          <img src={Assets.DOWNLOAD_FILE} alt="file" />
                        )}
                        <img
                          src={timesRed}
                          alt="delete file"
                          className="delete-preview-icon"
                          onClick={onDeleteUploadFile}
                        />
                      </div>
                    ) : (
                      <label htmlFor="chatFile">
                        <img src={Assets.GRAY_CLIP_ICON} alt="upload file" />
                      </label>
                    )}
                    <input
                      type="file"
                      id="chatFile"
                      className="load-file"
                      onChange={e => onUploadFile(e)}
                    />
                    <textarea
                      placeholder="Ваше сообщение..."
                      value={newMessage.value}
                      onChange={e =>
                        setNewMessage(prev => ({
                          ...prev,
                          value: e.target.value,
                        }))
                      }
                    ></textarea>
                    <div className="send-message-btn" onClick={onSendMessage}>
                      Отправить
                    </div>
                  </MessageEditor>
                )}

                <ActiveChatContainer ref={messagesEnd}>
                  {activeChat.messages &&
                    activeChat.messages.map(message => (
                      <ChatMessage
                        className={
                          message.sender === user._id ? '' : 'customer'
                        }
                        key={message._id}
                      >
                        {message?.file &&
                          (isFileImg(message?.file) ? (
                            <img
                              src={`${api.chatFiles}${message.file}`}
                              className="chat-img"
                              onClick={() => onOpenImage(message.file)}
                            />
                          ) : (
                            <FilePreview
                              onClick={() =>
                                onDownloadFile(
                                  getFileName(message?.file, activeChat._id),
                                )
                              }
                            >
                              <img src={Assets.DOWNLOAD_FILE} alt="file" />
                              {getFileName(message?.file, activeChat._id)}
                            </FilePreview>
                          ))}
                        <span className="message">{message.value}</span>

                        <div className="time">
                          <span>{parseDate(message.createdAt)}</span>

                          {message.pending && (
                            <img
                              className="message-time-icon"
                              src={clock}
                              alt="clock"
                            />
                          )}
                          {message.read && (
                            <img
                              className="message-icon"
                              src={Assets.GRAY_EYE_ICON}
                              alt="eye"
                            />
                          )}
                        </div>
                      </ChatMessage>
                    ))}
                  <ProfileModal
                    isModalOpen={isModalOpen}
                    onModalClose={() => setIsModalOpen(false)}
                    withoutPadding={true}
                  >
                    <img
                      src={`${api.chatFiles}${activeImage}`}
                      className="chat-modal-img"
                    />
                  </ProfileModal>
                </ActiveChatContainer>
              </ActiveChat>
            </ChatContent>
          )}
        </ChatWrapper>
      </HelpWrapper>
    </>
  )
}

export default Chat
