import { debounce } from 'lodash'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets } from '../../assets'
import { TableWrapper } from '../../components'
import { RowWrapper } from '../../components/Modals/styled'
import { Pagination } from '../../components/Pagination'
import { IPagination } from '../../components/Pagination/types'
import { SimpleDivider } from '../../components/Styled/formStyled'
import {
  getFinanceSelector,
  setAmountStatsAction,
  setSuppliersStatsAction,
} from '../../store/finance'
import { Title } from '../ProductCreate/styled'
import {
  ColumnsGrid,
  FlexColumnsWrapper,
  ItemNavLink,
  RelativeInput,
  RelativeInputContainer,
} from '../Roles/styled'
import { FinanceHeader, RowSpaceBetween, SumBlock } from './styled'

const FinanceStatistics: FC = () => {
  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    skip: 0,
  })

  const [searchQuery, setSearchQuery] = useState<string>('')

  const dispatch = useDispatch()

  const { suppliersStats, amountStats } = useSelector(getFinanceSelector)

  useEffect(() => {
    dispatch(
      setSuppliersStatsAction.request(
        `?limit=${pagination.limit}&skip=${pagination.skip}`,
      ),
    )
    dispatch(setAmountStatsAction.request())
  }, [])

  const onSearchSupplier = (query: string) => {
    setSearchQuery(query)

    if (query === '') {
      dispatch(
        setSuppliersStatsAction.request(
          `?limit=${pagination.limit}&skip=${pagination.skip}`,
        ),
      )
    } else {
      dispatchSearchQuery(query)
    }
  }

  const dispatchSearchQuery = useCallback(
    debounce((query: string) => {
      dispatch(
        setSuppliersStatsAction.request(
          `?limit=${pagination.limit}&skip=${pagination.skip}&name=${query}`,
        ),
      )
    }, 300),
    [],
  )

  return (
    <>
      <RowWrapper>
        <Title>Статистика финансов</Title>
      </RowWrapper>

      <SimpleDivider height="30px" />

      <RowSpaceBetween>
        <SumBlock bg="#E8FFE1" color="#39AE10">
          <div className="sum">
            {amountStats?.amountSum ? amountStats.amountSum + ' сум' : '...'}
          </div>
          <div className="description">Заказов выполнено на сумму</div>
        </SumBlock>

        <SumBlock bg="#FDFFE1" color="#AE8410">
          <div className="sum">
            {amountStats?.amountCash ? amountStats.amountCash + ' сум' : '...'}
          </div>
          <div className="description">Оплачено наличными</div>
        </SumBlock>

        <SumBlock bg="#EFE6FF" color="#76218F">
          <div className="sum">
            {amountStats?.amountNoCash
              ? amountStats.amountNoCash + ' сум'
              : '...'}
          </div>
          <div className="description">Оплачено картой</div>
        </SumBlock>
      </RowSpaceBetween>

      <SimpleDivider height="30px" />

      <TableWrapper>
        <FinanceHeader>
          <RelativeInputContainer maxwidth="300px">
            <RelativeInput
              value={searchQuery}
              onChange={e => onSearchSupplier(e.target.value)}
              padding="0px 10px 0 35px"
              type="text"
              placeholder="Поиск по имени..."
            />
            <img
              src={Assets.SEARCH_ICON}
              alt="search"
              className="search-icon"
            />
          </RelativeInputContainer>
        </FinanceHeader>

        <ColumnsGrid className="header" template="35% 20% 20% 20% 5%">
          <div>Поставщик</div>
          {/* <div>Телефон</div> */}
          <div></div>
          <div>Заказов</div>
          <div>Расход</div>
          <div></div>
        </ColumnsGrid>

        <FlexColumnsWrapper>
          {suppliersStats?.docs &&
            suppliersStats.docs.map(supplier => (
              <ColumnsGrid
                className="supplier-preview"
                template="35% 20% 20% 20% 5%"
                key={supplier._id}
              >
                <div>{supplier.name}</div>
                {/* <div>{supplier.phone ? supplier.phone : '+000000000000'}</div> */}
                <div></div>
                <div>{supplier.totalOrders}</div>
                <div>{supplier.expense} сум</div>
                <ItemNavLink to={`/finance/statistics/${supplier._id}`}>
                  <img src={Assets.SHOW_PASSWORD_ICON} alt="" />
                </ItemNavLink>
              </ColumnsGrid>
            ))}
        </FlexColumnsWrapper>

        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          count={suppliersStats.totalCount}
        />
      </TableWrapper>
    </>
  )
}

export default FinanceStatistics
