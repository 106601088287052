import React, { FC, useState } from 'react'
import { StyledImage } from '../../pages/ProductCreate/styled'
import { ImageHeader, UploadButton } from './styled'

interface IImages {
  files: any
  setFiles: any
  draggedImage: any
  setDraggedImage: any
  handleFile: any
  handleDelete: any
}

const Images: FC<IImages> = ({
  files,
  setFiles,
  draggedImage,
  setDraggedImage,
  handleFile,
  handleDelete,
}) => {
  const dragStartHandler = (e: any, i: any) => {
    setDraggedImage(i)
  }
  const dragLeaveHandler = (e: any) => {
    e.target.style.opacity = 1
  }
  const dragEndHandler = (e: any) => {
    e.target.style.opacity = 1
  }
  const dragOverHandler = (e: any) => {
    e.preventDefault()
    e.target.style.opacity = 0.5
  }
  const dropHandler = (e: any, i: any) => {
    e.preventDefault()
    e.target.style.opacity = 1
    setFiles(
      files.map((n: any) => {
        if (n.name === i.name) {
          return { ...n, order: draggedImage.order }
        }
        if (n.name === draggedImage.name) {
          return { ...n, order: i.order }
        }
        return n
      }),
    )
    setFiles(
      files.map((n: any) => {
        if (n.name === i.name) {
          return { ...n, order: draggedImage.order }
        }
        if (n.name === draggedImage.name) {
          return { ...n, order: i.order }
        }
        return n
      }),
    )
  }

  const sortImg = (a: any, b: any) => {
    if (a.order > b.order) {
      return 1
    } else {
      return -1
    }
  }

  return (
    <>
      <div
        style={{ display: 'flex', flexWrap: 'wrap', margin: '20px 10px 0 0' }}
      >
        {files.sort(sortImg).map((i: any, index: number) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '10px',
              }}
              draggable
              onDragStart={e => dragStartHandler(e, i)}
              onDragLeave={e => dragLeaveHandler(e)}
              onDragEnd={e => dragEndHandler(e)}
              onDragOver={e => dragOverHandler(e)}
              onDrop={e => dropHandler(e, i)}
            >
              <ImageHeader>
                <span>Фото {index + 1}</span>
                <label onClick={() => handleDelete(i.name)}>удалить</label>
              </ImageHeader>
              <StyledImage src={i.src} draggable={false} />
            </div>
          )
        })}
      </div>
      <UploadButton htmlFor="hiddenImageButton">Загрузить фото</UploadButton>
      <input
        multiple
        style={{ display: 'none' }}
        type="file"
        id="hiddenImageButton"
        onChange={e => handleFile(e)}
      />
    </>
  )
}

export default Images
