import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { changeEmailAction } from '../../store'
import { ChangeEmailTitle } from './styled'

export const ChangeEmail = () => {
  const dispatch = useDispatch()

  const search = useLocation().search

  const token = new URLSearchParams(search).get('token')

  useEffect(() => {
    if (token) {
      dispatch(changeEmailAction.request(token))
    }
  }, [])

  return <ChangeEmailTitle>Подтверждение изменения e-mail...</ChangeEmailTitle>
}
