import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetStylesActionResponse,
  GetStyleActionResponse,
  GetStyleActionPayload,
  CreateStyleActionPayload,
  CreateStyleActionResponse,
  DeleteStyleActionPayload,
} from './types'

export const getStylesAction = createApiActions<
  void,
  GetStylesActionResponse,
  AxiosError
>(createActionTypes('STYLE/GET_STYLES'))

export const getStyleAction = createApiActions<
  GetStyleActionPayload,
  GetStyleActionResponse,
  AxiosError
>(createActionTypes('STYLE/GET_STYLE'))

export const createStyleAction = createApiActions<
  CreateStyleActionPayload,
  CreateStyleActionResponse,
  AxiosError
>(createActionTypes('STYLE/CREATE_STYLE'))

export const deleteStyleAction = createApiActions<
  DeleteStyleActionPayload,
  void,
  AxiosError
>(createActionTypes('STYLE/DELETE_STYLE'))
