import React, { FC } from 'react'
import { StyledButton, Label } from './styled'
import { TButtonProps } from './types'

export const Button: FC<TButtonProps> = ({
  onClick,
  title,
  theme,
  icon = '',
}) => {
  return (
    <StyledButton
      onClick={onClick}
      background={theme.background}
      border={theme.border}
    >
      <Label color={theme.text}>
        {icon}
        <span>{title}</span>
      </Label>
    </StyledButton>
  )
}
