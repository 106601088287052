import { TRoutesTree } from './types'
import {
  LoginPage,
  TestPage,
  CustomersPage,
  CustomerPage,
  ProductsPage,
  ProductPage,
  ProductCreatePage,
  OrdersPage,
  OrderPage,
  OrderCreatePage,
  CategoriesPage,
  // CategoryPage,
  CategoryCreateReworkPage,
  BrandsPage,
  BrandPage,
  BrandCreatePage,
  SettingsPage,
  CategoryReworkPage,
  FeedbackPage,
  FeedbacksPage,
  ProfilePage,
} from '../pages'
import Help from '../pages/Help/Help'
import { Roles } from '../pages/Roles'
import UserProfile from '../pages/Roles/UserProfile'
import RoleSettings from '../pages/Roles/RoleSettings'
import CreateUserProfile from '../pages/Roles/CreateUserProfile'
import { Chat } from '../pages/Chat'
import { Finance } from '../pages/Finance'
import FinanceStatistics from '../pages/Finance/FinanceStatistics'
import SupplierOrders from '../pages/Finance/SupplierOrders'
import { ManagerStatistics } from '../pages/ManagerStatistics'
import ManagerStats from '../pages/ManagerStatistics/ManagerStats'
import { Stores } from '../pages/Stores'
import StoresList from '../pages/Stores/StoresList'
import { SubcategoryCreate } from '../pages/SubcategoryCreate'
import { Banners } from '../pages/Banners'
import { NotFound } from '../pages/NotFound/NotFound'
import { ChangeEmail } from '../pages/ChangeEmail/ChangeEmail'

export const RoutesTree: TRoutesTree = {
  login: {
    name: 'login',
    path: '/login',
    component: LoginPage,
  },
  client: {
    name: 'client',
    path: '/clients/:id',
    component: CustomerPage,
  },
  clients: {
    name: 'clients',
    path: '/clients',
    component: CustomersPage,
  },
  order: {
    name: 'order',
    path: '/orders/:id',
    component: OrderPage,
  },
  orderCreate: {
    name: 'orderCreate',
    path: '/order-create',
    component: OrderCreatePage,
  },
  orders: {
    name: 'orders',
    path: '/orders',
    component: OrdersPage,
  },
  main: {
    name: 'main',
    path: '/',
    component: ProfilePage,
  },
  chat: {
    name: 'chat',
    path: '/chat',
    component: Chat,
  },
  help: {
    name: 'help',
    path: '/help',
    component: Help,
  },
  products: {
    name: 'products',
    path: '/products',
    component: ProductsPage,
  },
  productCreate: {
    name: 'productCreate',
    path: '/product-create',
    component: ProductCreatePage,
  },
  product: {
    name: 'product',
    path: '/products/:id',
    component: ProductPage,
  },
  categories: {
    name: 'categories',
    path: '/categories',
    component: CategoriesPage,
  },
  category: {
    name: 'category',
    path: '/categories/:id',
    component: CategoryReworkPage,
  },
  categoryCreate: {
    name: 'categoryCreate',
    path: '/category-create',
    component: CategoryCreateReworkPage,
  },
  subcategory: {
    name: 'subcategory',
    path: '/subcategory/:id',
    component: SubcategoryCreate,
  },
  loyaltyProgram: {
    name: 'loyaltyProgram',
    path: '/loyalty-program',
    component: TestPage,
  },
  finance: {
    name: 'finance',
    path: '/finance',
    component: Finance,
  },
  financeStatistics: {
    name: 'financeStatistics',
    path: '/finance/statistics',
    component: FinanceStatistics,
  },
  financeSupplierStatistics: {
    name: 'financeSupplierStatistics',
    path: '/finance/statistics/:id',
    component: SupplierOrders,
  },
  banners: {
    name: 'banners',
    path: '/banners',
    component: Banners,
  },
  feedbacks: {
    name: 'feedbacks',
    path: '/feedback',
    component: FeedbacksPage,
  },
  feedback: {
    name: 'feedback',
    path: '/feedback/:id',
    component: FeedbackPage,
  },
  stores: {
    name: 'stores',
    path: '/stores',
    component: Stores,
  },
  storesList: {
    name: 'storesList',
    path: '/stores/:id',
    component: StoresList,
  },
  roles: {
    name: 'roles',
    path: '/roles',
    component: Roles,
  },
  rolesSettings: {
    name: 'rolesSettings',
    path: '/roles/settings',
    component: RoleSettings,
  },
  rolesProfile: {
    name: 'rolesProfile',
    path: '/roles/profile/:id',
    component: UserProfile,
  },
  rolesCreateProfile: {
    name: 'rolesCreateProfile',
    path: '/roles/create-profile',
    component: CreateUserProfile,
  },
  managersStats: {
    name: 'managersStats',
    path: '/managersStats',
    component: ManagerStatistics,
  },
  managerStats: {
    name: 'managerStats',
    path: '/managersStats/:id',
    component: ManagerStats,
  },
  settings: {
    name: 'settings',
    path: '/settings',
    component: SettingsPage,
  },
  brand: {
    name: 'brand',
    path: '/brands/:id',
    component: BrandPage,
  },
  brands: {
    name: 'brands',
    path: '/brands',
    component: BrandsPage,
  },
  brandCreate: {
    name: 'brandCreate',
    path: '/brand-create',
    component: BrandCreatePage,
  },
  profile: {
    name: 'profile',
    path: '/profile',
    component: ProfilePage,
  },
  changeEmail: {
    name: 'changeEmail',
    path: '/change-email',
    component: ChangeEmail,
  },
  notFound: {
    name: 'notFound',
    path: '/not-found',
    component: NotFound,
  },
}
