import { SHADOW } from './../../utils/css'
import styled from 'styled-components'
import { FLEX, FONT } from '../../utils'
import { Colors } from '../../assets'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  ${FLEX({ justify: 'space-between' })}
`

export const LoginWrapper = styled.div`
  width: 40vw;

  padding: 5% 8%;

  border-radius: 24px;

  background: ${Colors.white};

  ${SHADOW({})}
`

export const Title = styled.h1`
  ${FONT({
    size: '24px',
    weight: '700',
    align: 'center',
  })}
`

export const Link = styled.p`
  text-decoration: underline;

  cursor: pointer;

  ${FONT({
    size: '16px',
    weight: '600',
    align: 'center',
    color: Colors.boldGray,
  })}
`

export const Image = styled.img`
  height: 100vh;
  width: 50vw;
  /* resize-mode: cover; */
`

export const LoginContainer = styled.div`
  width: 50vw;
  height: 100vh;

  ${FLEX({})}
`

export const ButtonWrapper = styled.div`
  max-width: 200px;
  width: 100%;

  margin: auto;

  /* ${FLEX({})} */
`
