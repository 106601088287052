import { createReducer } from '@reduxjs/toolkit'
import { Orders, OrdersStatistic, TInitialState } from './types'
import {
  getOrderAction,
  unsetOrderAction,
  getOrdersAction,
  createOrderAction,
  updateOrderAction,
  deleteOrderAction,
  searchOrdersByQueryAction,
  searchOrdersByDateAction,
  sortOrdersByQueryAction,
  setOrdersFilterAction,
  getOrdersStatisticAction,
  getNotifyOrdersAction,
  getOrdersByOrderAction,
  getArchiveOrdersAction,
  getMyOrdersAction,
} from './actions'

const InitialState: TInitialState = {
  orders: {} as Orders,
  ordersStatistic: {} as OrdersStatistic,
  order: null,
  filter: '',
  loading: false,
}

export const ordersReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(getMyOrdersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getMyOrdersAction.success, (state, { payload }) => ({
      ...state,
      orders: { orders: payload.data, totalCount: payload.totalCount },
      loading: false,
    }))
    builder.addCase(getMyOrdersAction.failure, (state, { payload }) => ({
      ...state,
      orders: {} as Orders,
      loading: false,
    }))

    builder.addCase(getOrdersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getOrdersAction.success, (state, { payload }) => ({
      ...state,
      orders: payload.data,
      loading: false,
    }))
    builder.addCase(getOrdersAction.failure, (state, { payload }) => ({
      ...state,
      orders: {} as Orders,
      loading: false,
    }))

    builder.addCase(getNotifyOrdersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getNotifyOrdersAction.success, (state, { payload }) => ({
      ...state,
      orders: payload.data,
      loading: false,
    }))
    builder.addCase(getNotifyOrdersAction.failure, (state, { payload }) => ({
      ...state,
      orders: {} as Orders,
      loading: false,
    }))

    builder.addCase(getOrdersByOrderAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getOrdersByOrderAction.success, (state, { payload }) => ({
      ...state,
      orders: payload.data,
      loading: false,
    }))
    builder.addCase(getOrdersByOrderAction.failure, (state, { payload }) => ({
      ...state,
      orders: {} as Orders,
      loading: false,
    }))

    builder.addCase(getArchiveOrdersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getArchiveOrdersAction.success, (state, { payload }) => ({
      ...state,
      orders: payload.data,
      loading: false,
    }))
    builder.addCase(getArchiveOrdersAction.failure, (state, { payload }) => ({
      ...state,
      orders: {} as Orders,
      loading: false,
    }))

    builder.addCase(getOrderAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getOrderAction.success, (state, { payload }) => ({
      ...state,
      order: payload.data,
      loading: false,
    }))
    builder.addCase(getOrderAction.failure, (state, { payload }) => ({
      ...state,
      order: null,
      loading: false,
    }))

    builder.addCase(createOrderAction.request, state => ({
      ...state,
      loading: true,
    }))

    builder.addCase(setOrdersFilterAction, (state, { payload }) => ({
      ...state,
      filter: payload,
    }))

    builder.addCase(createOrderAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createOrderAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(updateOrderAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateOrderAction.success, (state, { payload }) => ({
      ...state,
      order: payload.data,
      loading: false,
    }))
    builder.addCase(updateOrderAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(deleteOrderAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteOrderAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteOrderAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(searchOrdersByQueryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      searchOrdersByQueryAction.success,
      (state, { payload }) => ({
        ...state,
        orders: { orders: payload.data, totalCount: payload.totalCount },
        loading: false,
      }),
    )
    builder.addCase(
      searchOrdersByQueryAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )

    builder.addCase(searchOrdersByDateAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(searchOrdersByDateAction.success, (state, { payload }) => ({
      ...state,
      orders: payload.data,
      loading: false,
    }))
    builder.addCase(searchOrdersByDateAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(sortOrdersByQueryAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(sortOrdersByQueryAction.success, (state, { payload }) => ({
      ...state,
      orders: payload.data,
      loading: false,
    }))
    builder.addCase(sortOrdersByQueryAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(unsetOrderAction, state => ({
      ...state,
      order: null,
      loading: false,
    }))

    builder.addCase(getOrdersStatisticAction.request, (state, { payload }) => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getOrdersStatisticAction.success, (state, { payload }) => ({
      ...state,
      ordersStatistic: payload,
      loading: false,
    }))
    builder.addCase(getOrdersStatisticAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
  },
)
