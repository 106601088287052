import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import { api, getUserSelector } from '../store'
import {
  setAllMyChatsAction,
  setAllNewChatsAction,
  setNewMessageAction,
} from '../store/chat'
import { IChat, ISocketMessage } from '../store/chat/types'
import {
  getAllNotificationsAction,
  setNotificationsCounterAction,
} from '../store/notifications'

const socket = io(api.socket, {
  autoConnect: true,
  forceNew: true,
  reconnection: true,
  transports: ['websocket', 'polling'],
})

const useChat = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(getUserSelector)

  const [message, setMessage] = useState<ISocketMessage>({} as ISocketMessage)
  const [notification, setNotification] = useState<any>()
  const [newChat, setnewChat] = useState<IChat>({} as IChat)
  const [readMessage, setReadMessage] = useState([])

  const addListener = useRef(true)

  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  useEffect(() => {
    dispatch(getAllNotificationsAction.request())
    if (notification && user.sendNotifications) {
      dispatch(setNotificationsCounterAction())
    }
  }, [notification])

  useEffect(() => {
    if (message?.lastMsg) {
      dispatch(setNewMessageAction(message))
    }
  }, [message])

  useEffect(() => {
    if (newChat._id) {
      checkPermission('chatGetAll')
        ? dispatch(setAllNewChatsAction.request(''))
        : dispatch(setAllNewChatsAction.request('?my=true'))
    }
  }, [newChat])

  useEffect(() => {
    if (readMessage.length > 0) {
      dispatch(setAllMyChatsAction.request())
    }
  }, [readMessage])

  const onConnect = () => {
    socket.on('connect', () => {
      user?._id && socket.emit('user', { admin: { _id: user?._id } })
    })
  }

  useEffect(() => {
    addListener.current &&
      socket.on('readMessage', messages => {
        if (readMessage.length !== messages.length) {
          setReadMessage(messages)
        }
      })

    addListener.current &&
      socket.on('newChatAvailable', chat => {
        if (!newChat._id) {
          setnewChat(chat)
          return
        }
        if (newChat._id !== chat._id) {
          setnewChat(chat)
          return
        }
      })

    addListener.current &&
      socket.on('sendMessage', msg => {
        if (msg.lastMsg.sender !== user._id) {
          if (!message?.lastMsg) {
            setMessage(msg)
          } else if (message?.lastMsg._id !== msg?.lastMsg._id) {
            setMessage(msg)
          }
        }
      })

    addListener.current &&
      socket.on('newAdminNotification', notifications => {
        console.log('newAdminNotification', notifications)
        if (
          notifications?.admins.find(id => {
            return user._id === id
          })
        ) {
          if (!notification) {
            setNotification(notifications)
          } else if (notification._id !== notifications._id) {
            setNotification(notifications)
          }
        }
      })

    addListener.current = false
  }, [])

  return { user, socket, onConnect, message }
}

export default useChat
