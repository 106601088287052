import styled from 'styled-components'
import { Colors } from '../../assets'

export const StarWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const MinorTitle = styled.div`
  font-weight: bold;
  margin: 0px 0px 5px 10px;
`

export const Comment = styled.div`
  border: 1px ${Colors.gray} solid;
  padding: 10px;
`

export const FeedbackProduct = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FeedbackUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 10px;
  // border: 1px ${Colors.gray} solid;
  border-radius: 15px;
  box-shadow: 0px 3px 6px ${Colors.gray};
`

export const Image = styled.img`
  width: 85px;
  height: 85px;
`
