import { AxiosResponse } from 'axios'
import {
  CreateStyleActionPayload,
  CreateStyleActionResponse,
  GetStylesActionResponse,
} from './types'

import { HttpService } from '../../services'
import { STYLES_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class StyleAPI extends HttpService {
  static getStyles(
    request: any,
  ): Promise<AxiosResponse<GetStylesActionResponse>> {
    return this.request<GetStylesActionResponse>('GET_STYLES', {
      url: STYLES_URL.rootPath,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getStyle(
    request: any,
  ): Promise<AxiosResponse<GetStylesActionResponse>> {
    return this.request<GetStylesActionResponse>('GET_STYLE', {
      url: STYLES_URL.idPath(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createStyle({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateStyleActionResponse>> {
    console.log(data)
    return this.request<CreateStyleActionResponse>('CREATE_STYLE', {
      url: STYLES_URL.rootPath,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
