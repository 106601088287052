export const useCitiesList = () => [
  {
    cityId: 1,
    regionId: 14,
    cityName: 'город Шахрисабз',
  },
  {
    cityId: 2,
    regionId: 3,
    cityName: 'Папский район',
  },
  {
    cityId: 3,
    regionId: 2,
    cityName: 'Асакинский район',
  },
  {
    cityId: 4,
    regionId: 5,
    cityName: 'Ташкентский район',
  },
  {
    cityId: 5,
    regionId: 5,
    cityName: 'город Янгийуль',
  },
  {
    cityId: 6,
    regionId: 5,
    cityName: 'город Ахангаран',
  },
  {
    cityId: 7,
    regionId: 5,
    cityName: 'город Нурафшон',
  },
  {
    cityId: 8,
    regionId: 11,
    cityName: 'город Нукус',
  },
  {
    cityId: 9,
    regionId: 11,
    cityName: 'Тахиаташский район',
  },
  {
    cityId: 10,
    regionId: 4,
    cityName: 'город Хива',
  },
  {
    cityId: 11,
    regionId: 11,
    cityName: 'Амударьинский район',
  },
  {
    cityId: 12,
    regionId: 11,
    cityName: 'Берунийский район',
  },
  {
    cityId: 13,
    regionId: 11,
    cityName: 'Кандикульский район',
  },
  {
    cityId: 14,
    regionId: 11,
    cityName: 'Караузякский район',
  },
  {
    cityId: 15,
    regionId: 11,
    cityName: 'Кегейлийский район',
  },
  {
    cityId: 16,
    regionId: 11,
    cityName: 'Кунградский район',
  },
  {
    cityId: 17,
    regionId: 11,
    cityName: 'Муйнакский район',
  },
  {
    cityId: 18,
    regionId: 11,
    cityName: 'Нукусский район',
  },
  {
    cityId: 19,
    regionId: 11,
    cityName: 'Тахтакупырский район',
  },
  {
    cityId: 20,
    regionId: 11,
    cityName: 'Турткульский район',
  },
  {
    cityId: 21,
    regionId: 11,
    cityName: 'Ходжейлийский район',
  },
  {
    cityId: 22,
    regionId: 11,
    cityName: 'Чимбайский район',
  },
  {
    cityId: 23,
    regionId: 11,
    cityName: 'Шуманайский район',
  },
  {
    cityId: 24,
    regionId: 11,
    cityName: 'Элликкалинский район',
  },
  {
    cityId: 25,
    regionId: 2,
    cityName: 'город Андижан',
  },
  {
    cityId: 26,
    regionId: 2,
    cityName: 'город Ханабад',
  },
  {
    cityId: 27,
    regionId: 2,
    cityName: 'Андижанский район',
  },
  {
    cityId: 28,
    regionId: 2,
    cityName: 'Балыкчинский район',
  },
  {
    cityId: 29,
    regionId: 2,
    cityName: 'Булакбашинский район',
  },
  {
    cityId: 30,
    regionId: 2,
    cityName: 'Бозский район',
  },
  {
    cityId: 31,
    regionId: 2,
    cityName: 'Джалалкудукский район',
  },
  {
    cityId: 32,
    regionId: 2,
    cityName: 'Избасканский район',
  },
  {
    cityId: 33,
    regionId: 2,
    cityName: 'Улугнорский район',
  },
  {
    cityId: 34,
    regionId: 2,
    cityName: 'Мархаматский район',
  },
  {
    cityId: 35,
    regionId: 2,
    cityName: 'Пахтаабадский район',
  },
  {
    cityId: 36,
    regionId: 2,
    cityName: 'Ходжаабадский район',
  },
  {
    cityId: 37,
    regionId: 2,
    cityName: 'Алтынкульский район',
  },
  {
    cityId: 38,
    regionId: 2,
    cityName: 'Кургантепинский район',
  },
  {
    cityId: 39,
    regionId: 2,
    cityName: 'Шахриханский район',
  },
  {
    cityId: 40,
    regionId: 7,
    cityName: 'город Бухара',
  },
  {
    cityId: 41,
    regionId: 7,
    cityName: 'город Каган',
  },
  {
    cityId: 42,
    regionId: 7,
    cityName: 'Бухарский район',
  },
  {
    cityId: 43,
    regionId: 7,
    cityName: 'Бабкентский район',
  },
  {
    cityId: 44,
    regionId: 7,
    cityName: 'Джандарский район',
  },
  {
    cityId: 45,
    regionId: 7,
    cityName: 'Каганский район',
  },
  {
    cityId: 46,
    regionId: 7,
    cityName: 'Алатский район',
  },
  {
    cityId: 47,
    regionId: 7,
    cityName: 'Пешкунский район',
  },
  {
    cityId: 48,
    regionId: 7,
    cityName: 'Ромитанский район',
  },
  {
    cityId: 49,
    regionId: 7,
    cityName: 'Шафирканский район',
  },
  {
    cityId: 50,
    regionId: 7,
    cityName: 'Каракульский район',
  },
  {
    cityId: 51,
    regionId: 7,
    cityName: 'Караулбазарский район',
  },
  {
    cityId: 52,
    regionId: 7,
    cityName: 'Гиждуванский район',
  },
  {
    cityId: 53,
    regionId: 9,
    cityName: 'Арнасайский район',
  },

  {
    cityId: 54,
    regionId: 9,
    cityName: 'Бахмальский район',
  },
  {
    cityId: 55,
    regionId: 9,
    cityName: 'Галляаральский район',
  },
  {
    cityId: 56,
    regionId: 9,
    cityName: 'Дустликский район',
  },
  {
    cityId: 57,
    regionId: 9,
    cityName: 'город Джизак',
  },
  {
    cityId: 58,
    regionId: 9,
    cityName: 'Жиззах',
  },
  {
    cityId: 59,
    regionId: 9,
    cityName: 'Зарбдорский район',
  },
  {
    cityId: 60,
    regionId: 9,
    cityName: 'Зафарабадский район',
  },
  {
    cityId: 61,
    regionId: 9,
    cityName: 'Заминский район',
  },
  {
    cityId: 62,
    regionId: 9,
    cityName: 'Мирзачульский район',
  },
  {
    cityId: 63,
    regionId: 9,
    cityName: 'Пахтакорский район',
  },
  {
    cityId: 64,
    regionId: 9,
    cityName: 'Фаришский район',
  },
  {
    cityId: 65,
    regionId: 9,
    cityName: 'Янгиабадский район',
  },
  {
    cityId: 66,
    regionId: 14,
    cityName: 'город Карши',
  },
  {
    cityId: 67,
    regionId: 14,
    cityName: 'Каршинский район',
  },
  {
    cityId: 68,
    regionId: 14,
    cityName: 'Мубарекский район',
  },
  {
    cityId: 69,
    regionId: 14,
    cityName: 'Гузарский район',
  },
  {
    cityId: 70,
    regionId: 14,
    cityName: 'Камашинский район',
  },
  {
    cityId: 71,
    regionId: 14,
    cityName: 'Чиракчинский район',
  },
  {
    cityId: 72,
    regionId: 14,
    cityName: 'Шахризабский район',
  },
  {
    cityId: 73,
    regionId: 14,
    cityName: 'Касбинский район',
  },
  {
    cityId: 74,
    regionId: 14,
    cityName: 'Касанский район',
  },
  {
    cityId: 75,
    regionId: 14,
    cityName: 'Китабский район',
  },
  {
    cityId: 76,
    regionId: 14,
    cityName: 'Нишанский район',
  },
  {
    cityId: 77,
    regionId: 14,
    cityName: 'Миришкорский район',
  },
  {
    cityId: 78,
    regionId: 14,
    cityName: 'Дехканабадский район',
  },
  {
    cityId: 79,
    regionId: 14,
    cityName: 'Яккабагский район',
  },
  {
    cityId: 80,
    regionId: 10,
    cityName: 'город Навойи',
  },
  {
    cityId: 81,
    regionId: 10,
    cityName: 'город Заравшан',
  },
  {
    cityId: 82,
    regionId: 10,
    cityName: 'Карманинский район',
  },
  {
    cityId: 83,
    regionId: 10,
    cityName: 'Тамдынский район',
  },
  {
    cityId: 84,
    regionId: 10,
    cityName: 'Навбахорский район',
  },
  {
    cityId: 85,
    regionId: 10,
    cityName: 'Нуратинский район',
  },
  {
    cityId: 86,
    regionId: 10,
    cityName: 'Хатырчинский район',
  },
  {
    cityId: 87,
    regionId: 10,
    cityName: 'Кызылтепинский район',
  },
  {
    cityId: 88,
    regionId: 10,
    cityName: 'Кенимехский район',
  },
  {
    cityId: 89,
    regionId: 10,
    cityName: 'город Учкудук',
  },
  {
    cityId: 90,
    regionId: 3,
    cityName: 'город Наманган',
  },
  {
    cityId: 91,
    regionId: 3,
    cityName: 'Мингбулакский район',
  },
  {
    cityId: 92,
    regionId: 3,
    cityName: 'Касансайский район',
  },
  {
    cityId: 93,
    regionId: 3,
    cityName: 'Наманганский район',
  },
  {
    cityId: 94,
    regionId: 3,
    cityName: 'Нарынский район',
  },
  {
    cityId: 95,
    regionId: 3,
    cityName: 'Туракурганский район',
  },
  {
    cityId: 96,
    regionId: 3,
    cityName: 'Уйчинский район',
  },
  {
    cityId: 97,
    regionId: 3,
    cityName: 'Учкурганский район',
  },
  {
    cityId: 98,
    regionId: 3,
    cityName: 'Чартакский район',
  },
  {
    cityId: 99,
    regionId: 3,
    cityName: 'Чустский район',
  },
  {
    cityId: 100,
    regionId: 3,
    cityName: 'Янгикурганский район',
  },
  {
    cityId: 101,
    regionId: 8,
    cityName: 'город Самарканд',
  },
  {
    cityId: 102,
    regionId: 8,
    cityName: 'Ургутсский район',
  },
  {
    cityId: 103,
    regionId: 8,
    cityName: 'Пахтаачинский район',
  },
  {
    cityId: 104,
    regionId: 8,
    cityName: 'Каттакурганский район',
  },
  {
    cityId: 105,
    regionId: 8,
    cityName: 'Самаркандский район',
  },
  {
    cityId: 106,
    regionId: 8,
    cityName: 'Булунгурский район',
  },
  {
    cityId: 107,
    regionId: 8,
    cityName: 'Джамбайский район',
  },
  {
    cityId: 108,
    regionId: 8,
    cityName: 'Кошрабадский район',
  },
  {
    cityId: 109,
    regionId: 8,
    cityName: 'Нарпайский район',
  },
  {
    cityId: 110,
    regionId: 8,
    cityName: 'Тайлякский район',
  },
  {
    cityId: 111,
    regionId: 8,
    cityName: 'Пастдаргомский район',
  },
  {
    cityId: 112,
    regionId: 8,
    cityName: 'Нуробадский район',
  },
  {
    cityId: 113,
    regionId: 8,
    cityName: 'город Каттакурган',
  },
  {
    cityId: 114,
    regionId: 8,
    cityName: 'Пайарыкский район',
  },
  {
    cityId: 115,
    regionId: 8,
    cityName: 'Акдарьинский район',
  },
  {
    cityId: 116,
    regionId: 8,
    cityName: 'Иштыханский район',
  },
  {
    cityId: 117,
    regionId: 13,
    cityName: 'город Термез',
  },
  {
    cityId: 118,
    regionId: 13,
    cityName: 'Термезский район',
  },
  {
    cityId: 119,
    regionId: 13,
    cityName: 'Музрабадский район',
  },
  {
    cityId: 120,
    regionId: 13,
    cityName: 'Алтынсайский район',
  },
  {
    cityId: 121,
    regionId: 13,
    cityName: 'Денаусский район',
  },
  {
    cityId: 122,
    regionId: 13,
    cityName: 'Сарыассийский район',
  },
  {
    cityId: 123,
    regionId: 13,
    cityName: 'Кизирикский район',
  },
  {
    cityId: 124,
    regionId: 13,
    cityName: 'Джаркурганский район',
  },
  {
    cityId: 125,
    regionId: 13,
    cityName: 'Ангорский район',
  },
  {
    cityId: 126,
    regionId: 13,
    cityName: 'Кумкурганский район',
  },
  {
    cityId: 127,
    regionId: 13,
    cityName: 'Байсунский район',
  },
  {
    cityId: 128,
    regionId: 13,
    cityName: 'Шурчинский район',
  },
  {
    cityId: 129,
    regionId: 13,
    cityName: 'Шерабадский район',
  },
  {
    cityId: 130,
    regionId: 13,
    cityName: 'Узунский район',
  },
  {
    cityId: 131,
    regionId: 12,
    cityName: 'город Гулистан',
  },
  {
    cityId: 132,
    regionId: 12,
    cityName: 'город Янгиер',
  },
  {
    cityId: 133,
    regionId: 12,
    cityName: 'город Ширин',
  },
  {
    cityId: 134,
    regionId: 12,
    cityName: 'Окалтынский район',
  },
  {
    cityId: 135,
    regionId: 12,
    cityName: 'Баяутский район',
  },
  {
    cityId: 136,
    regionId: 12,
    cityName: 'Гулистанский район',
  },
  {
    cityId: 137,
    regionId: 12,
    cityName: 'Мирзаабадский район',
  },
  {
    cityId: 138,
    regionId: 12,
    cityName: 'Сайхунабадский район',
  },
  {
    cityId: 139,
    regionId: 12,
    cityName: 'Сардобинский район',
  },
  {
    cityId: 140,
    regionId: 12,
    cityName: 'Сырдарьинский район',
  },
  {
    cityId: 141,
    regionId: 12,
    cityName: 'Хавастский район',
  },
  {
    cityId: 142,
    regionId: 5,
    cityName: 'город Ангрен',
  },
  {
    cityId: 143,
    regionId: 5,
    cityName: 'город Бекабад',
  },
  {
    cityId: 144,
    regionId: 5,
    cityName: 'город Алмалык',
  },
  {
    cityId: 145,
    regionId: 5,
    cityName: 'город Чирчик',
  },
  {
    cityId: 146,
    regionId: 5,
    cityName: 'Бекабадский район',
  },
  {
    cityId: 147,
    regionId: 5,
    cityName: 'Бостанлыкский район',
  },
  {
    cityId: 148,
    regionId: 5,
    cityName: 'Кибрайский район',
  },
  {
    cityId: 149,
    regionId: 5,
    cityName: 'Зангиатинский район',
  },
  {
    cityId: 150,
    regionId: 5,
    cityName: 'Куйи-чирчикский район',
  },
  {
    cityId: 151,
    regionId: 5,
    cityName: 'Аккурганский район',
  },
  {
    cityId: 152,
    regionId: 5,
    cityName: 'Паркентский район',
  },
  {
    cityId: 154,
    regionId: 5,
    cityName: 'Урта-чирчикский район',
  },
  {
    cityId: 155,
    regionId: 5,
    cityName: 'Чиназский район',
  },
  {
    cityId: 156,
    regionId: 5,
    cityName: 'Юкори чирчикский район',
  },
  {
    cityId: 157,
    regionId: 5,
    cityName: 'Букинский район',
  },
  {
    cityId: 158,
    regionId: 5,
    cityName: 'Янгийульский район',
  },
  {
    cityId: 159,
    regionId: 5,
    cityName: 'Ахангаранский район',
  },
  {
    cityId: 160,

    regionId: 15,
    cityName: 'город Фергана',
  },
  {
    cityId: 161,
    regionId: 15,
    cityName: 'город Маргилан',
  },
  {
    cityId: 162,
    regionId: 15,
    cityName: 'город Кувасай',
  },
  {
    cityId: 163,
    regionId: 15,
    cityName: 'город Коканд',
  },
  {
    cityId: 164,
    regionId: 15,
    cityName: 'Багдадский район',
  },
  {
    cityId: 165,
    regionId: 15,
    cityName: 'Бувайдинский район',
  },
  {
    cityId: 166,
    regionId: 15,
    cityName: 'Дангаринский район',
  },
  {
    cityId: 167,
    regionId: 15,
    cityName: 'Язьяванский район',
  },
  {
    cityId: 168,
    regionId: 15,
    cityName: 'Алтыарыкский район',
  },
  {
    cityId: 169,
    regionId: 15,
    cityName: 'Бешарыкский район',
  },
  {
    cityId: 170,
    regionId: 15,
    cityName: 'Куштепинский район',
  },
  {
    cityId: 171,
    regionId: 15,
    cityName: 'Риштанский район',
  },
  {
    cityId: 172,
    regionId: 15,
    cityName: 'Сохский район',
  },
  {
    cityId: 173,
    regionId: 15,
    cityName: 'Ташлакский район',
  },
  {
    cityId: 174,
    regionId: 15,
    cityName: 'Учкуприкский район',
  },
  {
    cityId: 175,
    regionId: 15,
    cityName: 'Ферганский район',
  },
  {
    cityId: 176,
    regionId: 15,
    cityName: 'Узбекистанский район',
  },
  {
    cityId: 177,
    regionId: 15,
    cityName: 'Кувинский район',
  },
  {
    cityId: 178,
    regionId: 15,
    cityName: 'Фуркатский район',
  },
  {
    cityId: 179,
    regionId: 4,
    cityName: 'город Ургенч',
  },
  {
    cityId: 180,
    regionId: 4,
    cityName: 'Багатский район',
  },
  {
    cityId: 181,
    regionId: 4,
    cityName: 'Ургенчский район',
  },
  {
    cityId: 182,
    regionId: 4,
    cityName: 'Кушкупырский район',
  },
  {
    cityId: 183,
    regionId: 4,
    cityName: 'Ханкинский район',
  },
  {
    cityId: 184,
    regionId: 4,
    cityName: 'Янгиарыкский район',
  },
  {
    cityId: 185,
    regionId: 4,
    cityName: 'Хивинский район',
  },
  {
    cityId: 186,
    regionId: 4,
    cityName: 'Янгибазарский район',
  },
  {
    cityId: 187,
    regionId: 4,
    cityName: 'Хазараспский район',
  },
  {
    cityId: 188,
    regionId: 4,
    cityName: 'Шаватский район',
  },
  {
    cityId: 189,
    regionId: 4,
    cityName: 'Гурленский район',
  },
  {
    cityId: 190,
    regionId: 6,
    cityName: 'Бектемирский район',
  },
  {
    cityId: 191,
    regionId: 6,
    cityName: 'Мирабадский район',
  },
  {
    cityId: 192,
    regionId: 6,
    cityName: 'М.Улугбекский район',
  },
  {
    cityId: 193,
    regionId: 6,
    cityName: 'Сергелийсикй район',
  },
  {
    cityId: 194,
    regionId: 6,
    cityName: 'Алмазарский район',
  },
  {
    cityId: 195,
    regionId: 6,
    cityName: 'Учтепинский район',
  },
  {
    cityId: 196,
    regionId: 6,
    cityName: 'Яшнабадский район',
  },
  {
    cityId: 197,
    regionId: 6,
    cityName: 'Чилонзарский район',
  },
  {
    cityId: 198,
    regionId: 6,
    cityName: 'Шайхантахурский район',
  },
  {
    cityId: 199,
    regionId: 6,
    cityName: 'Юнусабадский район',
  },
  {
    cityId: 200,
    regionId: 6,
    cityName: 'Яккасарайский район',
  },
  {
    cityId: 201,
    regionId: 5,
    cityName: 'Пскентский район',
  },
]
