import styled from 'styled-components'
import { Assets, Colors } from '../../assets'
import { FLEX, FONT, SHADOW } from '../../utils'

export const StyledRow = styled.tr`
  & td {
    border-bottom: 1px ${Colors.lightGray} solid;
    padding: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  ${FONT({ size: '14px', color: Colors.black, weight: '400' })}
  width: 20%;
`

export const StyledHeader = styled.th`
  padding: 1rem 1rem 1rem;
  border-bottom: 1px ${Colors.lightGray} solid;
  ${FONT({
    size: '14px',
    color: Colors.boldGray,
    weight: '700',
    align: 'left',
  })}
`

export const StyledCell = styled.td`
  width: 100px;
`

export const HeaderItem = styled.div`
  cursor: pointer;
`

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
`

export const ModalTableWrapper = styled.table`
  width: 100%;
  border: 1px solid ${Colors.lightGray};
  background: ${Colors.white};
  overflow-y: scroll;
  height: 300px;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledWrapper = styled.thead`
  padding: 0;
`

export const TableWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  border: 1px solid ${Colors.lightGray};
  background: ${Colors.white};
  overflow-x: scroll;
  border-radius: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
  ${SHADOW({})}
`

export const FunctionalRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem 1rem;
  align-items: center;
  .applications {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-left: auto;
    font-weight: 600;
    .success {
      color: ${Colors.green};
    }
    .canceled {
      color: ${Colors.red};
    }
  }
`

export const SearchInput = styled.input`
  width: 195px;
  height: 45px;
  border: ${Colors.gray} 1px solid;
  border-radius: 8px;
  padding-left: 10px;
  &::placeholder {
    color: ${Colors.gray};
  }
  &:focus {
    outline: none;
  }
`

export const DateSearchInput = styled.input`
  width: 195px;
  height: 45px;
  border: ${Colors.gray} 1px solid;
  border-radius: 8px;
  padding-left: 10px;
  &::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    background: url(${Assets.CALENDAR_PNG_ICON});
    z-index: 1;
    margin-right: 10px;
  }
  &::placeholder {
    color: ${Colors.gray};
  }
  &:focus {
    outline: none;
  }
`

export const ViewButton = styled.div`
  display: inline-flex;
  background: ${Colors.orange};
  padding: 6px;
  align-items: center;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`

export const CreateButtonContainer = styled.div`
  margin-left: auto;
  width: 195px;
  height: 45px;
`
