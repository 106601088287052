import styled from 'styled-components'
import { Colors } from '../../assets'
import { FLEX, SHADOW } from '../../utils'

export const CategoryImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
`
export const ModalPhotoLoader = styled.div`
  width: 300px;
  align-self: start;
  ${FLEX({ direction: 'column' })};
  gap: 10px;
  .btns {
    width: 100%;
    ${FLEX({ justify: 'space-between', align: 'center' })};
  }
  .load {
    color: ${Colors.green};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .delete {
    color: ${Colors.red};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .preview {
    width: 100%;
    max-width: 300px;
    object-fit: cover;
  }
`
