import styled from 'styled-components'
import { Colors } from '../../assets'
import { FONT, SHADOW } from '../../utils'

export const StyledTableWrapper = styled.div`
  /* padding: 20px; */
  width: 100%;
  border: 1px solid ${Colors.lightGray};
  background: ${Colors.white};
  overflow-x: scroll;
  border-radius: 24px;
  &::-webkit-scrollbar {
    display: none;
  }
  ${SHADOW({})}
`

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  /* padding: 20px; */
`

export const StyledTableHead = styled.thead`
  ${FONT({
    size: '14px',
    color: Colors.boldGray,
    weight: '700',
    align: 'left',
  })}
`

export const StyledTH = styled.th`
  border-bottom: 1px ${Colors.lightGray} solid;
  padding: 26px;
`

export const StyledTableRow = styled.tr`
  & td {
    border-bottom: 1px ${Colors.lightGray} solid;
    padding: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:last-child {
    & td {
      border-bottom: none;
    }
  }
  ${FONT({ size: '14px', color: Colors.black, weight: '400' })}
  width: 20%;
`

export const StyledViewButton = styled.div`
  display: inline-flex;
  background: ${Colors.orange};
  padding: 6px;
  align-items: center;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`
