import * as yup from 'yup'
import { errMsgs } from './errorMessages'

export const rangeCharacteristicsSchema = yup.object({
  en: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(5, errMsgs.min(5))
    .required(errMsgs.required),
  ru: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(5, errMsgs.min(5))
    .required(errMsgs.required),
  uz: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(5, errMsgs.min(5))
    .required(errMsgs.required),
  from: yup
    .number()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(0)
    .typeError(errMsgs.number)
    .required(errMsgs.required),
  to: yup
    .number()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(0)
    .typeError(errMsgs.number)
    .required(errMsgs.required),
})

export const textCharacteristicsSchema = yup.object({
  en: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(1, errMsgs.min(1))
    .required(errMsgs.required),
  ru: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(1, errMsgs.min(1))
    .required(errMsgs.required),
  uz: yup
    .string()
    .transform((val, origVal) => {
      return origVal === '' ? undefined : val
    })
    .min(1, errMsgs.min(1))
    .required(errMsgs.required),
})
