import { FC, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { useTypedSelector } from '../../hooks'
import { getBrands } from './api'
import { TableInstance } from '../../components/Table'
import {
  StyledTableWrapper,
  StyledViewButton,
} from '../../components/Table/styled'
import { Assets, Colors } from '../../assets'
import { Loader } from '../../contexts'
import { SimpleDivider, Title } from '../../components/Styled/formStyled'
import { DumbButton } from '../../components/FormComponents/styled'
import { RowWrapper } from '../../components/Modals/styled'

const Categories: FC = ({}) => {
  const [tableData, setTableData] = useState<any>(null)
  const queryClient = useQueryClient()
  const history = useHistory()
  const { token } = useTypedSelector(state => state.user)
  const { data, isLoading } = useQuery(['brands', token], () =>
    getBrands(token!),
  )

  useEffect(() => {
    if (data?.data) {
      setTableData([
        ...data.data.map((i: any) => {
          return {
            ...i,
            options: (
              <StyledViewButton
                onClick={() => history.push(`/brands/${i._id}`)}
              >
                <img
                  height="24px"
                  width="24px"
                  src={Assets.SHOW_PASSWORD_ICON}
                />
              </StyledViewButton>
            ),
          }
        }),
      ])
    }
  }, [data])

  const columns = [
    {
      Header: 'Название',
      accessor: 'ru',
    },
    {
      Header: 'К-во товаров',
      accessor: 'products.length',
    },
    {
      accessor: 'options',
    },
  ]

  if (isLoading || !tableData) {
    return <Loader isLoading={isLoading} />
  }

  return (
    <>
      <RowWrapper>
        <Title>Бренды</Title>
        <DumbButton
          onClick={() => history.push('/brand-create')}
          bg={Colors.green}
          txt={Colors.white}
        >
          Добавить
        </DumbButton>
      </RowWrapper>
      <SimpleDivider height="20px" />
      <StyledTableWrapper>
        {/* <FunctionalRow>
          <CreateButtonContainer>
            <Button
              title="Добавить"
              onClick={() => history.push('/brand-create')}
              theme={{ background: Colors.green, text: Colors.white }}
              icon={<img src={Assets.PLUS_WHITE_ICON} />}
            />
          </CreateButtonContainer>
        </FunctionalRow> */}

        <TableInstance tableData={tableData} importedColumns={columns} />
      </StyledTableWrapper>
    </>
  )
}

export default Categories
