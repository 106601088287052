import styled from 'styled-components'
import { Colors } from '../../assets'

export const Label = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${Colors.boldGray};
`

export const StarWrapper = styled.div`
  display: flex;
  align-items: center;
`
