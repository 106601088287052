import { createReducer } from '@reduxjs/toolkit'
import {
  getManagersStatsAction,
  getManagerStatsByIdAction,
  setManagerStatsFilterAction,
} from './actions'
import { IInitialState, IManager, IManagersStats } from './types'

const InitialState: IInitialState = {
  loading: false,
  managersStats: {} as IManagersStats,
  manager: {} as IManager,
  filter: '',
}

export const managerStatsReducer = createReducer<IInitialState>(
  InitialState,
  builder => {
    builder.addCase(getManagersStatsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getManagersStatsAction.success, (state, { payload }) => ({
      ...state,
      managersStats: payload,
      loading: false,
    }))
    builder.addCase(getManagersStatsAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(getManagerStatsByIdAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      getManagerStatsByIdAction.success,
      (state, { payload }) => ({
        ...state,
        manager: payload,
        loading: false,
      }),
    )
    builder.addCase(
      getManagerStatsByIdAction.failure,
      (state, { payload }) => ({
        ...state,
        loading: false,
      }),
    )

    builder.addCase(setManagerStatsFilterAction, (state, { payload }) => ({
      ...state,
      filter: payload,
    }))
  },
)
