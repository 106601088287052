import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  setAllOrdersStatsAction,
  setAmountStatsAction,
  setSupplierOrdersAction,
  setSuppliersStatsAction,
  updateNotesForOwnerAction,
} from './actions'

import { generateErrorToast } from '../../helpers'
import { AuthorizationError } from '../../errors'
import { getUserSelector } from './selectors'
import { PromiseReturnType } from '../types'
import { showToastAction } from '../toasts'
import { Log } from '../../utils'
import { FinanceAPI } from './api.service'
import { AxiosError } from 'axios'

export function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getSupplierStatsWorker({
  payload,
}: ReturnType<typeof setSuppliersStatsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof FinanceAPI.getSuppliersStats>
    > = yield call([FinanceAPI, FinanceAPI.getSuppliersStats], {
      authorization: token,
      data: payload,
    })

    yield put(setSuppliersStatsAction.success(response))
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка загрузки статистики')),
    )
    yield put(setSuppliersStatsAction.failure(e as AxiosError))
  }
}

function* getSupplierOrdersWorker({
  payload,
}: ReturnType<typeof setSupplierOrdersAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof FinanceAPI.getOrdersBySupplierId>
    > = yield call([FinanceAPI, FinanceAPI.getOrdersBySupplierId], {
      authorization: token,
      data: payload,
    })

    yield put(setSupplierOrdersAction.success(response))
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка загрузки заказов')),
    )
    yield put(setSupplierOrdersAction.failure(e as AxiosError))
  }
}

function* getAmountStatsWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof FinanceAPI.getAmountStats>
    > = yield call([FinanceAPI, FinanceAPI.getAmountStats], {
      authorization: token,
    })

    yield put(setAmountStatsAction.success(response.data))
  } catch (e: any) {
    Log.ruddy('Error: getRolesWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка загрузки статистики сумм'),
      ),
    )

    yield put(setAmountStatsAction.failure(e))
  }
}

function* getAllOrdersStatsWorker({
  payload,
}: ReturnType<typeof setAllOrdersStatsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof FinanceAPI.getAllOrdersStats>
    > = yield call([FinanceAPI, FinanceAPI.getAllOrdersStats], {
      authorization: token,
      data: payload,
    })

    yield put(setAllOrdersStatsAction.success(response))
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка загрузки статистики')),
    )
    yield put(setAllOrdersStatsAction.failure(e as AxiosError))
  }
}

function* updateNotesForOwnerWorker({
  payload,
}: ReturnType<typeof updateNotesForOwnerAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof FinanceAPI.updateNotesForOwner>
    > = yield call([FinanceAPI, FinanceAPI.updateNotesForOwner], {
      authorization: token,
      data: payload.data,
      id: payload.id,
    })

    const res: PromiseReturnType<
      ReturnType<typeof FinanceAPI.getAllOrdersStats>
    > = yield call([FinanceAPI, FinanceAPI.getAllOrdersStats], {
      authorization: token,
      data: payload.seacrhQuery,
    })

    yield put(updateNotesForOwnerAction.success(res))
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка изменения данных')),
    )
    yield put(updateNotesForOwnerAction.failure(e as AxiosError))
  }
}

export function* financeWatcher() {
  yield takeLatest(setSuppliersStatsAction.request, getSupplierStatsWorker)
  yield takeLatest(setAmountStatsAction.request, getAmountStatsWorker)
  yield takeLatest(setSupplierOrdersAction.request, getSupplierOrdersWorker)
  yield takeLatest(setAllOrdersStatsAction.request, getAllOrdersStatsWorker)
  yield takeLatest(updateNotesForOwnerAction.request, updateNotesForOwnerWorker)
}
