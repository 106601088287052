import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  getCustomerAction,
  getCustomersAction,
  updateCustomerAction,
  getCustomerByPhoneAction,
  sortCustomersByQueryAction,
  getMyCustomersAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { CustomerAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'

function* getMyCustomersWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CustomerAPI.getMyCustomers>
    > = yield call([CustomerAPI, CustomerAPI.getMyCustomers], {
      authorization: token,
      data: payload,
    })

    yield put(getMyCustomersAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения пользователей'),
      ),
    )

    yield put(getMyCustomersAction.failure(e))
  }
}

function* getCustomersWorker({ payload }: { payload: string }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CustomerAPI.getCustomers>
    > = yield call([CustomerAPI, CustomerAPI.getCustomers], {
      authorization: token,
      data: payload,
    })

    yield put(getCustomersAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomersWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения пользователей'),
      ),
    )

    yield put(getCustomersAction.failure(e))
  }
}

function* getCustomerByIDWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof CustomerAPI.getCustomer>
    > = yield call([CustomerAPI, CustomerAPI.getCustomer], {
      authorization: token,
      id: payload.id,
    })

    yield put(getCustomerAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения пользователя'),
      ),
    )

    yield put(getCustomerAction.failure(e))
  }
}

function* getCustomerByPhoneWorker({
  payload,
}: {
  payload: { phone: string }
}) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)
    const response: PromiseReturnType<
      ReturnType<typeof CustomerAPI.getCustomerByPhone>
    > = yield call([CustomerAPI, CustomerAPI.getCustomerByPhone], {
      authorization: token,
      phone: payload.phone,
    })

    yield put(getCustomerByPhoneAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка получения пользователя'),
      ),
    )

    yield put(getCustomerAction.failure(e))
  }
}

function* updateCustomerWorker({
  payload,
}: ReturnType<typeof updateCustomerAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const { id, data } = payload
    yield call([CustomerAPI, CustomerAPI.updateCustomer], {
      authorization: token,
      id,
      data,
    })
    yield put(updateCustomerAction.success())
    yield put(
      showToastAction.request(generateSuccessToast('Пользователь обновлен')),
    )
  } catch (e) {
    console.log('Error: updateCustomerWorker', e)
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка в обновлении пользователя'),
      ),
    )
    yield put(updateCustomerAction.failure(e))
  }
}

function* sortCustomersByQueryWorker({
  payload,
}: ReturnType<typeof sortCustomersByQueryAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof CustomerAPI.sortCustomersByQuery>
    > = yield call([CustomerAPI, CustomerAPI.sortCustomersByQuery], {
      authorization: token,
      query: payload.query,
    })
    yield put(sortCustomersByQueryAction.success(response.data))
  } catch (e) {
    console.log('Error: updateCustomerWorker', e)
    yield put(
      showToastAction.request(
        generateErrorToast('Ошибка в обновлении пользователя'),
      ),
    )
    yield put(updateCustomerAction.failure(e))
  }
}

export function* customersWatcher() {
  yield takeLatest(getMyCustomersAction.request, getMyCustomersWorker)
  yield takeLatest(getCustomersAction.request, getCustomersWorker)
  yield takeLatest(getCustomerAction.request, getCustomerByIDWorker)
  yield takeLatest(updateCustomerAction.request, updateCustomerWorker)
  yield takeLatest(getCustomerByPhoneAction.request, getCustomerByPhoneWorker)
  yield takeLatest(
    sortCustomersByQueryAction.request,
    sortCustomersByQueryWorker,
  )
}
