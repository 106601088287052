import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFinanceSelector,
  updateNotesForOwnerAction,
} from '../../store/finance'

interface IFinanceSelectProps {
  id: string
  notesForOwner: any
  supplierId: any
}

const FinanceSelect: FC<IFinanceSelectProps> = ({
  id,
  notesForOwner,
  supplierId,
}) => {
  const [paidToSupplier, setPaidToSupplier] = useState('no')

  const dispatch = useDispatch()

  useEffect(() => {
    setPaidToSupplier(notesForOwner?.paidToSupplier ? 'yes' : 'no')
  }, [notesForOwner])

  const { seacrhQuery } = useSelector(getFinanceSelector)

  const handleChange = (event: SelectChangeEvent) => {
    setPaidToSupplier(event.target.value)

    dispatch(
      updateNotesForOwnerAction.request({
        id,
        data: {
          ...notesForOwner,
          paidToSupplier: event.target.value === 'yes' ? true : false,
          supplier: supplierId,
        },
        seacrhQuery,
      }),
    )
  }

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={paidToSupplier}
          onChange={handleChange}
          autoWidth
        >
          <MenuItem value={'yes'}>Да</MenuItem>
          <MenuItem value={'no'}>Нет</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default FinanceSelect
