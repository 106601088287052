import axios from 'axios'
import { api } from '../../store'

export const getBrands = async (token: string) => {
  return (
    await axios(`${api.withAPI}/brands/`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  ).data
}
