import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetCategoriesActionResponse,
  GetCategoryActionResponse,
  GetCategoryActionPayload,
  CreateCategoryActionPayload,
  CreateCategoryActionResponse,
  DeleteCategoryActionPayload,
} from './types'

export const getCategoriesAction = createApiActions<
  void,
  GetCategoriesActionResponse,
  AxiosError
>(createActionTypes('CATEGORY/GET_CATEGORIES'))

export const getCategoryAction = createApiActions<
  GetCategoryActionPayload,
  GetCategoryActionResponse,
  AxiosError
>(createActionTypes('CATEGORY/GET_CATEGORY_BY_ID'))

export const createCategoryAction = createApiActions<any, any, AxiosError>(
  createActionTypes('CATEGORY/CREATE_CATEGORY'),
)

export const deleteCategoryAction = createApiActions<
  DeleteCategoryActionPayload,
  void,
  AxiosError
>(createActionTypes('CATEGORY/DELETE_CATEGORY'))

export const updateCategoryProductsAction = createApiActions<
  any,
  any,
  AxiosError
>(createActionTypes('CATEGORY/UPDATE_CATEGORY_PRODUCTS'))

export const updateCategoryAction = createApiActions<any, any, AxiosError>(
  createActionTypes('CATEGORY/UPDATE_CATEGORY'),
)

export const updateCategoryImageAction = createApiActions<any, any, AxiosError>(
  createActionTypes('CATEGORY/UPDATE_CATEGORY_IMAGE'),
)

export const pullEntityCategoryAction = createApiActions<any, any, AxiosError>(
  createActionTypes('CATEGORY/PUSH_ENTITY_CATEGORY'),
)

export const pushEntityCategoryAction = createApiActions<any, any, AxiosError>(
  createActionTypes('CATEGORY/PULL_ENTITY_CATEGORY'),
)

export const editEntityCategoryAction = createApiActions<any, void, AxiosError>(
  createActionTypes('CATEGORY/EDIT_ENTITY_CATEGORY'),
)

export const pullTextEntityCategoryAction = createApiActions<
  any,
  any,
  AxiosError
>(createActionTypes('CATEGORY/PULL_TEXT_ENTITY_CATEGORY'))

export const pushTextEntityCategoryAction = createApiActions<
  any,
  any,
  AxiosError
>(createActionTypes('CATEGORY/PUSH_TEXT_ENTITY_CATEGORY'))

export const updateSubcategoryImageAction = createApiActions<
  any,
  any,
  AxiosError
>(createActionTypes('CATEGORY/UPDATE_SUBCATEGORY_IMAGE'))

export const createSubsubcategoryAction = createApiActions<
  any,
  void,
  AxiosError
>(createActionTypes('CATEGORY/CREATE_SUBSUBCATEGORY'))

export const updateSubsubcategoryAction = createApiActions<
  any,
  void,
  AxiosError
>(createActionTypes('CATEGORY/UPDATE_SUBSUBCATEGORY'))

export const deleteSubsubcategoryAction = createApiActions<
  any,
  void,
  AxiosError
>(createActionTypes('CATEGORY/DELETE_SUBSUBCATEGORY'))

export const getSubsubcategoriesAction = createApiActions<
  void,
  any,
  AxiosError
>(createActionTypes('CATEGORY/GET_SUBSUBCATEGORY'))
