import moment from 'moment'
import { FC, memo } from 'react'
import { defaultFormatDate } from '../../helpers'
import { HelpMessageItem, HelpMessageWrapper } from './styled'

interface IHelpMessageProps {
  item: any
  onClick: (item: any, name: string, date: string) => void
  classname: string
}

const HelpMessage: FC<IHelpMessageProps> = memo(
  ({ item, onClick, classname }) => {
    // const parsedDate = new Date(item.createdAt)
    const name = `${item.user?.surname ? item.user?.surname : ''} ${
      item.user?.name ? item.user?.name : ''
    } ${item.user?.patronymic ? item.user?.patronymic : ''}`

    const newDate = defaultFormatDate(item.createdAt)
    // moment(parsedDate).format('DD.MM.yyyy hh:mm ')
    return (
      <>
        <HelpMessageItem
          onClick={() => onClick(item, name, newDate)}
          className={classname}
        >
          <span>{newDate}</span>
          <span>{item.status === 'new' ? 'Новое' : 'Обработано'}</span>
          <HelpMessageWrapper>
            <span>{name}</span>
            <span>{item.message}</span>
          </HelpMessageWrapper>
        </HelpMessageItem>
      </>
    )
  },
)

export default HelpMessage
