import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import { changeSupportStatusAction, setSupportMessagesAction } from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { AuthorizationError } from '../../errors'
import { getUserSelector } from './selectors'
import { PromiseReturnType } from '../types'
import { showToastAction } from '../toasts'
import { Log } from '../../utils'
import { SupportAPI } from './api.service'
import { AxiosError } from 'axios'

export function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getSupportMessagessWorker() {
  try {
    const token: string = yield call(verifyTokenWorker)

    const response: PromiseReturnType<
      ReturnType<typeof SupportAPI.getSupportMessages>
    > = yield call([SupportAPI, SupportAPI.getSupportMessages], {
      authorization: token,
    })

    yield put(setSupportMessagesAction.success(response.data))
  } catch (e: any) {
    Log.ruddy('Error: getSupportMessagessWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения сообщений')),
    )

    yield put(setSupportMessagesAction.failure(e))
  }
}

function* changeSupportStatusWorker({
  payload,
}: ReturnType<typeof changeSupportStatusAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    yield call([SupportAPI, SupportAPI.changeStatus], {
      authorization: token,
      data: payload,
    })

    const response: PromiseReturnType<
      ReturnType<typeof SupportAPI.getSupportMessages>
    > = yield call([SupportAPI, SupportAPI.getSupportMessages], {
      authorization: token,
    })

    yield put(changeSupportStatusAction.success(response.data))
    yield put(
      showToastAction.request(generateSuccessToast('Статус успешно обновлен')),
    )
  } catch (e) {
    console.log('Error: changeSupportStatusWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка обновления статуса')),
    )
    yield put(changeSupportStatusAction.failure(e as AxiosError))
  }
}

export function* supportWatcher() {
  yield takeLatest(setSupportMessagesAction.request, getSupportMessagessWorker)
  yield takeLatest(changeSupportStatusAction.request, changeSupportStatusWorker)
}
