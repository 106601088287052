import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetColorsActionResponse,
  GetColorActionResponse,
  GetColorActionPayload,
  CreateColorActionPayload,
  CreateColorActionResponse,
  DeleteColorActionPayload,
} from './types'

export const getExchangeRateAction = createApiActions<void, any, AxiosError>(
  createActionTypes('SETTINGS/GET_EXCHANGE_RATE'),
)

export const setExchangeRateAction = createApiActions<any, any, AxiosError>(
  createActionTypes('SETTINGS/SET_EXCHANGE_RATE'),
)

export const getBonusesAction = createApiActions<void, any, AxiosError>(
  createActionTypes('SETTINGS/GET_BONUSES'),
)

export const setBonusesAction = createApiActions<any, any, AxiosError>(
  createActionTypes('SETTINGS/SET_BONUSES'),
)
