import styled from 'styled-components'
import { FLEX } from '../../utils'
import { Colors } from '../../assets'

export const NotFoundWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${FLEX({ justify: 'center', align: 'center', direction: 'column' })};
    gap: 30px;
    text-align: center;
  }
  .code-error {
    color: ${Colors.boldGray};
    font-weight: 700;
    font-size: 160px;
  }
  .title {
    color: ${Colors.boldGray};
    font-size: 24px;
  }
  .text {
    max-width: 650px;
    line-height: 21px;
  }
`
