import { Checkbox, Radio } from '@mui/material'
import { FC, memo, ReactNode, useEffect, useRef, useState } from 'react'
import { IFilter } from '../Filter/types'
import {
  FilterDropdownArrow,
  FilterDropdownElement,
  FilterDropdownInputWrapper,
  FilterDropdownLabel,
  FilterDropdownList,
  FilterDropdownTitle,
  FilterDropdownWrapper,
  FilterInput,
} from './styled'
import { DateSearchInput } from '../Styled'
import moment from 'moment'

interface IFilterDropdownProps {
  title: string
  maxWidth?: string
  checkBoxElements?: IFilter[]
  radioElements?: IFilter[]
  setActiveFilters: (prev: any) => void
  setCheckedFilter?: (prev: any) => void
  createFiltersSubcategories?: (element: IFilter) => void
  deleteFiltersSubcategories?: (element: IFilter) => void
  setPrice?: (prev: any) => void
  setDate?: (prev: any) => void
  price?: IFilter[]
  date?: IFilter[]
  children?: ReactNode
  className?: string
}

const FilterDropdown: FC<IFilterDropdownProps> = memo<IFilterDropdownProps>(
  ({
    title,
    maxWidth,
    checkBoxElements,
    radioElements,
    setActiveFilters,
    setCheckedFilter,
    createFiltersSubcategories,
    deleteFiltersSubcategories,
    setPrice,
    setDate,
    price,
    date,
    children,
    className,
  }) => {
    const [visiblePopup, setVisiblePopup] = useState<boolean>(false)

    const listRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      document.body.addEventListener('click', closePopup)

      return () => document.body.removeEventListener('click', closePopup)
    }, [visiblePopup])

    const toggleVisiblePopup = () => {
      setVisiblePopup(!visiblePopup)
    }

    const closePopup = (event: any) => {
      if (visiblePopup) {
        const path = event.path || (event.composedPath && event.composedPath())
        if (!path.includes(listRef.current)) {
          setVisiblePopup(false)
        }
      }
    }

    const onCheckFilter = (element: IFilter) => {
      if (!element.checked) {
        if (element.key === 'category[]') {
          createFiltersSubcategories && createFiltersSubcategories(element)
        } else if (element.key === 'isPromo') {
          setCheckedFilter &&
            setCheckedFilter(prev =>
              prev.map(filter => {
                return filter.value === element.value
                  ? { ...filter, checked: true }
                  : { ...filter, checked: false }
              }),
            )

          setActiveFilters(prev =>
            prev.filter(activeFilter => {
              return (
                activeFilter.key === 'isPromo' &&
                activeFilter.value === element.value
              )
            }),
          )
        } else if (element.key === 'paidToSupplier') {
          setCheckedFilter &&
            setCheckedFilter(prev =>
              prev.map(filter => {
                return filter.value === element.value
                  ? { ...filter, checked: true }
                  : { ...filter, checked: false }
              }),
            )

          setActiveFilters(prev =>
            prev.filter(activeFilter => {
              return activeFilter.key === 'paidToSupplier'
                ? activeFilter.value === element.value
                : activeFilter
            }),
          )
        }
        setCheckedFilter &&
          setCheckedFilter(prev =>
            prev.map(filter => {
              return filter.value === element.value
                ? { ...filter, checked: true }
                : filter
            }),
          )

        setActiveFilters(prev => [...prev, element])
      } else {
        if (element.key === 'category[]') {
          deleteFiltersSubcategories && deleteFiltersSubcategories(element)
        }

        setCheckedFilter &&
          setCheckedFilter(prev =>
            prev.map(filter => {
              return filter.value === element.value
                ? { ...filter, checked: false }
                : filter
            }),
          )

        setActiveFilters(prev => prev.filter(el => el.value !== element.value))
      }
    }

    const onChangePrice = (price: string, element: IFilter) => {
      setPrice &&
        setPrice(prev =>
          prev.map(filter => {
            return filter.key === element.key
              ? { ...filter, value: price, name: price }
              : filter
          }),
        )

      setActiveFilters(prev => {
        const check = prev.find(obj => obj.key === element.key)

        if (check) {
          return prev.map(el => {
            return el.key === element.key
              ? { ...el, value: price, name: price }
              : el
          })
        } else {
          return [...prev, { ...element, value: price, name: price }]
        }
      })
    }

    const onChangeDate = (date: string, element: IFilter) => {
      const dateStr = moment(date).toISOString()

      setDate &&
        setDate(prev =>
          prev.map(filter => {
            return filter.label === element.label
              ? { ...filter, value: dateStr, name: date }
              : filter
          }),
        )

      setActiveFilters(prev => {
        const check = prev.find(obj => obj.label === element.label)

        if (check) {
          return prev.map(el => {
            return el.label === element.label
              ? { ...el, value: dateStr, name: date }
              : el
          })
        } else {
          return [...prev, { ...element, value: dateStr, name: date }]
        }
      })
    }

    return (
      <FilterDropdownWrapper maxWidth={maxWidth}>
        <FilterDropdownLabel onClick={toggleVisiblePopup} className={className}>
          {title}
          <FilterDropdownArrow className={`${visiblePopup ? 'open' : ''}`} />
        </FilterDropdownLabel>

        <FilterDropdownList
          className={`${visiblePopup ? 'open' : ''}`}
          ref={listRef}
        >
          {checkBoxElements?.map(element => (
            <FilterDropdownElement key={element.value}>
              <FilterDropdownTitle>{element.name}</FilterDropdownTitle>
              <Checkbox
                color="success"
                onChange={() => onCheckFilter(element)}
                checked={element.checked}
              />
            </FilterDropdownElement>
          ))}

          {radioElements?.map(element => (
            <FilterDropdownElement key={element.value}>
              <FilterDropdownTitle>{element.name}</FilterDropdownTitle>
              <Radio
                color="success"
                onChange={() => onCheckFilter(element)}
                checked={element.checked}
              />
            </FilterDropdownElement>
          ))}

          {price && (
            <FilterDropdownInputWrapper>
              {price?.map(element => (
                <FilterInput
                  value={element.value}
                  type="number"
                  onChange={e => onChangePrice(e.target.value, element)}
                  key={element.key}
                ></FilterInput>
              ))}
            </FilterDropdownInputWrapper>
          )}

          {date && (
            <FilterDropdownInputWrapper>
              {date.map(element => (
                <DateSearchInput
                  value={element.name}
                  onChange={e => onChangeDate(e.target.value, element)}
                  type="date"
                  key={element.label}
                />
              ))}
            </FilterDropdownInputWrapper>
          )}

          {children}
        </FilterDropdownList>
      </FilterDropdownWrapper>
    )
  },
)

export default FilterDropdown
