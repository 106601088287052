import { api } from '../config'

export const PRODUCTS_URL = {
  rootPath: `${api.withAPI}/_products`,
  getMyProducts: params => `${api.withAPI}/_products/my/?${params}`,
  getMyPendingProducts: params =>
    `${api.withAPI}/_products/myPending/?${params}`,
  queryParamsPath: params => `${api.withAPI}/_products/?${params}`,
  pendingProducts: params => `${api.withAPI}/_products/pending/?${params}`,
  idPath: (id: string) => `${api.withAPI}/_products/${id}`,
  idPathPhoto: (id: string) => `${api.withAPI}/_products/${id}/photo`,
  idPathPhotoOrder: (id: string) =>
    `${api.withAPI}/_products/${id}/photo-order`,
  titleSearchPath: (title: string) =>
    `${api.withAPI}/_products/?title=${title}`,
  dateSearchPath: (from: string, to: string) =>
    `${api.withAPI}/_products/?createdAt[]=${from}&createdAt[]=${to}`,
  querySortPath: (query: string) => `${api.withAPI}/_products/?sortBy=${query}`,
  limitlessPath: `${api.withAPI}/_products/?limit=100000`,
  queryFilterPath: (query: string) => `${api.withAPI}/_products/?${query}`,
  updateProductStatus: (version: string, id: string) =>
    `${api.withAPI}/_products/${id}/updateByAdmin?__v=${version}`,
}
