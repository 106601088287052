import React, { FC } from 'react'
import { useHistory } from 'react-router'
import { Divider } from '../Divider'
import { Container, Header, Title, TableWrapper } from './styled'

interface IPageContainer {
  title: string
  back?: boolean
}

const PageContainer: FC<IPageContainer> = ({
  title,
  children,
  back = false,
}) => {
  const history = useHistory()

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        {back ? <button onClick={() => history.goBack()}>назад</button> : ''}
      </Header>

      <Divider height={22} />

      {children}
    </Container>
  )
}

export default PageContainer
