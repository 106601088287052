import { api } from '../config'

export const USER_URL = {
  login: `${api.withAPI}/auth/login`,
  logout: `${api.withAPI}/auth/logout`,
  get_user: `${api.withAPI}/profile`,
  forgot_password: `${api.withAPI}/auth/forgot-password`,
  send_change_email_link: `${api.withAPI}/auth/send-change-email-link`,
  change_email: token =>
    `${api.withAPI}/auth/change-email-by-link/?token=${token}`,
  change_data: `${api.withAPI}/profile/update`,
  change_password: `${api.withAPI}/auth/change-password`,
}
