import React, { FC, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { useTypedSelector } from '../../hooks'
import { getCategories } from './api'
import { TableInstance } from '../../components/Table'
import { Button } from '../../components'
import { FunctionalRow, CreateButtonContainer } from '../../components/Styled'
import {
  StyledTableWrapper,
  StyledViewButton,
} from '../../components/Table/styled'
import { Assets, Colors } from '../../assets'
import { Loader } from '../../contexts'
import { DumbButton } from '../../components/FormComponents/styled'
import { RowWrapper } from '../../components/Modals/styled'
import {
  InfoTitle,
  SimpleDivider,
  Title,
} from '../../components/Styled/formStyled'
import { useDispatch } from 'react-redux'
import {
  api,
  getCategoriesAction,
  updateCategoryProductsAction,
} from '../../store'
import Checkbox from '../../components/Input/Checkbox'
import _ from 'lodash'

const Categories: FC = ({}) => {
  const [tableData, setTableData] = useState<any>([])
  const history = useHistory()
  const { categories } = useTypedSelector(state => state.categories)

  const dispatch = useDispatch()
  const handlePromo = (id: string, promo: boolean) => {
    console.log('lel')
    dispatch(
      updateCategoryProductsAction.request({ id, data: { isPromo: promo } }),
    )
  }

  useEffect(() => {
    if (categories) {
      setTableData([
        ...categories.map((i: any) => {
          const image = i.image ? (
            <img
              width="70px"
              height="70px"
              //@ts-ignore
              src={`${api.images}${i.image}`}
            />
          ) : (
            ''
          )
          console.log('data', i)
          return {
            ...i,
            image,
            status: i.status === 'show' ? 'Да' : 'Нет',
            total: i.productsTotal,
            promo: (
              <div>
                <Checkbox
                  value={i.promotional}
                  onChange={() => handlePromo(i._id, !i.promotional)}
                  name="Hello"
                />
              </div>
            ),
            options: (
              <StyledViewButton
                onClick={() => history.push(`/categories/${i._id}`)}
              >
                <img
                  height="24px"
                  width="24px"
                  src={Assets.SHOW_PASSWORD_ICON}
                />
              </StyledViewButton>
            ),
          }
        }),
      ])
    }
  }, [categories])

  useEffect(() => {
    dispatch(getCategoriesAction.request())
  }, [])

  const columns = [
    {
      Header: 'Фото',
      accessor: 'image',
    },
    {
      Header: 'Название',
      accessor: 'ru',
    },
    {
      Header: 'Позиция',
      accessor: 'position',
    },
    {
      Header: 'К-вo товаров',
      accessor: 'total',
    },
    // {
    //   Header: 'К-во товаров',
    //   accessor: 'products_count',
    // },
    {
      Header: 'Отображать',
      accessor: 'status',
    },
    {
      Header: 'Акция',
      accessor: 'promo',
    },
    {
      accessor: 'options',
    },
  ]

  // if (isLoading || !tableData) {
  //   return <Loader isLoading={isLoading} />
  // }

  return (
    <>
      <RowWrapper>
        <Title>Категории</Title>
        <DumbButton
          onClick={() => history.push('/category-create')}
          bg={Colors.green}
          txt={Colors.white}
        >
          Добавить
        </DumbButton>
      </RowWrapper>
      <SimpleDivider height="20px" />
      {categories.length !== 0 ? (
        <StyledTableWrapper>
          <TableInstance tableData={tableData} importedColumns={columns} />
        </StyledTableWrapper>
      ) : (
        <div></div>
      )}
    </>
  )
}

export default Categories
