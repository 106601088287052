import { AxiosResponse } from 'axios'
import {
  GetAllStatsManagersActionResponse,
  GetManagerStatsByIdResponse,
} from './types'

import { setAuthorizationHeadersProp } from '../../helpers'
import { HttpService } from '../../services'
import { MANAGERS_STATS_URL } from './config'
import { PayloadAuth } from '../types'

export class ManagersStatsAPI extends HttpService {
  static getAllStatsManagers({
    authorization,
    data,
  }: PayloadAuth & any): Promise<
    AxiosResponse<GetAllStatsManagersActionResponse>
  > {
    return this.request<GetAllStatsManagersActionResponse>(
      'GET_ALL_STATS_MANAGERS',
      {
        url: MANAGERS_STATS_URL.get_all_stats_managers(data),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static getManagerStatsById({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<GetManagerStatsByIdResponse>> {
    return this.request<GetManagerStatsByIdResponse>(
      'GET_MANAGER_STATS_BY_ID',
      {
        url: MANAGERS_STATS_URL.get_manager_stats_by_id(data),
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }
}
