import { TablePagination } from '@material-ui/core'
import { FC } from 'react'
import { PaginationContainer } from './styled'

interface IPagination {
  setPagination: (prev: any) => void
  pagination: any
  count: number
}

const Pagination: FC<IPagination> = ({ pagination, setPagination, count }) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPagination(prev => ({ ...prev, skip: newPage }))
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPagination(prev => ({ ...prev, limit: parseInt(event.target.value) }))
  }
  return (
    <PaginationContainer>
      <TablePagination
        component="div"
        count={count}
        page={pagination.skip}
        onPageChange={handleChangePage}
        rowsPerPage={pagination.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </PaginationContainer>
  )
}

export default Pagination
