import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetProductsActionResponse,
  GetProductActionResponse,
  SearchProductByTitleActionResponse,
  GetProductActionPayload,
  CreateProductActionPayload,
  UpdateProductActionPayload,
  DeleteProductActionPayload,
  DeleteProductPhotoActionPayload,
  UpdateProductPhotoActionPayload,
  SearchProductByTitleActionPayload,
  SearchProductByDateActionPayload,
  SearchProductByDateActionResponse,
  SortProductsByQueryActionPayload,
  SortProductsByQueryActionResponse,
  UpdateProductPhotoOrderActionPayload,
  GetFilteredProductsActionPayload,
  GetFilteredProductsActionResponse,
  GetPendingProductsActionResponse,
  GetPendingProductsActionPayload,
  SetProductsFilterActionPayload,
  UpdateProductStatusActionPayload,
  GetProductsActionPayload,
} from './types'

export const getMyProductsAction = createApiActions<
  GetProductsActionPayload,
  GetProductsActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/GET_MY_PRODUCTS'))

export const getMyPendingProductsAction = createApiActions<
  GetProductsActionPayload,
  GetProductsActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/GET_MY_PENDING_PRODUCTS'))

export const getProductsAction = createApiActions<
  GetProductsActionPayload,
  GetProductsActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/GET_PRODUCTS'))

export const getPendingProductsAction = createApiActions<
  GetPendingProductsActionPayload,
  GetPendingProductsActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/GET_PENDING_PRODUCTS'))

export const getProductAction = createApiActions<
  GetProductActionPayload,
  GetProductActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/GET_PRODUCT'))

export const getFilteredProductsAction = createApiActions<
  GetFilteredProductsActionPayload,
  GetFilteredProductsActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/GET_FILTERED_PRODUCTS'))

export const unsetProductAction = createAction('PRODUCTS/UNSET_PRODUCT')

export const createProductAction = createApiActions<
  CreateProductActionPayload,
  void,
  AxiosError
>(createActionTypes('PRODUCTS/CREATE_PRODUCT'))

export const updateProductAction = createApiActions<
  UpdateProductActionPayload,
  void,
  AxiosError
>(createActionTypes('PRODUCTS/UPDATE_PRODUCT'))

export const deleteProductAction = createApiActions<
  DeleteProductActionPayload,
  void,
  AxiosError
>(createActionTypes('PRODUCTS/DELETE_PRODUCT'))

export const updateProductPhotoAction = createApiActions<
  UpdateProductPhotoActionPayload,
  void,
  AxiosError
>(createActionTypes('PRODUCTS/UPDATE_PRODUCT_PHOTO'))

export const deleteProductPhotoAction = createApiActions<
  DeleteProductPhotoActionPayload,
  void,
  AxiosError
>(createActionTypes('PRODUCTS/DELETE_PRODUCT_PHOTO'))

export const updateProductPhotoOrderAction = createApiActions<
  UpdateProductPhotoOrderActionPayload,
  void,
  AxiosError
>(createActionTypes('PRODUCTS/UPDATE_PRODUCT_PHOTO_ORDER'))

export const searchProductByTitleAction = createApiActions<
  SearchProductByTitleActionPayload,
  SearchProductByTitleActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/SEARCH_PRODUCT_BY_TITLE'))

export const searchProductByDateAction = createApiActions<
  SearchProductByDateActionPayload,
  SearchProductByDateActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/SEARCH_PRODUCT_BY_DATE'))

export const sortProductsByQueryAction = createApiActions<
  SortProductsByQueryActionPayload,
  SortProductsByQueryActionResponse,
  AxiosError
>(createActionTypes('PRODUCTS/SORT_PRODUCTS_BY_QUERY'))

export const editProductPromoAction = createApiActions<any, void, AxiosError>(
  createActionTypes('PRODUCTS/EDIT_PRODUCTS_PROMO'),
)

export const setProductsFilterAction = createAction<
  SetProductsFilterActionPayload,
  'PRODUCTS/SET_FILTER'
>('PRODUCTS/SET_FILTER')

export const updateProductStatusAction = createApiActions<
  UpdateProductStatusActionPayload,
  void,
  AxiosError
>(createActionTypes('PRODUCTS/UPDATE_PRODUCT_STATUS'))
