import { createReducer } from '@reduxjs/toolkit'
import {
  IAllOrdersStats,
  IAmountStats,
  IInitialState,
  IOrdersStats,
  ISuppliersStats,
} from './types'

import {
  setAllOrdersStatsAction,
  setAmountStatsAction,
  setFinanceFilterAction,
  setFinanceSearchQueryAction,
  setSupplierOrdersAction,
  setSuppliersStatsAction,
  updateNotesForOwnerAction,
} from './actions'

const InitialState: IInitialState = {
  suppliersStats: {} as ISuppliersStats,
  amountStats: {} as IAmountStats,
  orders: {} as IOrdersStats,
  allOrdersStats: {} as IAllOrdersStats,
  filter: '',
  seacrhQuery: '',
  loading: false,
}

export const financeReducer = createReducer<IInitialState>(
  InitialState,
  builder => {
    builder.addCase(setSuppliersStatsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(setSuppliersStatsAction.success, (state, { payload }) => ({
      ...state,
      suppliersStats: payload.data,
      loading: false,
    }))
    builder.addCase(setSuppliersStatsAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setAmountStatsAction.request, state => ({
      ...state,
    }))
    builder.addCase(setAmountStatsAction.success, (state, { payload }) => ({
      ...state,
      amountStats: payload.data,
    }))
    builder.addCase(setAmountStatsAction.failure, (state, { payload }) => ({
      ...state,
    }))

    builder.addCase(setSupplierOrdersAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(setSupplierOrdersAction.success, (state, { payload }) => ({
      ...state,
      orders: payload.data,
      loading: false,
    }))
    builder.addCase(setSupplierOrdersAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setAllOrdersStatsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(setAllOrdersStatsAction.success, (state, { payload }) => ({
      ...state,
      allOrdersStats: payload.data,
      loading: false,
    }))
    builder.addCase(setAllOrdersStatsAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(setFinanceFilterAction, (state, { payload }) => ({
      ...state,
      filter: payload,
    }))

    builder.addCase(setFinanceSearchQueryAction, (state, { payload }) => ({
      ...state,
      seacrhQuery: payload,
    }))

    builder.addCase(updateNotesForOwnerAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(
      updateNotesForOwnerAction.success,
      (state, { payload }) => ({
        ...state,
        allOrdersStats: payload.data,
        loading: false,
      }),
    )
    builder.addCase(updateNotesForOwnerAction.failure, state => ({
      ...state,
      loading: false,
    }))
  },
)
