import { FC, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Assets, Colors } from '../../assets'
import { useTypedSelector } from '../../hooks'
import {
  createProductAction,
  showToastAction,
  getCategoriesAction,
  getMyStoresAction,
  getSubsubcategoriesAction,
  getUserSelector,
} from '../../store'
import {
  ButtonGroup,
  Container,
  Header,
  Title,
  BackButton,
  TitleGroup,
  Content,
  Column,
  InfoTitle,
  InfoLabel,
  InfoTextArea,
  InputContainer,
  SaveButton,
  StoreColumn,
} from './styled'
import { Input, Storage, Images, Slide } from '../../components/FormComponents'
import { generateErrorToast } from '../../helpers'
import Checkbox from '../../components/Input/Checkbox'
import { _productSchema as productSchema } from '../../validation/productSchema'
import { IFile } from './types'
import { getBrandsAction } from '../../store/brands'
import {
  SimpleDivider,
  StyledWidthWrapper,
} from '../../components/Styled/formStyled'
import {
  DumbInput,
  StyledInputLabel,
} from '../../components/FormComponents/styled'
import { getExchangeRateAction } from '../../store/settings'
import {
  Icon,
  ProfileInput,
  ProfileInputLabel,
  ProfileInputWrapper,
  SmallDumbBtn,
} from '../Profile/styled'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { RowStartWrapper } from '../../components/Modals/styled'
import { Divider } from '../../components'

interface IStore {
  store: string
  count: string
}

const ProductCreate: FC = () => {
  const [brand, setBrand] = useState<any>(null)
  const [storage, setStorage] = useState<IStore[]>([])
  const [status, setStatus] = useState('in_stock')
  const [files, setFiles] = useState<IFile[]>([])
  const [characteristics, setCharacteristics] = useState({
    categories: [],
  })
  const [selectedCharacteristics, setSelectedCharacteristics] = useState<any>({
    selectedCategory: {},
    selectedSubcategory: {},
    selectedSubSubcategory: {},
    selectedCharacteristics: [],
  })
  const [characteristicsInput, setCharacteristicsInput] = useState<any[]>([])
  const [draggedImage, setDraggedImage] = useState<any>(null)
  const [noveltyCheckbox, setNoveltyCheckbox] = useState(false)
  const [promoCheckbox, setPromoCheckbox] = useState(false)
  const [selectedStore, setSelectedStore] = useState<any>([
    {
      store: '',
      count: 0,
    },
  ])

  const { user } = useSelector(getUserSelector)

  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  const [categories, setCategories] = useState<any[]>([])

  const [categoryTextCharacteristics, setCategoryTextCharacteristics] =
    useState<any[]>([])

  const [categoryRangeCharacteristics, setCategoryRangeCharacteristics] =
    useState<any[]>([])

  const [selectedTextCharacteristics, setSelectedTextCharacteristics] =
    useState<any[]>([])

  const [selectedRangeCharacteristics, setSelectedRangeCharacteristics] =
    useState<any[]>([])

  const [rate, setRate] = useState(0)

  const dispatch = useDispatch()
  const history = useHistory()

  const categoriesState = useTypedSelector(state => state.categories)
  const brandsState = useTypedSelector(state => state.brands)
  const { stores, loading, myStores } = useTypedSelector(state => state.stores)
  const exchangeRateState = useTypedSelector(state => state.settings)

  useEffect(() => {
    dispatch(getCategoriesAction.request())
    dispatch(getExchangeRateAction.request())
    dispatch(getBrandsAction.request())
    dispatch(getMyStoresAction.request())
    // dispatch(getSubsubcategoriesAction.request())
  }, [])

  useEffect(() => {
    if (stores) {
      const fetchedStorage = stores.map(i => {
        return { store: i._id, count: '0' }
      })
      setStorage(fetchedStorage)
    }

    if (exchangeRateState?.exchangeRate?.rate) {
      setRate(exchangeRateState.exchangeRate.rate)
    }

    if (categoriesState.categories) {
      setCharacteristics((prev: any) => {
        return { ...prev, categories: categoriesState.categories }
      })
    }
    if (categoriesState.categories) {
      setCategories(categoriesState.categories)
    }
  }, [loading, categoriesState.loading])

  const handleStorageChange = (id: string, i: string) => {
    if (findStore(id) !== -1) {
      const storeIndex = findStore(id)
      const newState = [...storage]
      const store = { ...newState[storeIndex!] }
      store.count = i
      newState[storeIndex!] = store
      setStorage(newState)
    }
  }

  const findStore = (id: string) => {
    for (let i = 0; i < storage.length; i++) {
      const e = storage[i]
      if (e.store === id) {
        return i
      }
    }
    return -1
  }

  const storesElement = storage.map((i: any, index: number) => {
    return (
      <Storage
        id={i.store}
        onChange={handleStorageChange}
        value={i.count}
        key={index}
      />
    )
  })

  const handleFile = (e: any) => {
    const files: any[] = Array.from(e.target.files || [])
    for (let itr of files) {
      setFiles(prev => [
        ...prev,
        {
          order: prev.length + 1,
          file: itr,
          src: URL.createObjectURL(itr),
          name: itr.name,
        },
      ])
    }
  }

  const handleImageRemoval = (e: any) => {
    setFiles(files.filter(i => i.name !== e))
    // setImages(images.filter(i => i.name !== e))
  }

  const handleSave = (info: any) => {
    if (files.length !== 0) {
      const data = new FormData()
      const {
        en,
        ru,
        uz,
        price,
        usdPrice,
        vendorCode,
        discount,
        description,
        descriptionEn,
        descriptionUz,
        characteristics,
      } = info

      if (!selectedStore[0].store && status !== 'order') {
        dispatch(
          showToastAction.request(
            generateErrorToast('Необходимо выбрать склад'),
          ),
        )
        return
      }

      if (
        !selectedCharacteristics.selectedCategory?._id ||
        !selectedCharacteristics.selectedSubcategory?._id ||
        !selectedCharacteristics.selectedSubSubcategory._id
      ) {
        dispatch(
          showToastAction.request(
            generateErrorToast('Необходимо выбрать все уровни категорий'),
          ),
        )
        return
      }

      const parseStringIntoInt = (input: string) => {
        return input.replaceAll(' ', '')
      }

      const filteredStorage = () => {
        return storage.filter(i => i.count !== '0')
      }

      const formatCharacteristics = (type: string) => {
        switch (type) {
          case 'range':
            return JSON.stringify(
              selectedRangeCharacteristics.map(i => {
                return { value: i.value, title: i.title._id }
              }),
            )

          case 'text':
            return JSON.stringify(
              selectedTextCharacteristics.map(i => {
                return { value: i.value, title: i.title._id }
              }),
            )
        }
      }

      //   const getProductStatus = () => {
      //     for (let i = 0; i < storage.length; i++) {
      //       if (storage[i].count !== '0') {
      //         return 'in_stock'
      //       }
      //     }
      //     return 'order'
      //   }
      data.append('en', en)
      data.append('ru', ru)
      data.append('uz', uz)
      data.append('price', parseStringIntoInt(price))

      data.append('usdPrice', parseStringIntoInt(usdPrice))
      data.append('vendorCode', parseStringIntoInt(vendorCode))
      data.append('discount', parseStringIntoInt(discount))
      data.append('description', description.trim())
      data.append('descriptionEn', descriptionEn.trim())
      data.append('descriptionUz', descriptionUz.trim())

      data.append('category', selectedCharacteristics.selectedCategory._id)
      data.append(
        'subcategory',
        selectedCharacteristics.selectedSubcategory._id,
      )
      data.append(
        'subsubcategory',
        selectedCharacteristics.selectedSubSubcategory._id,
      )
      data.append('novelty', JSON.stringify(noveltyCheckbox))
      data.append('isPromo', JSON.stringify(promoCheckbox))
      data.append('rangeCharacteristics', formatCharacteristics('range')!)
      data.append('textCharacteristics', formatCharacteristics('text')!)
      data.append(
        'stores',
        selectedStore[0].store
          ? JSON.stringify(selectedStore)
          : JSON.stringify([]),
      )
      data.append('status', status)
      data.append('brand', brand._id)
      files.forEach((i: any) => {
        data.append('image', i.file)
      })
      //@ts-ignore
      for (var pair of data.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }

      if (window.confirm('Вы уверены?')) {
        dispatch(
          createProductAction.request({
            data,
            permission: checkPermission('productGetAll'),
          }),
        )
        history.push('/products')
      }
    } else
      dispatch(
        showToastAction.request(
          generateErrorToast('Необходимо добавить фотографию'),
        ),
      )
  }

  const handleCategories = (value: any) => {
    setCharacteristicsInput(
      value.textCharacteristics.map((i: any) => {
        return { ...i, value: '' }
      }),
    )
    setSelectedCharacteristics((prev: any) => {
      return {
        ...prev,
        selectedCategory: {
          ...value,
          characteristics: value.textCharacteristics.map((i: any) => {
            return {
              en: i.en,
              ru: i.ru,
              uz: i.uz,
              possibleValues: i.possibleValues,
              value: '',
            }
          }),
        },
      }
    })

    setCategoryTextCharacteristics([...value.textCharacteristics])
    setCategoryRangeCharacteristics([...value.rangeCharacteristics])
  }

  const handleSubcategories = (value: any) => {
    setSelectedCharacteristics((prev: any) => {
      return {
        ...prev,
        selectedSubcategory: value,
      }
    })
  }

  const handleSubSubcategories = (value: any) => {
    setSelectedCharacteristics((prev: any) => {
      return {
        ...prev,
        selectedSubSubcategory: value,
      }
    })
  }

  const handleTextCharacteristicSelect = (item: any, parent: any) => {
    setSelectedTextCharacteristics(prev => {
      return [
        ...prev.filter(f => f.title._id !== parent._id),
        { value: item, title: parent },
      ]
    })
  }

  const handleRangeCharacteristicChange = (value: string, parent: any) => {
    setSelectedRangeCharacteristics(prev => {
      return [
        ...prev.filter(f => f.title._id !== parent._id),
        { value, title: parent },
      ]
    })
  }

  const subcat = () => {
    if (Object.keys(selectedCharacteristics.selectedCategory).length !== 0) {
      return (
        <Slide
          label={'Подкатегория'}
          options={
            selectedCharacteristics?.selectedCategory?.subcategories
              ? selectedCharacteristics?.selectedCategory?.subcategories
              : []
          }
          select={handleSubcategories}
          selected={selectedCharacteristics.selectedSubcategory}
        />
      )
    }
  }

  const subSubcat = () => {
    if (selectedCharacteristics.selectedSubcategory?.child) {
      return (
        <Slide
          label={'3 уровень'}
          options={selectedCharacteristics.selectedSubcategory?.child}
          select={handleSubSubcategories}
          selected={selectedCharacteristics.selectedSubSubcategory}
        ></Slide>
      )
    }
  }

  const renderCharacteristics = (type: string) => {
    switch (type) {
      case 'text':
        if (categoryTextCharacteristics.length > 0) {
          return categoryTextCharacteristics.map(i => {
            const getSelected = () => {
              const findObj = selectedTextCharacteristics.find(
                f => f.title._id === i._id,
              )
              return findObj ? findObj.value : null
            }
            return (
              <StyledWidthWrapper
                // style={{ marginRight: '30px' }}
                width="45%"
              >
                <Slide
                  label={i.ru}
                  options={i.possibleValues}
                  select={(e: any) => handleTextCharacteristicSelect(e, i)}
                  selected={getSelected()}
                />
              </StyledWidthWrapper>
            )
          })
        }
        break

      case 'range':
        if (categoryRangeCharacteristics.length > 0) {
          return categoryRangeCharacteristics.map(i => {
            return (
              <StyledWidthWrapper width="45%">
                <div>
                  <StyledInputLabel>{i.ru}</StyledInputLabel>
                  <DumbInput
                    style={{ margin: '4px 0 0 0', width: '100%' }}
                    onChange={(e: any) =>
                      handleRangeCharacteristicChange(e.target.value, i)
                    }
                    placeholder={`${i.possibleValues[0]} - ${i.possibleValues[1]}`}
                  />
                </div>
              </StyledWidthWrapper>
            )
          })
        }
        break
    }
  }

  return (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>Создать товар</Title>
            <BackButton onClick={() => history.push('/products')}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
        </TitleGroup>
        <ButtonGroup>
          <SaveButton htmlFor="hiddenProductCreate">Cоздать</SaveButton>
        </ButtonGroup>
      </Header>
      <Content>
        <Formik
          initialValues={{
            en: '',
            ru: '',
            uz: '',
            price: '',
            usdPrice: '',
            vendorCode: '',
            discount: '',
            description: '',
            descriptionEn: '',
            descriptionUz: '',

            category: {
              label: '',
              title: '',
              characteristics: [] as any[],
              subcategories: [] as any[],
            },
            subcategory: {
              label: '',
              title: '',
            },
            customCharacteristic: '',
          }}
          validationSchema={productSchema}
          onSubmit={values => handleSave(values)}
        >
          {({ values, handleChange, errors, setFieldValue, submitForm }) => (
            <Form id="myForm" style={{ display: 'flex', width: '100%' }}>
              <Column>
                <InfoTitle>Основные данные</InfoTitle>
                <InputContainer>
                  <Input
                    title="English"
                    handleChange={handleChange}
                    value={values.en}
                    name="en"
                    error={errors.en}
                    width="33%"
                  />
                  <Input
                    title="Русский"
                    handleChange={handleChange}
                    value={values.ru}
                    name="ru"
                    error={errors.ru}
                    width="33%"
                  />
                  <Input
                    title="Ozbek"
                    handleChange={handleChange}
                    value={values.uz}
                    name="uz"
                    error={errors.uz}
                    width="33%"
                  />
                </InputContainer>
                <div style={{ width: '100%' }}>
                  <Images
                    files={files}
                    setFiles={setFiles}
                    draggedImage={draggedImage}
                    setDraggedImage={setDraggedImage}
                    handleFile={handleFile}
                    handleDelete={handleImageRemoval}
                  />
                </div>
                <InputContainer>
                  <Input
                    title="Цена в долларах"
                    handleChange={handleChange}
                    value={values.usdPrice}
                    name="usdPrice"
                    error={errors.usdPrice}
                    width="50%"
                  />
                  <Input
                    title="Цена в суммах"
                    handleChange={handleChange}
                    value={values.price}
                    name="price"
                    error={errors.price}
                    width="50%"
                    margin="10px"
                  />
                  <Input
                    title="Скидка"
                    handleChange={handleChange}
                    value={values.discount}
                    name="discount"
                    error={errors.discount}
                    width="50%"
                  />
                  <Input
                    title="Артикул"
                    handleChange={handleChange}
                    value={values.vendorCode}
                    name="vendorCode"
                    error={errors.vendorCode}
                    width="50%"
                  />
                  <div style={{ width: 'calc(50% - 30px)', marginTop: '10px' }}>
                    <Slide
                      label="Бренд"
                      options={brandsState.brands}
                      selected={brand}
                      select={setBrand}
                    />
                  </div>
                </InputContainer>
                <Checkbox
                  onChange={() =>
                    setStatus(prev => (prev === 'order' ? 'in_stock' : 'order'))
                  }
                  value={status === 'order' ? true : false}
                >
                  Товар под заказ
                </Checkbox>
                {status === 'serial' ? storesElement : null}
                <StoreColumn>
                  {selectedStore.map((store, index) => (
                    <ProfileInputWrapper
                      style={{ margin: '20px 0' }}
                      key={index}
                    >
                      <RowStartWrapper style={{ alignItems: 'flex-end' }}>
                        <ProfileInputWrapper style={{ maxWidth: '254px' }}>
                          <ProfileInputLabel>Выбор склада</ProfileInputLabel>

                          <Select
                            labelId="store"
                            id="store-select"
                            onChange={e =>
                              setSelectedStore(prev =>
                                prev.map((item, i) =>
                                  i === index
                                    ? { ...item, store: e.target.value }
                                    : item,
                                ),
                              )
                            }
                            value={selectedStore[index].store}
                            style={{ width: '100%', height: '40px' }}
                          >
                            {myStores.map(store => (
                              <MenuItem
                                key={store._id}
                                value={store._id}
                              >{`${store?.country} ${store?.city} ${store?.street} ${store?.home}`}</MenuItem>
                            ))}
                          </Select>
                        </ProfileInputWrapper>

                        <ProfileInputWrapper style={{ maxWidth: '90px' }}>
                          <ProfileInputLabel>Кол-во</ProfileInputLabel>
                          <ProfileInput
                            min={0}
                            value={selectedStore[index].count}
                            onChange={e =>
                              setSelectedStore(prev =>
                                prev.map((item, i) =>
                                  i === index
                                    ? { ...item, count: e.target.value }
                                    : item,
                                ),
                              )
                            }
                            type="number"
                          />
                        </ProfileInputWrapper>
                      </RowStartWrapper>
                    </ProfileInputWrapper>
                  ))}

                  <SmallDumbBtn
                    bg={Colors.green}
                    txt={Colors.white}
                    shadow={Colors.lightGreen}
                    onClick={() =>
                      setSelectedStore(prev => [
                        ...prev,
                        { store: '', count: 0 },
                      ])
                    }
                  >
                    <Icon src={Assets.PLUS_WHITE_ICON}></Icon>
                    Добавить
                  </SmallDumbBtn>
                </StoreColumn>
                <SimpleDivider height="15px" />
                <InfoLabel htmlFor="description">Описание Русский</InfoLabel>
                <InfoTextArea
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                />
                <SimpleDivider height="15px" />
                <InfoLabel htmlFor="descriptionEn">Описание English</InfoLabel>
                <InfoTextArea
                  name="descriptionEn"
                  onChange={handleChange}
                  value={values.descriptionEn}
                />
                <SimpleDivider height="15px" />
                <InfoLabel htmlFor="descriptionUz">Описание Ozbek</InfoLabel>
                <InfoTextArea
                  name="descriptionUz"
                  onChange={handleChange}
                  value={values.descriptionUz}
                />
              </Column>
              <Column>
                <InfoTitle>Характеристики</InfoTitle>

                <InputContainer>
                  <Slide
                    label="Категория"
                    options={categories}
                    select={handleCategories}
                    selected={selectedCharacteristics.selectedCategory}
                  />
                  <InputContainer>{subcat()}</InputContainer>
                  <InputContainer>{subSubcat()}</InputContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {renderCharacteristics('text')}
                    {renderCharacteristics('range')}
                  </div>
                </InputContainer>
                <Checkbox
                  value={noveltyCheckbox}
                  onChange={(e: any) => setNoveltyCheckbox(e.target.checked)}
                >
                  Новинка
                </Checkbox>
                <SimpleDivider height="10px" />
                <Checkbox
                  value={promoCheckbox}
                  onChange={(e: any) => setPromoCheckbox(e.target.checked)}
                >
                  Акция
                </Checkbox>
                <button
                  type="submit"
                  style={{ display: 'none' }}
                  onClick={() => {
                    console.log(errors)
                  }}
                  id="hiddenProductCreate"
                >
                  test
                </button>
                {/* <button
                  type="button"
                  onClick={() => console.log(selectedRangeCharacteristics)}
                >
                  test
                </button> */}
              </Column>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  )
}

export default ProductCreate
