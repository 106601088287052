import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  CreateRoleActionPayload,
  CreateRoleActionResponse,
  CreateUserActionPayload,
  CreateUserActionResponse,
  DeleteUserActionPayload,
  SetActiveRolePayload,
  SetAdminsActionPayload,
  SetAdminsActionResponse,
  SetRolesActionResponse,
  UpdatePermissionsActionPayload,
  UpdatePermissionsActionResponse,
  UpdateRolesActionPayload,
  UpdateRolesActionResponse,
} from './types'

export const setRolesAction = createApiActions<
  void,
  SetRolesActionResponse,
  AxiosError
>(createActionTypes('ROLES/SET_ROLES'))

export const setAdminsAction = createApiActions<
  SetAdminsActionPayload,
  SetAdminsActionResponse,
  AxiosError
>(createActionTypes('ROLES/SET_ADMINS'))

export const updateRolesAction = createApiActions<
  UpdateRolesActionPayload,
  void,
  AxiosError
>(createActionTypes('ROLES/UPDATE_ROLES'))

export const updatePermissionsAction = createApiActions<
  UpdatePermissionsActionPayload,
  UpdatePermissionsActionResponse,
  AxiosError
>(createActionTypes('ROLES/UPDATE_PERMISSIONS'))

export const createRoleAction = createApiActions<
  CreateRoleActionPayload,
  CreateRoleActionResponse,
  AxiosError
>(createActionTypes('ROLES/CREATE_ROLE'))

export const createUserAction = createApiActions<
  CreateUserActionPayload,
  void,
  AxiosError
>(createActionTypes('ROLES/CREATE_USER'))

export const deleteUserAction = createApiActions<
  DeleteUserActionPayload,
  void,
  AxiosError
>(createActionTypes('ROLES/DELETE_USER'))
