import { debounce } from 'lodash'
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Assets } from '../../assets'
import { DateSearchInput, TableWrapper } from '../../components'
import { RowStartWrapper } from '../../components/Modals/styled'
import { SimpleDivider } from '../../components/Styled/formStyled'
import {
  getFinanceSelector,
  setSupplierOrdersAction,
} from '../../store/finance'
import { setAdminsAction } from '../../store/roles'
import {
  ColumnsGrid,
  FlexColumnsWrapper,
  GrayTitle,
  ItemNavLink,
  LinkWithIcon,
  RelativeInput,
  RelativeInputContainer,
} from '../Roles/styled'
import { DateInputsWrapper, FinanceHeader } from './styled'
import { IParams } from './types'

const SupplierOrders: FC = () => {
  const [searchQuery, setSearchQuery] = useState({
    name: '',
    phone: '',
  })
  const [dateSearch, setDateSearch] = useState({
    from: '',
    to: '',
  })

  const { id } = useParams<IParams>()

  const dispatch = useDispatch()

  const history = useHistory()

  useEffect(() => {
    dispatch(setAdminsAction.request(''))
  }, [])

  const { orders } = useSelector(getFinanceSelector)

  useEffect(() => {
    let query = ''

    if (searchQuery.name !== '' || searchQuery.phone !== '') {
      query =
        searchQuery.name !== ''
          ? `name=${searchQuery.name}`
          : `phone=${searchQuery.phone}`
    }

    if (dateSearch.from !== '' && dateSearch.to !== '') {
      query === ''
        ? (query += `createdAt[]=${dateSearch.from}&createdAt[]=${dateSearch.to}`)
        : (query += `&createdAt[]=${dateSearch.from}&createdAt[]=${dateSearch.to}`)
    } else if (dateSearch.from !== '' || dateSearch.to !== '') {
      query === ''
        ? (query += `createdAt[]=${
            dateSearch.from ? dateSearch.from : dateSearch.to
          }`)
        : (query += `&createdAt[]=${
            dateSearch.from ? dateSearch.from : dateSearch.to
          }`)
    }

    dispatchSearchQuery(query)
  }, [searchQuery, dateSearch])

  const onChangeSearchDate = (date: string, value: string) => {
    if (value === 'from') {
      setDateSearch(prev => ({ ...prev, from: date }))
    } else {
      setDateSearch(prev => ({ ...prev, to: date }))
    }
  }

  const onSearchOrder = (query: string) => {
    if (query.match(/[0-9]/)) {
      setSearchQuery({ name: '', phone: query })
    } else {
      setSearchQuery({ phone: '', name: query })
    }
  }

  const dispatchSearchQuery = useCallback(
    debounce((query: string) => {
      if (query === '') {
        dispatch(setSupplierOrdersAction.request({ id: id }))
      } else {
        dispatch(
          setSupplierOrdersAction.request({ id: id, queryString: query }),
        )
      }
    }, 300),
    [],
  )

  const findSupplierName = () => {
    const order = orders?.docs?.find(order => {
      return order?.supplierId?._id === id
    })

    return order?.supplierId?.name
  }

  const parseDate = (date: string) => {
    const newDate = new Date(date).toLocaleString().slice(0, 10)
    const time = new Date(date).toLocaleString().slice(12, 17)

    return `${newDate} ${time}`
  }

  return (
    <>
      <RowStartWrapper>
        <GrayTitle>Заказы {findSupplierName()}</GrayTitle>

        <LinkWithIcon onClick={() => history.goBack()}>
          <img src={Assets.ARROW_BACK_ICON} alt="" />
          Назад
        </LinkWithIcon>
      </RowStartWrapper>

      <SimpleDivider height="20px" />

      <TableWrapper>
        <FinanceHeader>
          <RelativeInputContainer maxwidth="300px">
            <RelativeInput
              value={searchQuery.name ? searchQuery.name : searchQuery.phone}
              onChange={e => onSearchOrder(e.target.value)}
              padding="0px 10px 0 35px"
              type="text"
              placeholder="Поиск..."
            />
            <img
              src={Assets.SEARCH_ICON}
              alt="search"
              className="search-icon"
            />
          </RelativeInputContainer>

          <DateInputsWrapper>
            <DateSearchInput
              value={dateSearch.from}
              onChange={e => onChangeSearchDate(e.target.value, 'from')}
              type="date"
              style={{ maxWidth: '150px' }}
            />

            <DateSearchInput
              value={dateSearch.to}
              onChange={e => onChangeSearchDate(e.target.value, 'to')}
              type="date"
              style={{ maxWidth: '150px' }}
            />
          </DateInputsWrapper>
        </FinanceHeader>

        <SimpleDivider height="30px" />

        <ColumnsGrid className="header" template="10% 17% 23% 15% 18% 12% 5%">
          <div>ID заказа</div>
          <div className="sort-item">
            Дата <img src={Assets.SORT_ARROW_ICON} alt="arrow" />
          </div>
          <div>Клиент</div>
          <div>Телефон</div>
          <div>Сумма</div>
          <div className="sort-item">
            Тип оплаты <img src={Assets.SORT_ARROW_ICON} alt="arrow" />
          </div>
          <div></div>
        </ColumnsGrid>

        <FlexColumnsWrapper>
          {orders?.docs &&
            orders.docs.map(order => (
              <ColumnsGrid
                template="10% 17% 23% 15% 18% 12% 5%"
                className="supplier-preview"
                key={order._id}
              >
                <div>{order.increment}</div>
                <div>{parseDate(order.createdAt)}</div>
                <div>{`${order.surname} ${order.name} ${order.patronymic}`}</div>
                <div>{order.phone}</div>
                <div>{order.amountPrice} сум</div>
                <div>
                  {order.paymentType === 'no_cash'
                    ? 'Онлайн-оплата'
                    : 'Наличные'}
                </div>
                <ItemNavLink to={`/orders/${order._id}`}>
                  <img src={Assets.SHOW_PASSWORD_ICON} alt="" />
                </ItemNavLink>
              </ColumnsGrid>
            ))}
        </FlexColumnsWrapper>
      </TableWrapper>
    </>
  )
}

export default SupplierOrders
