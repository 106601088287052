import { FC, useState } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { Formik, Form } from 'formik'
import { Assets } from '../../assets'
import {
  Container,
  Header,
  TitleGroup,
  Title,
  BackButton,
  Content,
  Column,
  InfoTitle,
  InputContainer,
  SaveButton,
} from '../../components/Styled/formStyled'
import { Input, Image } from '../../components/FormComponents'
import { useTypedSelector } from '../../hooks'
import { createBrand } from './api'
import { IBrand, IImage } from './types'
import { UploadButton } from '../../components/FormComponents/styled'
import { brandSchema } from '../../validation'

const BrandCreate: FC = ({}) => {
  const queryClient = useQueryClient()
  const [brandData, setBrandData] = useState<IBrand>({
    en: '',
    ru: '',
    uz: '',
    products: [],
    createdAt: '',
  })
  const [image, setImage] = useState<IImage>({ src: '', file: null })
  const history = useHistory()
  const { token } = useTypedSelector(state => state.user)
  const { id } = useParams<{ id: string }>()

  const brandMutation = useMutation(
    (newBrand: any) => createBrand(newBrand, token!),
    { onSuccess: () => history.goBack() },
  )

  const handleSave = (i: any) => {
    const { en, ru, uz } = i
    const data = new FormData()
    data.append('en', en)
    data.append('ru', ru)
    data.append('uz', uz)
    data.append('products', JSON.stringify([]))
    if (image.file) {
      data.append('image', image.file as any)
    }
    brandMutation.mutate(data)
  }

  const handleImage = (e: any) => {
    setImage({
      file: e.target.files[0],
      src: URL.createObjectURL(e.target.files[0]),
    })
    e.target.value = null
  }

  const handleImageRemove = () => {
    setImage({ file: null, src: '' })
  }

  const onBack = () => {
    history.push('/brands')
  }

  const content = brandData ? (
    <Container>
      <Header>
        <TitleGroup>
          <div style={{ display: 'flex' }}>
            <Title>Создать новый бренд</Title>
            <BackButton onClick={() => onBack()}>
              <img src={Assets.ARROW_BACK_ICON} /> Назад
            </BackButton>
          </div>
        </TitleGroup>
        <SaveButton htmlFor="hiddenSave">Сохранить</SaveButton>
      </Header>
      <Content>
        <Formik
          enableReinitialize={true}
          initialValues={brandData}
          validationSchema={brandSchema}
          onSubmit={values => handleSave(values)}
        >
          {({ values, handleChange, errors, setFieldValue }) => (
            <Form id="myForm" style={{ display: 'flex', width: '100%' }}>
              <Column>
                <InfoTitle>Название</InfoTitle>
                <InputContainer>
                  <Input
                    title="English"
                    handleChange={handleChange}
                    value={values.en}
                    name="en"
                    error={errors.en}
                    width="33%"
                    margin="5px"
                  />
                  <Input
                    title="Русский"
                    handleChange={handleChange}
                    value={values.ru}
                    name="ru"
                    error={errors.ru}
                    width="33%"
                    margin="5px"
                  />
                  <Input
                    title="Ozbek"
                    handleChange={handleChange}
                    value={values.uz}
                    name="uz"
                    error={errors.uz}
                    width="33%"
                    margin="5px"
                  />
                </InputContainer>
                <Image src={image?.src} handleImage={handleImageRemove} />
                <UploadButton
                  style={{ margin: '10px 0' }}
                  htmlFor="hiddenBrandImage"
                >
                  Загрузить фото
                </UploadButton>
                <input
                  style={{ margin: '10px 0', display: 'none' }}
                  id="hiddenBrandImage"
                  type="file"
                  onChange={e => handleImage(e)}
                />
              </Column>
              <Column></Column>
              <button style={{ display: 'none' }} id="hiddenSave" type="submit">
                test
              </button>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  ) : (
    <div>loading</div>
  )

  return <>{content}</>
}

export default BrandCreate
