import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  GetMaterialsActionResponse,
  GetMaterialActionResponse,
  GetMaterialActionPayload,
  CreateMaterialActionPayload,
  CreateMaterialActionResponse,
  DeleteMaterialActionPayload,
} from './types'

export const getMaterialsAction = createApiActions<
  void,
  GetMaterialsActionResponse,
  AxiosError
>(createActionTypes('MATERIAL/GET_MATERIALS'))

export const getMaterialAction = createApiActions<
  GetMaterialActionPayload,
  GetMaterialActionResponse,
  AxiosError
>(createActionTypes('MATERIAL/GET_MATERIAL'))

export const createMaterialAction = createApiActions<
  CreateMaterialActionPayload,
  CreateMaterialActionResponse,
  AxiosError
>(createActionTypes('MATERIAL/CREATE_MATERIAL'))

export const deleteMaterialAction = createApiActions<
  DeleteMaterialActionPayload,
  void,
  AxiosError
>(createActionTypes('MATERIAL/DELETE_MATERIAL'))
