import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import {
  getMaterialsAction,
  getMaterialAction,
  createMaterialAction,
} from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { MaterialAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'

function* getMaterialsWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<
      ReturnType<typeof MaterialAPI.getMaterials>
    > = yield call([MaterialAPI, MaterialAPI.getMaterials], {
      authorization: token,
    })
    console.log(response)
    yield put(getMaterialsAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категорий')),
    )
    yield put(getMaterialsAction.failure(e))
  }
}

function* getMaterialByIDWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof MaterialAPI.getMaterial>
    > = yield call([MaterialAPI, MaterialAPI.getMaterial], {
      authorization: token,
      id: payload.id,
    })

    yield put(getMaterialAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категории')),
    )

    yield put(getMaterialAction.failure(e))
  }
}

function* createMaterialWorker({
  payload,
}: ReturnType<typeof createMaterialAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)
    const response: PromiseReturnType<
      ReturnType<typeof MaterialAPI.createMaterial>
    > = yield call([MaterialAPI, MaterialAPI.createMaterial], {
      authorization: token,
      data: payload,
    })
    if (response) {
      const res: PromiseReturnType<
        ReturnType<typeof MaterialAPI.getMaterials>
      > = yield call([MaterialAPI, MaterialAPI.getMaterials], {
        authorization: token,
      })
      yield put(createMaterialAction.success(response.data))
      yield put(getMaterialsAction.success(res.data))
    }

    yield put(
      showToastAction.request(generateSuccessToast('Материал успешно создан')),
    )
  } catch (e) {
    console.log('Error: createMaterialWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания материала')),
    )

    yield put(createMaterialAction.failure(e))
  }
}

// function* deleteMaterialWorker({ payload }: { payload: { id: string } }) {
//   try {
//     const { token }: ReturnType<typeof getUserSelector> = yield select(
//       getUserSelector,
//     )

//     const response: PromiseReturnType<
//       ReturnType<typeof MaterialAPI.deleteMaterial>
//     > = yield call([MaterialAPI, MaterialAPI.deleteMaterial], {
//       authorization: token,
//       id: payload.id,
//     })

//     yield put(deleteMaterialAction.success())

//     yield put(
//       showToastAction.request(generateInfoToast('Категория успешно удалена')),
//     )

//     yield put(getMaterialsAction.request())
//   } catch (e) {
//     console.log('Error: deleteMaterialWorker', e)

//     yield put(
//       showToastAction.request(generateErrorToast('Ошибка удаления категории')),
//     )

//     yield put(deleteMaterialAction.failure(e))
//   }
// }

export function* materialsWatcher() {
  yield takeLatest(getMaterialsAction.request, getMaterialsWorker)
  yield takeLatest(getMaterialAction.request, getMaterialByIDWorker)
  yield takeLatest(createMaterialAction.request, createMaterialWorker)
  // yield takeLatest(deleteMaterialAction.request, deleteMaterialWorker)
}
