import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Assets, Colors } from '../../assets'
import { SimpleDivider } from '../../components/Styled/formStyled'
import { StoreCard } from './styled'
import { StoreProps } from './types'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  deleteStoreAction,
  getUserSelector,
  updateStoreAction,
} from '../../store'
import { useParams } from 'react-router-dom'
import ProfileModal from '../../components/Modals/ProfileModal'
import {
  PassModalTitle,
  ProfileInput,
  ProfileInputLabel,
  ProfileInputWrapper,
} from '../Profile/styled'
import { ModalInputRow } from '../Roles/styled'
import {
  BtnWrapper,
  DumbButton,
  DumbOutlineButton,
} from '../../components/FormComponents/styled'

export const Store: FC<StoreProps> = ({ store }) => {
  const [editStoreModalIsOpen, setEditStoreModalIsOpen] =
    useState<boolean>(false)
  const [deleteStoreModalIsOpen, setDeleteStoreModalIsOpen] =
    useState<boolean>(false)

  const { id } = useParams<any>()

  const { user } = useSelector(getUserSelector)

  const checkPermission = (permission: string) => {
    return user.role.permissions.includes(permission)
  }

  const dispatch = useDispatch()

  const schema = yup.object().shape({
    title: yup.string().required(),
    country: yup.string().required(),
    city: yup.string().required(),
    street: yup.string().required(),
    home: yup.string().required(),
  })

  useEffect(() => {
    Object.entries(store).forEach(value => {
      setValue(`${value[0]}`, `${value[1]}`)
    })
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema), mode: 'onBlur' })

  const onSubmitEdit = data => {
    setEditStoreModalIsOpen(false)

    dispatch(
      updateStoreAction.request({
        data: {
          title: data.title,
          country: data.country,
          city: data.city,
          street: data.street,
          home: data.home,
        },
        storeId: data._id,
        supplierId: !checkPermission('storeGetAll') ? user._id : id,
      }),
    )
  }

  const onDeleteStore = () => {
    dispatch(
      deleteStoreAction.request({
        storeId: store._id,
        supplierId: !checkPermission('storeGetAll') ? user._id : id,
      }),
    )
    setDeleteStoreModalIsOpen(false)
  }

  return (
    <>
      <StoreCard>
        <img
          src={Assets.BLACK_EYE_ICON}
          alt="view"
          className="edit-store"
          onClick={() => setEditStoreModalIsOpen(true)}
        />

        <img
          src={Assets.DELETE_GRAY_ICON}
          alt="delete"
          className="delete-store"
          onClick={() => setDeleteStoreModalIsOpen(true)}
        />

        <img src={Assets.STORE_IMG} alt="warehouse" className="store" />

        <SimpleDivider height="15px" />

        <div className="name">
          {store.title} / {`${store.country} ${store.city}`}
        </div>

        <SimpleDivider height="10px" />

        <div>Количество товаров: {store.countProducts}</div>

        <SimpleDivider height="8px" />

        <div>На сумму: {store.totalAmountProducts}</div>
      </StoreCard>

      <form onSubmit={handleSubmit(onSubmitEdit)}>
        <ProfileModal
          isModalOpen={editStoreModalIsOpen}
          onModalClose={() => setEditStoreModalIsOpen(false)}
        >
          <PassModalTitle>Склад {store.title}</PassModalTitle>

          <SimpleDivider height="20px" />

          <ProfileInputWrapper className="role-wrapper">
            <ProfileInputLabel>Название</ProfileInputLabel>
            <ProfileInput
              {...register('title')}
              className={`${errors.title ? 'error' : ''}`}
            />
          </ProfileInputWrapper>

          <SimpleDivider height="20px" />

          <ModalInputRow>
            <ProfileInputWrapper className="role-wrapper" maxWidth="300px">
              <ProfileInputLabel>Страна</ProfileInputLabel>
              <ProfileInput
                {...register('country')}
                className={`${errors.country ? 'error' : ''}`}
              />
            </ProfileInputWrapper>

            <ProfileInputWrapper className="role-wrapper" maxWidth="300px">
              <ProfileInputLabel>Город</ProfileInputLabel>
              <ProfileInput
                {...register('city')}
                className={`${errors.city ? 'error' : ''}`}
              />
            </ProfileInputWrapper>

            <ProfileInputWrapper className="role-wrapper" maxWidth="300px">
              <ProfileInputLabel>Улица</ProfileInputLabel>
              <ProfileInput
                {...register('street')}
                className={`${errors.street ? 'error' : ''}`}
              />
            </ProfileInputWrapper>

            <ProfileInputWrapper className="role-wrapper" maxWidth="300px">
              <ProfileInputLabel>Дом</ProfileInputLabel>
              <ProfileInput
                {...register('home')}
                className={`${errors.home ? 'error' : ''}`}
              />
            </ProfileInputWrapper>
          </ModalInputRow>
          <SimpleDivider height="30px" />

          <BtnWrapper>
            <DumbOutlineButton
              color={Colors.boldGray}
              type="button"
              onClick={() => setEditStoreModalIsOpen(false)}
              className="cancel"
            >
              Отмена
            </DumbOutlineButton>

            <DumbButton
              bg={Colors.orange}
              txt={Colors.black}
              onClick={handleSubmit(onSubmitEdit)}
            >
              Сохранить
            </DumbButton>
          </BtnWrapper>
        </ProfileModal>
      </form>

      <ProfileModal
        isModalOpen={deleteStoreModalIsOpen}
        onModalClose={() => setDeleteStoreModalIsOpen(false)}
      >
        <PassModalTitle>Удаление склада {store.title}</PassModalTitle>

        <SimpleDivider height="20px" />

        <div style={{ fontWeight: 600 }}>
          Вы точно хотите удалить склад из списка?
        </div>

        <SimpleDivider height="30px" />

        <BtnWrapper>
          <DumbOutlineButton
            color={Colors.boldGray}
            type="button"
            onClick={() => setDeleteStoreModalIsOpen(false)}
            className="cancel"
          >
            Отмена
          </DumbOutlineButton>

          <DumbButton
            bg={Colors.orange}
            txt={Colors.black}
            onClick={onDeleteStore}
          >
            Подтвердить
          </DumbButton>
        </BtnWrapper>
      </ProfileModal>
    </>
  )
}
