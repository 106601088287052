import { createReducer } from '@reduxjs/toolkit'
import { TInitialState } from './types'

import {
  createCountdownAction,
  deleteCountdownAction,
  getCountdownsAction,
  getCountdownAction,
  updateCountdownAction,
} from './actions'

const InitialState: TInitialState = {
  countdowns: [],
  countdown: null,
  loading: false,
}

export const countdownReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(createCountdownAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(createCountdownAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(createCountdownAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(updateCountdownAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(updateCountdownAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(updateCountdownAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(getCountdownsAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCountdownsAction.success, (state, { payload }) => ({
      ...state,
      countdowns: payload.data,
      loading: false,
    }))
    builder.addCase(getCountdownsAction.failure, (state, { payload }) => ({
      ...state,
      countdowns: [],
      loading: false,
    }))

    builder.addCase(deleteCountdownAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(deleteCountdownAction.success, (state, { payload }) => ({
      ...state,
      loading: false,
    }))
    builder.addCase(deleteCountdownAction.failure, (state, { payload }) => ({
      ...state,
      loading: false,
    }))

    builder.addCase(getCountdownAction.request, state => ({
      ...state,
      loading: true,
    }))
    builder.addCase(getCountdownAction.success, (state, { payload }) => ({
      ...state,
      countdown: payload.data,
      loading: false,
    }))
    builder.addCase(getCountdownAction.failure, (state, { payload }) => ({
      ...state,
      countdown: null,
      loading: false,
    }))
  },
)
