import { createAction } from '@reduxjs/toolkit'
import { createActionTypes, createApiActions } from '../actions-util'
import { AxiosError } from 'axios'

import {
  CreateBannerActionPayload,
  CreateBannerActionResponse,
  DeleteBannerActionPayload,
  DeleteBannerActionResponse,
  getBannersActionPayload,
  getBannersActionResponse,
  SetNewBannerActionPayload,
  UpdateBannerActionPayload,
  UpdateBannerActionResponse,
} from './types'

export const getBannersAction = createApiActions<
  getBannersActionPayload,
  getBannersActionResponse,
  AxiosError
>(createActionTypes('BANNERS/GET_BANNERS'))

export const createBannerAction = createApiActions<
  CreateBannerActionPayload,
  CreateBannerActionResponse,
  AxiosError
>(createActionTypes('BANNERS/CREATE_BANNER'))

export const updateBannerAction = createApiActions<
  UpdateBannerActionPayload,
  UpdateBannerActionResponse,
  AxiosError
>(createActionTypes('BANNERS/UPDATE_BANNER'))

export const deleteBannerAction = createApiActions<
  DeleteBannerActionPayload,
  DeleteBannerActionResponse,
  AxiosError
>(createActionTypes('BANNERS/DELETE_BANNER'))

export const setNewBannerAction = createAction<
  SetNewBannerActionPayload,
  'BANNERS/SET_NEW_BANNER'
>('BANNERS/SET_NEW_BANNER')

export const deleteNewBannerAction = createAction<
  SetNewBannerActionPayload,
  'BANNERS/DELETE_NEW_BANNER'
>('BANNERS/DELETE_NEW_BANNER')

export const setEditedNewBannersAction = createAction<
  SetNewBannerActionPayload,
  'BANNERS/SET_EDITED_NEW_BANNERS'
>('BANNERS/SET_EDITED_NEW_BANNERS')

export const setEditedBannersAction = createAction<
  SetNewBannerActionPayload,
  'BANNERS/SET_EDITED_BANNERS'
>('BANNERS/SET_EDITED_BANNERS')
