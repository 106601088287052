import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import { getManagersStatsAction, getManagerStatsByIdAction } from './actions'

import { generateErrorToast } from '../../helpers'
import { AuthorizationError } from '../../errors'
import { getUserSelector } from './selectors'
import { PromiseReturnType } from '../types'
import { showToastAction } from '../toasts'
import { ManagersStatsAPI } from './api.service'
import { AxiosError } from 'axios'

export function* verifyTokenWorker() {
  const { token }: ReturnType<typeof getUserSelector> = yield select(
    getUserSelector,
  )

  if (token) return token

  throw new AuthorizationError('verifyTokenWorker')
}

function* getAllManagersStatsWorker({
  payload,
}: ReturnType<typeof getManagersStatsAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ManagersStatsAPI.getAllStatsManagers>
    > = yield call([ManagersStatsAPI, ManagersStatsAPI.getAllStatsManagers], {
      authorization: token,
      data: payload,
    })

    yield put(getManagersStatsAction.success(response.data))
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка загрузки статистики')),
    )
    yield put(getManagersStatsAction.failure(e as AxiosError))
  }
}

function* getManagerStatsByIdWorker({
  payload,
}: ReturnType<typeof getManagerStatsByIdAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<
      ReturnType<typeof ManagersStatsAPI.getManagerStatsById>
    > = yield call([ManagersStatsAPI, ManagersStatsAPI.getManagerStatsById], {
      authorization: token,
      data: payload,
    })

    console.log(response)

    yield put(getManagerStatsByIdAction.success(response.data))
  } catch (e) {
    console.log('Error: updatePermissionsWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка загрузки статистики')),
    )
    yield put(getManagerStatsByIdAction.failure(e as AxiosError))
  }
}

export function* managersStatsWatcher() {
  yield takeLatest(getManagersStatsAction.request, getAllManagersStatsWorker)
  yield takeLatest(getManagerStatsByIdAction.request, getManagerStatsByIdWorker)
}
