import { AxiosResponse } from 'axios'
import {
  CreateBannerActionResponse,
  DeleteBannerActionResponse,
  getBannersActionResponse,
  UpdateBannerActionResponse,
} from './types'

import { HttpService } from '../../services'
import { BANNERS_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class BannerAPI extends HttpService {
  static getBanners(
    request: any,
  ): Promise<AxiosResponse<getBannersActionResponse>> {
    return this.request<getBannersActionResponse>('GET_BANNERS', {
      url: BANNERS_URL.bannersByQuery(request.section),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static createBanner({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateBannerActionResponse>> {
    return this.request<CreateBannerActionResponse>('CREATE_BANNER', {
      url: BANNERS_URL.rootPath,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateBanner({
    authorization,
    data,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<UpdateBannerActionResponse>> {
    return this.request<UpdateBannerActionResponse>('UPDATE_BANNER', {
      url: BANNERS_URL.bannersByQuery(id),
      method: 'PATCH',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteBanner({
    authorization,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<DeleteBannerActionResponse>> {
    return this.request<DeleteBannerActionResponse>('DELETE_BANNER', {
      url: BANNERS_URL.bannersByQuery(id),
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
