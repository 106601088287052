import { takeLatest, select, call, put } from '@redux-saga/core/effects'
import { getStylesAction, getStyleAction, createStyleAction } from './actions'

import { generateErrorToast, generateSuccessToast } from '../../helpers'
import { PromiseReturnType } from '../types'
import { StyleAPI } from './api.service'
import { showToastAction } from '../toasts'
import { getUserSelector } from '../user'

function* getStylesWorker() {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    const response: PromiseReturnType<ReturnType<typeof StyleAPI.getStyles>> =
      yield call([StyleAPI, StyleAPI.getStyles], {
        authorization: token,
      })
    yield put(getStylesAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)
    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категорий')),
    )
    yield put(getStylesAction.failure(e))
  }
}

function* getStyleByIDWorker({ payload }: { payload: { id: string } }) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )

    const response: PromiseReturnType<ReturnType<typeof StyleAPI.getStyle>> =
      yield call([StyleAPI, StyleAPI.getStyle], {
        authorization: token,
        id: payload.id,
      })

    yield put(getStyleAction.success(response.data))
  } catch (e) {
    console.log('Error: getCustomerWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка получения категории')),
    )

    yield put(getStyleAction.failure(e))
  }
}

function* createStyleWorker({
  payload,
}: ReturnType<typeof createStyleAction['request']>) {
  try {
    const { token }: ReturnType<typeof getUserSelector> = yield select(
      getUserSelector,
    )
    console.log(payload)
    const response: PromiseReturnType<ReturnType<typeof StyleAPI.createStyle>> =
      yield call([StyleAPI, StyleAPI.createStyle], {
        authorization: token,
        data: payload,
      })
    if (response) {
      const res: PromiseReturnType<ReturnType<typeof StyleAPI.getStyles>> =
        yield call([StyleAPI, StyleAPI.getStyles], {
          authorization: token,
        })
      yield put(createStyleAction.success(response.data))
      yield put(getStylesAction.success(res.data))
    }

    yield put(
      showToastAction.request(generateSuccessToast('Материал успешно создан')),
    )
  } catch (e) {
    console.log('Error: createStyleWorker', e)

    yield put(
      showToastAction.request(generateErrorToast('Ошибка создания материала')),
    )

    yield put(createStyleAction.failure(e))
  }
}

// function* deleteStyleWorker({ payload }: { payload: { id: string } }) {
//   try {
//     const { token }: ReturnType<typeof getUserSelector> = yield select(
//       getUserSelector,
//     )

//     const response: PromiseReturnType<
//       ReturnType<typeof StyleAPI.deleteStyle>
//     > = yield call([StyleAPI, StyleAPI.deleteStyle], {
//       authorization: token,
//       id: payload.id,
//     })

//     yield put(deleteStyleAction.success())

//     yield put(
//       showToastAction.request(generateInfoToast('Категория успешно удалена')),
//     )

//     yield put(getStylesAction.request())
//   } catch (e) {
//     console.log('Error: deleteStyleWorker', e)

//     yield put(
//       showToastAction.request(generateErrorToast('Ошибка удаления категории')),
//     )

//     yield put(deleteStyleAction.failure(e))
//   }
// }

export function* stylesWatcher() {
  yield takeLatest(getStylesAction.request, getStylesWorker)
  yield takeLatest(getStyleAction.request, getStyleByIDWorker)
  yield takeLatest(createStyleAction.request, createStyleWorker)
  // yield takeLatest(deleteStyleAction.request, deleteStyleWorker)
}
