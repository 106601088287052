import { Colors } from '../../assets'
import styled from 'styled-components'
import { FLEX, FONT, SHADOW } from '../../utils'
import { NavLink } from 'react-router-dom'

export const ColumnWrapper = styled.div<{ padding?: string }>`
  ${FLEX({
    direction: 'column',
    justify: 'flex-start',
    wrap: 'nowrap',
    align: 'flex-start',
  })}
  padding: ${({ padding = '0' }) => padding};
  width: 100%;
  border: 1px solid ${Colors.lightGray};
  border-radius: 24px;
  background: ${Colors.white};
  height: 100vh;
  overflow: hidden;
  ${SHADOW({})}
  &.responsive-height {
    height: 100%;
  }
`
export const RolesHeader = styled.div`
  ${FLEX({ direction: 'column' })}
  width: 100%;
  padding: 30px 30px 20px;
  gap: 30px;
  border-bottom: 1px solid ${Colors.lightGray};
`
export const RelativeInput = styled.input<{
  padding?: string
}>`
  width: 100%;
  height: 45px;
  border: ${Colors.gray} 1px solid;
  border-radius: 8px;
  padding: ${({ padding = '0px 10px' }) => padding};
  transition: width 0.3s linear;
  &::placeholder {
    color: ${Colors.gray};
  }
  &:focus {
    outline: none;
  }
  &.absolute:focus {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-49%);
    width: 330px;
  }
`
export const RelativeInputContainer = styled.div<{
  maxwidth?: string
}>`
  position: relative;
  width: 100%;
  max-width: ${({ maxwidth = '100%' }) => maxwidth};
  .search-icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const ColumnsGrid = styled.div<{ template: string }>`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: ${({ template }) => template};
  div {
    padding-right: 10px;
    line-height: 26px;
  }
  &.padding {
    padding: 0 30px;
  }
  .choose-product-img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  &.sort {
    color: ${Colors.boldGray};
    font-weight: 500;
  }
  &.border {
    border-bottom: 1px solid ${Colors.lightGray};
    padding: 0 0 10px;
  }
  &.banner-product {
    border-bottom: 1px solid ${Colors.lightGray};
    padding: 15px 0;
  }
  &.border-bottom {
    border-bottom: 1px solid ${Colors.lightGray};
    padding: 0 30px 5px;
  }
  .banner-img {
    width: 147px;
    height: 100px;
    object-fit: contain;
  }
  .sort-item {
    ${FLEX({
      align: 'center',
      justify: 'flex-start',
    })}
    gap: 6px;
    cursor: pointer;
  }
  &.header {
    color: ${Colors.boldGray};
    font-weight: 500;
    padding: 0 30px 20px;
    border-bottom: 1px solid ${Colors.lightGray};
    &.center {
      padding: 20px 30px;
    }
  }
  &.userPreview {
    border-bottom: 1px solid ${Colors.lightGray};
    height: 60px;
    min-height: 60px;
    padding: 0 30px;
  }
  &.banner {
    border-bottom: 1px solid ${Colors.lightGray};
    height: 110px;
    min-height: 110px;
    padding: 0 30px;
  }
  &.supplier-preview {
    border-bottom: 1px solid ${Colors.lightGray};
    height: 60px;
    min-height: 60px;
    padding: 0 30px;
  }
  * {
    align-self: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
export const FlexColumnsWrapper = styled.div`
  ${FLEX({ direction: 'column', justify: 'flex-start', wrap: 'nowrap' })}
  width: 100%;
  overflow: auto;
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &.responsive-height {
    height: fit-content;
  }
`
export const LinkWithIcon = styled.div<{ weight?: string }>`
  ${FLEX({ align: 'center', justify: 'flex-start', wrap: 'nowrap' })}
  gap: 8px;
  color: ${Colors.black};
  cursor: pointer;
  font-weight: ${({ weight = '400' }) => weight};
`
export const GrayTitle = styled.div`
  ${FONT({ size: '24px', color: Colors.boldGray, weight: '700' })};
`
export const ColumnFlex = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })}
  width: 100%;
  & .date {
    font-size: 14px;
    color: ${Colors.boldGray};
  }
`
export const UserProfileContent = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })};
  width: 100%;
`

export const ChapterMenu = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start' })};
  width: 100%;
  max-width: 360px;
  padding: 30px 0;
  align-self: flex-start;
  border-right: 1px solid ${Colors.lightGray};
  min-height: 100%;
`

export const ChapterMenuItem = styled.div`
  ${FLEX({ align: 'center', justify: 'flex-start' })};
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  height: 45px;
  font-weight: 500;
  &.active {
    background: ${Colors.lightOrange};
    border-right: 3px solid ${Colors.black};
  }
`

export const RolePermissions = styled.div`
  width: 100%;
  padding: 30px;
  align-self: flex-start;
`
export const RolePermissionsWrapper = styled.div`
  ${FLEX({ direction: 'column', align: 'flex-start', justify: 'flex-start' })};
  width: 100%;
`

export const PageTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`

export const SelectRow = styled.div`
  ${FLEX({ align: 'center', justify: 'flex-start', wrap: 'nowrap' })};
  gap: 15px;
  width: 100%;
  padding-bottom: 100px;
  .select-title {
    width: 160px;
    line-height: 19px;
  }
  .select-container {
    width: 100%;
    max-width: 300px;
  }
`

export const MaxWidthButton = styled.button<{
  bg: string
  txt: string
  height?: string
  maxwidth?: string
}>`
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  background: ${({ bg }) => bg};
  color: ${({ txt }) => txt};
  height: ${({ height = '45px' }) => height};
  width: 100%;
  max-width: ${({ maxwidth = '100%' }) => maxwidth};
  ${FLEX({})};
  gap: 10px;
  outline: none;
  border: none;
  &:hover {
    box-shadow: 0px 0px 5px ${({ bg }) => bg};
  }
`
export const ModalInputRow = styled.div`
  ${FLEX({ justify: 'flex-start' })};
  width: 630px;
  gap: 20px 30px;
  label {
    align-self: flex-end;
  }
  .role-wrapper {
    align-self: flex-start;
  }
`
export const ItemNavLink = styled(NavLink)`
  ${FLEX({ justify: 'center', align: 'center' })};
  height: 36px;
  width: 36px;
  border-radius: 4px;
  background: ${Colors.orange};
  img {
    width: 22px;
  }
  &.justify-end {
    justify-self: end;
    margin-left: auto;
  }
`
