import { AxiosResponse } from 'axios'

import {
  GetProductsActionResponse,
  GetProductActionResponse,
  CreateProductActionResponse,
  UpdateProductActionResponse,
  DeleteProductActionResponse,
  SearchProductByTitleActionResponse,
  SearchProductByDateActionResponse,
  SortProductsByQueryActionResponse,
  GetFilteredProductsActionResponse,
  GetPendingProductsActionResponse,
  UpdateProductStatusActionPayload,
} from './types'

import { HttpService } from '../../services'
import { PRODUCTS_URL } from './config'
import { setAuthorizationHeadersProp } from '../../helpers'
import { PayloadAuth } from '../types'

export class ProductAPI extends HttpService {
  static getProducts({
    authorization,
  }): Promise<AxiosResponse<GetProductsActionResponse>> {
    return this.request<GetProductActionResponse>('GET_PRODUCTS', {
      url: PRODUCTS_URL.limitlessPath,
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getProductsByQueryParams({
    authorization,
    data,
  }): Promise<AxiosResponse<GetPendingProductsActionResponse>> {
    return this.request<GetPendingProductsActionResponse>(
      'GET_PRODUCTS_BY_QUERY_PARAMS',
      {
        url: PRODUCTS_URL.queryParamsPath(data),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static getMyProducts({
    authorization,
    data,
  }): Promise<AxiosResponse<GetPendingProductsActionResponse>> {
    return this.request<GetPendingProductsActionResponse>('GET_MY_PRODUCTS', {
      url: PRODUCTS_URL.getMyProducts(data),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static getMyPendingProducts({
    authorization,
    data,
  }): Promise<AxiosResponse<GetPendingProductsActionResponse>> {
    return this.request<GetPendingProductsActionResponse>(
      'GET_MY_PENDING_PRODUCTS',
      {
        url: PRODUCTS_URL.getMyPendingProducts(data),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static getPendingProducts({
    authorization,
    data,
  }): Promise<AxiosResponse<GetPendingProductsActionResponse>> {
    return this.request<GetPendingProductsActionResponse>(
      'GET_PENDING_PRODUCTS',
      {
        url: PRODUCTS_URL.pendingProducts(data),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static getProduct(
    request: any,
  ): Promise<AxiosResponse<GetProductActionResponse>> {
    return this.request<GetProductActionResponse>('GET_PRODUCT', {
      url: PRODUCTS_URL.idPath(request.id),
      method: 'GET',
      headers: {
        ...setAuthorizationHeadersProp(request.authorization),
      },
    })
  }

  static getFilteredProducts(
    request: any,
  ): Promise<AxiosResponse<GetFilteredProductsActionResponse>> {
    return this.request<GetFilteredProductsActionResponse>(
      'GET_FILTERED_PRODUCTS',
      {
        url: PRODUCTS_URL.queryFilterPath(request.query),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(request.authorization),
        },
      },
    )
  }

  static createProduct({
    authorization,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<CreateProductActionResponse>> {
    console.log(data)
    return this.request<CreateProductActionResponse>('CREATE_PRODUCT', {
      url: PRODUCTS_URL.rootPath,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateProduct({
    authorization,
    id,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<UpdateProductActionResponse>> {
    console.log(data)
    return this.request<UpdateProductActionResponse>('UPDATE_PRODUCT', {
      url: PRODUCTS_URL.idPath(id),
      method: 'PUT',
      data: { ...data },
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static addProductPhoto({
    authorization,
    id,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<UpdateProductActionResponse>> {
    console.log(data)
    return this.request<UpdateProductActionResponse>('UPDATE_PRODUCT_PHOTO', {
      url: PRODUCTS_URL.idPathPhoto(id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static deleteProductPhoto({
    authorization,
    id,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<UpdateProductActionResponse>> {
    console.log(data, id)
    return this.request<UpdateProductActionResponse>('DELETE_PRODUCT_PHOTO', {
      url: PRODUCTS_URL.idPathPhoto(id),
      method: 'DELETE',
      data: { photo: data },
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static updateProductPhotoOrder({
    authorization,
    id,
    data,
  }: PayloadAuth & any): Promise<AxiosResponse<UpdateProductActionResponse>> {
    console.log(data)
    return this.request<UpdateProductActionResponse>(
      'UPDATE_PRODUCT_PHOTO_ORDER',
      {
        url: PRODUCTS_URL.idPathPhotoOrder(id),
        method: 'PUT',
        data,
        headers: {
          'Content-Type': 'application/json',
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static deleteProduct({
    authorization,
    id,
  }: PayloadAuth & any): Promise<AxiosResponse<DeleteProductActionResponse>> {
    console.log(authorization, id)
    return this.request<DeleteProductActionResponse>('DELETE_PRODUCT', {
      url: PRODUCTS_URL.idPath(id),
      method: 'DELETE',
      headers: {
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }

  static searchProductByTitle({
    authorization,
    title,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SearchProductByTitleActionResponse>
  > {
    console.log(authorization, title)
    return this.request<SearchProductByTitleActionResponse>(
      'SEARCH_PRODUCT_BY_TITLE',
      {
        url: PRODUCTS_URL.titleSearchPath(title),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static searchProductByDate({
    authorization,
    from,
    to,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SearchProductByDateActionResponse>
  > {
    return this.request<SearchProductByDateActionResponse>(
      'SEARCH_PRODUCT_BY_DATE',
      {
        url: PRODUCTS_URL.dateSearchPath(from, to),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static sortProductsByQuery({
    authorization,
    query,
  }: PayloadAuth & any): Promise<
    AxiosResponse<SortProductsByQueryActionResponse>
  > {
    return this.request<SortProductsByQueryActionResponse>(
      'SORT_PRODUCT_BY_QUERY',
      {
        url: PRODUCTS_URL.querySortPath(query),
        method: 'GET',
        headers: {
          ...setAuthorizationHeadersProp(authorization),
        },
      },
    )
  }

  static updateProductStatus({
    authorization,
    data,
    version,
    id,
  }: PayloadAuth & any): Promise<
    AxiosResponse<UpdateProductStatusActionPayload>
  > {
    return this.request<UpdateProductStatusActionPayload>('UPDATE_PRODUCT', {
      url: PRODUCTS_URL.updateProductStatus(version, id),
      method: 'PUT',
      data,
      headers: {
        'Content-Type': 'application/json',
        ...setAuthorizationHeadersProp(authorization),
      },
    })
  }
}
